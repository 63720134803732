import React from 'react';
import cn from 'classnames';
import validationTableStyle from './validationTable.scss';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import * as correctionTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/correctionTypes';

/**
 * @param {string} correctionType
 * @returns {string}
 */
const getCellColorByCorrectionType = (correctionType) => {
  switch (correctionType) {
    case correctionTypes.duplicate:
    case correctionTypes.error:
    case correctionTypes.invalid:
    case correctionTypes.deprecated:
    case correctionTypes.notTrusted:
    case correctionTypes.notFound:
    case correctionTypes.notFoundInParent:
    case correctionTypes.mustLengthFail:
    case correctionTypes.maxLengthFail:
    case correctionTypes.rangeFail:
    case correctionTypes.requiredColumn:
    case correctionTypes.dependentColumn:
    case correctionTypes.dateFail:
    case correctionTypes.numberFail:
    case correctionTypes.numberOrFloatFail:
    case correctionTypes.floatFail:
      return validationTableStyle.cellRedColor;
    case correctionTypes.companyLead:
    case correctionTypes.companyParent:
    case correctionTypes.companySelect:
    default:
      return validationTableStyle.cellDefaultColor;
  }
};

/**
 * @param {object} cell
 * @param {object} headerCell
 * @param {number} rowIndex
 * @param {string} columnIndex
 * @param {function} getCellValueByType
 * @returns {string|*}
 */
export const getCellContent = (
  cell,
  headerCell,
  rowIndex,
  columnIndex,
  getCellValueByType,
) => (
  <Tooltip
    type={ getCellStyle(cell) === validationTableStyle.cellRedColor ? 'error' : 'default' }
    disabled={ headerCell.numberColumn || !cell.correctionType }
    content={ getTooltip(cell, headerCell, rowIndex, parseInt(columnIndex)) }
  >
    <div className={ getCellStyle(cell) }>{ getCellValueByType(cell) }</div>
  </Tooltip>
);

/**
 * @param {object} cell
 * @returns {string | *}
 */
export const getCellValue = (cell) => {
  if (cell.value) {
    return cell.value;
  }

  if (cell.correctionType) {
    return '{empty}';
  }

  return '';
};

const getTooltipByCell = (cell) => {
  switch (cell.correctionType) {
    case correctionTypes.duplicate:
      return 'is duplicate';
    case correctionTypes.error:
      return 'error';
    case correctionTypes.invalid:
      return 'invalid';
    case correctionTypes.deprecated:
      return 'is deprecated';
    case correctionTypes.notTrusted:
      return 'is not trusted';
    case correctionTypes.notFound:
      return 'not found';
    case correctionTypes.notFoundInParent:
      return 'not found in parent';
    case correctionTypes.mustLengthFail:
      return `must have ${ cell.data.mustLength } characters`;
    case correctionTypes.maxLengthFail:
      return `must have ${ cell.data.maxLength } characters`;
    case correctionTypes.rangeFail:
      return `value must be in the range from ${ cell.data.minValue } to ${ cell.data.maxValue }`;
    case correctionTypes.requiredColumn:
      return 'is required';
    case correctionTypes.dependentColumn:
      return `must set for ${ cell.data.depends }`;
    case correctionTypes.dateFail:
      return 'not valid date';
    case correctionTypes.numberFail:
      return 'not a number';
    case correctionTypes.numberOrFloatFail:
      return `must have ${ cell.data.mustIntegerLength } integer characters and if exists decimal, that -${
        cell.data.mustDecimalLength } decimal`;
    case correctionTypes.floatFail:
      return 'not a decimal';
    case correctionTypes.companyLead:
      return 'not matched';
    case correctionTypes.companyParent:
      return 'not matched';
    case correctionTypes.companySelect:
      return 'not matched';
    default:
      return cell.correctionType;
  }
};

/**
 * @param {object} cell
 * @param {object} headerCell
 * @param {number} bodyRowIndex
 * @param {number} bodyColumnIndex
 * @returns {JSX.Element}
 */
const getTooltip = (cell, headerCell, bodyRowIndex, bodyColumnIndex) => (
  <div className={ validationTableStyle.tooltipContainer }>
    <div><span>Cell: </span>{ toColumnName(bodyColumnIndex) }{ bodyRowIndex }</div>
    <div><span>Name: </span>{ headerCell.name }</div>
    <div><span>Value: </span>{ getCellValue(cell) }</div>
    <div><span>Error: </span>{ getTooltipByCell(cell) }</div>
  </div>
);

/**
 * Takes a positive integer and returns the corresponding column name.
 * @param {number} num  The positive integer to convert to a column name.
 * @return {string} The column name.
 */
const toColumnName = (num) => {
  let ret = '';
  // eslint-disable-next-line no-cond-assign
  for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
};

/**
 * @param {object} cell
 * @returns {string}
 */
const getCellStyle = (cell) => cn(
  getCellColorByCorrectionType(cell.correctionType),
);
