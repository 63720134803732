import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import stripTags from '@/Framework/dom/stripTags';
import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { IStatPayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStatPayload';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST } from '@/condor/ui/common/constants';
import { defaultInitialFormData } from './constants';
import { IStat } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import isEmpty from 'lodash/isEmpty';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import { getInitialStatData } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/helpers';

interface IUseStatProfile {
  statId?: string,
  setTitle: (title: string) => void,
}

const useStatProfile = ({ statId, setTitle }: IUseStatProfile) => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const [initialFormData, setInitialFormData] = useState<StatFormData>(defaultInitialFormData);
  const { regions } = useRegionsProductsContext();
  const [isInitialFetching, setIsInitialFetching] = useState(!!statId);
  const [initialStat, setInitialStat] = useState<IStat>(null);
  const [isAttachmentUploaded, setIsAttachmentUploaded] = useState(true);
  const [isPreviewUploaded, setIsPreviewUploaded] = useState(true);
  const { push } = useHistory();
  const [previewUploadId, setPreviewUploadId] = useState<string>('');

  const createOrUpdateStat = async (values: StatFormData) => {
    const attachments = values.attachments
      ? values.attachments.map(({ uuid, name }) => ({ uploadId: uuid, displayName: name }))
      : [];
    const payload: IStatPayload = {
      name: values.name,
      description: stripTags(values.description) ? values.description : null,
      isActive: values.isActive,
      previewUploadId: values.preview ? previewUploadId : null,
      attachment: attachments.length ? attachments[0] : null,
      categories: values.categories.map((category) => category.value),
      id: values.id || null,
    };

    try {
      if (payload.id) {
        await creditFlowResearchRepository.updateStat(payload);
      } else {
        await creditFlowResearchRepository.createStat(payload);
      }
      NotificationManager.success(`Stat has been successfully ${ payload.id ? 'updated' : 'saved' }`);
      push(CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST);
      return false;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return true;
    }
  };

  const getStatById = async (id: string) => {
    try {
      setIsInitialFetching(true);
      const [stat, categoriesList] = await Promise.all([
        creditFlowResearchRepository.getStatById(id),
        creditFlowResearchRepository.getStatsCategories<ICategory[]>(),
      ]);

      setTitle(stat.name);
      setInitialStat(stat);
      setPreviewUploadId(stat.preview?.uploadId);
      setInitialFormData(getInitialStatData(stat, categoriesList, regions));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsInitialFetching(false);
    }
  };

  const onProcessingDone = (data) => {
    setPreviewUploadId(data.data[0]);
    return {
    ...data,
      data: [data.presignedUrls[0]],
    };
  };

  useEffect(() => {
    if (!isEmpty(regions) && statId) {
      getStatById(statId);
    }
  }, [statId, regions]);

  return {
    isEdit: !!statId,
    isInitialFetching,
    isActive: initialStat?.isActive ?? false,

    initialStat,
    createOrUpdateStat,
    initialFormData,
    isAttachmentUploaded,
    setIsAttachmentUploaded,
    isPreviewUploaded,
    setIsPreviewUploaded,
    onProcessingDone,
  };
};

export default useStatProfile;
