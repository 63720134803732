import React from 'react';
import cn from 'classnames';

import Link from '@/Framework/Router/ReactRouter/Link';
import CallDateCell from './CallDateCell';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import StatusIndicator from '@dealroadshow/uikit/core/components/StatusIndicator';

import CallEvent from '@/evercall/domain/CallEvent';
import { CallsListFilterValues } from '@/evercall/application/condor/call/collection/constants';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import { CONDOR_EVERCALL_PROFILE, CONDOR_EVERCALL_GROUP_PROFILE } from '@/condor/ui/common/constants';

import styles from './callsList.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

export const PARTICIPANT_CONFERENCE_ID_LABEL = 'Participant Conference ID (TurboBridge ID)';
export const PARTICIPANT_CONFERENCE_ID_COLUMN_TOOLTIP = 'By adding this ID, this will enable an email input in the Event Registration page';

export const CONDOR_EVERCALL_LIST_COLUMNS = [
  {
    name: 'status',
    title: 'Status',
    width: 68,
    maxWidth: 68,
    className: styles.statusCell,
    cellCallback: ({ cellData }) => (
      <div className={ styles.statusContainer }>
        <Tooltip content={ `${ cellData.slice(0, 1) }${ cellData.slice(1).toLowerCase() }` }>
          <StatusIndicator
            className={ cn(
              styles.status,
              {
                [styles.expiredCallStatus]: CallEvent.isExpired(cellData),
                [styles.liveCallStatus]: CallEvent.isLive(cellData),
                [styles.pendingCallStatus]: CallEvent.isPending(cellData),
              },
            ) }
          />
        </Tooltip>
      </div>
    ),
  },
  {
    name: 'name',
    title: 'Name',
    sortable: true,
    cellCallback: ({ row }) => (
      <Tooltip content="Open Profile">
        <Link to={ `${ CONDOR_EVERCALL_PROFILE }/${ row.id }/settings` }>
          { row.name }
        </Link>
      </Tooltip>
    ),
  },
  {
    name: 'groupName',
    title: 'Group',
    sortable: true,
    cellCallback: ({ row, cellData }) => {
      if (!cellData) {
        return '-';
      }
      return (
        <Tooltip content="Open Group Profile">
          <Link to={ `${ CONDOR_EVERCALL_GROUP_PROFILE }/${ row.groupId }` }>
            { row.groupName }
          </Link>
        </Tooltip>
      );
    },
  },
  {
    name: 'roadshowId',
    title: 'Roadshow ID',
    cellCallback: commonCellCallback,
  },
  {
    name: 'participantConferenceId',
    title: (
      <>
        <span>{ PARTICIPANT_CONFERENCE_ID_LABEL }</span>
        <span className={ dataTableStyles.dataTableHeaderIcon }>
          <Tooltip
            content={ PARTICIPANT_CONFERENCE_ID_COLUMN_TOOLTIP }
            containerClassName={ styles.conferenceIdTooltipContainer }
          >
            <IconQuestion />
          </Tooltip>
        </span>
      </>
    ),
    className: alignStyles.alignRight,
    width: 280,
    cellCallback: commonCellCallback,
  },
  {
    name: 'estimatedDateTime',
    title: 'Call Date ET',
    sortable: true,
    minWidth: 180,
    width: 180,
    className: cn(alignStyles.alignRight, styles.callDateCell),
    cellCallback: CallDateCell,
  },
];

export const CONDOR_EVERCALL_LIST_FILTERS_RADIO_OPTIONS: Array<{ value: CallsListFilterValues, label: string }> = [
  {
    value: CallsListFilterValues.Hidden,
    label: 'Hidden',
  },
  {
    value: CallsListFilterValues.Deleted,
    label: 'Deleted',
  },
  {
    value: CallsListFilterValues.All,
    label: 'All',
  },
];
