import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  getCondorFinsightCompaniesByFilter,
  setCondorFinsightSearch,
  paginate,
} from '@/condor/application/actions/finsight/company/collection/actions';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput';
import selectors from '@/condor/application/actions/finsight/company/collection/selectors';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
  }
);

const mapDispatchToProps = (dispatch) => {
  const searchDebounced = debounce(() => dispatch(getCondorFinsightCompaniesByFilter()), 500);
  return {
    onChange: (e) => {
      dispatch(setCondorFinsightSearch(e.target.value));
      dispatch(paginate(1));
      searchDebounced();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
