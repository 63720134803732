import AttachmentItem from '@/condor/ui/components/Finsight/CreditFlowResearch/common/PreviewModalComponents/AttachmentItem';

import styles from './styles.scss';

export const Attachments = ({ attachments }) => {
  if (attachments.length === 0) {
    return null;
  }

  return (
    <div>
      <p className={ styles.attachmentLabel }>Attachments</p>
      {
        attachments.map((attachment) => (
          <AttachmentItem key={ attachment.uploadId } attachment={ attachment } />
        ))
      }
    </div>
  );
};
