import { ReactNode } from 'react';
import { GroupBase, Props } from 'react-select';
import { ISelectCustomProps, ISelectOption, Select } from '@dealroadshow/uikit/core/components/Select';
import MenuList from './MenuList';
import MultiValue from './MultiValue';

import styles from './styles.scss';

type TGroupSelectProps<
  SOption extends ISelectOption,
  Group extends GroupBase<SOption>,
> = ISelectCustomProps<SOption, true> & Props<SOption, true, Group>;

export interface IGroupSelectWithToolbarProps<
  SOption extends ISelectOption,
  Group extends GroupBase<SOption>,
> extends TGroupSelectProps<SOption, Group> {
  toolbarComponent?: () => ReactNode,
  formatValue?: (data: SOption) => ReactNode,
}

const GroupSelectWithToolbar = <
  SOption extends ISelectOption = ISelectOption,
  Group extends GroupBase<SOption> = GroupBase<SOption>,
>({
  formatValue,
  components,
  toolbarComponent,
  ...otherProps
}: IGroupSelectWithToolbarProps<SOption, Group>) => (
  <Select<SOption, true, Group>
    components={ {
      MenuList: (props) => (
        <MenuList<SOption, true, Group>
          toolbarComponent={ toolbarComponent }
          ownProps={ props }
        />
      ),
      MultiValue: (props) => (
        <MultiValue ownProps={ props } formatValue={ formatValue } />
      ),
      ...components,
    } }
    hideSelectedOptions={ false }
    closeMenuOnSelect={ false }
    optionClassName={ styles.selectOption }
    isMulti
    isGrouped
    { ...otherProps }
  />
);

export default GroupSelectWithToolbar;
