import React, { Component } from 'react';
import { Field } from 'redux-form';
import { RadioGroup, Radio } from '@dealroadshow/uikit/core/components/Radio';
import styles from './ViewerButtonTypeSwitch.scss';
import * as dealSettingsFormConstants from '../../constants';

interface IProps {
  fieldName?: string,
}

class CustomButtonTypeSwitch extends Component<IProps, any> {
  /**
   * @param {Object} nextProps
   */
  shouldComponentUpdate() {
    return false;
  }

  /**
   * @param {Object} fieldProps
   * @return {Node}
   */
  renderSwitch(fieldProps) {
    return (
      <div>
        <RadioGroup
          { ...fieldProps.input }
          selectedValue={ fieldProps.input.value }
          className={ styles.switcherWrp }
          dataTest="customButtonTypeSwitchRadio"
        >
          <Radio
            label="Deal Files"
            dataTest="dealFilesRadio"
            value={ dealSettingsFormConstants.DEAL_FILES }
            className={ styles.switcherRadio }
          />
          <Radio
            label="External Link"
            dataTest="externalLinkRadio"
            value={ dealSettingsFormConstants.EXTERNAL_LINKS }
            className={ styles.switcherRadio }
          />
        </RadioGroup>
      </div>
    );
  }

  render() {
    return (
      <Field
        name="topButtonType"
        label="Entry Code"
        component={ this.renderSwitch }
      />
    );
  }
}

export default CustomButtonTypeSwitch;
