import { memo } from 'react';
import cn from 'classnames';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import { buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import LastLoginAt from '@/Framework/UI/Molecules/Tables/CellCallbacks/LastLoginAt';
import TimeZoneAbbr from '@/Framework/TimeZone/TimeZoneAbbr';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import auditTrail from '../auditTrail.scss';

interface IProps {
  emptyMessageClassName?: string,
  dataTableContainerClassName?: string,
  collection: { [key: string]: any }[],
  isFetching: boolean,
  isAdmin: boolean,
  sortBy: string,
  sortOrder: string,
  onSortChange: (sortBy: string, sortOrder: string) => void,
  onShowEventDetailModal: (event: any) => void,
  timeZone: ITimezone,
  onChangeAccessType: (id: string, accessType: string) => void,
  columnsSettings?: { [key: string]: any },
  dataTest?: string,
}

const ACTION_MESSAGE_LENGTH = 120;

const AuditTrailTable = ({
  emptyMessageClassName,
  dataTableContainerClassName,
  collection,
  isFetching,
  isAdmin,
  sortBy,
  sortOrder,
  onSortChange,
  onShowEventDetailModal,
  timeZone,
  onChangeAccessType,
  columnsSettings,
  dataTest,
}: IProps) => {
  /**
   * Truncates message and removes the last incorrect html tag from truncated message.
   * @param {String} message
   * @return {String}
   */
  const getTruncatedMessage = (message) => (
    `${ message.slice(0, ACTION_MESSAGE_LENGTH)
      .replace(/(<([^>]*))$/gi, '') }...`
  );

  const columns = [
    {
      name: 'actionMessage',
      title: 'Event',
      minWidth: 300,
      sortable: true,
      cellCallback: (cellProps) => {
        const message = cellProps.row.actionMessage;

        if (!message) {
          return '-';
        }

        if (message.length > ACTION_MESSAGE_LENGTH) {
          return (
            <>
              <span
                data-test="message"
                className={ auditTrail.truncateMessage }
                dangerouslySetInnerHTML={ { __html: getTruncatedMessage(message) } }
              />
              <span
                className={ linkStyles.link }
                onClick={ () => onShowEventDetailModal(cellProps.row) }
                data-test="showMoreButton"
              >
                Show more
              </span>
            </>
          );
        }

        return <span data-test="message" dangerouslySetInnerHTML={ { __html: message } } />;
      },
    },
    {
      name: 'userEmail',
      title: 'Email',
      minWidth: columnsSettings?.userEmail?.minWidth || 200,
      sortable: true,
    },
    {
      name: 'ipAddress',
      minWidth: columnsSettings?.ipAddress?.minWidth || 120,
      title: 'IP',
      className: cn({
        [alignStyles.alignRight]: !isAdmin,
      }),
      cellCallback: (cellProps) => {
        const cityAndCountryName = [cellProps.row.cityName, cellProps.row.countryName]
          .filter((item) => item);
        const isTooltipDisabled = !cityAndCountryName.length;
        const tooltipContent = cityAndCountryName.join(', ');
        return (
          <Tooltip
            content={ tooltipContent }
            disabled={ isTooltipDisabled }
          >
            { cellProps.cellData || '–' }
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: 'occurredAt',
      title: (
        <>Timestamp (<TimeZoneAbbr abbr={ timeZone?.abbr } />)</>
      ),
      minWidth: columnsSettings?.occurredAt?.minWidth || 170,
      className: alignStyles.alignRight,
      sortable: true,
      cellCallback: (cellProps) => (
        <LastLoginAt
          value={ cellProps.cellData }
          timeZone={ timeZone.timeZone }
        />
      ),
    },
    {
      name: 'accessType',
      title: 'View',
      minWidth: 70,
      sortable: true,
      isVisible: isAdmin,
      className: alignStyles.alignRight,
      cellCallback: (cellProps) => {
        const reverseType = cellProps.row.accessType === 'External' ? 'Internal' : 'External';
        return (
          <div className={ auditTrail.accessType }>
            { cellProps.row.accessType }
            <button
              type="button"
              onClick={ () => onChangeAccessType(cellProps.row.id, reverseType) }
              className={ cn(
                buttonStyles.action,
                auditTrail.hide,
                auditTrail.btn,
              ) }
              data-test="auditTrailReverseButton"
            >
              Make { reverseType }
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <DataTable
      data={ collection }
      columns={ columns }
      isFetching={ isFetching }
      sortBy={ sortBy }
      sortOrder={ sortOrder }
      onSortChange={ onSortChange }
      className={ dataTableStyles.isHoverable }
      containerClassName={ cn(dataTableStyles.dataTableContainer, dataTableContainerClassName) }
      emptyMessageClassName={ cn(dataTableStyles.emptyMessage, emptyMessageClassName) }
      loadingComponent={ Spinner }
      dataTest={ dataTest }
    />
  );
};

export default memo(AuditTrailTable);
