import cn from 'classnames';

import {
  sectorIndustryCellCallback,
  headlineCellCallback,
  updatedAtCellCallback,
} from './cellCallbacks';

import {
  isActiveColumn,
  commonColumns,
} from '@/condor/ui/components/Finsight/CreditFlowResearch/common/listComponents/columns';
import TagsCell from '@/finsight/ui/common/components/TableCells/TagsCell';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

export default [
  isActiveColumn,
  {
    name: 'sectorOrIndustry',
    title: 'Sec/Ind',
    minWidth: 66,
    maxWidth: 66,
    width: 66,
    cellCallback: sectorIndustryCellCallback,
  },
  {
    name: 'headline',
    title: 'Headline',
    maxWidth: 757,
    width: 757,
    cellCallback: headlineCellCallback,
  },
  {
    name: 'themes',
    title: 'Themes',
    maxWidth: 250,
    minWidth: 250,
    cellCallback: (cellProps) => <TagsCell { ...cellProps } />,
    className: alignStyles.alignRight,
  },
  ...commonColumns,
  {
    name: 'updatedAt',
    title: 'Last Updated',
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
    className: cn(spacesStyles.pln, alignStyles.alignRight),
    cellCallback: updatedAtCellCallback,
  },
];
