import Router from 'next/router';

const decorateQuery = (
  pathname: string,
  query: { [key: string]: string },
) => {
  const nextPath = pathname;
  const nextPathQuery = decodeURIComponent(pathname).split('?')[1];
  const nextQuery = new URLSearchParams(nextPathQuery || '');
  nextQuery.delete('search');

  const { callbackUrl, tenant, customParams, SAMLRequest, RelayState } = query;
  !!callbackUrl && nextQuery.set('callbackUrl', callbackUrl);
  !!tenant && nextQuery.set('tenant', tenant);
  !!customParams && nextQuery.set('customParams', customParams);
  !!SAMLRequest && nextQuery.set('SAMLRequest', SAMLRequest);
  !!RelayState && nextQuery.set('RelayState', RelayState);

  return { pathname: nextPath, query: nextQuery.toString() };
};

const getRouter = () => {
  const UsersRouter = Object.create(Router);
  const { query } = UsersRouter;

  UsersRouter.push = (pathname) => Router.push.call(UsersRouter, decorateQuery(pathname, query));
  UsersRouter.replace = (pathname) => Router.replace.call(UsersRouter, decorateQuery(pathname, query));

  return UsersRouter;
};

export default getRouter;
