import React, { useCallback } from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';

import styles from './deleteTrancheButton.scss';

interface IProps {
  index: number,
  isExcluded: boolean,
  excludeTranche: (index: number) => void,
  undoExcludeTranche: (index: number) => void,
}

const DeleteTrancheButton = ({
  index,
  isExcluded,
  excludeTranche,
  undoExcludeTranche,
}: IProps) => {
  const onClick = useCallback(
    () => (!isExcluded ? excludeTranche(index) : undoExcludeTranche(index)),
    [isExcluded],
  );

  return (
    <Tooltip content={ `${ isExcluded ? 'Undo ' : '' }Delete` }>
      <Button
        variant={ ButtonVariantType.text }
        className={ styles.deleteTrancheBtn }
        onClick={ onClick }
        dataTest={ `deleteTrancheButton${ index }` }
      >
        {
          isExcluded
            ? <IconRotate className={ styles.deleteTrancheIcon } />
            : <IconTrash className={ styles.deleteTrancheIcon } />
        }
      </Button>
    </Tooltip>
  );
};

export default DeleteTrancheButton;
