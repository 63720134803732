import React from 'react';

import { CONDOR_CONTACT_LINKS_BASE_URL, CONDOR_CONTACT_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import IconLink from '@dealroadshow/uikit/core/components/Icon/IconLink';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';

interface IProps {
  cellProps: ICellProps,
  isSelectedForEditing: boolean,
  isUserUpdating: boolean,
  onEditClick: () => void,
  onSubmit: () => void,
  onReset: () => void,
  inlineEditFormState: {
    submitting: boolean,
    pristine: boolean,
    invalid: boolean,
  },
}

const LastLoginAtCell = (props: IProps) => {
  const actions = props.isSelectedForEditing ? [
    {
      tooltipContent: 'Save',
      icon: IconCheck,
      onClick: props.onSubmit,
      disabled: props.inlineEditFormState.submitting ||
        props.inlineEditFormState.pristine ||
        props.inlineEditFormState.invalid,
      dataTest: 'saveActionButton',
    },
    {
      tooltipContent: 'Cancel',
      icon: IconCancel,
      onClick: props.onReset,
      dataTest: 'cancelActionButton',
    },
  ] : [
    {
      tooltipContent: 'Edit',
      icon: IconPencil,
      onClick: props.onEditClick,
      dataTest: 'editActionButton',
    },
    {
      url: `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ props.cellProps.row.id }`,
      tooltipContent: 'Profile',
      icon: IconSettings,
      dataTest: 'profileActionButton',
    },
    {
      url: `${ CONDOR_CONTACT_LINKS_BASE_URL }/${ props.cellProps.row.id }`,
      tooltipContent: 'Links',
      icon: IconLink,
      dataTest: 'linksActionButton',
    },
  ];

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUserUpdating
          ? <TableRowActions actions={ actions } />
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    >
      { getCellDisplayDateFromString(props.cellProps.row.lastLoginAt) }
    </LastTableCell>
  );
};

export default LastLoginAtCell;
