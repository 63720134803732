import { connect } from 'react-redux';

import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';

import { sort, paginate, getDealsByFilter } from '@/condor/application/actions/finsight/dealNoPricingDate/actions';
import selectors from '@/condor/application/actions/finsight/dealNoPricingDate/selectors';

import columns from './columns';

const mapStateToProps = (state) => (
  {
    data: selectors.getCollectionData(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    columns,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({
        sortBy,
        sortOrder,
      }));
      dispatch(paginate(1));
      dispatch(getDealsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataTable);
