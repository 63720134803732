import { useEffect, useState } from 'react';
import cn from 'classnames';

import ManageStatsCategoriesContextProvider,
{ useManageCategoriesContext }
  from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsList/ManageStatsCategoriesContext';
import Tabs, { Tab } from '@dealroadshow/uikit/core/components/Tabs';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { RadioGroup, Radio } from '@dealroadshow/uikit/core/components/Radio';
import FinalForm, { FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import ManageCategoriesFields from './ManageCategoriesFields';
import { getSaveCategoriesPayload } from './helpers';

import styles from './styles.scss';
import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';

const CategoriesForm = ({ closeModal }: { closeModal: () => void }) => {
  const [isControlsDisabled, setIsControlsDisabled] = useState(false);
  const {
    productId,
    regionId,
    setRegionId,
    setProductId,
    isCategoriesInitialized,
    categories,
    submitForm,
    isSubmitting,
  } = useManageCategoriesContext();

  useEffect(() => {
    setIsControlsDisabled(isSubmitting);
  }, [isSubmitting]);

  const { products, regions } = useRegionsProductsContext();
  if (!isCategoriesInitialized) {
    return <Spinner isVisible isAbsolute overlay />;
  }
  const onTabClick = (productId: string) => !isControlsDisabled && setProductId(productId);

  const renderFields = ({ invalid }) => {
    if (invalid !== isControlsDisabled) {
      setIsControlsDisabled(invalid);
    }
    return (
      <FieldArray
        name="categoriesList"
        component={ ManageCategoriesFields }
        closeModal={ closeModal }
      />
    );
  };

  const onSaveClick = async (values: { categoriesList: ICategory[] }) => {
    const payload = {
      categories: getSaveCategoriesPayload(values.categoriesList),
    };
    const isSubmitted = await submitForm(payload);
    if (isSubmitted) {
      closeModal();
    }
  };

  const tabsCls = cn(styles.tabs, { [styles.isDisabled]: isControlsDisabled });
  const tabCls = cn(tabsStyles.tab, { [tabsStyles.isTabDisabled]: isControlsDisabled });

  return (
    <div className={ styles.modalForm }>
      <Tabs
        className={ tabsCls }
        defaultTab={ productId }
        dataTest="manageStatsCategoriesProductTabs"
      >
        { products.map(({ id, abbreviation }) => {
          if (abbreviation === 'LM') {
            return null;
          }
          return (
            <Tab
              key={ id }
              onClick={ () => onTabClick(id) }
              tabFor={ id }
              className={ tabCls }
              activeClassName={ tabsStyles.isTabActive }
              name={ abbreviation }
            >
              { abbreviation }
            </Tab>
          );
        }) }
      </Tabs>

      <div className={ tabsStyles.tabsFooter } />
      <div className={ styles.modalRegions }>
        <RadioGroup
          selectedValue={ regionId }
          onChange={ setRegionId }
          fieldClassName={ spacesStyles.mbn }
          dataTest="manageStatsCategoriesRegionRadioGroup"
          disabled={ isControlsDisabled }
        >
          { regions.map(({ id, abbreviation }) => (
            <Radio
              key={ id }
              label={ abbreviation }
              value={ id }
              dataTest={ `manageStatsCategoriesRegionRadioGroupItem_${ abbreviation }` }
            />
      )) }
        </RadioGroup>
      </div>
      <FinalForm
        name="categoriesForm"
        dataTest="categoriesForm"
        render={ renderFields }
        onSubmit={ onSaveClick }
        initialValues={ { categoriesList: categories } }
      />
      { isSubmitting && (
      <Spinner
        isVisible
        isAbsolute
        overlay
      />
  ) }
    </div>
  );
};

export default ({ closeModal }) => (
  <ManageStatsCategoriesContextProvider>
    <CategoriesForm closeModal={ closeModal } />
  </ManageStatsCategoriesContextProvider>
);
