import { connect } from 'react-redux';
import { change } from 'redux-form';
import DeleteTrancheButton from './DeleteTrancheButton';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS, TRANCHE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';

const mapDispatchToProps = {
  excludeTranche: (index) => change(
    CONDOR_DEAL_PROFILE_FORM_NAME,
    `${ DEAL_PROFILE_FIELDS.TRANCHES.name }[${ index }].${ TRANCHE_FIELDS.IS_EXCLUDED.name }`,
    true,
  ),
  undoExcludeTranche: (index) => change(
    CONDOR_DEAL_PROFILE_FORM_NAME,
    `${ DEAL_PROFILE_FIELDS.TRANCHES.name }[${ index }].${ TRANCHE_FIELDS.IS_EXCLUDED.name }`,
    false,
  ),
};

export default connect(
  null,
  mapDispatchToProps,
)(DeleteTrancheButton);
