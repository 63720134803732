import createAction from '@/Framework/State/Redux/createAction';
import { checkAuthBonds } from '@/finsight/application/actions/bonds/actions';
import downloadFile from '@/Framework/api/downloadFile';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import * as actionTypes from './actionTypes';
import filtersSelectors from '../filters/selectors';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {String} search
 * @return {Object}
 */
export const setSearch = (search = '') => createAction(actionTypes.BONDS_SEARCH_CHANGE, search);

/**
 * @param {Object} assetClass
 * @return {Object}
 */
export const setAssetClass = (assetClass) => createAction(actionTypes.BONDS_SET_ASSET_CLASS, assetClass);

/**
 * @param {String} tab
 * @return {Object}
 */
export const setActiveTab = (tab) => createAction(actionTypes.SET_ACTIVE_TAB, tab);

/**
 * @return {{type: string}}
 */
export const resetState = () => createAction(actionTypes.BONDS_RESET_METADATA);

export function exportToExcel({ assetClassId, search }) {
  return async (dispatch, getState) => {
    if (!dispatch(checkAuthBonds())) {
      return;
    }
    dispatch(createAction(actionTypes.GET_BONDS_EXPORT));
    try {
      const bondsRepository = getState().container.get(BondsRepository);
      const exportUrl = await bondsRepository.getExcelExportUrl(
        assetClassId,
        filtersSelectors.getFiltersPayload(getState()),
        search,
        window.location.href,
      );
      downloadFile(exportUrl);
      dispatch(createAction(actionTypes.GET_BONDS_EXPORT_SUCCESS));
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
      dispatch(createAction(actionTypes.GET_BONDS_EXPORT_FAILURE, e));
    }
  };
}

export function getAssetClassList() {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypes.GET_BONDS_ASSET_CLASSES));

    try {
      const bondsRepository = getState().container.get(BondsRepository);
      const response = await bondsRepository.getAssetClassList();
      dispatch(createAction(actionTypes.GET_BONDS_ASSET_CLASSES_SUCCESS, response));
      return response.collection;
    } catch (e) {
      dispatch(createAction(actionTypes.GET_BONDS_ASSET_CLASSES_FAILURE, e));
      throw e;
    }
  };
}
