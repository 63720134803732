import React from 'react';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconDoubleArrowsLeft from '@dealroadshow/uikit/core/components/Icon/IconDoubleArrowsLeft';
import IconDoubleArrowsRight from '@dealroadshow/uikit/core/components/Icon/IconDoubleArrowsRight';
import IconArrowLeft from '@dealroadshow/uikit/core/components/Icon/IconArrowLeft';
import IconArrowRight from '@dealroadshow/uikit/core/components/Icon/IconArrowRight';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import {
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';

import styles from './TranchesNavigation.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const TranchesNavigation = () => {
  const {
    tranchesViewOffset,
    setTranchesViewOffset,
    tranchesFieldArrayLength,
  } = useTranchesContext();

  const handleSingleArrowButtonClickForward = () => setTranchesViewOffset(
    tranchesFieldArrayLength - (tranchesViewOffset + TRANCHES_VISIBLE) > TRANCHES_VISIBLE
      ? tranchesViewOffset + TRANCHES_VISIBLE
      : tranchesFieldArrayLength - TRANCHES_VISIBLE,
  );

  const handleSingleArrowButtonClickBack = () => setTranchesViewOffset(
    tranchesViewOffset <= TRANCHES_VISIBLE ? 0 : tranchesViewOffset - TRANCHES_VISIBLE,
  );

  return (
    <div className={ alignStyles.flexSpaceBetween }>
      <Tooltip content="Go to the first">
        <Button
          className={ styles.button }
          variant={ ButtonVariantType.text }
          disabled={ tranchesViewOffset === 0 }
          onClick={ () => setTranchesViewOffset(0) }
          dataTest="condorTranchesNavigationDoubleLeftButton"
        >
          <IconDoubleArrowsLeft className={ styles.icon } />
        </Button>
      </Tooltip>
      <div className={ styles.separator } />
      <Button
        className={ styles.button }
        variant={ ButtonVariantType.text }
        disabled={ tranchesViewOffset === 0 }
        onClick={ handleSingleArrowButtonClickBack }
        dataTest="condorTranchesNavigationLeftButton"
      >
        <IconArrowLeft
          className={ styles.icon }
        />
      </Button>
      { tranchesViewOffset + 1 } - { tranchesViewOffset + TRANCHES_VISIBLE }
      <span className={ styles.tranchesLength }> { ` / ${ tranchesFieldArrayLength }` } </span>
      <Button
        className={ styles.button }
        variant={ ButtonVariantType.text }
        disabled={ tranchesFieldArrayLength <= TRANCHES_VISIBLE
          || tranchesViewOffset === tranchesFieldArrayLength - TRANCHES_VISIBLE }
        onClick={ handleSingleArrowButtonClickForward }
        dataTest="condorTranchesNavigationRightButton"
      >
        <IconArrowRight className={ styles.icon } />
      </Button>
      <div className={ styles.separator } />
      <Tooltip content="Go to the last">
        <Button
          className={ styles.button }
          variant={ ButtonVariantType.text }
          disabled={ tranchesFieldArrayLength <= TRANCHES_VISIBLE
            || tranchesViewOffset === tranchesFieldArrayLength - TRANCHES_VISIBLE }
          onClick={ () => setTranchesViewOffset(tranchesFieldArrayLength - TRANCHES_VISIBLE) }
          dataTest="condorTranchesNavigationDoubleRightButton"
        >
          <IconDoubleArrowsRight className={ styles.icon } />
        </Button>
      </Tooltip>
    </div>
  );
};

export default TranchesNavigation;
