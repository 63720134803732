import keyBy from 'lodash/keyBy';

import { IStat, IStatPreview } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { IOptionCategory, StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import { IRegion } from '@/finsight/domain/vo/Region';
import { IPreviewAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';

// using split('/') and slice(1) to get the name of the preview
// .join('/') to avoid error if name contains '/'
const getPreviewName = (url: string) => url.split('/').slice(1).join('/');

const getPreview = (preview: IStatPreview) => (
  preview ? { uuid: preview.uploadId, url: preview.url, name: getPreviewName(preview.url) } : null
);

const getAttachment = (attachment: IPreviewAttachment) => (
  attachment ? [{ uuid: attachment.uploadId, name: attachment.displayName, url: attachment.url }] : []
);

const getCategoryOptions = (
  categoriesList: ICategory[],
  categoryIds: string[],
  regions: IRegion[],
): IOptionCategory[] => {
  const regionMap = keyBy(regions, 'id');
  const categoriesOptions: IOptionCategory[] = [];

  categoriesList.forEach((category) => {
    if (categoryIds.includes(category.id)) {
      categoriesOptions.push({
        label: category.displayName,
        value: category.id,
        regionName: regionMap[category.regionId]?.abbreviation || '',
      });
    }
  });
  return categoriesOptions;
};

export const getInitialStatData = (
  {
    name,
    categories: categoryIds,
    description,
    isActive,
    attachment,
    preview,
    id,
  }: IStat,
  categoriesList: ICategory[],
  regions: IRegion[],
): StatFormData => ({
  attachments: getAttachment(attachment),
  preview: getPreview(preview),
  name,
  description,
  isActive,
  categories: getCategoryOptions(categoriesList, categoryIds, regions),
  id,
});
