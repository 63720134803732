import React from 'react';
import { Link } from 'react-router-dom';

import { Field, useFormState, useForm } from '@/Framework/UI/Organisms/FinalForm';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import {
  useEnhancedTaggingCondorContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import Company from '@/Framework/UI/Organisms/EnhancedTagging/Field/Company';
import { TruncateTextWithTooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';

import * as paths from '@/condor/ui/common/constants';

import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const CompanyCallback = ({
  row,
  rowIndex,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();
  const { companies } = useEnhancedTaggingCondorContext();
  const {
    parent: companyName,
    companyId,
  } = useFormState().values.editableTableArray[rowIndex];
  const { change } = useForm();

  if (editableRowId === row.id) {
    const onChangeHandler = (value, suggestion, event) => {
      const changeFieldValue = (value) => {
        change(`${ fieldRowName }.companyId`, value);
      };

      if (event.type !== 'change') {
        if (value) {
          changeFieldValue(suggestion.id);
          return;
        }
        changeFieldValue(null);
      }
    };

    return (
      <TextField
        // @ts-ignore
        clearOnBlur
        // @ts-ignore
        component={ Company }
        name={ `${ fieldRowName }.parent` }
        placeholder="Company Name"
        // @ts-ignore
        formFieldClassName={ spaces.mbn }
        fieldComponent={ Field }
        companies={ companies }
        usePortal={ false }
        dataTest={ `company[${ rowIndex }]` }
        onChange={ onChangeHandler }
      />
    );
  }

  if (!companyName) {
    return '-';
  }

  return (
    <TruncateTextWithTooltip value={ companyName }>
      <Link
        to={ `${ paths.CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ companyId }` }
        data-test={ `companyLink[${ rowIndex }]` }
      >
        { companyName }
      </Link>
    </TruncateTextWithTooltip>
  );
};

export default CompanyCallback;
