import { useSelector } from 'react-redux';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { TrancheGroup } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import { RATING_AGENCIES_COLLAPSED_LENGTH } from '@/condor/application/Finsight/DealProfile/constants';

import styles from './ratingsMoreButton.scss';

const RatingsMoreButton = ({ group }) => {
  const {
    collapsedGroup,
    isRatingAgenciesCollapsed,
    setIsRatingAgenciesCollapsed,
  } = useTranchesContext();
  const ratings = useSelector(selectors.getRatingAgencies);
  const moreRatingsAmount = ratings.length - RATING_AGENCIES_COLLAPSED_LENGTH;
  const isRatingAgenciesMoreButtonVisible = group === TrancheGroup.RATING_AGENCIES &&
      !collapsedGroup.includes(group) &&
      isRatingAgenciesCollapsed;

  if (!isRatingAgenciesMoreButtonVisible) {
    return null;
  }

  return (
    <Button
      variant={ ButtonVariantType.link }
      onClick={ () => setIsRatingAgenciesCollapsed(false) }
      className={ styles.showMoreButton }
      dataTest="condorRatingAgenciesShowMoreButton"
    >+{ moreRatingsAmount } more
    </Button>
  );
};

export default RatingsMoreButton;
