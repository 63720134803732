import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import StatPreviewContextProvider, {
  useStatPreviewContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatPreviewContext';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';

import PreviewArticleModalFooter from './PreviewStatModalFooter';
import {
  Attachments,
} from '@/condor/ui/components/Finsight/CreditFlowResearch/common/PreviewModalComponents';

import {
  Description,
  Metadata,
  Tags,
  Title,
} from '@/finsight/ui/common/components/CreditFlowResearch/CreditFlowData';

import commonStyles from '@/condor/ui/components/Finsight/CreditFlowResearch/common/common.scss';

const PreviewModal = () => {
  const { isEdit } = useStatProfileContext();

  const {
    isFetching,
    previewData,
    closePreviewModal,
  } = useStatPreviewContext();

  if (!previewData) {
    return <Spinner isFixed isVisible overlay />;
  }

  const {
    name,
    description,
    author,
    date,
    categories,
    attachments,
    previewUrl,
  } = previewData;

  return (
    <Modal
      className={ commonStyles.previewModal }
      onCloseClicked={ closePreviewModal }
      isVisible
      title={ isEdit ? 'Updating Stat' : 'Publishing New Stat' }
      footer={ (<PreviewArticleModalFooter />) }
      dataTest="statPreviewModal"
    >
      <Metadata
        author={ author }
        date={ date }
        authorDataTest="previewAuthor"
        dateDataTest="previewDate"
      />
      <Title title={ name } dataTest="previewName" />
      <div className={ commonStyles.modalContent }>
        <Tags tags={ categories } dataTest="previewCategories" tagDataTest="previewCategory" />
        <Description description={ description } dataTest="previewDescription" />
        { previewUrl && (
          <div data-test="previewImagePreview">
            <img src={ previewUrl } alt="preview image" />
          </div>
        ) }
        <Attachments attachments={ attachments } />
      </div>
      <Spinner isAbsolute isVisible={ isFetching } overlay />
    </Modal>
  );
};

export default ({ closePreviewModal }) => (
  <StatPreviewContextProvider closePreviewModal={ closePreviewModal }>
    <PreviewModal />
  </StatPreviewContextProvider>
);
