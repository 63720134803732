import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

import styles from '../dealsTable.scss';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconFileXls from '@dealroadshow/uikit/core/components/Icon/IconFileXls';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';

interface IProps {
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  isDownloading: boolean,
  inlineEditFormState: any,
  onSubmit: () => void,
  onCancel: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
  exportToExcel: () => void,
}

const ActionsCell = ({
  isSelectedForEditing,
  isUpdating,
  isDownloading,
  inlineEditFormState,
  onSubmit,
  onCancel,
  onEditClick,
  onDeleteClick,
  exportToExcel,
}: IProps) => {
  const actions = isSelectedForEditing ?
    [
      {
        tooltipContent: 'Save',
        icon: IconCheck,
        onClick: onSubmit,
        disabled: inlineEditFormState.submitting ||
          inlineEditFormState.pristine ||
          inlineEditFormState.invalid,
        className: styles.actionContainer,
        dataTest: 'saveButton',
      },
      {
        tooltipContent: 'Cancel',
        icon: IconCancel,
        onClick: onCancel,
        dataTest: 'cancelButton',
      },
    ] : [
      {
        tooltipContent: 'Edit',
        icon: IconPencil,
        onClick: onEditClick,
        className: styles.actionContainer,
        dataTest: 'editButton',
      },
      {
        tooltipContent: 'Export to Excel',
        icon: IconFileXls,
        onClick: exportToExcel,
        className: styles.actionContainer,
        dataTest: 'exportButton',
      },
      {
        tooltipContent: 'Delete',
        icon: IconTrash,
        onClick: onDeleteClick,
        dataTest: 'deleteButton',
      },
    ];

  const isLoading = isUpdating || isDownloading;
  const isVisibleSpinner = isSelectedForEditing || isDownloading;

  return (
    <LastTableCell
      showHoverContent={ isVisibleSpinner }
      childrenToShowOnHover={
        !isLoading
          ? <TableRowActions actions={ actions } />
          : (
            <Spinner
              size="small"
              isVisible={ isVisibleSpinner }
            />
          )
      }
    />
  );
};

export default ActionsCell;
