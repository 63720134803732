import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { getImportTimestamp } from '@/condor/ui/components/Finsight/Import/helpers';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

interface IProps {
  cellProps: any,
  onDelete: () => void,
}

const DateOfImportCell = (props: IProps) => (
  <LastTableCell
    childrenToShowOnHover={ (
      <TableRowActions actions={ [
        {
          tooltipContent: 'Delete import',
          icon: IconTrash,
          onClick: props.onDelete,
          disabled: !!props.cellProps.row.deletedAt,
        },
      ] }
      />
    ) }
  >
    { getImportTimestamp(props.cellProps) }
  </LastTableCell>
);

export default DateOfImportCell;
