import React from 'react';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import columns from './columns';
import ExpandableTable from '@dealroadshow/uikit/core/components/Table/ExpandableTable';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';
import {
  ExpandableTraceTable,
} from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/ExpandableTraceTable';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './traceTable.scss';

const TraceTable = ({ archived }: { archived: boolean }) => {
  const {
    traceData: { trades },
    isFetching,
    mapTradeFetching,
    sortBy,
    sortOrder,
    page,
    perPage,
    handlePageChange,
    handleSortChange,
    handleItemsPerPageChange,
  } = useTraceContext();

  return (
    <>
      <ExpandableTable
        className={ styles.tableContainer }
        isFetching={ isFetching || mapTradeFetching }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
        onSortChange={ handleSortChange }
        dataTest="traceDataTable"
        columns={ columns(archived) }
        collection={ trades.collection }
        expandableRowContent={ ExpandableTraceTable }
      />
      <Paginator
        className={ cardStyles.cardInner }
        totalCount={ trades.totalCount }
        page={ page }
        perPage={ perPage }
        onPageChange={ handlePageChange }
        onItemsPerPageChange={ handleItemsPerPageChange }
        dataTest="traceItemsListPaginator"
      />
    </>
  );
};

export default TraceTable;
