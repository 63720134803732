import React from 'react';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconFiltering from '@dealroadshow/uikit/core/components/Icon/IconFiltering';

const SearchInput = (props) => {
  return (
    <Input
      icon={ IconFiltering }
      { ...props }
    />
  );
};

export default SearchInput;
