import { connect } from 'react-redux';
import {
  getCondorFinsightSubsribersListByFilter,
  paginate,
  itemsPerPage,
} from '@/condor/application/actions/finsight/subscriptions/collection/actions';
import selectors from '@/condor/application/actions/finsight/subscriptions/collection/selectors';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';

const mapStateToProps = (state) => ({
  totalCount: selectors.getTotalCount(state),
  page: selectors.getPage(state),
  perPage: selectors.getPerPage(state),
  dataTest: 'subscribersTable',
});

const mapDispatchToProps = (dispatch) => (
  {
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getCondorFinsightSubsribersListByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getCondorFinsightSubsribersListByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
