import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { editorInitialValue } from '@/condor/application/Finsight/constants';

export const defaultInitialFormData: ArticleFormData = {
  headline: '',
  description: editorInitialValue,
  isActive: false,
  sendEmail: false,
  themes: [],
  deals: [],
  parentAndIssuer: [],
  attachments: [],
};
