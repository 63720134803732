import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Company from '@/Framework/UI/Organisms/EnhancedTagging/Field/Company';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Input from '@dealroadshow/uikit/core/components/Input';
import { validateName, validateDisplayName } from './validator';
import styles from './rows.scss';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

export const INPUT_MIN_LENGTH = 3;

interface IProps {
  fields: any,
  fieldComponent: React.ElementType,
  companies: object,
  rowClassName?: string,
  disabled?: boolean,
  label?: string,
  placeholder?: string,
  modalCall?: boolean,
  modalCallText?: string,
  modalShow: (payload: any) => void,
  clearOnBlur?: boolean,
  onSuggestionSelected?: (val: string, fieldName: string) => void,
  withUnderwritersDisplayName?: boolean,
  withUnderwritersLead?: boolean,
  isMultipleUnderwriters?: boolean,
}

const Rows = ({
  fields,
  fieldComponent: Field,
  companies,
  rowClassName,
  disabled,
  label,
  placeholder,
  modalCall,
  modalCallText,
  modalShow,
  clearOnBlur = false,
  onSuggestionSelected,
  withUnderwritersDisplayName,
  withUnderwritersLead,
  isMultipleUnderwriters,
}: IProps) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [emptyField, setEmptyField] = useState(false);

  const handleFieldRemove = (index) => {
    if (fields.length > 1) {
      fields.remove(index);
    } else {
      fields.update(index, { name: '' });
    }
  };

  useEffect(() => {
    if (withUnderwritersDisplayName && selectedIndex !== null) {
      const field = fields?.value[selectedIndex];
      field && fields.update(selectedIndex, { displayName: fields?.value[selectedIndex]?.name, ...field });
      setSelectedIndex(null);
    }
  }, [withUnderwritersDisplayName, selectedIndex, JSON.stringify(fields?.value)]);

  // method fields.getAll() refers to redux-form, and fields.value refers to final-form
  useEffect(() => {
    const fieldsValue = fields.getAll?.() || fields?.value;
    if (fieldsValue?.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fieldsValue) {
        if (field?.name?.trim().length >= INPUT_MIN_LENGTH) {
          setEmptyField(false);
        } else {
          setEmptyField(true);
          break;
        }
      }
    }
  }, [JSON.stringify(fields.getAll?.() || fields?.value)]);

  const handleSuggestionSelected = (index: number) => (value: string, fieldName: string) => {
    onSuggestionSelected(value, fieldName);
    const item = fields?.value[index];
    !item?.displayName && setSelectedIndex(index);
  };

  const rows = (
    <div className={ styles.inputsWrp } data-test="underwritersRows">
      { fields.map((field, index) => (
        <div
          key={ field }
          className={ cn(styles.row, rowClassName, { [styles.noHighlight]: !isMultipleUnderwriters }) }
          data-test="underwriterRow"
        >
          <div className={ styles.inputWrapper }>
            <TextField
              name={ `${ field }.name` }
              dataTest="underwriterName"
              placeholder={ placeholder || 'Name' }
              // @ts-ignore
              validate={ validateName }
              component={ Company }
              fieldComponent={ Field }
              formFieldClassName={ styles.inputField }
              companies={ companies }
              disabled={ disabled }
              modalCall={ modalCall }
              modalCallText={ modalCallText }
              modalShow={ modalShow }
              clearOnBlur={ clearOnBlur }
              onSuggestionSelected={ handleSuggestionSelected(index) }
            />
          </div>
          { withUnderwritersLead && (
            <div className={ styles.checkboxContainer }>
              <Field
                component={ CheckboxInput }
                type="checkbox"
                name={ `${ field }.lead` }
                disabled={ disabled }
                dataTest="underwriterLead"
              />
            </div>
          ) }
          { withUnderwritersDisplayName && (
            <div className={ cn(styles.inputWrapper, styles.displayName) }>
              <TextField
                name={ `${ field }.displayName` }
                dataTest="displayName"
                placeholder="Display Name"
                // @ts-ignore
                validate={ validateDisplayName }
                component={ Input }
                fieldComponent={ Field }
                formFieldClassName={ styles.inputField }
                disabled={ disabled }
              />
            </div>
          ) }
          { isMultipleUnderwriters && !disabled && (
            <Button
              variant={ ButtonVariantType.text }
              onClick={ () => handleFieldRemove(index) }
              className={ styles.deleteButton }
              dataTest="underwriterDelete"
              disabled={ disabled }
            >
              <Tooltip
                placement="bottom"
                content="Delete underwriter"
              >
                <IconTrash
                  className={ styles.deleteButtonIcon }
                />
              </Tooltip>
            </Button>
          ) }
        </div>
      )) }
    </div>
  );

  return (
    <>
      { rows }
      { isMultipleUnderwriters && !disabled && (
        <Button
          variant={ ButtonVariantType.text }
          className={ cn(styles.addMoreButton, { [styles.addMoreButtonDisabled]: emptyField }) }
          onClick={ () => fields.push({ name: '' }) }
          dataTest="underwritersAdd"
          disabled={ emptyField }
        >
          <IconAdd
            className={ styles.addMoreIcon }
          />
          { label || 'Add Underwriter' }
        </Button>
      ) }
    </>
  );
};

export default Rows;
