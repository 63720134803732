import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { UserPlanType, UserPlanTypePrice } from '@/users/domain/UserPlan';
import UserPlanTag from '@/Framework/UI/Atoms/UserPlanTag';
import { Link } from '@/Framework/Router/Next/Link';
import useGetUserPlan from '@/users/ui/helpers/useGetUserPlan';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

import styles from './subscriptionField.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

const priceMap = {
  [UserPlanType.STARTER]: UserPlanTypePrice[UserPlanType.STARTER],
  [UserPlanType.PREMIUM]: `$${ UserPlanTypePrice[UserPlanType.PREMIUM] } / month (billed annually)`,
  [UserPlanType.PRO]: `$${ UserPlanTypePrice[UserPlanType.PRO] } / month (billed annually)`,
};

const SubscriptionField = ({ contactId = null }) => {
  const {
    userPlan,
    isFetching,
  } = useGetUserPlan(contactId);
  const pricingLink = finsightWebUrl.getPricingPageUrl();

  return (
    <div className={ spacesStyles.pbxl }>
      {
        isFetching ? <Spinner /> : (
          <>
            <span className={ formStyles.formLabel }>{ contactId ? 'Finsight.com subscriptions' : 'subscription' }</span>
            <div className={ styles.field }>
              <div>
                <UserPlanTag userPlan={ userPlan } />
                <span className={ styles.domain }>Finsight.com</span>
                <span className={ styles.price }>{ priceMap[userPlan] }</span>
              </div>
              { !contactId && (
                <Link to={ pricingLink } target="_blank">
                  { userPlan === UserPlanType.STARTER ? 'Upgrade Plan' : 'Change' }
                </Link>
              ) }
            </div>
          </>
        )
      }
    </div>
  );
};

export default SubscriptionField;
