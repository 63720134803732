import { Dependencies } from 'constitute';
import DmPortalUploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import UploadDispatcher from '@/evercall/application/DI/FileUpload/UploadDispatcher';

@Dependencies(DmPortalUploadDispatcher, UploadDispatcher)
class LogoRepository {
  constructor(
    private dmPortalUploadDispatcher: typeof DmPortalUploadDispatcher,
    private uploadDispatcher: typeof UploadDispatcher,
  ) {}

  upload = (file: File) => {
    return this.dmPortalUploadDispatcher.upload(file, { type: 'EVERCALL_CUSTOM_ASSET' });
  };

  uploadUnderwriterLogo = (file: File) => {
    return this.uploadDispatcher.upload(file, { type: 'CUSTOMIZATION_UNDERWRITER_LOGO' });
  };
}

export default LogoRepository;
