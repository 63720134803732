import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';
import { ICustomRegistrationFormValues } from './interfaces';

export const getCustomRegistrationFormInitialValues = (
  settings: ICustomRegistration,
): ICustomRegistrationFormValues => {
  const {
    customDetailsSectionSubfields,
    underwritersLogos,
    ...restSettings
  } = settings;

  return {
    ...restSettings,
    underwritersLogos: underwritersLogos.map((logo) => ({
      ...logo,
      originalName: logo.name,
      id: logo.url,
    })),
    customDetailsSectionSubfields: customDetailsSectionSubfields.length !== 0
      ? customDetailsSectionSubfields
      : [
        {
          title: '',
          text: '',
          hyperlinkText: '',
        },
      ],
  };
};
