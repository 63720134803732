import { Field } from '@/Framework/UI/Organisms/FinalForm';
import FinalFormWrapper from '@/condor/ui/common/Layout/FormWrapper/FinalFormWrapper';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import AccountTypeProfileContextProvider, {
  useAccountTypeProfileContext,
} from '@/condor/application/Accounts/Admin/AccountType/Profile/AccountTypeProfileContext';
import {
  CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME,
} from '@/condor/ui/common/constants';
import Input from '@dealroadshow/uikit/core/components/Input';
import ColorPicker from '@dealroadshow/uikit/core/components/Picker/ColorPicker';
import validate from './profileFormValidators';

import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const commonInputProps = {
  placeholder: '',
  component: Input,
  className: layoutStyles.inputField,
};

const AccountTypeProfileFields = ({ isCreating }: { isCreating: boolean }) => (
  <>
    { !isCreating && (
      <Field
        name="id"
        label="Unique Id"
        disabled
        { ...commonInputProps }
      />
    ) }
    <Field
      { ...commonInputProps }
      name="name"
      label={ !isCreating ? 'Account Type Name' : null }
      placeholder="Account Type Name"
    />
    <Field
      name="color"
      label={ !isCreating ? 'Account Type Color' : null }
      isNarrow
      component={ ColorPicker }
      containerClassName={ layoutStyles.inputField }
      placeholder="#ffffff"
    />
  </>
);

const AccountTypeProfile = () => {
  const {
    isCreating,
    onCancel,
    item,
    isFetching,
    createOrUpdateAccountType,
  } = useAccountTypeProfileContext();

  return (
    <PageWrapper label={ isCreating ? 'Create Account Type' : item?.name }>
      <ProfileWrapper isSpinnerVisible={ isFetching }>
        <FinalFormWrapper
          name={ CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME }
          dataTest={ CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME }
          headerText="Account Type Details"
          initialValues={ isCreating ? { verified: true } : { ...item } }
          enableReinitialize
          validate={ validate }
          onSubmit={ createOrUpdateAccountType }
          onCancelClick={ onCancel }
          submitButtonText={ isCreating ? 'Create' : 'Save' }
          render={ () => <AccountTypeProfileFields isCreating={ isCreating } /> }
        />
      </ProfileWrapper>
    </PageWrapper>
  );
};

export default () => (
  <AccountTypeProfileContextProvider>
    <AccountTypeProfile />
  </AccountTypeProfileContextProvider>
);
