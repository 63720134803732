import React from 'react';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import {
  useEnhancedTaggingCondorContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { TruncateTextWithTooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';

import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const IndustryCallback = ({
  row: {
    id,
    subindustryId,
  },
  rowIndex,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();
  const { industryOptions } = useEnhancedTaggingCondorContext();

  if (editableRowId === id) {
    return (
      <Field
        name={ `${ fieldRowName }.subindustryId` }
        placeholder="Company Industry & Subindustry"
        formFieldClassName={ spaces.mbn }
        // @ts-ignore
        component={ FinalFormSelect }
        simpleValue
        asterisk
        options={ industryOptions }
        dataTest={ `subindustry[${ rowIndex }]` }
      />
    );
  }

  if (!subindustryId) {
    return '-';
  }

  const industryName = industryOptions.find((item) => item.value === subindustryId)?.label;

  return <TruncateTextWithTooltip value={ industryName } />;
};

export default IndustryCallback;
