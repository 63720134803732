import {
  useStatPreviewContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatPreviewContext';
import { PreviewModalFooter } from '@/condor/ui/components/Finsight/CreditFlowResearch/common/PreviewModalComponents';

const PreviewStatModalFooter = () => {
  const { closePreviewModal, publishStat, isFetching } = useStatPreviewContext();
  return (
    <PreviewModalFooter
      closePreviewModal={ closePreviewModal }
      onPublish={ publishStat }
      isFetching={ isFetching }
    />
  );
};

export default PreviewStatModalFooter;
