import React, { useCallback } from 'react';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import debounce from 'lodash/debounce';
import { TaggingStateTypes } from '@/condor/domain/vo/EnhancedTagging/TaggingState';
import TaggingState from '@/condor/ui/common/EnhancedTagging/TaggingState';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';

const BULK_TABLE_NAME = 'bulkEditingForm';

const TaggingCell = ({
  row,
  rowIndex,
  customCellProps,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;
  const {
    updateTaggingState,
    editableRowId,
  } = useEnhancedTaggingRoadshowsContext();

  const shouldBeSavedImmediately = editableRowId !== row.id && customCellProps.formProps.id !== BULK_TABLE_NAME;

  const updateTaggingStateDebounce = useCallback(
    debounce((value: TaggingStateTypes) => {
      updateTaggingState(row.id, value);
    }, 500),
    [row],
  );

  return (
    <Field name={ `${ fieldRowName }.taggingState` }>
      { ({
        input: {
          onChange,
          value,
        },
      }) => {
        const onChangeHandler = (value: TaggingStateTypes) => {
          onChange(value);

          if (shouldBeSavedImmediately) {
            updateTaggingStateDebounce(value);
          }
        };

        return <TaggingState status={ value } onChange={ onChangeHandler } />;
      } }
    </Field>
  );
};

export default TaggingCell;
