import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import InputLabel from '@dealroadshow/uikit/core/components/Input/Label';
import Radio, { RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { ProfileAccessTypes } from '@/openMarket/domain/Condor/ProfileAccessType';

import profileFormStyles from '@/openMarket/ui/components/Condor/Profiles/ProfileForm/profileForm.scss';
import styles from './restrictAccess.scss';

const RestrictAccess = (props: IFinalFormFieldInputComponentProps) => {
  return (
    <FormField isNarrow dataTest={ props.name }>
      <div className={ profileFormStyles.formInputWrp }>
        <InputLabel className={ styles.label }>Restrict Access</InputLabel>
        <RadioGroup
          { ...props.input }
          meta={ props.meta }
          isNarrow
          isErrorMessage
          selectedValue={ props.input.value }
          className={ styles.formGroup }
        >
          <Radio
            className={ styles.radioInput }
            dataTest="restrictByLogin"
            label="Restricted with FINSIGHT Login"
            value={ ProfileAccessTypes.LOGIN }
          />
          <Radio
            className={ styles.radioInput }
            dataTest="restrictByLoginAndWhitelist"
            label="Restricted with FINSIGHT Login and Whitelist"
            value={ ProfileAccessTypes.LOGIN_WITH_WHITELIST }
          />
        </RadioGroup>
      </div>
      <p className={ styles.description }>
        Whitelists can be set within the “Tabs” section below. Access to the Profile’s Events (by tab) will be
        restricted to individuals whose domain or specific email address (e.g., finsight.com or support@finsight.com) is
        included in the Whitelist.
      </p>
    </FormField>
  );
};

export default RestrictAccess;
