import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { TRANCHES_VISIBLE } from '@/condor/ui/components/Finsight/DealProfile/constants';

import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import { getNewTrancheInitialValues } from '@/condor/ui/components/Finsight/DealProfile/helpers/useDealInitialValues';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

import styles from './addTrancheButton.scss';

interface IProps {
  disabled: boolean,
  addTranche: (tranche: any) => void,
  ratingAgencies: any[],
  tranchesLength: number,
}

const AddTrancheButton = (
  {
    disabled,
    addTranche,
    ratingAgencies,
    tranchesLength,
  }: IProps,
) => {
  const { setTranchesViewOffset } = useTranchesContext();
  const { initialIssuer, deal } = useSelector((state) => ({
    initialIssuer: selectors.getInitialIssuer(state),
    deal: selectors.getItemData(state),
  }));
  const issuerForTranche = deal.primaryIssuer || initialIssuer;

  const onClick = useCallback(
    () => {
      addTranche(getNewTrancheInitialValues(ratingAgencies, issuerForTranche));

      if (tranchesLength >= TRANCHES_VISIBLE) {
        setTranchesViewOffset(tranchesLength - (TRANCHES_VISIBLE - 1));
      }
    },
    [tranchesLength, issuerForTranche],
  );

  return (
    <div className={ styles.addTrancheBtnContainer }>
      <Button
        disabled={ disabled }
        className={ cn(styles.addTrancheBtn, { [styles.disabled]: disabled }) }
        variant={ ButtonVariantType.text }
        onClick={ onClick }
        dataTest="condorAddTrancheButton"
      >
        <IconAdd className={ styles.addTrancheIcon } />
        <span className={ styles.addTrancheLabel }>Add Tranche</span>
      </Button>
    </div>
  );
};

export default AddTrancheButton;
