import { v4 as uuid } from 'uuid';

import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import {
  useManageCategoriesContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsList/ManageStatsCategoriesContext';
import { DataTable, dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';
import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import getColumns from './columns';

import styles from './styles.scss';

const ManageCategoriesFields = ({ fields, closeModal }: IFinalFormFieldArrayInputComponentProps<ICategory>) => {
  const {
    productId,
    regionId,
    isSubmitting,
  } = useManageCategoriesContext();
  const { invalid, pristine, dirty } = useFormState();

  const tableData = fields
    .map((fieldName, fieldIndex) => ({ fieldName, fieldIndex, ...fields.value[fieldIndex] }))
    .filter((category) => category.regionId === regionId && category.productId === productId);

  const orderCallback = (oldIndex: number, newIndex: number) => {
    const oldField = tableData[oldIndex];
    const newField = tableData[newIndex];
    fields.move(oldField.fieldIndex, newField.fieldIndex);
  };

  const addNewCategory = () => fields.push({
    name: '',
    productId,
    regionId,
    canBeDeleted: true,
    id: uuid(),
    isNew: true,
  });

  return (
    <div>
      <DataTable
        isDraggable
        className={ dataTableStyles.isSecondaryTable }
        containerClassName={ styles.modalTable }
        dataTest="manageStatsCategoriesTable"
        columns={ getColumns(fields) }
        data={ tableData }
        orderCallback={ orderCallback }
        isScrollable
        emptyMessage="No categories found"
        showEmptyStateMessage
        showEmptyStateMessageFirst
      />
      <div>
        <Button
          dataTest="manageStatsCategoriesAddButton"
          variant={ ButtonVariantType.text }
          onClick={ addNewCategory }
          className={ styles.addCategoryButton }
          disabled={ isSubmitting }
        >
          <IconAdd />
          Add Category
        </Button>
      </div>
      <div className={ styles.modalButtons }>
        <Button
          dataTest="manageStatsCategoriesSaveButton"
          variant={ ButtonVariantType.action }
          type="submit"
          title="Save"
          disabled={ invalid || isSubmitting || pristine || !dirty }
        />
        <Button
          dataTest="manageStatsCategoriesCancelButton"
          variant={ ButtonVariantType.text }
          title="Cancel"
          disabled={ isSubmitting }
          onClick={ closeModal }
        />
      </div>
    </div>
  );
};

export default ManageCategoriesFields;
