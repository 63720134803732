import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { useRoadshowVideoGeneration } from './useRoadshowVideoGeneration';
import RoadshowType, { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import UploadPresentationMediaAudioContainer from './UploadPresentationMediaAudioContainer';
import UploadPresentationMediaVideoContainer from './UploadPresentationMediaVideoContainer';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import Button, { buttonStyles, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@dealroadshow/uikit/core/components/Input';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import IconShare from '@dealroadshow/uikit/core/components/Icon/IconShare';
import CuePoints from './CuePoints';
import validate from './validators/dealAudioVideoValidators';
import {
  ICuePoint,
  IInitialValues,
  IVideoOnlyCuePoint,
} from './CuePoints/types';
import CondorDropzoneInternalField from './CondorDropzoneInternalField/CondorDropzoneInternalField';

import { roadshowTypesOptions } from '@/condor/ui/components/DealRoadshows/Roadshow/roadshowTypesOptions';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './audioVideoForm.scss';

interface IProps {
  cuePoints: ICuePoint[],
  videoOnlyCuePoints: IVideoOnlyCuePoint[],
  shiftCuePoints: () => void,
  shiftVideoOnlyCuePoints: () => void,
  uploadedCuePoints: [],
  getAudioVideo: (e: number) => void,
  initialValues: IInitialValues,
  type: RoadshowTypes,
  uploadedMedia: {},
  handleSubmit: () => void,
  uploadClearForForm: () => void,
  resetAudioVideo: () => void,
  initUploadPresentationMediaAudio: (e) => void,
  initUploadPresentationMediaVideo: (e) => void,
  initUploadPresentationMediaThumbnail: (e) => void,
  canBeChangedVideoType: boolean,
  uploadedCuePointsFileName: string,

  // todo should be inherited from redux-form types
  reset: () => void,
  change: (e, i) => void,
  pristine: boolean,
  isFetching: boolean,
  disabled: boolean,
  isModerated: boolean,
}

const AudioVideoForm = (props: IProps) => {
  const {
    getAudioVideo,
    resetAudioVideo,
    initialValues,
    type,
    uploadedCuePoints,
    initUploadPresentationMediaAudio,
    initUploadPresentationMediaVideo,
    initUploadPresentationMediaThumbnail,
    uploadClearForForm,
    cuePoints,
    videoOnlyCuePoints,
    uploadedMedia,
    handleSubmit,
    canBeChangedVideoType,
    shiftVideoOnlyCuePoints,
    shiftCuePoints,
    reset,
    change,
    pristine,
    isFetching,
    disabled,
    isModerated,
    uploadedCuePointsFileName,
  } = props;
  const [audioConferenceCode, setAudioConferenceCode] = useState<string>('');

  const { generateVideoReplay } = useRoadshowVideoGeneration();

  const isVideoOnly = RoadshowType.isVideoOnly(type);
  const isVideo = RoadshowType.isSlidesAndVideo(type) || isVideoOnly;
  const isAudio = RoadshowType.isSlidesAndAudio(type);

  const isCuePointsDisabled = (isVideo || isAudio) && isModerated;

  const exportButtonIsVisible = !isVideoOnly && !RoadshowType.isSlides(type) && !RoadshowType.isLiveVideo(type);
  const canExport = !(initialValues?.presentationMedia?.mediaVideo || initialValues?.presentationMedia?.mediaAudio);

  const { roadshowId } = useParams();

  useEffect(() => {
    getAudioVideo(roadshowId);

    return resetAudioVideo;
  }, []);

  useEffect(() => {
    initData(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (isVideoOnly) {
      let nextVideoOnlyCuePoints = uploadedCuePoints.map((cuePoint: ICuePoint, index) => {
        if (
          index < videoOnlyCuePoints.length &&
          Object.prototype.hasOwnProperty.call(videoOnlyCuePoints[index], 'sectionName')
        ) {
          return {
            number: cuePoint.slideNumber,
            time: cuePoint.time,
            sectionName: videoOnlyCuePoints[index].sectionName,
            displayName: cuePoint.displayName,
          };
        }
        return {
          number: cuePoint.slideNumber,
          time: cuePoint.time,
          displayName: cuePoint.displayName,
        };
      });
      change('videoOnlyCuePoints', nextVideoOnlyCuePoints);
    } else {
      change('cuePoints', uploadedCuePoints);
    }
  }, [uploadedCuePoints.length]);

  useEffect(() => {
    if (uploadedCuePointsFileName) {
      change('cuePoints', []);
    }
  }, [uploadedCuePointsFileName]);

  const getInitialAudioConferenceCode = (initialValues) => {
    let {
      type,
      audioConferenceCode,
    } = initialValues;

    if (
      RoadshowType.isSlidesAndVideo(type) ||
      RoadshowType.isSlidesAndAudio(type) ||
      RoadshowType.isVideoOnly(type)
    ) {
      return audioConferenceCode;
    }

    return undefined;
  };

  const initData = (initialValues) => {
    const audioConferenceCode = getInitialAudioConferenceCode(initialValues);
    if (audioConferenceCode) {
      setAudioConferenceCode(audioConferenceCode);
    }

    if (initialValues.presentationMedia?.mediaAudio) {
      initUploadPresentationMediaAudio(initialValues.presentationMedia.mediaAudio);
    }
    if (initialValues.presentationMedia?.mediaVideo) {
      initUploadPresentationMediaVideo(initialValues.presentationMedia.mediaVideo);
    }
    if (initialValues.presentationMedia?.mediaThumbnail) {
      initUploadPresentationMediaThumbnail(initialValues.presentationMedia.mediaThumbnail);
    }
  };

  const resetForm = () => {
    uploadClearForForm();
    initData(initialValues);
    reset();
  };

  const hasMediaChanges = (initialMedia, uploadedMedia) => {
    if (!initialMedia || !uploadedMedia) {
      return false;
    }

    if (isVideo) {
      if (!initialMedia.mediaVideo && uploadedMedia.presentationMediaVideo.video) {
        return true;
      }

      return (initialMedia.mediaVideo && uploadedMedia.presentationMediaVideo.video)
        && (initialMedia.mediaVideo.id !== uploadedMedia.presentationMediaVideo.video.id)
        && uploadedMedia.presentationMediaVideo.canSave;
    }

    if (isAudio) {
      if (!initialMedia.mediaAudio && uploadedMedia.presentationMediaAudio.audio) {
        return true;
      }

      return (initialMedia.mediaAudio && uploadedMedia.presentationMediaAudio.audio)
        && (initialMedia.mediaAudio.id !== uploadedMedia.presentationMediaAudio.audio.id)
        && uploadedMedia.presentationMediaAudio.canSave;
    }

    return false;
  };

  const hasChanges = useMemo(() => !pristine
      || initialValues.type !== type
      || !isEqual(initialValues.cuePoints, cuePoints)
      || !isEqual(initialValues.videoOnlyCuePoints, videoOnlyCuePoints)
      || hasMediaChanges(initialValues.presentationMedia, uploadedMedia),
    [initialValues, type, cuePoints, videoOnlyCuePoints, uploadedMedia, pristine],
  );

  return (
    <form
      onSubmit={ handleSubmit }
      autoComplete="off"
      data-test="audioVideoForm"
    >
      <div className={ cn(cardStyles.cardContainer, styles.formCard) }>
        <div className={ styles.formHeader }>
          <h3>Audio/Video</h3>
          { exportButtonIsVisible && (
            <Button
              variant={ ButtonVariantType.outline }
              dataTest="exportToMp4Button"
              className={ cn(
                buttonStyles.btnIcon,
                buttonStyles.btnSquare,
                spacesStyles.mrn,
                styles.exportBtn,
              ) }
              onClick={ () => generateVideoReplay(roadshowId) }
              disabled={ canExport }
            >
              <IconShare />
              Export to MP4
            </Button>
          ) }
        </div>
        <Field
          name="type"
          label="Roadshow Type:"
          component={ (fieldProps) => (
            <Select
              label={ fieldProps.label }
              name={ fieldProps.input.name }
              value={ fieldProps.input.value }
              onChange={ fieldProps.input.onChange }
              onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
              options={ roadshowTypesOptions }
              disabled={ isVideoOnly && !canBeChangedVideoType }
              placeholder="Select"
              simpleValue
              dataTest="audioVideoSelect"
              className={ cn(styles.formField, styles.firstFormField) }
            />
          ) }
        />
        { (isAudio || isVideo) &&
          (
            <>
              <FormField
                dataTest={ `condorRoadshow${ isVideo ? 'Video' : 'Audio' }UploadField` }
              >
                <div className={ formStyles.formLabel2 }>
                  Synced { isVideo ? 'Video' : 'Audio' }
                </div>
                { isVideo ? (
                  <UploadPresentationMediaVideoContainer
                    dropzoneInternalFieldComponent={ CondorDropzoneInternalField }
                    dropzoneHelperClassName={ styles.dropzone }
                  />
                ) : (
                  <UploadPresentationMediaAudioContainer
                    dropzoneInternalFieldComponent={ CondorDropzoneInternalField }
                    dropzoneHelperClassName={ styles.dropzone }
                  />
                ) }
                <div className={ styles.maxSizeTip }>The max file size supported 2 gigabytes.</div>
              </FormField>

              <Field
                name="audioConferenceCode"
                label="Conference Code:"
                component={ Input }
                dataTest="audioVideoConferenceCodeInput"
                disabled
                input={ { value: audioConferenceCode } }
                formFieldClassName={ styles.formField }
              />

              <>
                <div className={ formStyles.formLabel }>Cues:</div>
                <FieldArray
                  name={ isVideoOnly ? 'videoOnlyCuePoints' : 'cuePoints' }
                  component={ CuePoints }
                  cuePoints={ isVideoOnly ? videoOnlyCuePoints : cuePoints }
                  shiftCuePoints={ isVideoOnly ? shiftVideoOnlyCuePoints : shiftCuePoints }
                  type={ type }
                  disabled={ isCuePointsDisabled }
                />
              </>
            </>
          ) }
      </div>
      <Button
        variant={ ButtonVariantType.action }
        type="submit"
        disabled={ isFetching || disabled || !hasChanges }
        title="Save"
        dataTest="condorAudioVideoFormSaveButton"
      >Save
      </Button>
      <Button
        variant={ ButtonVariantType.text }
        disabled={ isFetching }
        onClick={ resetForm }
        dataTest="condorAudioVideoFormCancelButton"
      >
        Cancel
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'audioVideoForm',
  enableReinitialize: true,
  validate,
})(AudioVideoForm);
