import useUploadAttachments from './useUploadAttachments';
import { createContext, ReactNode, useContext } from 'react';

type UploadAttachmentsContextType = ReturnType<typeof useUploadAttachments>;

const UploadAttachmentsContext = createContext<UploadAttachmentsContextType>(null);

export const useUploadAttachmentsContext = () => {
  const context = useContext(UploadAttachmentsContext);

  if (!context) {
    throw new Error('useUploadAttachmentsContext must be used within the UploadAttachmentsContextProvider');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  onUploadStart?: () => void,
}

const UploadAttachmentsContextProvider = ({ children, onUploadStart }: IProps) => (
  <UploadAttachmentsContext.Provider value={ useUploadAttachments(onUploadStart) }>
    { children }
  </UploadAttachmentsContext.Provider>
);

export default UploadAttachmentsContextProvider;
