import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TRANCHES_VISIBLE } from '@/condor/ui/components/Finsight/DealProfile/constants';
import TRANCHE_FIELDS from '@/condor/ui/components/Finsight/DealProfile/constants/trancheFields';
import IconDraggable from '@dealroadshow/uikit/core/components/Icon/IconDraggable';
import TrancheFieldGroup from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/TrancheFieldGroup';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import DeleteTrancheButton from './DeleteTrancheButton';
import DuplicateTrancheButton
  from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/DuplicateTrancheButton';

import styles from './tranche.scss';

interface IProps {
  trancheIndex: number,
  id: string,
  field: string,
  isExcluded: boolean,
}

const Tranche = ({
  trancheIndex,
  id,
  field,
  isExcluded,
}: IProps) => {
  const {
    isStickyHeaderVisible,
    tranchesViewOffset,
  } = useTranchesContext();
  const {
    active,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = active && {
    transform: CSS.Translate.toString(
      isDragging && transform
        ? {
          ...transform,
          y: 0, // set to 0 to compensate for negative margin in styles
        }
        : transform,
    ),
    transition,
  };

  const trancheContainerStyle = cn(styles.trancheContainer, {
    [styles.isHoverable]: !isDragging,
    [styles.hoveredTranche]: isExcluded,
    [styles.isDragging]: isDragging,
    [styles.isVisible]: tranchesViewOffset <= trancheIndex && trancheIndex < tranchesViewOffset + TRANCHES_VISIBLE,
  });

  const trancheFieldsGrouped = groupBy(TRANCHE_FIELDS, 'groupId');

  return (
    <div
      ref={ setNodeRef }
      style={ style }
      className={ trancheContainerStyle }
    >
      <div className={ cn(styles.trancheHeader, { [styles.withStickyHeader]: isStickyHeaderVisible }) }>
        <span
          className={ styles.trancheDragIcon }
          { ...attributes }
          { ...listeners }
          data-test={ `trancheDragIcon${ trancheIndex }` }
        >
          <IconDraggable />
        </span>
        <div className={ styles.trancheLabel }>{ `Tranche ${ trancheIndex + 1 }` }</div>
        <div className={ styles.deleteTrancheBtnContainer }>
          <DuplicateTrancheButton index={ trancheIndex } field={ field } />
          <DeleteTrancheButton
            index={ trancheIndex }
            isExcluded={ isExcluded }
          />
        </div>
      </div>
      <div className={ styles.trancheBody }>
        { Object.entries(trancheFieldsGrouped).map(([groupName, groupFields]) => (
          <TrancheFieldGroup
            key={ groupName }
            groupName={ groupName }
            groupFields={ groupFields }
            field={ field }
            trancheIndex={ trancheIndex }
            isExcluded={ isExcluded }
          />
        )) }
      </div>
    </div>
  );
};

export default Tranche;
