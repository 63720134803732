import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import StatusIndicator, { StatusIndicatorType } from '@dealroadshow/uikit/core/components/StatusIndicator';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Input from '@dealroadshow/uikit/core/components/Input';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import DatePicker from '@dealroadshow/uikit/core/components/Picker/DatePicker';
import { FULL_DATE_FORMAT } from '@/Framework/DateTime/dateFormats';
import { useDataroomCondorActionsContext } from '@/dataroom/application/condor/DataroomCondorActionsContext';
import byteConverter from '@/Framework/dataHelpers/formatters/byteConverter';
import config from '@/dataroom/application/config/config';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './dataroomForm.scss';
import validate from './validator';
import ToggleActivation from 'src/dataroom/ui/components/Condor/EditDataroom/ToggleActivation';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';

const DataroomForm = () => {
  const history = useHistory();
  const {
    details: {
      isFetching: isDetailsFetching,
      dataroomDetails: dataroom,
    },
    edit: {
      editDataroom,
      isFetching: isEditFetching,
    },
  } = useDataroomCondorActionsContext();
  const { tenant } = useDataroomTenantContext();

  const isFetching = isDetailsFetching || isEditFetching;
  const { name: tenantName } = DataroomTenantConfig.fromCode(tenant);

  const handleCancel = () => {
    history.push(`/core/${ tenant }`);
  };

  const handleSubmit = (values) => editDataroom(dataroom.id, values)
    .then(() => {
      history.push(`/core/${ tenant }`);
    });
  const renderForm = useCallback(
    ({
      submitting,
      valid,
      submitFailed,
      dirty,
    }, { Field }) => (

      <div>
        <div className={ cn(cardStyles.cardContainer, spacesStyles.mbn, styles.card) }>
          <div className={ styles.header }>
            <div className={ styles.headerText }>{ tenantName } Data Room Settings</div>
            <div>
              <Tooltip
                placement="bottom"
                content={ dataroom.isActive ? 'Live' : 'Deactivated' }
                className={ styles.hideTooltipOnMobile }
              >
                <StatusIndicator
                  className={ styles.statusIndicator }
                  status={
                    dataroom.isActive ? StatusIndicatorType.live : StatusIndicatorType.expired
                  }
                />
              </Tooltip>
            </div>
          </div>
          <Field
            name="displayName"
            label="Data Room Name"
            component={ Input }
            dataTest="dataroomNameInput"
            className={ styles.wideInput }
          />
          <Field
            name="name"
            label="Data Room Url"
            component={ Input }
            dataTest="dataroomUrlInput"
            className={ styles.wideInput }
            disabled
          />
          { tenant === config.tenant.tenant17g5.code && (
            <Field
              name="defaultUploadingEmail"
              label="Email (for uploading files)"
              component={ Input }
              dataTest="dataroomEmailUploadingInput"
              className={ styles.wideInput }
              disabled
            />
          ) }
          <Field
            name="createdAt"
            component={ (fieldProps) => (
              // TODO: add attribute onChange
              // @ts-ignore
              <DatePicker
                customInput={ (
                  <Input
                    /* @ts-ignore */
                    input={ { name: fieldProps.input.name } }
                    meta={ fieldProps.meta }
                    name={ fieldProps.input.name }
                    label="Date Created"
                    icon={ IconCalendar }
                    iconPosition="right"
                    dataTest="createdAtDateInput"
                    className={ styles.input }
                  />
                ) }
                selected={ new Date(fieldProps.input.value) }
                dateFormat={ FULL_DATE_FORMAT }
                dataTest="createdAtDatePicker"
                disabled
              />
            ) }
          />
          <Field
            name="size"
            label="Actual Data Room Size"
            component={ Input }
            dataTest="sizeInput"
            className={ styles.input }
            isNarrow
            disabled
          />
        </div>
        <div className={ styles.createButtonWrp }>
          <Button
            type="submit"
            variant={ ButtonVariantType.action }
            disabled={
              (!valid && submitFailed) || !dirty || isEditFetching || submitting
            }
            title="Save"
            dataTest="submit"
          />
          <Button
            variant={ ButtonVariantType.text }
            onClick={ handleCancel }
            disabled={ isEditFetching || submitting }
            dataTest="cancel"
            title="Cancel"
          />
          <div className={ styles.deactivateButtonWrp }>
            <ToggleActivation />
          </div>
        </div>
        <Spinner
          className={ styles.spinner }
          isVisible={ isFetching }
          centered
          overlay
        />
      </div>
    ),
    [dataroom.isActive, isDetailsFetching, isEditFetching],
  );

  return (
    <FinalForm
      initialValues={ {
        name: dataroom.name,
        displayName: dataroom.displayName,
        defaultUploadingEmail: dataroom.defaultUploadingEmail,
        createdAt: dataroom.createdAt,
        size: byteConverter(Number(dataroom.size)),
      } }
      onSubmit={ handleSubmit }
      render={ renderForm }
      isFetching={ isDetailsFetching }
      validate={ validate }
      name="editDataroomForm"
      dataTest="editDataroomForm"
      keepDirtyOnReinitialize
    />
  );
};

export default DataroomForm;
