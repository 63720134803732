import React, { Fragment } from 'react';
import cn from 'classnames';
import { TRANCHE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';

import styles from './properties.scss';

const Property = ({
  name,
  labelTooltipContent,
  label,
  groupId,
}: IDealProfileFormField) => {
  const { collapsedGroup } = useTranchesContext();

  return (
    <Fragment key={ name }>
      <div className={ cn(styles.property,
        {
          [styles.commentProperty]: name === TRANCHE_FIELDS.COMMENTS.name,
          [styles.collapsedProperty]: collapsedGroup.includes(groupId),
        },
      ) }
      >
        <Tooltip content={ labelTooltipContent || label }>
          { label }
        </Tooltip>
      </div>
    </Fragment>
  );
};

export default Property;
