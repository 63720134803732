import { Field, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS, TRANCHE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { ProductAbbreviations } from '@/finsight/domain/Product';
import { isTrancheFieldVisible } from '@/condor/ui/components/Finsight/DealProfile/Tranches/helpers';
import Sector from '@/finsight/domain/Sector';
import FIELD_COMPONENTS from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/FieldComponents';
import { FIELD_TYPE } from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/constants';
import type { ITranche } from '@/condor/domain/vo/Finsight/Deal/Tranche';
import type { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

interface ITrancheFieldProps {
  field: string,
  trancheIndex: number,
  fieldData: IDealProfileFormField,
  selectedProduct: ProductAbbreviations,
}

export interface IFieldBaseProps {
  name: string,
  dataTest: string,
  disabled: boolean,
}

export interface IFieldProps extends IFieldBaseProps {
  cellClassName: string,
  tranche?: ITranche,
  isSlim?: boolean,
  options?: ISelectOption[],
  config?: { options: ISelectOption[] },
  className?: string,
  input?: { value: string, name: string, disabled: boolean },
  issuerSelect?: boolean,
}

export const TrancheField = ({ field, trancheIndex, fieldData, selectedProduct }: ITrancheFieldProps) => {
  const options = useSelector(selectors.getOptions);
  const { isExcluded, sectorSubsector, tranche } = useSelector((state) => ({
    isExcluded: formSelector(state, `${ field }.${ TRANCHE_FIELDS.IS_EXCLUDED.name }`),
    sectorSubsector: formSelector(state, DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name),
    tranche: formSelector(state, field),
  }));

  const isSectorCmbs = sectorSubsector?.sectorId ? Sector.isCmbsSectorId(sectorSubsector.sectorId) : false;

  if (!isTrancheFieldVisible(fieldData, selectedProduct, isSectorCmbs)) {
    return null;
  }

  const getFieldType = () => Object.entries(FIELD_TYPE).find(([key]) => fieldData[key])?.[1] || 'text';

  const fieldType = getFieldType();
  const Component = FIELD_COMPONENTS[fieldType as keyof typeof FIELD_COMPONENTS];

  const baseProps: IFieldBaseProps = {
    name: `${ field }.${ fieldData.name }`,
    dataTest: `dealTranche[${ trancheIndex }].${ fieldData.name }`,
    disabled: isExcluded,
  };

  const additionalProps = {
    ...(fieldData.isSelect && { options: options[fieldData.optionsKey!] }),
    ...(fieldData.isRadio && { config: fieldData.config }),
    ...(fieldData.className && { className: fieldData.className }),
    ...(fieldData.placeholder && { placeholder: fieldData.placeholder }),
    ...(fieldData.issuerSelect && { isSlim: true }),
    ...(fieldData.autoCalculated && { tranche }),
  };

  return (
    <Field
      component={ Component }
      { ...baseProps }
      { ...additionalProps }
    />
  );
};

export default TrancheField;
