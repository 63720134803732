import React, { memo } from 'react';

import styles from './validationTable.scss';
import IconAddCircle from '@dealroadshow/uikit/core/components/Icon/IconAddCircle';

interface IProps {
  activeCell: any,
  companiesCollection: any[],
  company: any,
  initCollection: (companiesCollection: any[]) => void,
  initCompany: (company: any) => void,
  onSelectModalOpen: () => void,
  setActiveCell: (activeCell: any) => void,
}

const SelectCompanyIcon = (
  {
    activeCell,
    companiesCollection,
    company,
    initCollection,
    initCompany,
    onSelectModalOpen,
    setActiveCell,
  }: IProps,
) => (
  <IconAddCircle
    className={ styles.plusCircleIcon }
    onClick={ () => {
      setActiveCell(activeCell);
      initCollection(companiesCollection);
      initCompany(company);
      onSelectModalOpen();
    } }
  />
);

export default memo(SelectCompanyIcon);
