import cn from 'classnames';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import validate from './validate';

import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import styles from './styles.scss';

const DELETE_ENABLED_TOOLTIP = 'Delete';
const DELETE_DISABLED_TOOLTIP = 'This Category cannot be deleted because existing Stats are tied to it. Please move these Stats to a different Category in order to delete this Category.';

const getColumns = (fields) => [
  {
    name: 'index',
    title: '#',
    width: '15px',
    className: styles.indexCol,
    cellCallback: ({
      rowIndex,
    }: { rowIndex: number }) => rowIndex + 1,
  },
  {
    name: 'dragAndDrop',
    width: '21px',
    maxWidth: '21px',
    className: cn(dataTableStyles.draggableCol, styles.draggableCol),
    isDraggable: true,
    title: '',
  },
  {
    name: 'categories',
    title: 'Categories',
    minWidth: '491px',
    maxWidth: '491px',
    width: '491px',
    className: dataTableStyles.inputCol,
    cellCallback: ({ row }) => {
      return (
        <Field
          name={ `${ row.fieldName }.name` }
          component={ Input }
          dataTest={ `${ row.fieldName }.nameInput` }
          isNarrow
          isClearable={ false }
          validate={ validate }
          validateFields={ [] }
        />
      );
    },

  },
  {
    name: 'actions',
    title: '',
    className: styles.actionCol,
    cellCallback: ({ row }) => {
      return (
        <div
          className={ cn(dataTableStyles.actionItem, {
            [dataTableStyles.isActionDisabled]: !row.canBeDeleted,
          }) }
          data-test="removeCategoryButton"
          onClick={ () => {
            if (row.canBeDeleted) {
              fields.remove(row.fieldIndex);
            }
          } }
        >
          <Tooltip
            content={ row.canBeDeleted ? DELETE_ENABLED_TOOLTIP : DELETE_DISABLED_TOOLTIP }
          >
            <IconTrash />
          </Tooltip>
        </div>
      );
    },
  },
];

export default getColumns;
