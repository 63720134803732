import React from 'react';

import Button from '@dealroadshow/uikit/core/components/Button';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

interface IProps {
  isFetching: boolean,
  isAlreadyUpdated: boolean,
}

const ModalButton = ({
  isAlreadyUpdated,
  isFetching,
  ...otherProps
}: IProps) => (
  <Button
    disabled={ isAlreadyUpdated }
    dataTest="modalAlreadyUpdatedButton"
    { ...otherProps }
  >
    {
      isFetching && (
        <Spinner size="small" mode="light" />
      )
    }
  </Button>
);

export default ModalButton;
