import { Link } from 'react-router-dom';

import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { Paginator } from '@dealroadshow/uikit/core/components/Paginator';
import StatsListContextProvider,
{ useStatsListContext } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsList';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import columns from './columns';
import TableFilters from '@/condor/ui/components/Finsight/CreditFlowResearch/common/TableFilters';
import {
  CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_STAT_BASE_URL,
} from '@/condor/ui/common/constants';
import { IStatToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import paginatorPerPageOptions from '@/condor/ui/components/Finsight/CreditFlowResearch/common/constants';
import ManageStatsCategories from './ManageStatsCategories';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import { buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import styles from './styles.scss';

const StatsTable = () => {
  const {
    collection,
    totalCount,
    page,
    perPage,
    paginate,
    sort,
    setItemsPerPage,
    sortBy,
    sortOrder,
    isFetching,
    debouncedSearch,
    debouncedSearchString,
  } = useStatsListContext();

  return (
    <>
      <TableWrapper
        filterComponent={ (
          <div className={ styles.tableFilters }>
            <TableFilters
              debouncedSearchString={ debouncedSearchString }
              onSearchChange={ debouncedSearch }
              searchPlaceholder="Filter by Name, Description or Category"
            />
            <ManageStatsCategories />
            <Link
              to={ CONDOR_FINSIGHT_CREDIT_FLOW_CREATE_STAT_BASE_URL }
              className={ buttonStyles.action }
              data-test="addStatButton"
            >
              Add Stat
            </Link>
          </div>
        ) }
      >
        <DataTable
          columns={ columns }
          dataTest="statsTable"
          data={ collection as IStatToDisplay[] }
          onSortChange={ sort }
          sortBy={ sortBy }
          sortOrder={ sortOrder }
          isFetching={ isFetching }
          className={ dataTableStyles.isHoverable }
          loadingComponent={ Spinner }
        />
        <Paginator
          totalCount={ totalCount }
          page={ page }
          perPage={ perPage }
          onItemsPerPageChange={ ({ value }) => setItemsPerPage(value) }
          onPageChange={ ({ selected }) => paginate(selected) }
          className={ cardStyles.cardInner }
          dataTest="statsTablePaginator"
          customOptions={ paginatorPerPageOptions }
        />
      </TableWrapper>
    </>
  );
};

export default () => (
  <StatsListContextProvider>
    <StatsTable />
  </StatsListContextProvider>
);
