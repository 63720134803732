import TRANCHE_FIELDS from '@/condor/ui/components/Finsight/DealProfile/constants/trancheFields';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import { ProductAbbreviations } from '@/finsight/domain/Product';

export const isTrancheFieldVisible = (
  fieldData: IDealProfileFormField,
  selectedProduct: ProductAbbreviations,
  isSectorCmbs: boolean,
) => {
  const { isHidden, hiddenWithProducts, name } = fieldData;

  if (name === TRANCHE_FIELDS.INTEREST_ONLY.name) {
    return isSectorCmbs;
  }

  const isHiddenByProduct = hiddenWithProducts?.includes(selectedProduct) ?? isHidden;
  return !isHiddenByProduct;
};
