import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CONDOR_ACCOUNT_TYPES_LIST_BASE_URL } from '@/condor/ui/common/constants';
import useItemFactory from '@/Framework/State/useItemFactory';
import { AccountType, AccountTypePayload } from '@/condor/domain/vo/Admin/AccountType/AccountType';
import AccountTypeRepository from '@/users/infrastructure/repository/AccountTypeRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { INITIAL_ACCOUNT_TYPE_STATE } from '@/condor/application/Accounts/Admin/AccountType/Profile/constants';
import sleep from '@/Framework/dataHelpers/sleep';

const useAccountTypeProfile = () => {
  const { container } = useDIContext();
  const { push, location: { pathname } } = useHistory();
  const { id } = useParams();
  const isCreating = pathname.includes('create');
  const [isFetching, setIsFetching] = useState(false);

  const onError = () => push(CONDOR_ACCOUNT_TYPES_LIST_BASE_URL);
  const accountTypeRepository = container.get<AccountTypeRepository>(AccountTypeRepository);

  const {
    state: itemState,
    getItemData,
  } = useItemFactory<AccountType, typeof accountTypeRepository.getById>(
    {
      initialItemState: INITIAL_ACCOUNT_TYPE_STATE,
      getRequestConfig: {
        method: accountTypeRepository.getById,
        callbacks: {
          onError,
        },
      },
    },
  );

  const createOrUpdateAccountType = async (formData: AccountTypePayload) => {
    setIsFetching(true);
    await (formData?.id
        ? accountTypeRepository.update(formData)
        : accountTypeRepository.create(formData)
    );
    await sleep(2500);
    setIsFetching(false);
    push(CONDOR_ACCOUNT_TYPES_LIST_BASE_URL);
  };

  useEffect(() => {
    if (isCreating) return;
    getItemData(id);
  }, []);

  return {
    ...itemState,
    isFetching: isFetching || itemState.isFetching,
    isCreating,
    onCancel: onError,
    createOrUpdateAccountType,
  };
};

export default useAccountTypeProfile;
