import React from 'react';
import { Label } from '@dealroadshow/uikit/core/components/Badge/Label';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import ISector from '@/finsight/domain/vo/ISector';

import styles from './styles.scss';

const TableSectorTooltip = ({
  sector,
  tooltip,
}: { sector: ISector, tooltip: string }) => (
  <Tooltip
    placement="bottom"
    content={ tooltip }
  >
    <Label
      className={ styles.sectorLabel }
      backgroundColor={ sector.color }
    >
      { sector.abbreviation }
    </Label>
  </Tooltip>
);

export default TableSectorTooltip;
