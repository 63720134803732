import { GroupBase } from 'react-select';
import React, { useEffect, useMemo, useState } from 'react';
import { getOptionsMapper, getToolbarOptions } from './helpers';
import RadioButtonsToolbar from './RadioButtonsToolbar';
import { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';
import { IOption } from './interfaces';

interface IUseToolbarSelectSyncProps<SOption, Group extends GroupBase<SOption>> {
  options: IOption<SOption, Group>[],
  dataTest: string,
  defaultOptionValue?: string | number,
}

const useToolbarSelectSync = <
  SOption extends ISelectOption,
  Group extends GroupBase<SOption>,
>({
  options,
  dataTest,
  defaultOptionValue,
}: IUseToolbarSelectSyncProps<SOption, Group>) => {
  const firstNoDisabledOption = options.find((option) => !option.isDisable);
  const [currentOption, setCurrentOption] = useState(defaultOptionValue || firstNoDisabledOption?.value);
  const optionsMapper = useMemo(() => getOptionsMapper(options), [options]);
  const toolbarOptions = useMemo(() => getToolbarOptions(options), [options]);
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (!currentOption) {
      setCurrentOption(defaultOptionValue || firstNoDisabledOption?.value);
    }
  }, [defaultOptionValue, options]);

  useEffect(() => {
    setSelectOptions(optionsMapper[currentOption] || []);
  }, [currentOption, optionsMapper]);

  return {
    options: selectOptions,
    toolbarComponent: () => (
      <RadioButtonsToolbar
        options={ toolbarOptions }
        dataTest={ dataTest }
        selectedValue={ currentOption }
        onChange={ (value) => setCurrentOption(value) }
      />
    ),
  };
};

export default useToolbarSelectSync;
