import React from 'react';
import { RadioGroup, Radio } from '@dealroadshow/uikit/core/components/Radio';

import { useFilterContext } from '../Filter';

import styles from '../filterGroup.scss';

interface IProps {
  radioGroupNames?: { [key: string]: any },
}

const RadioGroups = ({ radioGroupNames = {} }: IProps) => {
  const context = useFilterContext();

  const handleFilterChange = (value: any, groupName: string) => {
    context.onChange('radio', value, groupName);
  };

  return (
    <div className={ styles.filterRadioGroups }>
      {
        Object.keys(context.config.radio)
          .map((groupName, index) => {
            let group = context.config.radio[groupName];
            return (
              /* eslint-disable-next-line react/no-array-index-key */
              <div className={ styles.filterRadioGroup } key={ index }>
                { (!!radioGroupNames[groupName] && (
                  <div className={ styles.filterRadioGroupLabel }>
                    { radioGroupNames[groupName] }
                  </div>
                )) }
                <RadioGroup
                  name={ groupName }
                  selectedValue={ context.state.radio[groupName] }
                  onChange={ (value) => handleFilterChange(value, groupName) }
                  dataTest="condorFilterGroupRadio"
                >
                  {
                    group.group.map((item, index) => (
                      <Radio
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={ index }
                        dataTest={ item.text }
                        value={ item.value }
                        label={ item.text }
                      />
                    ))
                  }
                </RadioGroup>
              </div>
            );
          })
      }
    </div>
  );
};

export default RadioGroups;
