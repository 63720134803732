import { ReactNode } from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import styles from './styles.scss';

interface IProps {
  children?: ReactNode,
  closePreviewModal: () => void,
  onPublish: () => Promise<void>,
  isFetching: boolean,
}

export const PreviewModalFooter = ({ children, closePreviewModal, onPublish, isFetching }: IProps) => (
  <div className={ styles.previewModalFooter }>
    <div>
      <Button
        variant={ ButtonVariantType.action }
        onClick={ onPublish }
        title="Publish"
        dataTest="previewModalPublishButton"
        disabled={ isFetching }
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ closePreviewModal }
        title="Cancel"
        dataTest="previewModalCancelButton"
        disabled={ isFetching }
      />
    </div>
    { children }
  </div>
);
