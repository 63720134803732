import isEmpty from 'lodash/isEmpty';
import { DEAL_PROFILE_FIELDS, TRANCHE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { messageCodes } from '@/Framework/Message/messages';

/**
 * @param {Object} values
 */
const validateOnSubmit = (values) => {
  const errors = [];

  if (
    (values[DEAL_PROFILE_FIELDS.ANNOUNCED_DATE.name] || values[DEAL_PROFILE_FIELDS.PRICING_DATE.name]) &&
    isEmpty(
      values[DEAL_PROFILE_FIELDS.TRANCHES.name].filter(
        (tranche) => !tranche[TRANCHE_FIELDS.IS_EXCLUDED.name],
      ),
    )
  ) {
    errors.push({
      text: messageCodes.CONDOR_DEAL_PROFILE_REQUIRED_TRANCHES_ERROR,
      data: {
        tranchesLabel: DEAL_PROFILE_FIELDS.TRANCHES.label,
        announcedDateLabel: DEAL_PROFILE_FIELDS.ANNOUNCED_DATE.label,
        pricingDateLabel: DEAL_PROFILE_FIELDS.PRICING_DATE.label,
      },
    });
  }

  return errors;
};

export default validateOnSubmit;
