import { IValidationError } from './interfaces/ValidationError';

export interface IParams {
  value: string,
  allFieldsValue: { collection: ({ [key: string] : string })[] },
  fieldName: string,
  fieldCaption?: string,
  listName: string,
  inputErrors: {
    listName: string,
    value: string,
  },
  errorText?: string,
  isArray?: boolean,
}

export const validateDuplicate = (params: IParams): IValidationError => {
  if (!params.value || params.allFieldsValue.collection.length === 0) {
    return null;
  }
  const exist = params.allFieldsValue.collection.filter((field) => field[params.fieldName] === params.value);

  if (exist.length > 1 && (!params.inputErrors[params.value] || params.isArray)) {
    params.inputErrors[params.value] = true;
    return {
      [params.fieldName]: params.errorText
        ? params.errorText
        : `${ params.fieldCaption || 'Field' } is already in ${ params.listName }`,
    };
  }
  return null;
};
