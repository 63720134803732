import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { CallReplayMethod } from '@/evercall/domain/vo/call/CallReplayMethod';

export const INITIAL_CALL_ITEM_STATE: IAdminCall = {
  id: '',
  name: '',
  groupId: null,
  groupName: '',
  groupPath: '',
  isFooterHidden: false,
  participantConferenceId: '',
  roadshowId: '',
  roadshowEntryCode: '',
  replayMethod: CallReplayMethod.None,
  replayUrl: null,
};
