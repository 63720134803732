import React from 'react';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Link from '@/Framework/Router/ReactRouter/Link';
import { CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';

import styles from '../traceTable.scss';

interface IProps {
  isMapped: boolean,
  dealId: string,
  onMapClick: () => void,
  onArchiveClick: (e: React.SyntheticEvent) => void,
}

const ActionButtons = ({
  isMapped,
  dealId,
  onMapClick,
  onArchiveClick,
}: IProps) => (
  <div className={ styles.hoveredContainer }>
    { isMapped && (
      <Tooltip content="View deal profile">
        <Link to={ `${ CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/${ dealId }` }>
          <IconExternalLink className={ styles.link } />
        </Link>
      </Tooltip>
    ) }
    <Tooltip
      disabled={ !isMapped }
      content="Due to CUSIP conflict, mapping this trade to this tranche requires manual CUSIP removal"
    >
      <Button
        variant={ ButtonVariantType.action }
        className={ isMapped ? styles.mapBtnDisabled : styles.mapBtn }
        onClick={ onMapClick }
        title={ isMapped ? 'Unmap' : 'Map' }
        dataTest={ isMapped ? 'tradeUnmapButton' : 'tradeMapButton' }
      />
    </Tooltip>
    <Button
      variant={ ButtonVariantType.action }
      className={ styles.mapBtn }
      onClick={ onArchiveClick }
      title="Archive"
      dataTest="tradeArchiveButton"
    />
  </div>
);

export default ActionButtons;
