import { useDispatch } from 'react-redux';
import selectors from '@/condor/application/actions/finsight/subscriptions/collection/selectors';
import {
  sort,
  getCondorFinsightSubsribersListByFilter,
} from '@/condor/application/actions/finsight/subscriptions/collection/actions';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import columns from './columns';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

const stateSelector = (state) => (
  {
    data: selectors.getCollectionData(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }
);

const SubscribersTableContainer = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    isFetching,
    sortBy,
    sortOrder,
  } = useReduxSelector(stateSelector);

  const onSortChange = (sortBy, sortOrder) => {
    dispatch(sort({
      sortBy,
      sortOrder,
    }));
    dispatch(getCondorFinsightSubsribersListByFilter());
  };

  return (
    <DataTable
      { ...props }
      columns={ columns }
      loadingComponent={ Spinner }
      className={ dataTableStyles.isHoverable }
      data={ data }
      isFetching={ isFetching }
      sortBy={ sortBy }
      sortOrder={ sortOrder }
      onSortChange={ onSortChange }
      dataTest="subscribersTable"
    />
  );
};

export default SubscribersTableContainer;
