import Modal from '@dealroadshow/uikit/core/components/Modal';
import formatEventDescription from './helper';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import styles from './eventDetailsModal.scss';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

interface IProps {
  data: { [key: string]: any },
  onClose: () => void,
}

function EventDetailsModal({
  data,
  onClose,
}: IProps) {
  const {
    title,
    description,
  } = formatEventDescription(data?.actionMessage);

  return (
    <Modal
      isVisible
      title={ title }
      modalTitleClassName={ styles.breakWord }
      hideOnOverlayClicked
      onCloseClicked={ onClose }
      footer={ (
        <Button
          onClick={ onClose }
          variant={ ButtonVariantType.action }
          title="Close"
          dataTest="closeFooterButton"
        />
      ) }
      dataTest="eventDetailsModal"
    >
      <span className={ styles.breakWord } data-test="message">
        { decodeSymbols(description) }
      </span>
    </Modal>
  );
}

export default EventDetailsModal;
