import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: any[],
  length: number,
  fieldName: string,
  fieldCaption?: string,
}

export const validateArrayMaxLength = (params: IParams): IValidationError => {
  if (params.value?.length > params.length) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Field' } may not contain more than ${ params.length } items` };
  }
  return null;
};
