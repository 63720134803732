import { Field } from 'redux-form';
import cn from 'classnames';
import { RadioGroup, Radio } from '@dealroadshow/uikit/core/components/Radio';
import withTrancheCell from '@/condor/ui/components/Finsight/DealProfile/helpers/withTrancheCell';
import type { IFieldProps } from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/TrancheField';

import styles from './styles.scss';

const TrancheRadioGroup = withTrancheCell(RadioGroup);

export const RadioGroupField = ({ config, ...props }: IFieldProps) => (
  <Field
    name={ props.input.name }
    component={ (fieldProps) => (
      <TrancheRadioGroup
        { ...fieldProps.input }
        selectedValue={ fieldProps.input.value }
        className={ cn(styles.radioGroup, props.className) }
        disabled={ props.disabled }
      >
        { config.options.map((option) => (
          <Radio
            key={ option.label }
            label={ option.label }
            value={ option.value as string }
            dataTest={ `${ props.dataTest }${ option.value }` }
          />
        )) }
      </TrancheRadioGroup>
    ) }
    { ...props }
  />
);
