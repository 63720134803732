import { useHistory } from 'react-router';

import ActionModal from 'src/condor/ui/components/Finsight/CreditFlowResearch/common/ActionModal';
import { ActionModalType, ResearchType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import {
  UnpublishModalContextProvider,
  useUnpublishModalContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/UnpublishModalContext';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST } from '@/condor/ui/common/constants';

interface IProps {
  closeUnpublishModal: () => void,
}

const UnpublishModal = ({ closeUnpublishModal }: IProps) => {
  const { push } = useHistory();
  const { stat, onUnpublish, isUnpublishing } = useUnpublishModalContext();

  const unpublishStat = async () => {
    const isUnpublished = await onUnpublish();
    if (isUnpublished) {
      closeUnpublishModal();
      push(CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST);
    }
  };

  return (
    <ActionModal
      isModalOpen
      modalType={ ActionModalType.UNPUBLISH_STAT }
      closeModal={ closeUnpublishModal }
      title={ stat.name }
      authorName={ stat.author.name }
      tags={ stat.categories }
      handleAction={ unpublishStat }
      isRequestPerforming={ isUnpublishing }
      researchType={ ResearchType.STAT }
    />
  );
};

export default ({ closeUnpublishModal }: IProps) => (
  <UnpublishModalContextProvider>
    <UnpublishModal closeUnpublishModal={ closeUnpublishModal } />;
  </UnpublishModalContextProvider>
);
