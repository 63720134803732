import { connect } from 'react-redux';
import {
  getGlobalBlacklistByFilter,
  sort,
} from '@/condor/application/actions/blacklists/global/actions';
import selectors from '@/condor/application/actions/blacklists/global/selectors';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';

const mapStateToProps = (state) => (
  {
    data: selectors.getCollectionData(state),
    isFetching: selectors.isFetching(state) || selectors.isAdding(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({
        sortBy,
        sortOrder,
      }));
      dispatch(getGlobalBlacklistByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataTable);
