import React, { useMemo, useCallback } from 'react';
import getDealTrancheProperties from '../../helpers/getDealTrancheProperties';
import PropertyGroup from '@/condor/ui/components/Finsight/DealProfile/Tranches/Properties/PropertyGroup';
import { isTrancheFieldVisible } from '@/condor/ui/components/Finsight/DealProfile/Tranches/helpers';
import { ProductAbbreviations } from '@/finsight/domain/Product';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import { TrancheGroup } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';

import styles from './properties.scss';

interface IProps {
  isSectorCmbs: boolean,
  selectedProduct: ProductAbbreviations,
}

const Properties = ({ isSectorCmbs, selectedProduct }: IProps) => {
  const { agenciesToShow } = useTranchesContext();
  const properties = useMemo(
    () => getDealTrancheProperties(agenciesToShow),
    [agenciesToShow],
  );

  const checkIsPropertyVisible = useCallback(
    (property: IDealProfileFormField) => isTrancheFieldVisible(property, selectedProduct, isSectorCmbs),
    [isSectorCmbs, selectedProduct],
  );

  return (
    <div className={ styles.properties }>
      { Object.entries(properties).map(([group, propertiesByGroup]) => (
        <PropertyGroup
          propertiesByGroup={ propertiesByGroup.filter(checkIsPropertyVisible) }
          group={ group as TrancheGroup }
          key={ group }
        />
      )) }
    </div>
  );
};

export default Properties;
