import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { IColumn } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/Column';
import { IListEvent } from '@/openMarket/domain/Condor/vo/IEvent';
import { columns as commonColumns } from '@/openMarket/ui/common/Condor/EventsList/columns';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

import eventListStyles from '@/openMarket/ui/common/Condor/EventsList/eventsList.scss';
import styles from './profileEvents.scss';

interface ICellProps {
  row: IListEvent,
}

const DisplayTab = ({ row }: ICellProps) => {
  const { displayTabs } = row.profiles[0];

  return (
    <Tooltip
      disabled={ displayTabs.length === 0 }
      content={ displayTabs.map((tab) => decodeSymbols(tab.name))
        .join(', ') }
      maxWidth={ 480 }
    >
      <span className={ eventListStyles.profilesTooltipAction }>
        {
          displayTabs.length > 0
            ? displayTabs.map((tab) => decodeSymbols(tab.name))
              .join(', ')
            : '-'
        }
      </span>
    </Tooltip>
  );
};

export const columns: IColumn[] = commonColumns.map((column) => {
  if (column.name === 'profiles') {
    return {
      name: 'profiles',
      title: 'Display tab',
      cellCallback: DisplayTab,
    };
  }

  if (column.name === 'name') {
    return {
      ...column,
      width: 520,
      className: styles.name,
    };
  }

  return column;
});
