import React, { useRef, useState } from 'react';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import IconCheckCircle from '@dealroadshow/uikit/core/components/Icon/IconCheckCircle';
import IconTagged from '@dealroadshow/uikit/core/components/Icon/IconTagged';
import IconCancelCircle from '@dealroadshow/uikit/core/components/Icon/IconCancelCircle';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconKebabMenu from '@dealroadshow/uikit/core/components/Icon/IconKebabMenu';
import {
  Dropdown,
  DropdownItem,
  DropdownItemType,
  DropdownRef,
} from '@dealroadshow/uikit/core/components/Menu/Dropdown';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';
import { Button, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import { useCheckBoxArrayContext } from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import { useFieldArray, useForm, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes as ActionButtonVariantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';

import styles from './actionButtons.scss';
import IconDeny from '@dealroadshow/uikit/core/components/Icon/IconDeny';

interface ICheckboxProps {
  allItems: string[],
  selectedItems: string[],
}

const fieldArrayName = 'editableTableArray';

export const ListActionButtons = ({
  row,
  rowIndex,
}: ICellProps) => {
  const formState = useFormState();
  const form = useForm();

  const dropdownRef = useRef<DropdownRef>();
  const {
    editableRowId,
    setEditableRowId,
    updateRoadshow,
  } = useEnhancedTaggingRoadshowsContext();
  const { setValues: setCheckboxValues } = useCheckBoxArrayContext();

  const currentId = row.id;

  const deleteIdFromCheckedList = (id = row.id) => {
    setCheckboxValues((prevProps: ICheckboxProps) => {
      const { selectedItems } = prevProps;
      return {
        ...prevProps,
        selectedItems: selectedItems.filter((element) => element !== id),
      };
    });
  };

  const handleOnEdit = () => {
    if (editableRowId) {
      deleteIdFromCheckedList(editableRowId);
      form.reset();
    }
    setEditableRowId(currentId);
    setCheckboxValues((prevProps: ICheckboxProps) => {
      const { selectedItems } = prevProps;
      return {
        ...prevProps,
        selectedItems: selectedItems.includes(currentId) ? selectedItems : [...selectedItems, currentId],
      };
    });
    dropdownRef.current.hide();
  };

  const onSubmit = async () => {
    dropdownRef.current.hide();

    try {
      const { editableTableArray } = formState.values;
      await updateRoadshow(editableTableArray[rowIndex]);
      form.submit();
      setEditableRowId(null);
      deleteIdFromCheckedList();
    } catch (e) {
      // do nothing
    }
  };

  const onCancel = () => {
    dropdownRef.current.hide();
    form.reset();
    setEditableRowId(null);
    deleteIdFromCheckedList();
  };

  const isEditable = editableRowId === currentId;

  return (
    <>
      <span className={ styles.desktopActions }>
        { isEditable ? (
          <>
            <ActionButton
              title="Save Changes"
              className={ styles.actionButton }
              icon={ IconCheckCircle }
              variant={ ActionButtonVariantTypes.icon }
              dataTest="submit"
              onClick={ onSubmit }
              isDisabled={ formState.errors.editableTableArray?.[rowIndex] }
            />
            <ActionButton
              title="Save & Mark as Fully Tagged"
              className={ styles.actionButton }
              icon={ IconTagged }
              variant={ ActionButtonVariantTypes.icon }
              dataTest="fullyTagged"
              onClick={ noop }
              isDisabled
            />
            <ActionButton
              title="Cancel Changes"
              className={ styles.actionButton }
              icon={ IconCancelCircle }
              variant={ ActionButtonVariantTypes.icon }
              dataTest="cancel"
              onClick={ onCancel }
            />
          </>
        ) : (
          <ActionButton
            title="Edit"
            className={ styles.actionButton }
            icon={ IconPencil }
            variant={ ActionButtonVariantTypes.icon }
            dataTest="editRoadshow"
            onClick={ handleOnEdit }
          />
        ) }
      </span>
      <span className={ styles.mobileAction }>
        <Dropdown
          usePortal
          ref={ dropdownRef }
          trigger={ (
            <Button
              variant={ ButtonVariantType.link }
              className={ styles.dropdownMenuButton }
              dataTest="openMobileActions"
            >
              <IconKebabMenu />
            </Button>
          ) }
          contentClassName={ styles.dropdownContent }
        >
          { isEditable ? (
            <>
              <DropdownItem
                icon={ IconCheckCircle }
                dataTest="submit"
                type={ DropdownItemType.button }
                onClick={ onSubmit }
              >
                Save Changes
              </DropdownItem>
              <DropdownItem
                icon={ IconTagged }
                dataTest="fullyTagged"
                type={ DropdownItemType.button }
                onClick={ noop }
                disabled
              >
                Save as Fully Tagged
              </DropdownItem>
              <DropdownItem
                icon={ IconCancelCircle }
                dataTest="cancel"
                type={ DropdownItemType.button }
                onClick={ onCancel }
              >
                Cancel Changes
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem
                icon={ IconPencil }
                dataTest="editRoadshow"
                type={ DropdownItemType.button }
                onClick={ handleOnEdit }
              >
                Edit
              </DropdownItem>
            </>
          ) }
        </Dropdown>
      </span>
    </>
  );
};

export const BulkEditActionButtons = ({ rowIndex }: ICellProps) => {
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const { fields: fieldsArray } = useFieldArray(fieldArrayName);

  const debouncedRemove = debounce(() => {
    fieldsArray.remove(rowIndex);
    setIsRemovingItem(false);
  }, 100);

  const onRemoveFromBulk = () => {
    if (!isRemovingItem) {
      setIsRemovingItem(true);
      debouncedRemove();
    }
  };

  return (
    <ActionButton
      title="Remove from Bulk Editing"
      className={ styles.actionButton }
      icon={ IconDeny }
      variant={ ActionButtonVariantTypes.icon }
      dataTest="deleteItemFromBulk"
      onClick={ onRemoveFromBulk }
      isDisabled={ isRemovingItem }
    />
  );
};
