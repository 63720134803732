import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import FinalForm, { FormSpy } from '@/Framework/UI/Organisms/FinalForm';
import UploadInputsStatusContextProvider, {
  useUploadInputsStatusContext,
} from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import Link from '@/Framework/Router/ReactRouter/Link';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import CustomRegistrationFormFields from './CustomRegistrationFormFields';

import { ICustomRegistrationFormValues } from './interfaces';
import { CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM } from './constants';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';

import CustomRegistrationContext, {
  useCustomRegistrationContext,
} from '@/evercall/application/condor/call/profile/customRegistration/CustomRegistrationContext';
import validate from './validate';
import stripTags from '@/Framework/dom/stripTags';
import { addHttpToUrl } from '@/Framework/url/helpers';

import callProfileStyles from '../callProfile.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

const CustomRegistration = () => {
  const isFirstRender = useRef(true);

  const {
    state: {
      isFetching,
      isUpdating,
    },
    initCustomRegistration,
    updateSettings,
  } = useCustomRegistrationContext();
  const { isAllUploaded } = useUploadInputsStatusContext();

  useEffect(
    () => {
      isFirstRender.current = false;
      initCustomRegistration();
    },
    [],
  );

  const submit = (formData: ICustomRegistrationFormValues) => {
    const {
      customDetailsSectionSubfields,
      registrationButtonText,
      confirmationTitleText,
      customDetailsSectionName,
      ...restFormData
    } = formData;
    const getEditorValue = (value: string | null) => {
      if (!value) {
        return '';
      }
      return stripTags(value) // check if plain text exists
        ? value
        : '';
    };
    return updateSettings(
      {
        ...restFormData,
        registrationButtonText: registrationButtonText || '',
        confirmationTitleText: confirmationTitleText || '',
        registrationCustomMessage: getEditorValue(formData.registrationCustomMessage),
        confirmationDescription: getEditorValue(formData.confirmationDescription),
        customDetailsSectionName: customDetailsSectionName || '',
        customDetailsSectionSubfields: customDetailsSectionSubfields
          .filter((subfield) => (subfield.title && subfield.text))
          .map(({
            text,
            title,
            hyperlinkText,
          }) => ({
            text: text || '',
            title: title || '',
            hyperlinkText: addHttpToUrl(hyperlinkText),
          })),
        underwritersLogos: (formData.underwritersLogos ?? []).map((logo) => ({
          name: logo.name,
          url: logo.url,
        })),
      },
    );
  };

  const isFormVisible = !isFetching && !isFirstRender.current;

  return (
    <ProfileWrapper>
      <Spinner
        isVisible={ !isFormVisible || isUpdating }
        overlay={ isUpdating }
        isFixed={ isUpdating }
      />
      { isFormVisible && (
        <FinalForm
          name={ CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM }
          dataTest={ CONDOR_EVERCALL_CUSTOM_REGISTRATION_FORM }
          validate={ validate }
          className={ formStyles.formWrp }
          onSubmit={ submit }
          subscription={ {} }
          render={ () => (
            <>
              <CustomRegistrationFormFields />
              <FormSpy
                subscription={ {
                  errors: true,
                  pristine: true,
                  validating: true,
                } }
              >
                { ({ errors, pristine, validating }) => (
                  <div className={ callProfileStyles.formButtonsContainer }>
                    <Button
                      type="submit"
                      variant={ ButtonVariantType.action }
                      title="Save"
                      dataTest="evercallCustomRegistrationFormSaveButton"
                      disabled={ !isEmpty(errors) || !isAllUploaded || pristine || isUpdating || validating }
                    />
                    <Link to={ CONDOR_EVERCALL_LIST }>
                      <Button
                        variant={ ButtonVariantType.text }
                        title="Cancel"
                        dataTest="evercallCustomRegistrationFormCancelButton"
                      />
                    </Link>
                    <AreYouSureModal
                      when={ !pristine && !isUpdating }
                      disableNative={ false }
                    />
                  </div>
                ) }
              </FormSpy>
            </>
          ) }
        />
      ) }
    </ProfileWrapper>
  );
};

export default () => (
  <UploadInputsStatusContextProvider>
    <CustomRegistrationContext>
      <CustomRegistration />
    </CustomRegistrationContext>
  </UploadInputsStatusContextProvider>
);
