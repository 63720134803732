import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import { TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';

import styles from './styles.scss';

export const getAlertsHistory = (alerts: string[]) => {
  return (
    <>
      { alerts.map((alert) => (
        <p key={ alert } className={ styles.emailAlertItem }>
          <span className={ styles.emailAlertLabel }>
            Email Alert sent at:
          </span>
          <span>
            { getFormatInTimeZoneDate(alert, TIMEZONE_DEFAULT) } ET
          </span>
        </p>
      )) }
    </>
  );
};
