import { injectable, inject } from 'inversify';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { IMarketArticlePayload, IMarketArticleResponse } from '@/finsight/domain/vo/CreditFlow/Articles/IMarketArticle';
import { IMarketTheme } from '@/finsight/domain/vo/CreditFlow/IMarketTheme';
import { ISingleArticle } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import {
  IGetArticleDealsPayload,
  IGetArticleThemesPayload,
  IGetArticleCompaniesPayload,
  IGetArticleDealsResponse,
} from '@/finsight/domain/vo/CreditFlow/Article';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';
import Request from '@/Framework/api/Rpc/Request';
import type { IGroupedThemes } from '@/finsight/domain/vo/CreditFlow/Article';
import ICompany from '@/finsight/domain/vo/ICompany';
import { IMarketStatPayload, IMarketStatResponse } from '@/finsight/domain/vo/CreditFlow/Stats/IMarketStat';
import { IMarketCategory, IMarketCategoryPayload } from '@/finsight/domain/vo/CreditFlow/Stats/IMarketCategory';
import { ISingleStat } from '@/finsight/domain/vo/CreditFlow/Stat';
import { ICategoryRequestPayload, IStatCategory } from '@/finsight/domain/vo/CreditFlow/ICategory';

@injectable()
class CreditFlowResearchRepository {
  constructor(@inject(FinsightRpcDispatcher) protected readonly rpc: typeof FinsightRpcDispatcher) {}

  // Articles

  getArticleByViewId = async (viewId: number): Promise<ISingleArticle> => {
    const request = new Request('finsight_web.market.research.article.get_by_view_id', { viewId });
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getThemesList = async (payload: IGetArticleThemesPayload = {}): Promise<ITheme[]> => {
    const request = new Request('finsight_web.market.research.theme.get_all', payload);
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticleDeals = async (payload: IGetArticleDealsPayload = {}): Promise<IGetArticleDealsResponse> => {
    const request = new Request('finsight_web.market.deal_transaction.deal.get_info', payload);
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticleCompanies = async (payload: IGetArticleCompaniesPayload): Promise<ICompany[]> => {
    const request = new Request('finsight_web.market.company.get_info_by_ids', payload);
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticlesList = async (payload: IMarketArticlePayload): Promise<IMarketArticleResponse> => {
    const request = new Request('finsight_web.market.research.article.get_all', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getGroupedThemesData = async(): Promise<IGroupedThemes[]> => {
    const request = new Request('finsight_web.research.theme.get_all', { isGrouped: true });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getMarketThemesList = async (): Promise<IMarketTheme[]> => {
    let request = new Request('finsight_web.market.research.theme.get_all', {});
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  getAttachmentDownloadUrl = async (
    { uploadId, displayName }: { uploadId: string, displayName: string },
  ): Promise<{ url: string }> => {
    let request = new Request('finsight_web.market.attachment.get_downloading_url', { uploadId, displayName });
    let response = await this.rpc.call(request);
    return response.getResult().payload;
  };

  // Stats

  getStatsList = async (payload: IMarketStatPayload): Promise<IMarketStatResponse> => {
    const request = new Request('finsight_web.market.research.stat.get_all', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getMarketCategoriesList = async (payload: IMarketCategoryPayload): Promise<IMarketCategory[]> => {
    const request = new Request('finsight_web.market.research.stat_category.get_all', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getStatByViewId = async (viewId: number): Promise<ISingleStat> => {
    const request = new Request('finsight_web.market.research.stat.get_by_view_id', { viewId });
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getStatsCategories = async (payload: ICategoryRequestPayload): Promise<IStatCategory[]> => {
    const request = new Request('finsight_web.market.research.stat_category.get_all', payload);
    const response = await this.rpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default CreditFlowResearchRepository;
