import { v4 as uuid } from 'uuid';
import { components as ReactSelectComponents } from 'react-select';

import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { convertNumberWithCurrencyToNumber } from '@/Framework/dataHelpers/convertTypes';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import { defaultOption, FieldsNames } from '@/openMarket/ui/components/Condor/Events/EventForm/constants';
import { IDisplayedProfile, IEventPayload, IRanking, IEvent } from '@/openMarket/domain/Condor/vo/IEvent';
import { IFormValues } from '@/openMarket/ui/components/Condor/Events/EventForm/interfaces';
import { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';

export const ratedOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'rated',
    label: 'Rated',
  },
  {
    value: 'unrated',
    label: 'Unrated',
  },
];

export const getUpdateFormValues = (data: IEvent): IFormValues => {
  const expectedPricingDate = data.expectedPricingDate ? new Date(data.expectedPricingDate.dateTime) : null;

  return {
    ...data,
    [FieldsNames.NAME]: decodeSymbols(data.name),
    [FieldsNames.DESCRIPTION]: decodeSymbols(data.description),
    [FieldsNames.DISPLAY_OF_PROFILE]: data.profiles.map(({
      id,
      name,
    }): ISelectOption => {
      return {
        value: id,
        label: decodeSymbols(name),
      };
    }),
    [FieldsNames.DISPLAY_ON]: data.displayOn,
    [FieldsNames.RANKING]: data.ranking ? {
      value: data.ranking.rankingId,
      label: data.ranking.name,
    } : defaultOption,
    [FieldsNames.RATED]: data.rated ? {
      value: data.rated,
      label: data.rated,
    } : defaultOption,
    [FieldsNames.ROADSHOW_ID]: data.roadshowId,
    [FieldsNames.ROADSHOW_LINK]: null,
    [FieldsNames.EXPECTED_PRICING_DATE]: expectedPricingDate,
  };
};

export const getInitialValues = (initProfile: { id: string, name: string }): IFormValues => ({
  id: uuid(),
  published: false,
  [FieldsNames.NAME]: '',
  [FieldsNames.DESCRIPTION]: '',
  [FieldsNames.DISPLAY_ON]: {
    finsight: true,
    openMarket: true,
  },
  [FieldsNames.LOGO]: null,
  [FieldsNames.TILE_IMAGE]: null,
  [FieldsNames.TENOR]: null,
  [FieldsNames.EQUITY_TICKER]: '',
  [FieldsNames.RANKING]: defaultOption,
  [FieldsNames.RATED]: defaultOption,
  [FieldsNames.DISPLAY_OF_PROFILE]: initProfile
    ? [{
      value: initProfile.id,
      label: decodeSymbols(initProfile.name),
    }]
    : [],
  [FieldsNames.ROADSHOW_ID]: '',
  [FieldsNames.ROADSHOW_LINK]: null,
  [FieldsNames.EXPECTED_PRICING_DATE]: null,
});

export const getModifyKeyNames = (data) => ({
  ...data,
  name: decodeSymbols(data.name),
  dealSize: data.dealSize ? convertNumberWithCurrencyToNumber(String(data.dealSize)) : '',
});

export const abbreviationValueRenderer = ({
  data,
  ...otherProps
}) => (
  // @ts-ignore
  <ReactSelectComponents.MultiValueLabel { ...otherProps }>{ data.label }</ReactSelectComponents.MultiValueLabel>
);

export const formatOptionLabel = ({ label }) => <div>{ label }</div>;

export const getParentsOptions = (options: IDisplayedProfile[]): ISelectOption[] => options.map(({
  id,
  name,
}) => ({
  value: id,
  label: decodeSymbols(name),
}));

export const getRankingOptions = (options: IRanking[]): ISelectOption[] => {
  const rankingOptions = options.map(({
    rankingId,
    name,
  }) => ({
    value: rankingId,
    label: name,
  }));

  return [{
    value: 'none',
    label: 'None',
  }, ...rankingOptions];
};

export const getEventPayload = (values: IFormValues): IEventPayload => {
  const expectedPricingDate = values[FieldsNames.EXPECTED_PRICING_DATE]
    ? getUnixTimestamp(values[FieldsNames.EXPECTED_PRICING_DATE])
    : null;

  return {
    id: values.id,
    roadshowId: values[FieldsNames.ROADSHOW_ID],
    name: values[FieldsNames.NAME],
    description: values[FieldsNames.DESCRIPTION],
    logo: values[FieldsNames.LOGO],
    tileImage: values[FieldsNames.TILE_IMAGE],
    tenor: Number(values[FieldsNames.TENOR]),
    displayOn: values[FieldsNames.DISPLAY_ON],
    equityTicker: values[FieldsNames.EQUITY_TICKER],
    rankingId: values[FieldsNames.RANKING].value === 'none' ? null : (values[FieldsNames.RANKING].value as string),
    rated: values[FieldsNames.RATED].value as string,
    published: values.published,
    profiles: values[FieldsNames.DISPLAY_OF_PROFILE].map(({ value }) => value) as string[],
    expectedPricingDate,
  };
};
