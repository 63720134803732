import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';

import styles from './styles.scss';

interface ITagsCellProps {
  cellData: string[],
}

const TagsCell = ({ cellData }: ITagsCellProps) => {
  if (cellData.length === 0) {
    return '-';
  }

  const [firstTag, ...otherTags] = cellData;

  const renderTag = (tag: string) => (
    <span className={ styles.tagsCellItem }>{ tag }</span>
  );

  if (!otherTags.length) {
    return renderTag(firstTag);
  }

  const renderTooltipTag = (tag: string) => (
    <p className={ styles.tagsCellTooltipItem } key={ tag }>{ tag }</p>
  );

  const allTagsList = (
    <div className={ styles.tagsCellTooltipContent }>
      { cellData.map((tag) => renderTooltipTag(tag)) }
    </div>
  );

  return (
    <Tooltip content={ allTagsList } maxWidth={ 350 }>
      { renderTag(firstTag) }
      { renderTag('...') }
    </Tooltip>
  );
};

export default TagsCell;
