import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { CallReplayMethod } from '@/evercall/domain/vo/call/CallReplayMethod';
import styles from './settings.scss';

export const CONDOR_EVERCALL_SETTINGS_FORM = 'evercallProfileForm';
export const PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP = 'Link to display dial-ins in the event registration form. An event page connected to a TurboBridge ID will not accessible until it is linked to an Evercall.';
const LINK_TO_DRS_TOOLTIP_TEXT = 'Set a URL where investors can access the Replay for this event. Investors will be able to click on this URL after inputting their email from the event page. If the URL is a Deal Roadshow URL, investors will be taken straight to the Roadshow without having to type in their email again.';

export const CALL_REPLAY_METHOD_RADIO_OPTIONS = [
  {
    value: CallReplayMethod.None,
    label: 'None',
  },
  {
    value: CallReplayMethod.Url,
    label: (
      <>
        <span>Link to Deal Roadshow</span>
        <span className={ styles.questionIconContainer }>
          <Tooltip content={ LINK_TO_DRS_TOOLTIP_TEXT }>
            <IconQuestion />
          </Tooltip>
        </span>
      </>
    ),
  },
];
