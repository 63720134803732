import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { editorInitialValue } from '@/condor/application/Finsight/constants';

export const defaultInitialFormData: StatFormData = {
  name: '',
  categories: [],
  description: editorInitialValue,
  isActive: false,
  attachments: [],
  preview: null,
};
