import React from 'react';
import Tag from '@dealroadshow/uikit/core/components/Tag';
import styles from './tags.scss';

interface IProps {
  filters: any,
  filterFormatterData?: any,
  filtersFormatter: (key: string, filter: any, data: any) => string,
  allowedToRemoveFilters: any[],
  removableFilters?: any[],
  onTagRemove: (key: string, filter: any) => void,
}

const Tags = ({
  filters,
  allowedToRemoveFilters = [],
  removableFilters = allowedToRemoveFilters,
  filtersFormatter,
  filterFormatterData,
  onTagRemove,
}: IProps) => {
  if (!Object.keys(filters).length) {
    return <div className={ styles.noFiltersText }>No Filters Selected</div>;
  }

  return (
    <>
      { Object.keys(filters).map((key) => {
        const isAllowedToRemove = allowedToRemoveFilters.includes(key);
        const isRemovable = removableFilters.includes(key);
        let title = filtersFormatter ? filtersFormatter(key, filters[key], filterFormatterData) : key;

        return (
          <Tag
            key={ key }
            title={ title }
            // @ts-ignore
            onClick={
              isRemovable
                ? (event) => (isAllowedToRemove ? onTagRemove(key, filters[key]) : event.preventDefault())
                : undefined
            }
            allowedToRemove={ isAllowedToRemove }
          />
        );
      }) }
    </>
  );
};

export default Tags;
