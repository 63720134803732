import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import IconDuplicate from '@dealroadshow/uikit/core/components/Icon/IconDuplicate';
import { useDuplicateTranche } from './useDuplicateTranche';

import styles from './duplicateTrancheButton.scss';

const DuplicateTrancheButton = ({ index, field }) => {
  const { handleButtonClick, isButtonDisabled } = useDuplicateTranche({ field, index });

  return (
    <Tooltip content="Duplicate">
      <Button
        disabled={ isButtonDisabled }
        className={ styles.button }
        variant={ ButtonVariantType.text }
        onClick={ handleButtonClick }
        dataTest="condorDuplicateTrancheButton"
      >
        <IconDuplicate className={ styles.icon } />
      </Button>
    </Tooltip>
  );
};

export default DuplicateTrancheButton;
