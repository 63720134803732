import cn from 'classnames';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import TrancheField from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/TrancheField';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import {
  DEAL_PROFILE_FIELDS,
  TrancheGroup,
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import RatingAgenciesFields from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/RatingAgenciesFields';

import styles from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/tranche.scss';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const TrancheFieldGroup = ({ field, trancheIndex, groupName, groupFields, isExcluded }) => {
  const { collapsedGroup } = useTranchesContext();
  const productValue = useSelector((state) => formSelector(state, DEAL_PROFILE_FIELDS.PRODUCT.name));

  const separatorStyle = trancheIndex < (TRANCHES_VISIBLE - 2) ? styles.cellSeparator : styles.cellSeparatorLast;
  const isGroupCollapsed = collapsedGroup.includes(groupName);

  const renderFields = () => !isGroupCollapsed && groupFields.map((fieldData: IDealProfileFormField) => (
    <TrancheField
      key={ fieldData.name }
      trancheIndex={ trancheIndex }
      field={ field }
      fieldData={ fieldData }
      selectedProduct={ productValue?.label }
    />
  ));

  return (
    <div
      className={ cn(styles.trancheCellGroup, {
        [styles.trancheCellGroupCollapsed]: isGroupCollapsed,
      }) }
    >
      { groupName === TrancheGroup.RATING_AGENCIES
        ? (
          <RatingAgenciesFields
            field={ field }
            isExcluded={ isExcluded }
            trancheIndex={ trancheIndex }
          />
        )
        : renderFields() }
      <div className={ separatorStyle } />
    </div>
  );
};

export default TrancheFieldGroup;
