import React from 'react';
import cn from 'classnames';
import { BANNER_FORM_FIELDS } from '../constants';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import styles from './previewBanner.scss';

interface IProps {
  color: string,
  content: string,
}

const PreviewBanner = ({
  color = '#ffe484',
  content = '',
}: IProps) => (
  <div>
    <div className={ styles.label }>{ BANNER_FORM_FIELDS.PREVIEW.label }</div>
    <div className={ styles.ieWrapper }>
      <div className={ cn(styles.container) } style={ { backgroundColor: `${ color }` } }>
        <div className={ styles.content } dangerouslySetInnerHTML={ { __html: content } } />
        <IconClose className={ styles.closeIcon } />
      </div>
    </div>
  </div>
);

export default PreviewBanner;
