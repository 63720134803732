import { useEffect } from 'react';
import {
  UPLOAD_ATTACHMENTS_ALLOWED_FILES,
  UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT,
  UPLOAD_TABLE_COLUMNS_WITHOUT_DND,
  UPLOAD_TABLE_WIDTHS_WITHOUT_DND,
} from '@/Framework/UI/Organisms/Upload/constants';
import UploadDropzoneOneFile from '@/Framework/UI/Organisms/Upload/components/UploadDropzoneOneFile';
import UploadAttachments from '@/condor/ui/components/Finsight/CreditFlowResearch/common/UploadAttachments';
import UploadInputsStatusContextProvider, {
  useUploadInputsStatusContext,
} from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';

import styles from './styles.scss';

const UploadAttachment = (props: IFinalFormFieldArrayInputComponentProps<IAttachment>) => {
  const { setIsAttachmentUploaded } = useStatProfileContext();
  const { isAllUploaded } = useUploadInputsStatusContext();

  useEffect(() => {
    setIsAttachmentUploaded(isAllUploaded);
  }, [isAllUploaded]);

  const UploadDropzoneArea = ({ onUpload, uploadFiles }) => {
    if (uploadFiles.sort.length) {
      return null;
    }

    return (
      <UploadDropzoneOneFile
        onUpload={ onUpload }
        allowedFileTypes={ UPLOAD_ATTACHMENTS_ALLOWED_FILES }
        allowedFileText={ UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT }
        dropzoneHelperClassName={ styles.dropzoneContainer }
        uploadName=""
        maxSize={ Infinity }
        maxSizeUnits="B"
        formFieldName="attachments"
      />
    );
  };

  return (
    <UploadAttachments
      { ...props }
      title="Attachment"
      columnsConfig={ UPLOAD_TABLE_COLUMNS_WITHOUT_DND }
      widthsConfig={ UPLOAD_TABLE_WIDTHS_WITHOUT_DND }
      UploadDropzoneArea={ UploadDropzoneArea }
    />
  );
};

export default (props: IFinalFormFieldArrayInputComponentProps<IAttachment>) => (
  <UploadInputsStatusContextProvider>
    <UploadAttachment { ...props } />
  </UploadInputsStatusContextProvider>
);
