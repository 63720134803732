import React from 'react';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';

import styles from './addBannerButton.scss';

interface IProps {
  isDisabled?: boolean,
  onClick: () => void,
}

const AddBannerButton = ({
  isDisabled,
  onClick,
}: IProps) => (
  <>
    <div className={ cn(styles.addBannerHeader) }>
      <div className={ styles.addBannerBtnContainer }>
        <Button
          disabled={ isDisabled }
          className={ cn(styles.addBannerBtn, { [styles.disabled]: isDisabled }) }
          variant={ ButtonVariantType.text }
          onClick={ onClick }
          dataTest="addBannerButton"
        >
          <IconAdd className={ styles.addBannerIcon } />
          <span className={ styles.addBannerLabel }>Add Banner</span>
        </Button>
      </div>
    </div>
  </>
);

export default AddBannerButton;
