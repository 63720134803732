import { createContext, useContext, useState, useMemo, useCallback, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { INITIAL_COLLAPSED_GROUPS, TrancheGroup } from '@/condor/ui/components/Finsight/DealProfile/constants';
import {
  RATING_AGENCIES_COLLAPSED_LENGTH,
} from '@/condor/application/Finsight/DealProfile/constants';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

interface IProps {
  children: ReactNode,
  isStickyHeaderVisible: boolean,
}

export const TranchesContext = createContext(null);

export const useTranchesContext = () => {
  const context = useContext(TranchesContext);
  if (!context) {
    throw new Error('useTranchesContext must be used within the TranchesContext');
  }
  return context;
};

const TranchesContextProvider = ({ children, isStickyHeaderVisible }: IProps) => {
  const [tranchesViewOffset, setTranchesViewOffset] = useState(0);
  const [collapsedGroup, setCollapsedGroup] = useState(INITIAL_COLLAPSED_GROUPS);
  const [tranchesFieldArrayLength, setTranchesFieldArrayLength] = useState(0);
  const [isRatingAgenciesCollapsed, setIsRatingAgenciesCollapsed] = useState(true);
  const ratingAgencies = useSelector(selectors.getRatingAgencies);

  const toggleTrancheGroupIsCollapsed = useCallback((group) => setCollapsedGroup(
    (prev) => (prev.includes(group)
      ? prev.filter((prevGroup) => prevGroup !== group)
      : [...prev, group]),
  ), []);

  useEffect(() => {
    if (collapsedGroup.includes(TrancheGroup.RATING_AGENCIES) && !isRatingAgenciesCollapsed) {
      setIsRatingAgenciesCollapsed(true);
    }
  }, [collapsedGroup, isRatingAgenciesCollapsed]);

  const agenciesToShow = isRatingAgenciesCollapsed
      ? ratingAgencies.slice(0, RATING_AGENCIES_COLLAPSED_LENGTH)
      : ratingAgencies;

  const contextProviderValue = useMemo(
    () => (
      {
        tranchesViewOffset,
        setTranchesViewOffset,
        isStickyHeaderVisible,
        collapsedGroup,
        toggleTrancheGroupIsCollapsed,
        tranchesFieldArrayLength,
        setTranchesFieldArrayLength,
        isRatingAgenciesCollapsed,
        setIsRatingAgenciesCollapsed,
        agenciesToShow,
      }
    ),
    [
      tranchesViewOffset,
      isStickyHeaderVisible,
      collapsedGroup,
      tranchesFieldArrayLength,
      isRatingAgenciesCollapsed,
      agenciesToShow,
    ],
  );

  return (
    <TranchesContext.Provider value={ contextProviderValue }>{ children }</TranchesContext.Provider>
  );
};

export default TranchesContextProvider;
