import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import DomainField from '@/condor/ui/components/AccountsAndContacts/Accounts/DomainField';
import LeisField from '@/condor/ui/components/AccountsAndContacts/Accounts/LeisField';
import TypeField from '@/condor/ui/components/AccountsAndContacts/Accounts/TypeField';
import AumField from '@/condor/ui/components/AccountsAndContacts/Accounts/AumField';
import CurrencyField from '@/condor/ui/components/AccountsAndContacts/Accounts/CurrencyField';
import Input from '@dealroadshow/uikit/core/components/Input';
import { InputLabel as Label } from '@dealroadshow/uikit/core/components/Input/Label';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import {
  SelectOptionWithCountryFlag,
  SelectSingleValueWithCountryFlag,
} from '@dealroadshow/uikit/core/components/Select/components';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';
import { Select } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import { ICurrency } from '@/condor/domain/vo/Currency';
import { getAccountTypes } from '@/condor/application/actions/accountTypes/collection/actions';
import { ICountryOption } from '@/dealroadshow/domain/vo/Country';

import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import styles from '../accountProfile.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

const commonInputProps = {
  placeholder: '',
  component: Input,
  className: layoutStyles.inputField,
};

export interface IValues {
  verified: boolean,
  aum: number,
  aumCurrencyId: string,
}

interface IProps {
  fieldComponent: React.ElementType,
  isCreating: boolean,
  values: IValues,
  onSetCurrencyList: (currencyList: ICurrency[]) => void,
  countriesOptions: ICountryOption[],
}

const AccountProfileFields = ({
  fieldComponent: Field,
  isCreating,
  values,
  onSetCurrencyList,
  countriesOptions,
}: IProps) => {
  const {
    verified,
    aum,
    aumCurrencyId,
  } = values;
  const dispatch = useDispatch();

  return (
    <>
      { !isCreating && (
        <Field
          name="id"
          label="Unique Id"
          dataTest="uniqueId"
          disabled
          { ...commonInputProps }
        />
      ) }
      <Field
        { ...commonInputProps }
        name="name"
        label="Account Name"
        inputClassName={ cn({ [styles.verifiedCompanyNameInputField]: verified }) }
        placeholder="Account Name"
        dataTest="accountName"
      />
      <Field
        { ...commonInputProps }
        name="abbreviation"
        label="Abbreviation"
        placeholder="Abbreviation"
        className={ layoutStyles.inputField }
        dataTest="abbreviation"
      />
      <DomainField
        fieldComponent={ Field }
        label="Web Domains"
        name="webDomains"
        placeholder="Web Domains"
        className={ layoutStyles.inputField }
        dataTest="webDomains"
      />
      <DomainField
        fieldComponent={ Field }
        label="Email Domains"
        name="emailDomains"
        placeholder="Email Domains"
        className={ layoutStyles.inputField }
        dataTest="emailDomains"
      />
      <LeisField
        fieldComponent={ Field }
        label="Legal Entity Identifier (LEI)"
        name="leis"
        placeholder="Legal Entity Identifier"
        className={ layoutStyles.inputField }
      />
      <TypeField
        onInputChange={ (query) => {
          if (!query) {
            dispatch(getAccountTypes({
              limit: 50,
              orderBy: 'name',
              orderAsc: true,
            }));
            return;
          }
          dispatch(getAccountTypes({
            limit: 50,
            query,
          }));
        } }
        fieldComponent={ Field }
        label="Account Type"
        className={ layoutStyles.inputField }
      />
      <Field
        component={ Select }
        name="country"
        label="Country"
        dataTest="accountCountry"
        options={ countriesOptions }
        components={ {
          Option: SelectOptionWithCountryFlag,
          SingleValue: SelectSingleValueWithCountryFlag,
        } }
        formFieldClassName={ layoutStyles.inputField }
      />
      <Field
        label="Account HQ Address"
        name="hqLocation"
        component={ RichTextInput }
        placeholder="Account HQ Address"
        maxLength={ 100 }
        isEnabledCountdownMessage
        dataTest="hqLocation"
        containerCls={ layoutStyles.inputField }
        editableWrpCls={ styles.accountAddressArea }
        /* Please do not do this. Instead, use Textarea and apply 'white-space: pre-line' to the div where
         * the submitted text is being inserted via dangerouslySetInnerHTML tag. */
        toolbarComponent={ () => null }
        hasLineBreaks
      />
      <Field
        name="description"
        label="Account Description"
        component={ RichTextInput }
        placeholder="Account Description"
        maxLength={ 5000 }
        isEnabledCountdownMessage
        dataTest="accountDescription"
        containerCls={ layoutStyles.inputField }
        editableWrpCls={ styles.accountAddressArea }
        /* Please do not do this. Instead, use Textarea and apply 'white-space: pre-line' to the div where
         * the submitted text is being inserted via dangerouslySetInnerHTML tag. */
        toolbarComponent={ () => null }
        hasLineBreaks
      />
      <div className={ cn(layoutStyles.inputField, styles.currencyWrapper) }>
        <AumField
          name="aum"
          label="AUM"
          placeholder="10,000,000"
          dataTest="aum"
          className={ layoutStyles.inputField }
          currentValue={ aum }
          fieldComponent={ Field }
        />
        <CurrencyField
          name="aumCurrencyId"
          label="Currency"
          fieldComponent={ Field }
          currentCurrencyId={ aumCurrencyId }
          onSetCurrencyList={ onSetCurrencyList }
        />
      </div>
      { !isCreating && (
        <>
          <Field
            name="contactCount"
            label="# of Contacts Tied to Account"
            disabled
            { ...commonInputProps }
            dataTest="contactCount"
          />
          <Field
            name="dealCount"
            label="# of Deals Tied to Account"
            disabled
            { ...commonInputProps }
            dataTest="dealCount"
          />
          <Field
            name="allocationCount"
            label="# of Mappings in Deal Roadshow Allocations"
            disabled
            { ...commonInputProps }
            dataTest="allocationCount"
          />
          <Field
            name="evercallCount"
            label="# of Mappings in Evercall Participant List"
            disabled
            { ...commonInputProps }
            dataTest="evercallCount"
          />
        </>
      ) }
      <Label className={ styles.accountStateLabel }>Account State</Label>
      <Field
        name="verified"
        dataTest="verifiedAccount"
        label="Verified (if checked, will appear in Company drop-down on registration and DRS first time investor form)"
        component={ CheckboxInput }
        type="checkbox"
        className={ formStyles.formCheckbox }
      />
      <Field
        name="topAccount"
        dataTest="topAccount"
        label="Top Accounts (used when adding top accounts in InvestorSet whitelist)"
        component={ CheckboxInput }
        type="checkbox"
      />
    </>
  );
};

export default AccountProfileFields;
