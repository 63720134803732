import React, { memo } from 'react';
import CheckboxFiltersBlock from '@/condor/ui/common/Layout/CheckboxFiltersBlock';
import styles from '../contactsList.scss';

interface IProps {
  filters: any,
  setIsActivated: () => void,
  setIsUnactivated: () => void,
  setIsVerified: () => void,
  setIsUnverified: () => void,
  setIsBanned: () => void,
  setIsUnbanned: () => void,
}

const CheckboxFilters = (props: IProps) => {
  const activated = [
    {
      label: 'Activated',
      dataTest: 'activatedContacts',
      checked: props.filters.isActivated,
      onClick: props.setIsActivated,
    },
    {
      label: 'Unactivated',
      dataTest: 'unactivatedContacts',
      checked: props.filters.isUnactivated,
      onClick: props.setIsUnactivated,
    },
  ];

  const verified = [
    {
      label: 'Verified',
      dataTest: 'verifiedContacts',
      checked: props.filters.isVerified,
      onClick: props.setIsVerified,
    },
    {
      label: 'Unverified',
      dataTest: 'unverifiedContacts',
      checked: props.filters.isUnverified,
      onClick: props.setIsUnverified,
    },
  ];

  const banned = [
    {
      label: 'Banned',
      dataTest: 'bannedContacts',
      checked: props.filters.isBanned,
      onClick: props.setIsBanned,
    },
    {
      label: 'Unbanned',
      dataTest: 'unbannedContacts',
      checked: props.filters.isUnbanned,
      onClick: props.setIsUnbanned,
    },
  ];

  return (
    <>
      <CheckboxFiltersBlock className={ styles.filtersBlock } items={ activated } />
      <CheckboxFiltersBlock className={ styles.filtersBlock } items={ verified } />
      <CheckboxFiltersBlock className={ styles.filtersBlock } items={ banned } />
    </>
  );
};

export default memo(CheckboxFilters);
