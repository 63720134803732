import React, { useMemo, useCallback } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';

import styles from './tableRowFields.scss';

interface IProps {
  fields: any[],
}

const TableRowFields = ({ fields }: IProps) => {
  const headCells = useMemo(
    () => fields.map(({
      label,
      labelTooltipContent,
      name,
      width,
      minWidth,
      maxWidth,
      cellClassName,
      labelSuffix,
    }) => (
      <th
        key={ name }
        className={ cellClassName }
        style={
          {
            ...(width && { width }),
            ...(minWidth && { minWidth }),
            ...(maxWidth && { maxWidth }),
          }
        }
      >
        <Tooltip content={ labelTooltipContent || label }>
          <div className={ styles.labelContainer }>
            { label }
            { labelSuffix }
          </div>
        </Tooltip>
      </th>
    )),
    [fields],
  );

  const renderCellField = useCallback(
    (
      {
        name,
        width,
        minWidth,
        maxWidth,
        cellClassName,
        ...otherProps
      },
    ) => (
      <td
        key={ name }
        className={ cn(styles.rowCell, cellClassName) }
        style={
          {
            ...(width && { width }),
            ...(minWidth && { minWidth }),
            ...(maxWidth && { maxWidth }),
          }
        }
      >
        <Field
          { ...otherProps }
          name={ name }
          label=""
          isNarrow
        />
      </td>
    ),
    [],
  );

  return (
    <div className={ styles.tableRowFieldsContainer }>
      <table className={ cn(dataTableStyles.dataTable, dataTableStyles.isStripedOdd) }>
        <thead>
          <tr>
            { headCells }
          </tr>
        </thead>
        <tbody>
          <tr>
            { fields.map(renderCellField) }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableRowFields;
