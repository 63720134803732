import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
  CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL,
  CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL,
} from '@/condor/ui/common/constants';

import pageStyles from '@/Framework/GlobalStyles/page.scss';
import floatStyles from '@dealroadshow/uikit/core/styles/helpers/floats.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps {
  isCreateNewDeal: boolean,
  item: any,
  initialIssuer: any,
}

const DealProfileHeaderLinks = (
  {
    isCreateNewDeal,
    item,
    initialIssuer,
  }: IProps,
) => {
  if (!isCreateNewDeal || (isCreateNewDeal && !isEmpty(initialIssuer))) {
    return (
      <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
        <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
          <Link
            to={
              `${ CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/${
                !isEmpty(initialIssuer)
                  ? initialIssuer.id
                  : item.primaryIssuer.id
              }`
            }
            data-test="companyProfileLink"
          >
            Profile
          </Link>
          <Link
            to={
              `${ CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${
                !isEmpty(initialIssuer)
                  ? initialIssuer.id
                  : item.primaryIssuer.id
              }`
            }
            data-test="companyDealsLink"
          >
            Deals
          </Link>
        </div>
      </div>
    );
  }

  return null;
};

export default DealProfileHeaderLinks;
