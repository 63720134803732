import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import { CONDOR_DRS_ROADSHOW_URL, CONDOR_DRS_ROADSHOWS_URL } from '@/condor/ui/common/constants';
import getRouter from '@/users/infrastructure/next/Router';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import useUpdateEffect from '@/Framework/hooks/useUpdateEffect';

import pageStyles from '@/Framework/GlobalStyles/page.scss';
import usePrevious from '@/Framework/hooks/usePrevious';

interface IProps {
  children: React.ReactNode,
  checkTabState: (roadshowId: string, nextTabLink: string, prevTabLink?: string) => void,
  removeBreadcrumb: (value: string) => void,
  setBreadcrumb: (value: any) => void,
  match: { [key: string]: any },
  tabs: Array<{
    dataTest: string,
    name: string,
    link: string,
    disabled?: boolean,
    disabledTooltip?: string,
  }>,
  isFetching: boolean,
  setSettingsNeedToRefreshModalVisible: (value: boolean) => void,
  setMappingNeedToRefreshModalVisible: (value: boolean) => void,
  setDealSlidesNeedToRefreshModalVisible: (value: boolean) => void,
  push: (url: string) => void,
  isRoadshowSettingsNeedToRefresh: boolean,
  isRoadshowMappingNeedToRefresh: boolean,
  isRoadshowSlidesNeedToRefresh: boolean,
  dealSettings: {
    name: string,
  },
}

const getTabLink = (pathname: string) => pathname?.substr(pathname.lastIndexOf('/') + 1);

const getPath = (pathname: string) => pathname.split('/');

const Roadshow = (props: IProps) => {
  const {
    children,
    isFetching,
    match,
    tabs,
    push,
    setBreadcrumb,
    checkTabState,
    isRoadshowSettingsNeedToRefresh,
    isRoadshowMappingNeedToRefresh,
    isRoadshowSlidesNeedToRefresh,
    setSettingsNeedToRefreshModalVisible,
    setMappingNeedToRefreshModalVisible,
    setDealSlidesNeedToRefreshModalVisible,
    removeBreadcrumb,
    dealSettings,
  } = props;
  const { roadshowId } = match.params;
  const { asPath: pathname } = getRouter();
  const previousPathname = usePrevious(pathname);
  const nextTabLink = getTabLink(pathname);
  const prevTabLink = getTabLink(previousPathname);

  useEffect(() => {
    if (typeof roadshowId === 'undefined') {
      push(CONDOR_DRS_ROADSHOWS_URL);
    }
    setBreadcrumb({
      name: 'Roadshows',
      url: CONDOR_DRS_ROADSHOWS_URL,
    });

    return () => {
      let tabLink = getTabLink(pathname);
      removeBreadcrumb(`${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ tabLink }`);
      removeBreadcrumb(CONDOR_DRS_ROADSHOWS_URL);
    };
  }, []);

  useEffect(() => {
    let tabLink;
    if (getPath(pathname).length < 5) {
      tabLink = tabs[0].link;
      push(`${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ tabLink }`);
    } else {
      tabLink = getTabLink(pathname);
    }
    checkTabState(roadshowId, tabLink);
  }, [dealSettings.name]);

  useUpdateEffect(() => {
    if (getPath(pathname).length === 5 && getPath(previousPathname).length === 5) {
      checkTabState(roadshowId, nextTabLink, prevTabLink);
    }
  }, [nextTabLink]);

  const handleModalRefresh = () => {
    isRoadshowSettingsNeedToRefresh && setSettingsNeedToRefreshModalVisible(false);
    isRoadshowMappingNeedToRefresh && setMappingNeedToRefreshModalVisible(false);
    isRoadshowSlidesNeedToRefresh && setDealSlidesNeedToRefreshModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <Spinner isVisible={ isFetching } overlay />
      <div className={ pageStyles.pageNavigation }>
        <div className={ pageStyles.pageNavigationLinks }>
          { tabs.map((tab) => {
            if (!tab.disabled) {
              return (
                <NavLink
                  key={ tab.link }
                  data-test={ tab.dataTest }
                  activeClassName={ pageStyles.isActiveNavLink }
                  to={ `${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ tab.link }` }
                >
                  { tab.name }
                </NavLink>
              );
            }
            return (
              <a key={ tab.link } className={ pageStyles.isDisabledNavLink }>
                <Tooltip
                  disabled={ tab.disabled && isEmpty(tab.disabledTooltip) }
                  content={ tab.disabledTooltip }
                >
                  { tab.name }
                </Tooltip>
              </a>
            );
          }) }
        </div>
      </div>
      { children }
      <Modal
        isVisible={
          isRoadshowSettingsNeedToRefresh ||
          isRoadshowMappingNeedToRefresh ||
          isRoadshowSlidesNeedToRefresh
        }
        title="Roadshow Settings Have Been updated"
        showCloseButton={ false }
        dataTest="roadshowSettingsInfoModal"
        footer={ (
          <>
            <Button
              onClick={ handleModalRefresh }
              variant={ ButtonVariantType.action }
              title="Continue"
              dataTest="roadshowModalRefreshButton"
            />
          </>
        ) }
      >
        The roadshow settings have been updated since accessing this page.
        The page will immediately refresh with these updated settings after you select the button below.
      </Modal>
    </>
  );
};

export default Roadshow;
