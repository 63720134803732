import React, { useCallback, useState, useEffect, Fragment } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
// eslint-disable-next-line no-restricted-imports
import { Field as FieldComponent } from 'react-final-form';

import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import {
  DataTable,
  StickyDataTable,
  BodyWrapper,
  BodyRow,
  IColumn,
  IRowData,
} from '@dealroadshow/uikit/core/components/Table/DataTable';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';
import validate from './validate';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './editableTable.scss';

const LoadingComponent = () => <Spinner overlayClassName={ cn('spinner', styles.spinner) } overlay />;

interface IProps {
  isBulkEdit?: boolean,
  name: string,
  collection: IRowData[],
  columns: IColumn[],
  stickyTable?: boolean,
  sortBy?: string,
  sortOrder?: string,
  sort?: (sortBy: string, sortOrder: string) => void,
  isFetching: boolean,
  totalCount?: number,
  pagination?: {
    page: number,
    perPage: number,
    paginate: (page: number) => void,
    setItemsPerPage: (perPage: number) => void,
  },
  checkedRoadshows?: string[],
  editableRowId?: string,
  bulkEditRow?: (args: {
    formProps: object,
    fieldComponent: typeof FieldComponent,
  }) => React.ReactNode,
}

const EditableTable = ({
  isBulkEdit = false,
  name,
  stickyTable = false,
  collection,
  columns,
  checkedRoadshows,
  sortBy,
  sortOrder,
  sort,
  isFetching,
  totalCount,
  pagination,
  editableRowId,
  bulkEditRow,
}: IProps) => {
  const [initialValues, setInitialValues] = useState({ editableTableArray: collection });

  useEffect(() => {
    if (
      !initialValues.editableTableArray.length ||
      initialValues.editableTableArray.length !== collection.length ||
      !isBulkEdit
    ) {
      setInitialValues({ editableTableArray: collection });
    }
  }, [collection]);

  const table = useCallback((formProps, {
    FieldArray,
    Field,
    FormSpy,
    ...otherFormFields
  }) => {
    const bodyComponent = (columns) => (
      <BodyWrapper>
        { !stickyTable && bulkEditRow({
          fieldComponent: Field,
          formProps,
        }) }
        <FieldArray
          name="editableTableArray"
          render={
            ({ fields }) => (
              fields.map((field, index) => {
                const rowData = collection[index];

                if (!rowData) {
                  return <Fragment key={ field } />;
                }

                return (
                  <BodyRow
                    key={ field }
                    rowIndex={ index }
                    rowData={ rowData }
                    columns={ columns }
                    isSelected={ checkedRoadshows?.some((id) => (
                      id === rowData?.id) || editableRowId === rowData?.id,
                    ) }
                    customCellProps={ {
                      formProps: {
                        ...formProps,
                        fieldsArray: fields,
                      },
                      formFields: {
                        FieldArray,
                        Field,
                        ...otherFormFields,
                      },
                      fieldArrayName: field,
                    } }
                  />
                );
              })
            )
          }
        />
      </BodyWrapper>
    );

    const tableProps = {
      dataTest: 'table',
      columns,
      loadingComponent: LoadingComponent,
      sortBy,
      sortOrder,
      onSortChange: sort,
      isFetching,
      data: collection,
      bodyComponent,
    };

    if (stickyTable) {
      return (
        <StickyDataTable
          { ...tableProps }
          stickyDataTableContainer={ cn(
            styles.tableContainerStickyTable,
            styles.table,
            { [styles.stickyTableIsFetching]: isFetching },
          ) }
          stickyTableContainerClassName={ styles.tableContainerStickyLeft }
        />
      );
    }

    return (
      <DataTable
        { ...tableProps }
        className={ styles.table }
      />
    );
  }, [collection, isFetching, sortBy, sortOrder, checkedRoadshows, editableRowId]);

  return (
    <>
      <div className={ styles.tableWrapper }>
        <FinalForm
          initialValues={ initialValues }
          name={ name }
          dataTest={ name }
          id={ name }
          withArrays
          render={ table }
          className={ styles.form }
          validate={ validate }
          onSubmit={ noop }
        />
      </div>
      { pagination && (
        <Paginator
          dataTest={ `${ name }Paginator` }
          className={ cn(cardStyles.cardInner, styles.paginator) }
          totalCount={ totalCount }
          page={ pagination.page }
          perPage={ pagination.perPage }
          onItemsPerPageChange={ (perPage: { value: number }) => {
            pagination.setItemsPerPage(perPage.value);
          } }
          onPageChange={ (page: { selected: number }) => {
            pagination.paginate(page.selected);
          } }
        />
      ) }
    </>
  );
};

export default EditableTable;
