import React from 'react';

import styles from '../traceTable.scss';
import IconExpand from '@dealroadshow/uikit/core/components/Icon/IconExpand';
import IconCollapse from '@dealroadshow/uikit/core/components/Icon/IconCollapse';

export const ExpandCollapseCell = ({ isExpanded }) => (
  isExpanded ?
    <IconCollapse className={ styles.expandCollapse } /> :
    <IconExpand className={ styles.expandCollapse } />
);
