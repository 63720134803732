import { useMemo } from 'react';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import {
  CONDOR_FINSIGHT_CREDIT_FLOW_STATS_PROFILE_BASE_URL,
} from '@/condor/ui/common/constants';
import DeleteStatModal
  from '@/condor/ui/components/Finsight/CreditFlowResearch/Stats/StatsList/StatsTable/DeleteStatModal';
import { IStatToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import StatActionsContextProvider, {
  useStatActionsContext,
} from 'src/condor/application/Finsight/CreditFlowResearch/Stats/StatsList/StatActionsContext';

import styles from './styles.scss';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

const UpdatedAtCell = ({
  cellData,
  row,
}: { cellData: string, row: IStatToDisplay }) => {
  const {
    openModal,
    isRequestPerforming,
    downloadAttachment,
  } = useStatActionsContext();

  const editAction = {
    tooltipContent: 'Edit',
    icon: IconPencil,
    className: styles.actionWrapper,
    url: `${ CONDOR_FINSIGHT_CREDIT_FLOW_STATS_PROFILE_BASE_URL }/${ row.id }`,
    dataTest: 'editStatButton',
  };

  const deleteAction = {
    tooltipContent: 'Delete',
    icon: IconTrash,
    onClick: () => openModal(row),
    dataTest: 'deleteStatButton',
  };

  const downloadAction = [{
    tooltipContent: 'Download',
    icon: IconDownload,
    className: styles.actionWrapper,
    onClick: () => downloadAttachment(row),
    disabled: isRequestPerforming,
    dataTest: 'downloadStatButton',
  }];

  const actions = useMemo(() => {
    return [
      editAction,
      ...(row.attachment ? downloadAction : []),
      deleteAction,
    ];
  }, [row.id, isRequestPerforming]);

  return (
    <>
      <LastTableCell
        childrenToShowOnHover={ (
          <TableRowActions
            actions={ actions }
          />
        ) }
      >
        { getCellDisplayDateFromString(cellData) }
      </LastTableCell>
      <DeleteStatModal />
    </>
  );
};

export default (props) => (
  <StatActionsContextProvider>
    <UpdatedAtCell { ...props } />
  </StatActionsContextProvider>
);
