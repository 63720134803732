import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse, isAfter } from 'date-fns';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import Input from '@dealroadshow/uikit/core/components/Input';
import TruncateTextWithTooltip from '@dealroadshow/uikit/core/components/Tooltip/TruncateTextWithTooltip';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { CONDOR_TIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import * as paths from '@/condor/ui/common/constants';

import styles from '../roadshows.scss';
import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const BULK_TABLE_NAME = 'bulkEditingForm';

const DealNameCallback = ({
  row: {
    finishedAt,
    id,
  },
  rowIndex,
  customCellProps,
  cellData,
  cellName,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();

  const isExpire = useMemo(() => {
    const finishedAtUtcDate = parse(finishedAt.dateTime, CONDOR_TIME_FORMAT, new Date());
    const finishedAtWithTimezone = utcToZonedDate(finishedAt.timezone.timeZone, finishedAtUtcDate);
    const currentTimeWithTimezone = utcToZonedDate(finishedAt.timezone.timeZone);
    return isAfter(currentTimeWithTimezone, finishedAtWithTimezone);
  }, [finishedAt]);

  if (editableRowId === id || customCellProps.formProps.id === BULK_TABLE_NAME) {
    return (
      <div className={ styles.dealNameField }>
        <Field
          name={ `${ fieldRowName }.${ cellName }` }
          placeholderText="Deal Name"
          component={ Input }
          dataTest={ `dealName[${ rowIndex }]` }
          formFieldClassName={ spaces.mbn }
          disabled={ isExpire }
        />
      </div>
    );
  }

  const name = decodeSymbols(cellData);

  return (
    <TruncateTextWithTooltip value={ name }>
      <Link
        to={ `${ paths.CONDOR_DRS_ROADSHOW_URL }/${ id }/deal-settings` }
        dataTest={ `dealNameLink[${ rowIndex }]` }
      >
        { name }
      </Link>
    </TruncateTextWithTooltip>
  );
};

export default DealNameCallback;
