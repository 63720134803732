import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';

interface IAllValues {
  categoriesList: ICategory[],
}

export default (value: string, allValues: IAllValues, meta: { name: string }) => {
  if (!value) {
    return 'Category name is required';
  }

  const trimmedValue = value.trim();

  if (trimmedValue.length < 3) {
    return 'Category name should be at least 3 characters long';
  }

  if (value.length > 50) {
    return 'Category name may not contain more than 50 characters';
  }

  const index = meta.name.match(/\d+/)[0];
  const currentValue = allValues.categoriesList[index];
  if (!currentValue) {
    return null;
  }

  const isDuplicatExist = allValues.categoriesList.some((category) => (category.name === currentValue.name
    && category.regionId === currentValue.regionId
    && category.productId === currentValue.productId
    && category.id !== currentValue.id));

  if (isDuplicatExist) {
    return 'Category with this name already exists';
  }

  return null;
};
