import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';
import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { IStat } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { IUser } from '@/users/domain/vo/User';
import { IStatPreviewData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStatPayload';

export const getAttachments = (attachments: IAttachment[]) => {
  return attachments.map(({ uuid, name }) => ({ uploadId: uuid, displayName: name }));
};

export const getStatDataForPreview = (
  values: StatFormData,
  isEdit: boolean,
  initialStat: IStat,
  currentUser: IUser,
): IStatPreviewData => ({
    name: values.name,
    description: values.description,
    categories: values.categories.map(({ label, regionName }) => `${ regionName } ${ label }`),
    author: isEdit ? initialStat.author.name : `${ currentUser.firstName } ${ currentUser.lastName }`,
    date: isEdit ? initialStat.createdAt : '',
    attachments: getAttachments(values.attachments || []),
    previewUrl: values.preview?.url || null,
    previewName: values.preview?.name || null,
    id: isEdit ? initialStat.id : '',
});
