import { groupValidators, applyValidatorToMultipleFields } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateDate } from '@/Framework/UI/Organisms/FinalForm/validators/validateDate';
import { validateRegExp } from '@/Framework/UI/Organisms/FinalForm/validators/validateRegExp';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import {
  DEAL_PROFILE_FIELDS,
  REGULAR_EXPRESSIONS,
  DEAL_PROFILE_FIELDS_ARRAY,
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
  TRANCHE_FIELDS,
} from './constants';
import Product from '@/finsight/domain/Product';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';

/**
 * @param {Object} field
 * @return {Object}
 */
const formatFieldForValidator = (field) => ({ fieldName: field.name, fieldCaption: field.label, ...field });

/**
 * This validator is needed in order to avoid the following redux-form bug:
 * FieldArray removing property names if value is empty on blur (added after initialization).
 *
 * Ticket: https://finsight.myjetbrains.com/youtrack/issue/FIN-10353
 * More on bug: https://github.com/redux-form/redux-form/issues/3366
 *
 * @param {Object} field
 * @return {Object}
 */
const validateRegexForTrancheField = (field) => {
  if (typeof field.value === 'undefined') {
    return {};
  }

  return validateRegExp(field);
};

const validateTranches = (tranches = []) => (
  {
    [DEAL_PROFILE_FIELDS.TRANCHES.name]: tranches.map(
      (values) => {
        if (values[TRANCHE_FIELDS.IS_EXCLUDED.name]) {
          return {};
        }
        return groupValidators(
          // REQUIRED
          applyValidatorToMultipleFields(
            {
              values,
              validator: validateRequiredField,
              fields: Object.values(TRANCHE_FIELDS)
                  .filter(({ isRequired }) => isRequired)
                  .map(formatFieldForValidator),
            },
          ),
          // MIN LENGTH
          validateMinLength(
            {
              value: values[TRANCHE_FIELDS.CLASS.name],
              fieldName: TRANCHE_FIELDS.CLASS.name,
              fieldCaption: TRANCHE_FIELDS.CLASS.label,
              length: 1,
            },
          ),
          // MAX LENGTH
          applyValidatorToMultipleFields(
            {
              values,
              validator: validateMaxLength,
              fields: [
                { ...TRANCHE_FIELDS.CLASS, length: 20 },
                { ...TRANCHE_FIELDS.GUIDANCE_IPT, length: 50 },
                { ...TRANCHE_FIELDS.IPT_PXD, length: 50 },
                { ...TRANCHE_FIELDS.GUIDANCE, length: 50 },
                { ...TRANCHE_FIELDS.SPREAD, length: 50 },
              ].map(formatFieldForValidator),
            },
          ),
          // DATE
          applyValidatorToMultipleFields(
            {
              values,
              validator: validateDate,
              fields: DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.filter(
                  (field: IDealProfileFormField) => field.isDate,
              ).map(formatFieldForValidator),
            },
          ),
          // REG EXP
          applyValidatorToMultipleFields(
            {
              values,
              validator: validateRegexForTrancheField,
              fields: [
                {
                  ...TRANCHE_FIELDS.SIZE,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.WAL_YRS,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.WAL_EXT,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.TENOR_YRS,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_4_BEFORE_3_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.CREDIT_ENHANCEMENT,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_3_BEFORE_3_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.LOAN_TO_VALUE,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_3_BEFORE_2_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.ISSUE_PRICE,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.BOOK_SIZE,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.ANNOUNCED_SIZE,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.PEAK_BOOK_M,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
                },
                {
                  ...TRANCHE_FIELDS.CUSIPS,
                  expression: REGULAR_EXPRESSIONS.STRING_CUSIP_CODE,
                },
                {
                  ...TRANCHE_FIELDS.FIGI,
                  expression: REGULAR_EXPRESSIONS.STRING_ISIN_CODE,
                },
                {
                  ...TRANCHE_FIELDS.CUSIPS_REGS,
                  expression: REGULAR_EXPRESSIONS.STRING_CUSIP_REGS_CODE,
                },
                {
                  ...TRANCHE_FIELDS.ISINS,
                  expression: REGULAR_EXPRESSIONS.STRING_ISIN_CODE,
                },
                {
                  ...TRANCHE_FIELDS.SIZE_AFTER_TAP,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
                {
                  ...TRANCHE_FIELDS.PAR_CALL_MONTHS,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
                {
                  ...TRANCHE_FIELDS.MWC_T,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
                {
                  ...TRANCHE_FIELDS.PAR_AMOUNT,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
                {
                  ...TRANCHE_FIELDS.BACK_END_SPREAD,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
                {
                  ...TRANCHE_FIELDS.TRADING_DELTA,
                  expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL,
                },
              ].map(formatFieldForValidator),
            },
          ),
        );
      },
    ),
  }
);

export default (values) => groupValidators(
  // TRANCHES
  validateTranches(values[DEAL_PROFILE_FIELDS.TRANCHES.name]),
  // REQUIRED
  applyValidatorToMultipleFields(
    {
      values,
      validator: validateRequiredField,
      fields: [
        DEAL_PROFILE_FIELDS.PRIMARY_ISSUER,
        DEAL_PROFILE_FIELDS.PARENT,
        DEAL_PROFILE_FIELDS.SERIES,
        DEAL_PROFILE_FIELDS.COUNTRY,
        DEAL_PROFILE_FIELDS.PRODUCT,
        DEAL_PROFILE_FIELDS.STATUS,
        ...(
          Product.isAbsProductId(mapOptionToValue(values[DEAL_PROFILE_FIELDS.PRODUCT.name]))
            ? [DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR]
            : []
        ),
      ].map(formatFieldForValidator),
    },
  ),
  // MIN LENGTH
  validateMinLength(
    {
      value: values[DEAL_PROFILE_FIELDS.SERIES.name],
      fieldName: DEAL_PROFILE_FIELDS.SERIES.name,
      fieldCaption: DEAL_PROFILE_FIELDS.SERIES.label,
      length: 1,
    },
  ),
  validateMinLength(
    {
      value: values[DEAL_PROFILE_FIELDS.TIMING.name],
      fieldName: DEAL_PROFILE_FIELDS.TIMING.name,
      fieldCaption: DEAL_PROFILE_FIELDS.TIMING.label,
      length: 1,
    },
  ),
  validateMinLength(
    {
      value: values[DEAL_PROFILE_FIELDS.ROADSHOW_DATES.name],
      fieldName: DEAL_PROFILE_FIELDS.ROADSHOW_DATES.name,
      fieldCaption: DEAL_PROFILE_FIELDS.ROADSHOW_DATES.label,
      length: 1,
    },
  ),
  // MAX LENGTH
  applyValidatorToMultipleFields(
    {
      values,
      validator: validateMaxLength,
      fields: [
        { ...DEAL_PROFILE_FIELDS.SERIES, length: 255 },
        { ...DEAL_PROFILE_FIELDS.PRICING_SPEED, length: 50 },
        { ...DEAL_PROFILE_FIELDS.NUMBER_OF_LOANS, length: 10 },
        { ...DEAL_PROFILE_FIELDS.NUMBER_OF_PROPERTIES, length: 10 },
        { ...DEAL_PROFILE_FIELDS.TOP_10_LOANS, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_CUT_OFF_LTV, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_MATURITY_LTV, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_REM_AMRT, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_SEASONING_MONTHS, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_TERM_TO_MAT, length: 10 },
        { ...DEAL_PROFILE_FIELDS.WA_UW_NOI_DEBT_YIELD, length: 10 },
        { ...DEAL_PROFILE_FIELDS.MIN_DENOMINATION, length: 50 },
        { ...DEAL_PROFILE_FIELDS.COLLATERAL, length: 750 },
        { ...DEAL_PROFILE_FIELDS.COMMENTS, length: 750 },
        { ...DEAL_PROFILE_FIELDS.GUARANTOR, length: 255 },
        { ...DEAL_PROFILE_FIELDS.ORIGINATOR, length: 255 },
        { ...DEAL_PROFILE_FIELDS.RISK_RETENTION, length: 750 },
        { ...DEAL_PROFILE_FIELDS.USE_OF_PROCEEDS, length: 750 },
        { ...DEAL_PROFILE_FIELDS.VOLCKER_COMPLIANCE, length: 750 },
        { ...DEAL_PROFILE_FIELDS.TIMING, length: 128 },
        { ...DEAL_PROFILE_FIELDS.ROADSHOW_DATES, length: 128 },
      ].map(formatFieldForValidator),
    },
  ),
  // DATE
  applyValidatorToMultipleFields(
    {
      values,
      validator: validateDate,
      fields: DEAL_PROFILE_FIELDS_ARRAY.filter(
        (field: IDealProfileFormField) => field.isDate,
      ).map(formatFieldForValidator),
    },
  ),
  // REG EXP
  applyValidatorToMultipleFields(
    {
      values,
      validator: validateRegExp,
      fields: [
        {
          ...DEAL_PROFILE_FIELDS.CUT_OFF_BALANCE,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITH_REQUIRED_DECIMAL,
        },
        {
          ...DEAL_PROFILE_FIELDS.NUMBER_OF_LOANS,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITHOUT_DECIMAL,
        },
        {
          ...DEAL_PROFILE_FIELDS.NUMBER_OF_PROPERTIES,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITHOUT_DECIMAL,
        },
        {
          ...DEAL_PROFILE_FIELDS.REINVESTMENT_PERIOD,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_3_BEFORE_3_AFTER_POINT,
        },
        {
          ...DEAL_PROFILE_FIELDS.WAL_TEST_PD,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_4_BEFORE_3_AFTER_POINT,
        },
        {
          ...DEAL_PROFILE_FIELDS.ESTIMATED_DEAL_SIZE,
          expression: REGULAR_EXPRESSIONS.NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT,
        },
      ].map(formatFieldForValidator),
    },
  ),
);
