import React, { useEffect } from 'react';
import cn from 'classnames';

import { Field, FieldArray, useField, useForm } from '@/Framework/UI/Organisms/FinalForm';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';
import UploadImageInput from '@/Framework/UI/Organisms/Upload/components/UploadImageInput';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Input from '@dealroadshow/uikit/core/components/Input';
import DetailsSections from './DetailsSections';
import ColorFields from './ColorFields';
import UnderwritersLogos from './UnderwritersLogos';

import {
  useCustomRegistrationContext,
} from '@/evercall/application/condor/call/profile/customRegistration/CustomRegistrationContext';
import LogoRepository from '@/evercall/infrastructure/repository/LogoRepository';
import useFormReinitializeAfterSubmit from '../useFormReinitializeAfterSubmit';
import { getCustomRegistrationFormInitialValues } from './helpers';
import { useDIContext } from '@/Framework/DI/DIContext';

import { ICustomRegistrationFormValues } from './interfaces';
import { CONDOR_EVERCALL_CUSTOM_MESSAGE_MAX_LENGTH } from './constants';
import * as UPLOAD_CONSTANTS from '@/Framework/UI/Organisms/Upload/constants';

import styles from './customRegistration.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';

const CustomRegistrationFormFields = () => {
  const {
    state: {
      isFetching,
      isUpdating,
      settings,
    },
  } = useCustomRegistrationContext();

  const { container } = useDIContext();
  const logoRepository = container.get<LogoRepository>(LogoRepository);

  const form = useForm<ICustomRegistrationFormValues>();
  const {
    input: {
      value: isEnableCustomRegistrationFormValue,
    },
  } = useField<ICustomRegistrationFormValues['isEnableCustomRegistration']>('isEnableCustomRegistration', {
    subscription: {
      value: true,
    },
  });

  useEffect(
    () => {
      if (!isFetching && settings) {
        form.initialize(getCustomRegistrationFormInitialValues(settings));
      }
    },
    [settings],
  );

  useFormReinitializeAfterSubmit(
    () => form.restart(getCustomRegistrationFormInitialValues(settings)),
    isUpdating,
  );

  const headerCls = cn(headerStyles.isH3, styles.panelHeaderLabel);

  return (
    <>
      <div className={ headerCls }>Custom Event Page</div>
      <Field
        name="isEnableCustomRegistration"
        dataTest="isEnableCustomRegistration"
        label="Enable custom registration & confirmation pages "
        component={ CheckboxInput }
        type="checkbox"
        className={ cn({ [styles.registrationEnabledCheckboxContainer]: isEnableCustomRegistrationFormValue }) }
      />
      { isEnableCustomRegistrationFormValue && (
        <>
          <Field
            name="companyLogo"
            label="Company Logo"
            uploadName="logo"
            component={ UploadImageInput }
            uploadMethod={ logoRepository.upload }
            touchedError
          />
          <Field
            name="backgroundImage"
            label="Background Image"
            uploadName="background image"
            component={ UploadImageInput }
            uploadMethod={ logoRepository.upload }
            touchedError
            allowedFileText={ UPLOAD_CONSTANTS.UPLOAD_BACKGROUND_ALLOWED_FILES_TEXT }
          />
          <ColorFields />
          <div className={ headerStyles.isH3 }>Underwriters Logos</div>
          <FieldArray
            name="underwritersLogos"
            component={ UnderwritersLogos }
          />
          <div className={ headerCls }>Email Registration Page</div>
          <Field
            component={ Input }
            formFieldClassName={ formStyles.formInput }
            name="registrationButtonText"
            label="Button Text"
            dataTest="registrationButtonTextInput"
          />
          <Field
            name="registrationCustomMessage"
            label="Custom Message"
            placeholder=""
            dataTest="registrationCustomMessageRichTextInput"
            component={ RichTextInput }
            maxLength={ CONDOR_EVERCALL_CUSTOM_MESSAGE_MAX_LENGTH }
            isEnabledCountdownMessage
            editableWrpCls={ styles.editorInput }
          />
          <div className={ headerCls }>Confirmation Page</div>
          <Field
            component={ Input }
            formFieldClassName={ formStyles.formInput }
            name="confirmationTitleText"
            label="Title"
            dataTest="confirmationTitleTextInput"
          />
          <Field
            name="confirmationDescription"
            label="Description"
            placeholder="A confirmation email was sent to name@company.com and we’ve included the call and roadshow details below for quick reference."
            dataTest="confirmationDescriptionRichTextInput"
            component={ RichTextInput }
            maxLength={ CONDOR_EVERCALL_CUSTOM_MESSAGE_MAX_LENGTH }
            isEnabledCountdownMessage
            isFocused={ false }
            editableWrpCls={ styles.editorInput }
          />
          <FieldArray
            name="detailsOrder"
            component={ DetailsSections }
          />
        </>
      ) }
    </>
  );
};

export default CustomRegistrationFormFields;
