import { AsyncSelect } from '@dealroadshow/uikit/core/components/Select/AsyncSelect';
import { components as SelectComponents, MultiValueProps } from 'react-select';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import {
  ParentIssuerContextProvider,
  useParentIssuerContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import { IOptionParentIssuer } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

const ParentIssuerSelect = ({
  input,
  ...otherProps
}: IFinalFormFieldInputComponentProps) => {
  const {
    loadParentIssuerOptions,
    currentParentIssuerOptions,
  } = useParentIssuerContext();

  const MultiValue = ({
    children,
    ...otherProps
  }: MultiValueProps<IOptionParentIssuer>) => (
    <SelectComponents.MultiValue { ...otherProps }>
      { `${ otherProps.data.label } - ${ otherProps.data.isParent ? 'Parent' : 'Issuer' }` }
    </SelectComponents.MultiValue>
  );

  return (
    <AsyncSelect
      { ...otherProps }
      name={ input.name }
      value={ input.value }
      onBlur={ input.onBlur }
      onChange={ input.onChange }
      label="Parent/Issuer"
      dataTest="parentAndIssuer"
      placeholder="Select related parent company or issuer"
      loadOptions={ (query, callback) => {
        loadParentIssuerOptions(query, callback);
      } }
      defaultOptions={ currentParentIssuerOptions }
      hideSelectedOptions={ false }
      closeMenuOnSelect={ false }
      components={ { MultiValue } }
      isGrouped
      isMulti
      noOptionsMessage={ () => 'You need to enter at least 3 letters' }
    />
  );
};

export default (props: IFinalFormFieldInputComponentProps) => (
  <ParentIssuerContextProvider initialFormData={ props.initialFormData }>
    <ParentIssuerSelect { ...props } />
  </ParentIssuerContextProvider>
);
