import React, { forwardRef } from 'react';
import cn from 'classnames';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';

import avatarStyles from './avatar.scss';

interface IProps {
  firstName?: string,
  lastName?: string,
  email?: string,
  tooltipContent?: string,
  showTitle?: boolean,
  containerClass?: string,
  avatarClass?: string,
  nameClass?: string,
  avatarContent?: any,
  nameContent?: any,
  color?: string,
}

export type TRefType = HTMLDivElement;

const generateAvatarPlaceholder = (email, firstName, lastName) => {
  const placeholder = [];
  if (!firstName && !lastName && email) {
    placeholder.push(email.substring(0, 1)
      .toLocaleUpperCase());
  }
  if (firstName) {
    placeholder.push(firstName.substring(0, 1)
      .toLocaleUpperCase());
  }
  if (lastName) {
    placeholder.push(lastName.substring(0, 1)
      .toLocaleUpperCase());
  }
  return placeholder.join('');
};

export const Avatar = forwardRef<TRefType, IProps>(({
  firstName,
  lastName,
  email,
  containerClass,
  avatarClass,
  nameClass,
  avatarContent,
  nameContent,
  color,
  tooltipContent = 'Finsight Account',
  showTitle = false,
}, ref) => (
  <Tooltip
    placement="bottom"
    content={ tooltipContent }
    disabled={ !showTitle }
  >
    <div
      className={ cn(avatarStyles.container, containerClass) }
      data-test="userProfileAvatar"
    >
      <div
        className={ cn(avatarStyles.avatar, avatarClass) }
        style={ { backgroundColor: color } }
        ref={ ref }
        data-test="userProfileNameAbbreviation"
      >
        { avatarContent || generateAvatarPlaceholder(email, firstName, lastName) }
      </div>
      <div
        className={ cn(avatarStyles.name, nameClass) }
        data-test="userProfileFullName"
      >
        { nameContent || (
          <>
            <span>
              { !firstName && !lastName && email }
              { firstName }
            </span>
            <span>{ lastName }</span>
          </>
        ) }
      </div>
    </div>
  </Tooltip>
));

export default Avatar;
