import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import { GroupSelectWithToolbarField } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import useToolbarSelectSync from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/useToolbarSelectSync';
import { RegionAbbreviations } from '@/finsight/domain/Region';

const ThemesSelect = ({ disabled }: { disabled?: boolean }) => {
  const { options, themesOptions } = useCommentaryDealFlowContext();

  const { isFetchingThemes, isFetchingRegions, regions } = options;

  const defaultOptionValue = regions.find((region) => region.abbreviation === RegionAbbreviations.USOA)?.id;

  return (
    <Field
      name="articleParams.themes"
      label="Themes"
      placeholder="Select themes"
      isLoading={ isFetchingThemes || isFetchingRegions }
      isDisabled={ disabled }
      dataTest="commentaryDealFlowSelectThemes"
      formatValue={ (option) => `${ option.regionName } ${ option.label }` }
      { ...useToolbarSelectSync({ options: themesOptions, defaultOptionValue, dataTest: 'commentaryDealFlowSelectThemesRegions' }) }
      component={ GroupSelectWithToolbarField }
    />
  );
};

export default ThemesSelect;
