import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { CountLabel } from '@dealroadshow/uikit/core/components/Badge/CountLabel';
import { LABEL_COLOR_COUNT } from '@/finsight/ui/components/bonds/constants';
import filtersSelectors from '@/finsight/application/actions/bonds/filters/selectors';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import bwicSelectors from '@/condor/application/actions/finsight/import/bond/bwic/selectors';
import inventorySelectors from '@/condor/application/actions/finsight/import/bond/inventory/selectors';

function mapStateToProps(state) {
  return {
    canShowCount: (!bwicSelectors.isFetching(state) && !inventorySelectors.isFetching(state))
                  && (
                    !isEqual(filtersSelectors.getAppliedFilters(state), filtersSelectors.getInitialFilters(state))
                    || metadataSelectors.getSearch(state).length > 0
                  ),
    totalCount: bwicSelectors.getTotalCount(state),
    maxTotalCount: 99,
    backgroundColor: LABEL_COLOR_COUNT,
  };
}

export default connect(
  mapStateToProps,
)(CountLabel);
