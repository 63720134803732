import React, { useCallback } from 'react';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import styles from './deleteImportModal.scss';

interface IProps {
  isVisible: boolean,
  importItem: any,
  changeModalVisible: (visible: boolean) => void,
  onDelete: (id: string) => void,
}

const DeleteImportModal = ({
  importItem,
  isVisible,
  changeModalVisible,
  onDelete,
}: IProps) => {
  const handleDeleteClick = useCallback(() => {
    onDelete(importItem.importId);
  }, [importItem]);

  const onCloseHandler = () => changeModalVisible(false);

  return (
    <Modal
      title="Delete Confirmation"
      isVisible={ isVisible }
      className={ styles.modalWindow }
      onCloseClicked={ onCloseHandler }
      dataTest="deleteDealConfirmationModal"
      footer={ (
        <>
          <Button
            title="Delete"
            onClick={ handleDeleteClick }
            variant={ ButtonVariantType.warning }
            dataTest="condorDeleteImportModalButton"
          />
          <Button
            title="Cancel"
            onClick={ onCloseHandler }
            variant={ ButtonVariantType.text }
            dataTest="condorDeleteImportModalCancelButton"
          />
        </>
      ) }
    >
      <p>
        Are you sure you want to delete this import with { importItem.dealCount } deals?
      </p>
    </Modal>
  );
};

export default DeleteImportModal;
