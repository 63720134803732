import { useEffect, useRef } from 'react';
import LogoRepository from '@/evercall/infrastructure/repository/LogoRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import UploadTable from '@/Framework/UI/Organisms/Upload/components/UploadTable';
import {
  UPLOAD_LOGO_ALLOWED_FILES,
  UPLOAD_LOGO_ALLOWED_FILES_TEXT,
  UPLOAD_TABLE_COLUMNS_WITHOUT_NAME,
  UPLOAD_TABLE_WIDTHS_WITHOUT_NAME,
} from '@/Framework/UI/Organisms/Upload/constants';
import useUploadDocuments from '@/Framework/UI/Organisms/Upload/hooks/useUploadDocuments';
import isEmpty from 'lodash/isEmpty';
import { ICustomRegistrationFormValues, IUploadedFileFormValue } from '../interfaces';
import FormError from '@dealroadshow/uikit/core/components/Form/FormError';
import { UNDERWRITERS_LOGOS_MAX_COUNT } from '../constants';
import styles from '../customRegistration.scss';

const UnderwritersLogos = ({ fields, meta }: IFinalFormFieldArrayInputComponentProps<IUploadedFileFormValue>) => {
  const isUploadInitialized = useRef(false);
  const form = useForm<ICustomRegistrationFormValues>();

  const { container } = useDIContext();
  const logoRepository = container.get(LogoRepository);

  const onUploadDone = (payload) => {
    const relevantFormValues = form.getState().values[fields.name] ?? [];
    const index = relevantFormValues.findIndex((value) => value.uuid === payload.uuid);
    if (index >= 0) {
      fields.update(index, { ...relevantFormValues[index], url: payload.data[0] });
      form.blur(fields.name as keyof ICustomRegistrationFormValues);
    }
  };

  const { state, uploadDocuments, initUpload, cancelUpload, orderUploads } = useUploadDocuments({
    name: fields.name,
    uploadMethod: logoRepository.uploadUnderwriterLogo,
    onUploadDone,
  });

  useEffect(() => {
    if (!isUploadInitialized.current && fields.length && isEmpty(state.files)) {
      initUpload(fields.value);
      isUploadInitialized.current = true;
    }
  }, [fields.length]);

  return (
    <div className={ styles.underwritersLogosWrapper }>
      <UploadTable
        fields={ fields }
        fieldComponent={ Field }
        uploadFiles={ state }
        uploadList={ fields.value || [] }
        formFieldName={ fields.name }
        columns={ UPLOAD_TABLE_COLUMNS_WITHOUT_NAME }
        widths={ UPLOAD_TABLE_WIDTHS_WITHOUT_NAME }
        allowedFileTypes={ UPLOAD_LOGO_ALLOWED_FILES }
        allowedFileText={ UPLOAD_LOGO_ALLOWED_FILES_TEXT }
        uploadName="logos"
        limit={ UNDERWRITERS_LOGOS_MAX_COUNT }
        onUpload={ uploadDocuments }
        onUploadCancel={ cancelUpload }
        onUploadOrder={ orderUploads }
        nameFieldTooltipContent="An alternate text for an image, if the image cannot be displayed"
      />
      <FormError { ...meta } className={ styles.underwritersLogosError } />
    </div>
  );
};

export default UnderwritersLogos;
