import React, { memo, useEffect } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Paginator from '../PaginatorContainer';
import FilingModal from './FilingModal';

import Filing from '@/finsight/domain/Filing';
import columns from './columns';

import styles from '../tableStyles.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const Abs15GTable = ({
  init,
  reset,
  ...otherProps
}: IProps) => {
  const location = useLocation().pathname;
  const types = Filing.getFilingTypesArray(location);

  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <>
      <FilingModal />
      <DataTable
        loadingComponent={ Spinner }
        className={ cn(dataTableStyles.isHoverable, styles.tableRow) }
        dataTest="abs15GDataTable"
        columns={ columns }
        { ...otherProps }
      />
      <Paginator
        types={ types }
        dataTest="abs15GTable"
      />
    </>
  );
};

export default memo(Abs15GTable, isEqual);
