import { useState } from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconList from '@dealroadshow/uikit/core/components/Icon/IconList';
import ManageStatsCategoriesModal from './ManageStatsCategoriesModal';

import styles from './styles.scss';

const ManageStatsCategories = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      <Button
        variant={ ButtonVariantType.outline }
        className={ styles.buttonWithIcon }
        dataTest="manageStatsCategories"
        onClick={ openModal }
      >
        <IconList />
      </Button>
      { isModalOpened && (
        <ManageStatsCategoriesModal
          closeModal={ closeModal }
        />
      ) }
    </>
  );
};

export default ManageStatsCategories;
