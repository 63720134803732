import React from 'react';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import { RadioGroup, Radio } from '@dealroadshow/uikit/core/components/Radio';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import UserPlanTag from '@/Framework/UI/Atoms/UserPlanTag';
import { UserPlanType } from '@/users/domain/UserPlan';
import config from './configPlansPanel';

import styles from '../Subscribers/ManageSubscriptionModal/styles.scss';

interface IProps {
  plan: string,
  name: string,
  dataTest: string,
  label?: string,
  withTag?: boolean,
  labelClassName?: string,
}

const PlansPanel = ({
  plan,
  name,
  dataTest,
  label,
  withTag = true,
  labelClassName,
}: IProps) => {
  const renderLabel = (text: string, userPlan: UserPlanType) => (
    <div className={ cn(styles.planLabelContainer, labelClassName) }>
      <span className={ styles.textLabel }>{ text }</span>
      { withTag && (<UserPlanTag userPlan={ userPlan } />) }
    </div>
  );

  return (
    <div className={ styles.plansPanelContainer }>
      <Field
        name={ name }
        render={ (fieldProps) => (
          <RadioGroup
            { ...fieldProps.input }
            isNarrow
            dataTest={ dataTest }
            className={ styles.plansGroupContainer }
            label={ label }
            labelCls={ styles.plansPanelTitle }
            selectedValue={ fieldProps.input.value }
          >
            {
              (config[plan] || []).map(({
                text,
                plan,
              }) => (
                <Radio
                  key={ plan }
                  label={ renderLabel(text, plan) }
                  value={ plan }
                  dataTest={ camelCase(`userPlan${ plan }`) }
                />
              ))
            }
          </RadioGroup>
        ) }
      />
    </div>
  );
};

export default PlansPanel;
