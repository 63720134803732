import React, { useEffect, useState } from 'react';
import { Switch, useParams, useLocation } from 'react-router';

import NavLink from '@/Framework/Router/ReactRouter/NavLink';
import Route from '@/Framework/Router/ReactRouter/Route';
import PageWrapper from '@/evercall/ui/common/PageWrapper';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

import * as CONDOR_URL from '@/condor/ui/common/constants';

import CallProfileContext, {
  useCallProfileContext,
} from '@/evercall/application/condor/call/profile/CallProfileContext';

import Settings from './Settings';
import AuditTrail from './AuditTrail';
import CustomRegistration from './CustomRegistration';

import pageStyles from '@/Framework/GlobalStyles/page.scss';

const CallProfile = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const {
    state: {
      item,
    },
    initCallProfile,
  } = useCallProfileContext();

  const { callId: callIdFromUrl } = useParams();
  const location = useLocation();

  useEffect(
    () => {
      initCallProfile()
        .then(() => setIsInitialized(true));
    },
    [],
  );

  useEffect(
    () => {
      if (isInitialized && location.pathname?.includes('settings')) {
        initCallProfile();
      }
    },
    [location.pathname],
  );

  return (
    <PageWrapper
      label={ item?.name || 'Evercall' }
      navigationComponent={ (
        <div className={ pageStyles.pageNavigation }>
          <div className={ pageStyles.pageNavigationLinks }>
            <NavLink
              key="settings"
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/${ callIdFromUrl }/settings` }
              dataTest="settings"
            >
              Settings
            </NavLink>
            <NavLink
              key="auditTrail"
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/${ callIdFromUrl }/audit-trail` }
              dataTest="auditTrailLink"
            >
              Audit Trail
            </NavLink>
            <NavLink
              key="customEventPage"
              activeClassName={ pageStyles.isActiveNavLink }
              to={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/${ callIdFromUrl }/custom-event-page` }
              dataTest="customEventPage"
            >
              Custom Event Page
            </NavLink>
          </div>
        </div>
      ) }
    >
      {
        !isInitialized
          ? (
            <Spinner
              isVisible
              overlay
              isFixed
            />
          )
          : (
            <Switch>
              <Route
                exact
                path={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/:callId/settings` }
                component={ Settings }
              />
              <Route
                exact
                path={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/:callId/audit-trail` }
                component={ AuditTrail }
              />
              <Route
                exact
                path={ `${ CONDOR_URL.CONDOR_EVERCALL_PROFILE }/:callId/custom-event-page` }
                component={ CustomRegistration }
              />
            </Switch>
          )
      }
    </PageWrapper>
  );
};

export default () => (
  <CallProfileContext>
    <CallProfile />
  </CallProfileContext>
);
