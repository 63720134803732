import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import ArticlePreviewContextProvider, { useArticlePreviewContext }
  from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/ArticlePreviewContext';
import SectorIndustryLabel from '@/finsight/ui/common/components/CreditFlowResearch/SectorIndustryLabel';
import PreviewArticleModalFooter from './PreviewArticleModalFooter';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import {
  Attachments,
} from '@/condor/ui/components/Finsight/CreditFlowResearch/common/PreviewModalComponents';
import {
  Description,
  Metadata,
  Tags,
  Title,
} from '@/finsight/ui/common/components/CreditFlowResearch/CreditFlowData';

import commonStyles from '@/condor/ui/components/Finsight/CreditFlowResearch/common/common.scss';

const PreviewModal = () => {
  const { isEdit } = useArticleProfileContext();
  const {
    isFetching,
    previewData,
    closePreviewModal,
  } = useArticlePreviewContext();

  if (isFetching || !previewData) {
    return <Spinner isFixed isVisible overlay />;
  }

  const {
    sectorOrIndustry,
    subsectorOrSubindustry,
    headline,
    description,
    author,
    date,
    themes,
    attachments,
  } = previewData;
  return (
    <Modal
      className={ commonStyles.previewModal }
      onCloseClicked={ closePreviewModal }
      isVisible
      title={ isEdit ? 'Updating Post' : 'Publishing New Post' }
      footer={ (<PreviewArticleModalFooter />) }
      dataTest="articlePreviewModal"
    >
      <Metadata
        author={ author }
        date={ date }
        authorDataTest="previewAuthor"
        dateDataTest="previewDate"
      >
        <SectorIndustryLabel
          sectorOrIndustry={ sectorOrIndustry }
          subsectorOrSubindustry={ subsectorOrSubindustry }
        />
      </Metadata>
      <Title title={ headline } dataTest="previewHeadline" />
      <div className={ commonStyles.modalContent }>
        <Tags tags={ themes } dataTest="previewThemes" tagDataTest="previewTheme" />
        <Description description={ description } dataTest="previewDescription" />
        <Attachments attachments={ attachments } />
      </div>
    </Modal>
  );
};

export default ({ setIsPreviewOpen }) => (
  <ArticlePreviewContextProvider setIsPreviewOpen={ setIsPreviewOpen }>
    <PreviewModal />
  </ArticlePreviewContextProvider>
);
