import React from 'react';
import { components as ReactSelectComponents, SingleValueProps, OptionProps } from 'react-select';
import { TaggingStateTypes } from '@/condor/domain/vo/EnhancedTagging/TaggingState';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import IconTag from '@dealroadshow/uikit/core/components/Icon/IconTag';
import IconPartiallyTagged from '@dealroadshow/uikit/core/components/Icon/IconPartiallyTagged';
import IconNotTagged from '@dealroadshow/uikit/core/components/Icon/IconNotTagged';

import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import styles from './taggingStateSelect.scss';

interface ITaggingStateOption {
  value: TaggingStateTypes,
  label: string,
  icon: IconComponentType,
}

const taggingStateOptions: ITaggingStateOption[] = [
  {
    value: TaggingStateTypes.FULLY_TAGGED,
    label: 'Mark all as Fully Tagged',
    icon: IconTag,
  },
  {
    value: TaggingStateTypes.PARTIALLY_TAGGED,
    label: 'Mark all as Partially Tagged',
    icon: IconPartiallyTagged,
  },
  {
    value: TaggingStateTypes.NOT_TAGGED,
    label: 'Mark all as Untagged',
    icon: IconNotTagged,
  },
];

const Option = ({
  children,
  ...props
}: OptionProps<ITaggingStateOption>) => {
  const { data: { icon: IconComponent } } = props;
  return (
    <ReactSelectComponents.Option { ...props } className={ styles.selectAccountOptions }>
      <IconComponent className={ styles.selectIcon } />
      { children }
    </ReactSelectComponents.Option>
  );
};

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<ITaggingStateOption>) => {
  const { data: { icon: IconComponent } } = props;
  return (
    <ReactSelectComponents.SingleValue { ...props }>
      { IconComponent && <IconComponent className={ styles.selectIcon } /> }
    </ReactSelectComponents.SingleValue>
  );
};

interface IProps {
  value: TaggingStateTypes,
}

const TaggingStateSelect = ({
  value,
  ...restProps
}: IProps & Record<string, any>) => {
  return (
    // @ts-ignore
    <FinalFormSelect
      components={ {
        Option,
        SingleValue,
      } }
      selectClassName={ styles.select }
      options={ taggingStateOptions }
      placeholder="Tag"
      searchable={ false }
      { ...restProps }
    />
  );
};

export default TaggingStateSelect;
