import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import UploadDropzoneOneFile from '@/Framework/UI/Organisms/Upload/components/UploadDropzoneOneFile';
import {
  UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES,
  UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES_TEXT,
  BYTES_IN_TWO_GIGABYTES,
} from '@/Framework/UI/Organisms/Upload/constants';
import columns from './tableColumns';
import {
  useUploadSsoCertificateContext,
} from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import DeleteCertificateModal from './DeleteCertificateModal';
import ViewCertificateModal from './ViewCertificateModal';

import styles from './styles.scss';

const SsoCertificateUploadForm = () => {
  const { id: accountId } = useSelector((state) => selectors.getItemData(state));
  const {
    certificates,
    isFetching,
    getListCertificates,
    addCertificate,
  } = useUploadSsoCertificateContext();

  const onUpload = (files) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const content = (event.target.result).toString();

      addCertificate({
        accountId,
        name: files[0].name,
        content,
      });
    };

    reader.readAsText(files[0]);
  };

  useEffect(() => {
    getListCertificates(accountId);
  }, []);

  return (
    <div className={ styles.ssoUploadForm } data-test="ssoCertificateUploadForm">
      <Spinner isVisible={ isFetching } overlay />
      <div className={ styles.ssoUploadFormTitle }>Account SSO</div>
      <TableWrapper contentClassName={ styles.ssoTableWrapper }>
        <DataTable
          dataTest="ssoCertificatesTable"
          columns={ columns }
          data={ certificates }
          className={ styles.ssoUploadFormTable }
        />
      </TableWrapper>
      <div className={ styles.ssoUploadZoneContainer }>
        <UploadDropzoneOneFile
          onUpload={ onUpload }
          allowedFileTypes={ UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES }
          allowedFileText={ UPLOAD_SSO_CERTIFICATE_ALLOWED_FILES_TEXT }
          formFieldName="ssoCertificateUpload"
          uploadName=""
          maxSize={ BYTES_IN_TWO_GIGABYTES }
          maxSizeUnits="GB"
          dropzoneHelperClassName={ styles.ssoUploadZoneItem }
        />
        <div className={ styles.maxSizeTip }>The max file size supported 2 gigabytes.</div>
      </div>
      <DeleteCertificateModal />
      <ViewCertificateModal />
    </div>
  );
};

export default SsoCertificateUploadForm;
