import filesUrl from '@/Framework/url/filesUrl';
import { validateColors } from '@/Framework/UI/Organisms/FinalForm/validators/validateColors';
import { validateImageDimensions } from '@/Framework/UI/Organisms/FinalForm/validators/validateImageDimensions';
import { applyValidatorToMultipleFields, groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateFieldsArrayForFinalForm } from '@/Framework/UI/Organisms/FinalForm/validators/validateFieldsArrayForFinalForm';
import { validateLink } from '@/Framework/UI/Organisms/FinalForm/validators/validateLink';
import { validateArrayMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateArrayMaxLength';
import { UNDERWRITERS_LOGOS_MAX_COUNT } from './constants';

const colorFields = [
  { fieldName: 'backgroundColor' },
  { fieldName: 'tileColor' },
  { fieldName: 'primaryTextColor' },
  { fieldName: 'secondaryTextColor' },
  { fieldName: 'accentColor' },
  { fieldName: 'highlightBackgroundColor' },
  { fieldName: 'inputBorderColor' },
  { fieldName: 'registerButtonTextColor' },
  { fieldName: 'registerButtonColor' },
  { fieldName: 'addToCalendarAndCopyButtonsColor' },
];

const buttonTextField = {
  fieldName: 'registrationButtonText',
  fieldCaption: 'Button Text',
};

const confirmationTitleTextField = {
  fieldName: 'confirmationTitleText',
  fieldCaption: 'Title',
};

const customDetailsSectionNameField = {
  fieldName: 'customDetailsSectionName',
  fieldCaption: 'Section Name',
};

const paramsSubfields = [
  {
    fieldName: 'title',
    fieldCaption: 'Subfield Title',
    functionValidation: [
      {
        name: validateMaxLength,
        params: {
          length: 50,
        },
      },
    ],
  },
  {
    fieldName: 'text',
    fieldCaption: 'Subfield Text',
    functionValidation: [
      {
        name: validateMaxLength,
        params: {
          length: 1000,
        },
      },
    ],
  },
  {
    fieldName: 'hyperlinkText',
    fieldCaption: 'Hyperlink Text',
    functionValidation: [
      {
        name: validateLink,
        params: {},
      },
      {
        name: validateMaxLength,
        params: {
          length: 1000,
        },
      },
    ],
  },
];

export default async (values) => {
  let asyncError = null;
  const { isEnableCustomDetailsSection } = values;

  if (values.backgroundImage) {
    asyncError = await validateImageDimensions(
      {
        url: filesUrl.getFileUrl(values.backgroundImage.url),
        fieldName: 'backgroundImage',
        fieldCaption: 'Background Image',
        minWidth: 1600,
        minHeight: 1024,
      },
    );
  }

  const colorErrors = validateColors(
    colorFields,
    values,
  );

  return groupValidators(
    asyncError,
    colorErrors,
    applyValidatorToMultipleFields(
      {
        values,
        validator: validateMaxLength,
        fields: [
          { ...buttonTextField, length: 30 },
          { ...confirmationTitleTextField, length: 80 },
          ...(isEnableCustomDetailsSection ? [{ ...customDetailsSectionNameField, length: 50 }] : []),
        ],
      },
    ),
    isEnableCustomDetailsSection
      ? groupValidators(
        validateRequiredField({ ...customDetailsSectionNameField, value: values.customDetailsSectionName }),
        validateFieldsArrayForFinalForm(
          'customDetailsSectionSubfields',
          paramsSubfields,
          { collection: values.customDetailsSectionSubfields || [] },
        ),
      )
      : null,
    validateArrayMaxLength({
      value: values.underwritersLogos,
      fieldCaption: 'Underwriters Logos',
      fieldName: 'underwritersLogos',
      length: UNDERWRITERS_LOGOS_MAX_COUNT,
     }),
  );
};
