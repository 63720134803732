import React, { useEffect } from 'react';
import cn from 'classnames';
import CondorBondTypes from '@/condor/ui/components/Finsight/Import/Bond/List/CondorBondTypes/CondorBondTypes';

import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import AssetClasses from '@/condor/ui/components/Finsight/Import/Bond/AssetClasses';
import BondTables from '@/condor/ui/components/Finsight/Import/Bond/BondTables';
import SearchContainer from '@/condor/ui/components/Finsight/Import/Bond/SearchContainer';
import BwicRow from './Bwic';
import InventoryRow from './Inventory';
import { getBwicColumns as getBwicHeaderColumns } from './Bwic/BwicColumns';
import { getInventoryColumns as getInventoryHeaderColumns } from './Inventory/InventoryColumns';
import DeleteBondModal from '@/condor/ui/components/Finsight/Import/Bond/List/DeleteBondModal';

import bondStyles from '@/finsight/ui/components/bonds/bonds.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import styles from './bondList.scss';

interface IProps {
  baseUrl: string,
  isInitialized: boolean,
  init: () => void,
  reset: () => void,
}

const Bonds = (
  {
    baseUrl,
    isInitialized,
    init,
    reset,
  }: IProps,
) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <>
      <DeleteBondModal />
      <Spinner
        overlay
        isVisible={ !isInitialized }
      />
      <div className={ cn(bondStyles.header, styles.bondsListHeader) }>
        <div className={ bondStyles.actions }>
          <div className={ bondStyles.searchInputWrapper }>
            <SearchContainer />
          </div>
        </div>
        <div className={ bondStyles.tabs }>
          { isInitialized && (
            <CondorBondTypes baseUrl={ baseUrl } />
          ) }
        </div>
      </div>
      <div className={ cn(styles.bondsListNavigation, pageStyles.pageNavigation) }>
        <div className={ pageStyles.pageNavigationLinks }>
          <AssetClasses baseUrl={ baseUrl } />
        </div>
      </div>
      { isInitialized && (
        <BondTables
          baseUrl={ baseUrl }
          getBwicHeaderColumns={ getBwicHeaderColumns }
          // @ts-ignore
          bwicRow={ BwicRow }
          getInventoryHeaderColumns={ getInventoryHeaderColumns }
          // @ts-ignore
          inventoryRow={ InventoryRow }
        />
      ) }
    </>
  );
};

export default Bonds;
