import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import styles from './companySelectModalButtons.scss';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';

interface IProps {
  submitDisabled: boolean,
  onCancelClick: () => void,
  onCreateCompanyClick: () => void,
}

const CompanySelectModalButtons = (
  {
    submitDisabled,
    onCancelClick,
    onCreateCompanyClick,
  }: IProps,
) => (
  <div className={ styles.buttonsWrp }>
    <div>
      <Button
        type="submit"
        variant={ ButtonVariantType.action }
        title="Save"
        disabled={ submitDisabled }
        dataTest="companySelectModalSaveButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        title="Cancel"
        onClick={ onCancelClick }
        dataTest="companySelectModalCancelButton"
      />
    </div>
    <Button
      variant={ ButtonVariantType.text }
      onClick={ onCreateCompanyClick }
      className={ styles.createBtn }
      dataTest="companySelectModalCreateCompanyButton"
    >
      <IconAdd className={ styles.addIcon } />
      Create New Company
    </Button>
  </div>
);

export default CompanySelectModalButtons;
