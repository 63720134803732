import React, { useCallback } from 'react';
import cn from 'classnames';
import { FormSection, Field } from 'redux-form';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import { getOptionMapper } from '@/Framework/dataHelpers/selectOptionMappers';
import { TRANCHE_FIELDS } from '../../../constants';
import withTrancheCell from '../../../helpers/withTrancheCell';
import { IRatingAgency } from '@/finsight/domain/vo/RatingAgency/IRatingAgency';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';

import styles from './ratingAgencies.scss';

const Select = withTrancheCell(ReduxFormSelect);
const createOption = getOptionMapper({ pathToLabel: 'abbreviation' });

interface IProps {
  field: string,
  isExcluded: boolean,
  trancheIndex: number,
}

const RatingAgenciesFields = (
  {
    field,
    isExcluded,
    trancheIndex,
  }: IProps,
) => {
  const { isRatingAgenciesCollapsed, agenciesToShow } = useTranchesContext();

  const renderField = useCallback(
    (agency: IRatingAgency) => (
      <Field
        key={ agency.abbreviation }
        name={ agency.abbreviation }
        placeholder="Select"
        options={ agency.ratings.map(createOption) }
        component={ Select }
        cellClassName={ cn({ [styles.ratingAgency]: isRatingAgenciesCollapsed }) }
        disabled={ isExcluded }
        dataTest={ `tranche${ trancheIndex }RatingAgency${ agency.abbreviation }` }
      />
    ),
    [isExcluded, isRatingAgenciesCollapsed],
  );

  return (
    <FormSection name={ `${ field }.${ TRANCHE_FIELDS.RATINGS.name }` }>
      { agenciesToShow.map(renderField) }
    </FormSection>
  );
};

export default RatingAgenciesFields;
