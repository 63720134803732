import React from 'react';

import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import IconCopy from '@dealroadshow/uikit/core/components/Icon/IconCopy';
import CopyToClipboard from '@/Framework/UI/Molecules/CopyToClipboard';

import styles from './styles.scss';

enum UrlTypes {
  AssertUrl = 'assertUrl',
  MetadataUrl = 'metadataUrl',
  LoginUrl = 'loginUrl',
}

const getPreviewURL = (urlType: UrlTypes, subdomain: string, shortname: string) => {
  switch (urlType) {
    case UrlTypes.AssertUrl:
      return `https://${ subdomain || '[SSO_SUBDOMAIN]' }.finsight.com/users/api/sso/${ shortname || '[SSO_SHORT_NAME]' }/assert`;
    case UrlTypes.MetadataUrl:
      return `https://${ subdomain || '[SSO_SUBDOMAIN]' }.finsight.com/users/api/sso/${ shortname || '[SSO_SHORT_NAME]' }/metadata.xml`;
    case UrlTypes.LoginUrl:
      return `https://${ subdomain || '[SSO_SUBDOMAIN]' }.finsight.com/users/api/sso/${ shortname || '[SSO_SHORT_NAME]' }/login`;
    default:
      return `https://${ subdomain || '[SSO_SUBDOMAIN]' }.finsight.com/users/api/sso/${ shortname || '[SSO_SHORT_NAME]' }/login`;
  }
};

const PreviewBlock = () => {
  const {
    values: {
      shortname,
      subdomain,
    },
  } = useFormState();
  return (
    <div className={ styles.previewBlock }>
      <h3 className={ styles.title }>Preview</h3>
      <div className={ styles.previewItem }>
        <CopyToClipboard
          dataTest="copyAssertUrl"
          content={ getPreviewURL(UrlTypes.AssertUrl, subdomain, shortname) }
        >
          { getPreviewURL(UrlTypes.AssertUrl, subdomain, shortname) }
          <IconCopy className={ styles.copyIcon } />
        </CopyToClipboard>
      </div>
      <div className={ styles.previewItem }>
        <CopyToClipboard
          dataTest="copyMetadataUrl"
          content={ getPreviewURL(UrlTypes.MetadataUrl, subdomain, shortname) }
        >
          { getPreviewURL(UrlTypes.MetadataUrl, subdomain, shortname) }
          <IconCopy className={ styles.copyIcon } />
        </CopyToClipboard>
      </div>
      <div className={ styles.previewItem }>
        <CopyToClipboard
          dataTest="copyLoginUrl"
          content={ getPreviewURL(UrlTypes.LoginUrl, subdomain, shortname) }
        >
          { getPreviewURL(UrlTypes.LoginUrl, subdomain, shortname) }
          <IconCopy className={ styles.copyIcon } />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default PreviewBlock;
