import { useEffect, useReducer } from 'react';
import getActions from './getActions';
import defaultReducer, { IReducerState, initialState as initialReducerState } from './reducer';
import { useUploadInputsStatusContext } from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import { TUploadMethod } from '@/Framework/UI/Organisms/Upload/interfaces';
import { TOnUploadDone } from './interfaces';

interface IProps {
  name: string,
  uploadMethod: TUploadMethod,
  onUploadDone?: TOnUploadDone,
  reducer?: (state: IReducerState, action: any) => IReducerState,
  initialState?: IReducerState,
}

const useUploadDocuments = (
  {
    name,
    uploadMethod,
    onUploadDone,
    reducer = defaultReducer,
    initialState = initialReducerState,
  }: IProps,
) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setIsUploaded } = useUploadInputsStatusContext();

  const isAllDocumentsCanSave = Object.values(state.files).every((file: any) => file.canSave);

  useEffect(() => {
    setIsUploaded(name, isAllDocumentsCanSave);
  }, [name, isAllDocumentsCanSave]);

  return {
    state,
    dispatch,
    ...getActions({ ...state, uploadMethod, onUploadDone }, dispatch),
  };
};

export default useUploadDocuments;
