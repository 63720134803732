import React from 'react';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import {
  useUploadSsoCertificateContext,
} from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

const CellAction = ({
  id,
  name,
}: ISsoCertificate) => {
  const { setCertificateForDelete } = useUploadSsoCertificateContext();

  const onClick = () => {
    setCertificateForDelete({
      id,
      name,
    });
  };

  return (
    <LastTableCell
      className={ styles.cellActions }
      childrenToShowOnHover={ (
        <IconTrash className={ styles.buttonRemove } onClick={ onClick } data-test="deleteCertificate" />
      ) }
    />
  );
};

export default CellAction;
