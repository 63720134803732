import React from 'react';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';

import styles from '../subscribersTable.scss';

interface IProps {
  onClick: () => void,
}

const UnsubscribeButton = ({
  onClick,
}: IProps) => (
  <Tooltip content="Manage Alerts">
    <IconSettings onClick={ onClick } className={ styles.unsubscribeIcon } />
  </Tooltip>
);

export default UnsubscribeButton;
