import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { useHistory } from 'react-router-dom';
import { Field } from 'redux-form';

import validate from './profileFormValidators';
import { CONDOR_CONTACT_PROFILE_FORM_NAME, CONDOR_CONTACTS_LIST_BASE_URL } from '@/condor/ui/common/constants';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import CompanyFieldContainer from '@/condor/ui/components/AccountsAndContacts/Contacts/CompanyFieldContainer';
import TitleField from '@/condor/ui/components/AccountsAndContacts/Contacts/TitleFieldContainer';
import PhoneNumber from '@/users/domain/PhoneNumber';
import { InputLabel as Label } from '@dealroadshow/uikit/core/components/Input/Label';
import {
  SelectOptionWithCountryFlag,
  SelectSingleValueWithCountryFlag,
} from '@dealroadshow/uikit/core/components/Select/components';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Input from '@dealroadshow/uikit/core/components/Input';
import PhoneInput from '@dealroadshow/uikit/core/components/Input/PhoneInput';
import {
  mapCountriesToOptions,
  getLabelWithOptionalMark,
} from '@/users/ui/components/Profile/Common/helpers';

import CommonProfileContextProvider, {
  useCommonProfileContext,
} from '@/users/ui/components/Profile/Common/CommonProfileContext';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import { cutProtocolAndWWW } from '@/Framework/url/helpers';
import ConsentCheckbox from '@/dataroom/ui/components/ConsentCheckbox';
import SubscriptionField from '@/users/ui/components/Profile/UserProfile/SubscriptionField';
import { IUser } from '@/users/domain/vo/User';

import styles from './profileForm.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';

const commonInputProps = {
  placeholder: '',
  component: Input,
  className: layoutStyles.inputField,
};

const commonCheckboxProps = {
  component: CheckboxInput,
  type: 'checkbox',
  className: styles.checkbox,
};

interface IProps {
  contact: IUser,
  onSubmit: (formData) => void,
  isTwoFactorCodeGenerating: boolean,
  generateTwoFactorCode: (contactId: number) => void,
}

const ProfileForm = ({
  contact,
  onSubmit,
  isTwoFactorCodeGenerating,
  generateTwoFactorCode,
}: IProps) => {
  const {
    isCountriesFetching,
    countries,
    isTitlesFetching,
    titles,
  } = useCommonProfileContext();
  const { push } = useHistory();
  const onRefreshCodeClick = () => {
    if (isTwoFactorCodeGenerating) {
      return;
    }
    generateTwoFactorCode(contact.id);
  };
  const onCancel = () => push(CONDOR_CONTACTS_LIST_BASE_URL);

  const onLinkedinBlur = (value) => {
    return cutProtocolAndWWW(value?.trim());
  };

  if (isCountriesFetching || isTitlesFetching) {
    return (<Spinner overlay isSpinnerFixed centered />);
  }

  return (
    <FormWrapper
      form={ CONDOR_CONTACT_PROFILE_FORM_NAME }
      dataTest={ CONDOR_CONTACT_PROFILE_FORM_NAME }
      headerText="Contact Details"
      initialValues={ {
        ...contact,
        officePhone: {
          value: PhoneNumber.fixUsPhoneNumber(contact.workPhone),
          code: PhoneNumber.getPhoneCode(contact.workPhone),
        },
        personalPhone: {
          value: PhoneNumber.fixUsPhoneNumber(contact.personalPhone),
          code: PhoneNumber.getPhoneCode(contact.personalPhone),
        },
        twoFactorPhone: {
          value: PhoneNumber.fixUsPhoneNumber(contact.mobilePhone),
          code: PhoneNumber.getPhoneCode(contact.mobilePhone),
        },
        jurisdiction: {
          value: contact.jurisdictionCountryId,
        },
        linkedinProfile: contact.linkedinProfile || '',
        bloombergEmail: contact.bloombergEmail || '',
        titleId:
          contact.title
            ? {
              label: contact.title.name,
              value: contact.title.id,
            }
            : null,
        companyId:
          contact.company
            ? {
              value: contact.company.id,
              label: contact.company.name,
              className: { [styles.verifiedCompany]: contact.company.verified },
            }
            : null,
      } }
      validate={ validate }
      enableReinitialize
      onSubmit={ onSubmit }
      onCancelClick={ onCancel }
    >
      <Field
        name="id"
        label="Unique Id"
        disabled
        dataTest="idFormField"
        { ...commonInputProps }
      />
      <Field
        name="email"
        label="Email"
        disabled
        dataTest="emailFormField"
        icon={ IconEmail }
        { ...commonInputProps }
      />
      <Field
        name="firstName"
        label="First Name"
        dataTest="firstNameFormField"
        { ...commonInputProps }
      />
      <Field
        name="lastName"
        label="Last Name"
        dataTest="lastNameFormField"
        { ...commonInputProps }
      />
      <TitleField label="Title" titles={ titles } isProfile />
      <div className={ layoutStyles.inputField }>
        <Field
          name="officePhone"
          label="Office Phone"
          component={ PhoneInput }
          placeholder=""
          dataTest="officePhoneFormField"
        />
        <Field
          name="personalPhone"
          label={ getLabelWithOptionalMark('Mobile Phone') }
          component={ PhoneInput }
          placeholder=""
          dataTest="mobilePhoneFormField"
        />
        <Field
          component={ ReduxFormSelect }
          name="jurisdiction"
          placeholder="Jurisdiction"
          label="Jurisdiction"
          dataTest="profileFormJurisdictionSelect"
          options={ mapCountriesToOptions(countries) }
          components={ {
            Option: SelectOptionWithCountryFlag,
            SingleValue: SelectSingleValueWithCountryFlag,
          } }
        />
        <CompanyFieldContainer
          className={ styles.formSelect }
          label="Account"
          dataTest="account"
        />
        <Field
          name="linkedinProfile"
          label={ getLabelWithOptionalMark('Linkedin profile URL') }
          dataTest="profileFormLinkedingProfile"
          normalize={ onLinkedinBlur }
          { ...commonInputProps }
          placeholder="linkedin.com/id/username"
        />
        <Field
          name="bloombergEmail"
          label={ getLabelWithOptionalMark('Bloomberg Email') }
          dataTest="profileFormBloombergEmailInput"
          { ...commonInputProps }
        />
      </div>
      <Field
        component={ ConsentCheckbox }
        type="checkbox"
        name="consentEnabled"
        change={ noop }
        disabled
        contactId={ contact.id }
      />
      <Label className={ styles.contactStatesLabel }>CONTACT STATES</Label>
      <Field
        name="activated"
        dataTest="activatedContact"
        label="Activated (for Finsight registered users)"
        { ...commonCheckboxProps }
      />
      <Field
        name="emailVerified"
        dataTest="emailVerifiedContact"
        label="Verified (for Deal Roadshow investors)"
        { ...commonCheckboxProps }
      />
      <Field
        name="banned"
        dataTest="bannedContact"
        label="Banned (to revoke access to all Finsight platforms)"
        { ...commonCheckboxProps }
      />
      <div className={ layoutStyles.inputField }>
        <div className={ cn(
          headerStyles.isH3,
          styles.title,
        ) }
        >
          Application Settings
        </div>
        <SubscriptionField contactId={ contact.id } />
      </div>
      <div className={ layoutStyles.inputField }>
        <div className={ cn(
          headerStyles.isH3,
          styles.title,
          styles.titleSmallMT,
        ) }
        >
          Two-Factor Authentication (2FA)
        </div>
        <div>
          <Label>live verification code</Label>
          <div className={ styles.twoFactorBlock }>
            <div className={ styles.twoFactorInput }>
              <div className={ styles.twoFactorCode } data-test="twoFactorCode">
                { contact.twoFactorCode || '' }
              </div>
              <div
                className={ styles.twoFactorButton }
                onClick={ onRefreshCodeClick }
                data-test="twoFactorCodeGenerateNew"
              >
                <Tooltip content="Generate new code">
                  <IconRefresh
                    className={ cn(styles.refreshIcon, {
                      [styles.refreshIconDisabled]: isTwoFactorCodeGenerating,
                    }) }
                  />
                </Tooltip>
              </div>
            </div>
            <div className={ styles.twoFactorDescription }>
              Verification codes must only be sent by email.
              Do NOT provide codes over the phone.
            </div>
          </div>
        </div>
        <Field
          name="twoFactorPhone"
          label="2FA Mobile Number (verification code to be sent via text message)"
          disabled
          isNarrow
          dataTest="twoFactorPhoneFormField"
          component={ PhoneInput }
        />
      </div>
    </FormWrapper>
  );
};

export default (props: IProps) => (
  <CommonProfileContextProvider>
    <ProfileForm { ...props } />
  </CommonProfileContextProvider>
);
