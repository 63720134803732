import React from 'react';
import { Field } from 'redux-form';

import Input from '@dealroadshow/uikit/core/components/Input';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import TextEditor from '@dealroadshow/uikit/core/components/TextEditor';
import InputLabel from '@dealroadshow/uikit/core/components/Input/Label';

import { DEAL_PROFILE_FIELDS } from '../constants';
import withTooltipOnInputLabel from '@/Framework/UI/Molecules/Form/withTooltipOnInputLabel';

import styles from '../dealProfile.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const InputWithTooltipOnLabel = withTooltipOnInputLabel(Input);
const TextAreaWithTooltipOnLabel = withTooltipOnInputLabel(Textarea);
const TextEditorWithTooltipOnLabel = withTooltipOnInputLabel(TextEditor);

interface IProps {
  isProductAbs: boolean,
}

const DealComments = ({ isProductAbs }: IProps) => (
  <div className={ styles.panelContent }>
    <div className={ styles.dealRow }>
      <Field
        { ...DEAL_PROFILE_FIELDS.COLLATERAL }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
      />
      <Field
        { ...DEAL_PROFILE_FIELDS.GUARANTOR }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
        isNarrow
      />
      <Field
        { ...DEAL_PROFILE_FIELDS.USE_OF_PROCEEDS }
        className={ layoutStyles.inputField }
        component={ InputWithTooltipOnLabel }
      />
    </div>
    { isProductAbs && (
      <div className={ styles.dealRow }>
        <Field
          { ...DEAL_PROFILE_FIELDS.ORIGINATOR }
          className={ layoutStyles.inputField }
          component={ InputWithTooltipOnLabel }
        />
        <Field
          { ...DEAL_PROFILE_FIELDS.RISK_RETENTION }
          className={ layoutStyles.inputField }
          component={ InputWithTooltipOnLabel }
        />
        <Field
          { ...DEAL_PROFILE_FIELDS.VOLCKER_COMPLIANCE }
          className={ layoutStyles.inputField }
          component={ InputWithTooltipOnLabel }
          isNarrow
        />
      </div>
    ) }
    <div className={ styles.dealRow }>
      <Field
        { ...DEAL_PROFILE_FIELDS.COMMENTS }
        placeholder="type text here..."
        rows={ 5 }
        textareaClassName={ styles.textArea }
        component={ TextAreaWithTooltipOnLabel }
      />
    </div>
    { !isProductAbs && (
      <div className={ styles.dealRow }>
        <div>
          <InputLabel
            htmlFor={ DEAL_PROFILE_FIELDS.RELATIVE_VALUE.name }
            className={ styles.textEditorContainerLabel }
          >
            { DEAL_PROFILE_FIELDS.RELATIVE_VALUE.label }
          </InputLabel>
          <Field
            { ...DEAL_PROFILE_FIELDS.RELATIVE_VALUE }
            component={ (fieldProps) => (
              <TextEditorWithTooltipOnLabel
                { ...fieldProps }
                value={ fieldProps.input.value }
                onChange={ fieldProps.input.onChange }
                label={ fieldProps.label }
                maxCharactersLimit={ 65000 }
              />
            ) }
          />
        </div>
      </div>
    ) }
  </div>
);

export default DealComments;
