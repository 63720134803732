import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage, getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';

export const useRoadshowVideoGeneration = () => {
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const generateVideoReplay = async (roadshowId: string) => {
    try {
      const status = await roadshowRepository.canStartVideoReplayGeneration(roadshowId);

      if (status === 'ok') {
        await roadshowRepository.startVideoReplayGeneration(roadshowId);
        NotificationManager.success(getMessage(messageCodes.ROADSHOW_EXPORT_VIDEO_REPLAY_START));
      }
    } catch ({ error }) {
      NotificationManager.warning(getMessageByErrorCode(error?.code));
    }
  };

  return {
    generateVideoReplay,
  };
};
