import React from 'react';
import cn from 'classnames';
import memoize from '@/Framework/dataHelpers/memoize';

import StatusIndicator from '@dealroadshow/uikit/core/components/StatusIndicator';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import keyResolver from '@/Framework/dataHelpers/keyResolver';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';

import ResendEmailModalButton from './FilingModal/ModalButtons/ResendEmailModalButtonContainer';
import SendEmailModalButton from './FilingModal/ModalButtons/SendEmailModalButtonContainer';
import DisableEmailModalButton from './FilingModal/ModalButtons/DisableEmailModalButtonContainer';
import CancelModalButton from './FilingModal/ModalButtons/CancelModalButtonContainer';
import UpdateStatusButtonsCell from './UpdateStatusButtonsCell';

import { FILINGS_STATUS } from '../constants';

import styles from '../tableStyles.scss';

/**
 @param {object} filing
 @return {jsx}
 */
export const getStatusCell = ({ row: filing }) => {
  const statusColorStyles = cn({
    [styles.sent]: filing.status === FILINGS_STATUS.SENT,
    [styles.pending]: filing.status === FILINGS_STATUS.PENDING,
    [styles.disable]: filing.status === FILINGS_STATUS.DISABLE,
  });
  const status = `${ filing.status.charAt(0)
    .toUpperCase() }${ filing.status.slice(1) }`;

  return (
    <span className={ styles.statusLabel }>
      <Tooltip content={ `15G Alert Email ${ filing.status === FILINGS_STATUS.DISABLE ? 'Disabled' : status }` }>
        <StatusIndicator className={ statusColorStyles } />
      </Tooltip>
    </span>
  );
};

/**
 @param {object} filing
 @return {string}
 */
export const getIssuerNameCell = ({ row: filing }) => (
  <Tooltip content="View SEC filing">
    <a
      href={ filing.url }
      target="_blank"
    >
      {
        filing.secIssuerName
      }
      <IconExternalLink className={ styles.link } />
    </a>
  </Tooltip>
);

/**
 @param {boolean} isDisabledEmailModalOpen
 @param {boolean} isSendEmailModalOpen
 @param {boolean} isResendEmailModalOpen
 @param {object} selectedFiling
 @return [object] - object with jsx
 */
export const getModalContent = memoize((
  isDisabledEmailModalOpen,
  isSendEmailModalOpen,
  isResendEmailModalOpen,
  selectedFiling,
) => {
  const issuerName = selectedFiling.secIssuerName;

  if (isDisabledEmailModalOpen) {
    return {
      content: (
        <>
          <p>
            You are about to <strong>disable</strong> email notifications.
          </p>
          <p>
            The ABS-15G alert for { issuerName } will <strong>NOT</strong> be send to all subscribers.
          </p>
        </>
      ),
      footer: (
        <>
          <DisableEmailModalButton />
          <CancelModalButton />
        </>
      ),
    };
  }

  if (isSendEmailModalOpen) {
    return {
      content: (
        <>
          <p>
            You are about to <strong>send</strong> email notifications.
          </p>
          <p>
            The ABS-15G alert for { issuerName } will be <strong>sent</strong> to all subscribers.
          </p>
        </>
      ),
      footer: (
        <>
          <SendEmailModalButton />
          <CancelModalButton />
        </>
      ),
    };
  }

  if (isResendEmailModalOpen) {
    return {
      content: (
        <>
          <p>
            You are about to <strong>resend</strong> email notifications, subcribers have already received this 15G
            alert.<br />
          </p>
          <p>
            The ABS-15G alert for { issuerName } will be <strong>resend</strong> to all
            subscribers.
          </p>
        </>
      ),
      footer: (
        <>
          <ResendEmailModalButton />
          <CancelModalButton />
        </>
      ),
    };
  }

  return {};
}, keyResolver);

/**
 @param {object} filing
 @return {jsx}
 */
export const updateStatusButtonsCellCallback = (filing) => (
  <UpdateStatusButtonsCell filing={ filing } />
);
