import React from 'react';
import cn from 'classnames';
import {
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import AddTrancheButton from './AddTrancheButton';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconArrowLeftBold from '@dealroadshow/uikit/core/components/Icon/IconArrowLeftBold';
import IconArrowRightBold from '@dealroadshow/uikit/core/components/Icon/IconArrowRightBold';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';

import styles from './tranchesHeader.scss';

interface IProps {
  tranchesLength: number,
}

const TranchesHeader = ({ tranchesLength }: IProps) => {
  const {
    tranchesViewOffset,
    setTranchesViewOffset,
    isStickyHeaderVisible,
  } = useTranchesContext();

  const someArrowVisible = tranchesLength > TRANCHES_VISIBLE;
  const leftArrowVisible = someArrowVisible && tranchesViewOffset > 0;
  const rightArrowVisible = someArrowVisible && tranchesLength !== tranchesViewOffset + TRANCHES_VISIBLE;

  return (
    <div className={ cn(styles.tranchesHeader, { [styles.withStickyHeader]: isStickyHeaderVisible }) }>
      <AddTrancheButton />
      { someArrowVisible && (
        <>
          { leftArrowVisible && (
            <Button
              className={ cn(styles.leftArrowBtn, { [styles.withShadow]: tranchesViewOffset > 0 }) }
              variant={ ButtonVariantType.text }
              onClick={ () => setTranchesViewOffset(tranchesViewOffset - 1) }
              dataTest="condorTranchesHeaderDeleteOffsetButton"
            >
              <IconArrowLeftBold className={ styles.arrowIcon } />
            </Button>
          ) }
          { rightArrowVisible && (
            <Button
              className={ styles.rightArrowBtn }
              variant={ ButtonVariantType.text }
              onClick={ () => setTranchesViewOffset(tranchesViewOffset + 1) }
              dataTest="condorTranchesHeaderAddOffsetButton"
            >
              <IconArrowRightBold className={ styles.arrowIcon } />
            </Button>
          ) }
        </>
      ) }
    </div>
  );
};

export default TranchesHeader;
