import { useEffect, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import {
  DEAL_PROFILE_FIELDS,
  DEAL_PROFILE_FIELDS_ARRAY,
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import mapDateToValue from './mapDateToValue';
import TRANCHE_FIELDS from '../constants/trancheFields';

/**
 * @param {Object} formValues
 * @param {Object} formMeta
 * @param {Object} initialValues
 * @param {Boolean} isModalVisible
 * @param {Array} ratingAgencies
 */
const useDealChangesList = (
  {
    formValues,
    formMeta,
    initialValues,
    isModalVisible,
    ratingAgencies,
  },
) => {
  const [dealChangesList, setDealChangesList] = useState([]);
  const [tranchesChangesList, setTranchesChangesList] = useState([]);

  const getTrancheClassLabel = useCallback(
    (tranche) => (
      `${ formValues[DEAL_PROFILE_FIELDS.SERIES.name] } ${ tranche[TRANCHE_FIELDS.CLASS.name] || '-' }`
    ),
    [formValues[DEAL_PROFILE_FIELDS.SERIES.name]],
  );

  const getChangedFieldDisplayValue = useCallback(
    (key, value, isTrancheField = false) => {
      const field = (
        isTrancheField
          ? DEAL_PROFILE_TRANCHE_FIELDS_ARRAY
          : DEAL_PROFILE_FIELDS_ARRAY
      ).find((field) => field.name === key);

      if (field.isBoolean) {
        return value ? 'Yes' : 'No';
      }
      if (field.isSelect) {
        return value?.label || '-';
      }
      if (field.isMultiSelect) {
        return value.map((val) => (val?.label || '-')).join(', ');
      }
      if (field.isDate) {
        return mapDateToValue(value) || '-';
      }

      return value || '-';
    },
    [],
  );

  const areValuesEqual = useCallback(
    (valueA, valueB) => isEqual(valueA, valueB) || (!valueA && !valueB),
    [],
  );

  useEffect(
    () => {
      if (isModalVisible) {
        const formMetaKeys = Object.keys(formMeta);
        const tranchesFieldKey = DEAL_PROFILE_FIELDS.TRANCHES.name;
        const trancheIdFieldKey = TRANCHE_FIELDS.ID.name;
        const trancheIsExcludedFieldKey = TRANCHE_FIELDS.IS_EXCLUDED.name;
        const trancheRatingsFieldKey = TRANCHE_FIELDS.RATINGS.name;

        const dealChanges = [];
        const newTranches = [];
        const deletedTranches = [];

        // Tranches have changed
        if (
          formMetaKeys.includes(tranchesFieldKey) ||
          formValues[tranchesFieldKey].some((tranche) => !tranche[trancheIsExcludedFieldKey])
        ) {
          const tranchesChanges = [];
          const trancheObjectKeys = Object.keys(
            formValues[tranchesFieldKey].length
              ? formValues[tranchesFieldKey][0]
              : {},
          );
          const trancheRatingsObjectKeys = Object.keys(
            formValues[tranchesFieldKey].length
              ? formValues[tranchesFieldKey][0][trancheRatingsFieldKey]
              : {},
          );

          formValues[tranchesFieldKey].forEach(
            (tranche, i) => {
              if (tranche[trancheIsExcludedFieldKey]) {
                if (tranche[trancheIdFieldKey]) {
                  // Tranche existed and will be deleted
                  deletedTranches.push(tranche);
                }
              } else if (!tranche[trancheIdFieldKey]) {
                // Tranche is new.
                newTranches.push(tranche);
              } else {
                // Tranche existed and possibly was changed.
                if (i !== tranche[TRANCHE_FIELDS.ORDERING_POSITION.name]) {
                  // Tranche was moved with drag and drop.
                  tranchesChanges.push(
                    {
                      trancheClass: getTrancheClassLabel(tranche),
                      oldValue: tranche[TRANCHE_FIELDS.ORDERING_POSITION.name] + 1,
                      newValue: i + 1,
                      fieldLabel: TRANCHE_FIELDS.ORDERING_POSITION.label,
                    },
                  );
                }
                // Find the related tranche from initialValues.
                const relatedInitialTranche = initialValues[tranchesFieldKey].find(
                  (initTranche) => initTranche[trancheIdFieldKey] === tranche[trancheIdFieldKey],
                );

                // Search for changes in tranche object comparing to relatedInitialTranche.
                trancheObjectKeys.forEach((trancheObjectKey) => {
                  // For 'tranches.ratings' form section:
                  if (trancheObjectKey === trancheRatingsFieldKey) {
                    trancheRatingsObjectKeys.forEach(
                      (ratingAbbreviation) => {
                        if (
                          !areValuesEqual(
                            tranche[trancheRatingsFieldKey][ratingAbbreviation],
                            relatedInitialTranche[trancheRatingsFieldKey][ratingAbbreviation],
                          )
                        ) {
                          tranchesChanges.push(
                            {
                              trancheClass: getTrancheClassLabel(tranche),
                              oldValue: (
                                relatedInitialTranche[trancheRatingsFieldKey][ratingAbbreviation]?.label || '-'
                              ),
                              newValue: (
                                tranche[trancheRatingsFieldKey][ratingAbbreviation]?.label || '-'
                              ),
                              fieldLabel: ratingAgencies
                                .find((field) => field.abbreviation === ratingAbbreviation)
                                .displayName,
                            },
                          );
                        }
                      },
                    );
                    return;
                  }

                  // For other tranche fields:
                  if (
                    !areValuesEqual(
                      tranche[trancheObjectKey],
                      relatedInitialTranche[trancheObjectKey],
                    )
                  ) {
                    tranchesChanges.push(
                      {
                        trancheClass: getTrancheClassLabel(tranche),
                        oldValue: getChangedFieldDisplayValue(
                          trancheObjectKey,
                          relatedInitialTranche[trancheObjectKey],
                          true,
                        ),
                        newValue: getChangedFieldDisplayValue(
                          trancheObjectKey,
                          tranche[trancheObjectKey],
                          true,
                        ),
                        fieldLabel: DEAL_PROFILE_TRANCHE_FIELDS_ARRAY
                          .find((field) => field.name === trancheObjectKey)
                          .label,
                      },
                    );
                  }
                });
              }
            },
          );

          // Push the changes to global tranches change list
          setTranchesChangesList(tranchesChanges);
        }

        // Search for changes (comparing to initialValues) among Deal Profile fields that were touched.
        formMetaKeys
          .filter((fieldName) => formMeta[fieldName].touched)
          .forEach((touchedFieldKey) => {
            if (!areValuesEqual(formValues[touchedFieldKey], initialValues[touchedFieldKey])) {
              dealChanges.push(
                {
                  value: getChangedFieldDisplayValue(touchedFieldKey, formValues[touchedFieldKey]),
                  label: DEAL_PROFILE_FIELDS_ARRAY
                    .find((field) => field.name === touchedFieldKey)
                    .label,
                },
              );
            }
          });

        setDealChangesList(
          [
            ...dealChanges,
            ...(
              newTranches.length
                ? [{ label: 'Create Tranches', value: newTranches.map(getTrancheClassLabel).join(', ') }]
                : []
            ),
            ...(
              deletedTranches.length
                ? [{ label: 'Delete Tranches', value: deletedTranches.map(getTrancheClassLabel).join(', ') }]
                : []
            ),
          ],
        );
      } else {
        setDealChangesList([]);
      }
    },
    [isModalVisible],
  );

  return {
    dealChangesList,
    tranchesChangesList,
    setTranchesChangesList,
  };
};

export default useDealChangesList;
