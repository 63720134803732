import React from 'react';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import IconDocument from '@dealroadshow/uikit/core/components/Icon/IconDocument';

import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import {
  useUploadSsoCertificateContext,
} from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';

const CellFileName = ({
  row,
  rowIndex,
  cellData,
}: ICellProps<ISsoCertificate>) => {
  const { setCertificateForView } = useUploadSsoCertificateContext();

  const onClick = () => {
    setCertificateForView({
      number: rowIndex + 1,
      content: row.content,
    });
  };

  return (
    <div className={ styles.cellFileName } onClick={ onClick }>
      <IconDocument className={ styles.cellFileNameIcon } />
      <span className={ styles.cellFileNameContent }>{ cellData }</span>
    </div>
  );
};

export default CellFileName;
