import React from 'react';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import {
  useEnhancedTaggingCondorContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';

import {
  OtherExcludedAnalyticsAbbr,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext/constants';
import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from '../roadshows.scss';

const getCurrentTrnsType = (id, collection) => collection?.find((item) => item.id === id);

const TransactionTypeCallback = ({
  row: {
    id,
    transactionTypeId,
  },
  rowIndex,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;
  const { editableRowId } = useEnhancedTaggingRoadshowsContext();
  const {
    transactionTypeOptions,
    transactionCollection,
  } = useEnhancedTaggingCondorContext();

  if (editableRowId === id) {
    return (
      <Field
        placeholder="Transaction Type"
        name={ `${ fieldRowName }.transactionTypeId` }
        formFieldClassName={ spaces.mbn }
        // @ts-ignore
        component={ FinalFormSelect }
        clearable={ false }
        simpleValue
        asterisk
        options={ transactionTypeOptions }
        dataTest={ `transactionType[${ rowIndex }]` }
      />
    );
  }

  if (!transactionTypeId) {
    return '-';
  }

  const currentType = getCurrentTrnsType(transactionTypeId, transactionCollection);
  return (
    <div>
      <Tooltip containerClassName={ styles.tooltip } content={ currentType?.name }>
        <span className={ styles.oneInlineLine }>{ currentType?.abbreviation || OtherExcludedAnalyticsAbbr }</span>
      </Tooltip>
    </div>
  );
};

export default TransactionTypeCallback;
