import { createContext, useContext, useState, useMemo, PropsWithChildren } from 'react';

const useUploadInputsStatus = () => {
  const [isUploadedState, setIsUploadedState] = useState<Record<string, boolean>>({});
  const [processStatusState, setProcessStatusState] = useState<Record<string, string>>({});

  const isAllUploaded = useMemo(() => {
    const allLoaders = Object.values(isUploadedState);

    return allLoaders.length === 0 || allLoaders.every(Boolean);
  }, [isUploadedState]);

  const setIsUploaded = (key: string, value: boolean) => {
    setIsUploadedState((isUploadedState) => ({
      ...isUploadedState,
      [key]: value,
    }));
  };

  const setProcessStatus = (key: string, value: string) => {
    setProcessStatusState((isUploadedState) => ({
      ...isUploadedState,
      [key]: value,
    }));
  };

  return {
    isAllUploaded,
    isUploadedState,
    processStatusState,
    setIsUploaded,
    setProcessStatus,
  };
};

type UploadInputsStatusContextType = ReturnType<typeof useUploadInputsStatus>;

export const UploadInputsStatusContext = createContext<UploadInputsStatusContextType>(null);

export const useUploadInputsStatusContext = () => {
  const context = useContext(UploadInputsStatusContext);

  if (!context) {
    throw new Error('useUploadInputsStatusContext must be used within the UploadInputsStatusContext');
  }

  return context;
};

const UploadInputsStatusContextProvider = ({ children }: PropsWithChildren) => (
  <UploadInputsStatusContext.Provider value={ useUploadInputsStatus() }>
    { children }
  </UploadInputsStatusContext.Provider>
);

export default UploadInputsStatusContextProvider;
