import React from 'react';
import Head from 'next/head';

import EnhancedTaggingRoadshowsContextProvider from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import EnhancedTaggingCondorContextProvider from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import EnhancedTaggingContextProvider from '@/Framework/UI/Organisms/EnhancedTagging/EnhancedTaggingContext';
import TenantContextProvider from '@/Framework/Tenant/TenantContext';
import RoadshowsTable from './RoadshowsTable';
import RoadshowsHeader from './RoadshowsHeader';
import config from '@/Framework/config';

const Roadshows = () => {
  return (
    <TenantContextProvider
      tenant={ config.tenant.dealroadshow.code }
      useReduxStore={ false }
    >
      <EnhancedTaggingContextProvider>
        <EnhancedTaggingCondorContextProvider>
          <EnhancedTaggingRoadshowsContextProvider>
            <Head>
              <title>Roadshows | Enhanced Tagging</title>
            </Head>
            <RoadshowsHeader />
            <RoadshowsTable />
          </EnhancedTaggingRoadshowsContextProvider>
        </EnhancedTaggingCondorContextProvider>
      </EnhancedTaggingContextProvider>
    </TenantContextProvider>
  );
};

export default Roadshows;
