import React, { useCallback } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';

import styles from './tableRowActions.scss';

interface IAction {
  className?: string,
  iconClassName?: string,
  tooltipContent?: string,
  disabled?: boolean,
  onClick?: () => void,
  icon?: IconComponentType,
  url?: string,
  dataTest?: string,
}

interface IProps {
  actions?: IAction[],
  tableRowActionsClassName?: string,
}

const TableRowActions = ({ actions = [], tableRowActionsClassName = '' }: IProps) => {
  /**
   * @param {Object} rowAction
   * @param {Number} i
   */

  const { push } = useHistory();

  const onClick = (event, rowAction) => {
    if (rowAction.onClick) {
      rowAction.onClick();
    }

    if (rowAction.url) {
      onOpenUrl(event, rowAction.url);
    }
  };

  const onOpenUrl = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
      return;
    }

    push(url);
  };

  const renderRowAction = useCallback(
    (rowAction: IAction, i: number) => {
      const { icon: IconComponent } = rowAction;
      const icon = (
        <Tooltip content={ rowAction.tooltipContent }>
          <IconComponent
            { ...(!rowAction.disabled ? { onClick: (event) => onClick(event, rowAction) } : {}) }
            className={ cn(styles.action, rowAction.iconClassName, { [styles.disabledAction]: rowAction.disabled }) }
            data-test={ rowAction.dataTest ?? '' }
          />
        </Tooltip>
      );

    return (
      <div key={ i } className={ cn(styles.actionContainer, rowAction.className) }>
        { icon }
      </div>
    );
  }, []);

  return <div className={ cn(styles.tableRowActions, tableRowActionsClassName) }>{ actions.map(renderRowAction) }</div>;
};

export default TableRowActions;
