import { useState } from 'react';
import { useCreditFlowResearchListsFilters } from '@/condor/application/Finsight/CreditFlowResearch/Filters';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { getRegionsByIds, mapRegionsToOptions } from './helpers';

import styles from './styles.scss';

interface IProps {
  onSearchChange: (val: string) => void,
  searchPlaceholder: string,
  debouncedSearchString: string,
}

const TableFilters = ({ onSearchChange, searchPlaceholder, debouncedSearchString }: IProps) => {
  const [searchString, setSearchString] = useState('');
  const {
    listFilters: { includedRegionIds },
    setIncludedRegions,
  } = useCreditFlowResearchListsFilters();

  const handleSearchChange = ({ target: { value } }) => {
    setSearchString(value);
    const trimmedValue = value.trim();
    if ((trimmedValue.length > 2 || trimmedValue.length === 0) && trimmedValue !== debouncedSearchString) {
      onSearchChange(value);
    }
  };

  const { regions } = useRegionsProductsContext();

  return (
    <>
      <Select
        value={ getRegionsByIds(regions, includedRegionIds) }
        onChange={ setIncludedRegions }
        dataTest="creditFlowRegionsSelect"
        options={ mapRegionsToOptions(regions) }
        isMulti
        isSearchable={ false }
        className={ styles.select }
        placeholder="Select Region"
        isNarrow
      />
      <SearchInput
        value={ searchString }
        onChange={ handleSearchChange }
        placeholder={ searchPlaceholder }
        className={ styles.searchInput }
        isNarrow
        dataTest="creditFlowSearchInput"
      />
    </>
  );
};

export default TableFilters;
