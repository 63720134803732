import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { IRatingAgency } from '@/finsight/domain/vo/RatingAgency/IRatingAgency';

@Dependencies(FinsightWebRpcDispatcher)
class RatingAgencyRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  getRatingAgencies = async (payload = {}): Promise<IRatingAgency[]> => {
    const request = new Request('finsight_web.rating_agency.get_rating_agencies', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default RatingAgencyRepository;
