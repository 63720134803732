import { AccountType } from '@/condor/domain/vo/Admin/AccountType/AccountType';

export const INITIAL_ACCOUNT_TYPE_STATE: AccountType = {
  color: '',
  createdAt: '',
  deletedAt: '',
  id: '',
  name: '',
  updatedAt: '',
};
