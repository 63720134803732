import { arraySplice, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import {
  DEAL_PROFILE_FIELDS,
  TRANCHE_FIELDS,
  TRANCHE_UNIQUE_FIELD_NAMES,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import { ITranche } from '@/condor/domain/vo/Finsight/Deal/Tranche';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const createTrancheSelector = (field: string) => (state: any) => ({
  currentTranche: formSelector(state, field),
  isExcluded: formSelector(state, `${ field }.${ TRANCHE_FIELDS.IS_EXCLUDED.name }`),
});
const prepareNewTranche = (currentTranche: ITranche) => ({
  ...omit(currentTranche, TRANCHE_UNIQUE_FIELD_NAMES),
  [TRANCHE_FIELDS.DROPPED_TRANCHE.name]: false,
  id: null,
});

export const useDuplicateTranche = ({ field, index }) => {
  const dispatch = useDispatch();
  const { currentTranche, isExcluded } = useSelector(createTrancheSelector(field));

  const handleButtonClick = () => {
    const newTranche = prepareNewTranche(currentTranche);
    dispatch(arraySplice(
      CONDOR_DEAL_PROFILE_FORM_NAME,
      DEAL_PROFILE_FIELDS.TRANCHES.name,
      index + 1,
      0,
      newTranche,
    ));
  };

  return {
    handleButtonClick,
    isButtonDisabled: isExcluded,
  };
};
