import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ICellProps as IDataTableCellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

import {
  CONDOR_ACCOUNT_PROFILE_BASE_URL,
  CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL,
} from '@/condor/ui/common/constants';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';
import { getIsAccountRestrictedToEdit } from '@/condor/ui/components/AccountsAndContacts/Accounts/helpers';
import { RestrictedEditAccountTooltip } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';

import styles from './updatedAtCell.scss';
import accountTableStyles from '../accountsTable.scss';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconMappings from '@dealroadshow/uikit/core/components/Icon/IconMappings';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate';

interface IProps {
  cellProps: IDataTableCellProps<IAccountProfileItem>,
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  inlineEditFormState: {
    pristine: boolean,
    invalid: boolean,
    submitting: boolean,
  },
  onSubmit: () => void,
  onReset: () => void,
  onRestore: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
}

const getDeleteTooltipContent = (
  canBeDeleted: boolean,
  hasSsoIntegration: boolean,
  isAccountRestrictedToEdit: boolean,
): string => {
  if (hasSsoIntegration) {
    return 'This Account must be removed from SSO integration before deleting';
  }
  if (isAccountRestrictedToEdit) {
    return RestrictedEditAccountTooltip;
  }
  return canBeDeleted ? 'Delete' : 'This Account must be re-mapped before Deleting';
};

const getMappingTooltipContent = (
  isContactPermission: boolean,
  isAccountRestrictedToEdit: boolean,
): string => {
  if (isAccountRestrictedToEdit) {
    return RestrictedEditAccountTooltip;
  }
  return isContactPermission ? 'Account Mappings' : 'No Access';
};

const UpdatedAtCell = (props: IProps) => {
  const {
    canBeDeleted,
    hasSsoIntegration,
  } = props.cellProps.row;
  const { currentUserPermissions } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const isContactPermission = currentUserPermissions.find((permission) => permission.moduleKey === 'contacts');

  const isAccountRestrictedToEdit = getIsAccountRestrictedToEdit(props.cellProps.row, currentUserPermissions);

  let actions;
  if (props.cellProps.row.deletedAt) {
    actions = [
      {
        tooltipContent: 'Restore Account',
        icon: IconRotate,
        onClick: props.onRestore,
        dataTest: 'restoreAccountAction',
      },
    ];
  } else {
    actions = props.isSelectedForEditing ?
      [
        {
          tooltipContent: 'Save',
          icon: IconCheck,
          onClick: props.onSubmit,
          dataTest: 'saveAccountAction',
          disabled: props.inlineEditFormState.submitting ||
            props.inlineEditFormState.pristine ||
            props.inlineEditFormState.invalid,
        },
        {
          tooltipContent: 'Cancel',
          icon: IconCancel,
          onClick: props.onReset,
          dataTest: 'cancelEditAction',
        },
      ] : [
        {
          tooltipContent: isAccountRestrictedToEdit ? RestrictedEditAccountTooltip : 'Edit',
          icon: IconPencil,
          onClick: props.onEditClick,
          disabled: isAccountRestrictedToEdit,
          dataTest: 'editAccountAction',
        },
        {
          url: `${ CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ props.cellProps.row.id }`,
          tooltipContent: isContactPermission ? 'Profile' : 'No Access',
          icon: IconSettings,
          disabled: !isContactPermission,
          dataTest: 'profileAccountAction',
        },
        {
          url: `${ CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/${ props.cellProps.row.id }`,
          tooltipContent: getMappingTooltipContent(isContactPermission, isAccountRestrictedToEdit),
          icon: IconMappings,
          disabled: !isContactPermission || isAccountRestrictedToEdit,
          dataTest: 'accountMappingsAction',
        },
        {
          onClick: props.onDeleteClick,
          tooltipContent: getDeleteTooltipContent(canBeDeleted, hasSsoIntegration, isAccountRestrictedToEdit),
          disabled: !canBeDeleted || hasSsoIntegration || isAccountRestrictedToEdit,
          icon: IconTrash,
          dataTest: 'deleteAccountAction',
        },
      ];
  }

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUpdating
          ? (
            <TableRowActions
              actions={ actions }
              tableRowActionsClassName={
                props.isSelectedForEditing || props.cellProps.row.deletedAt
                  ? ''
                  : styles.updatedAtWrapper
              }
            />
          )
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    >
      <span className={ cn({
        [accountTableStyles.deletedAccountField]: props.cellProps.row.deletedAt,
      }) }
      >
        { getCellDisplayDateFromString(props.cellProps.row.updatedAt) }
      </span>
    </LastTableCell>
  );
};

export default UpdatedAtCell;
