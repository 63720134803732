import React, { useEffect } from 'react';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import DeleteImportModal from './DeleteImportModal';
import columns from './columns';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Paginator from './AuditTrailPaginatorContainer';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const AuditTrail = (
  {
    init,
    reset,
    ...otherProps
  }: IProps,
) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <TableWrapper>
      <DeleteImportModal />
      <DataTable
        loadingComponent={ Spinner }
        className={ dataTableStyles.isHoverable }
        dataTest="companyAuditTrailDataTable"
        columns={ columns }
        { ...otherProps }
      />
      <Paginator
        className={ cardStyles.cardInner }
        dataTest="companyAuditTrail"
      />
    </TableWrapper>
  );
};

export default AuditTrail;
