import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import { triggerUserPermissionGroup } from '@/users/application/actions/permissions/actions';
import * as userSelectors from '@/users/application/actions/permissions/selectors';

interface IPermissionCheckBoxProps {
  moduleKey: string,
  groupIndex: number,
  hasPermissionToUpdate: boolean,
  updateOwnContact: boolean,
}

const PermissionCheckBox: FC<IPermissionCheckBoxProps> = ({
  moduleKey,
  groupIndex,
  hasPermissionToUpdate,
  updateOwnContact,
}) => {
  const dispatch = useDispatch();
  const {
    contactPermissions,
    group,
  } = useSelector(userSelectors.getUserPermissionsState);
  const permissionGroupKey = group[groupIndex].key;

  const [isAccountDisabled, setAccountDisabled] = useState(false);

  const isChecked = contactPermissions.some((contact) => contact.moduleKey === moduleKey);
  const isDisabled = !hasPermissionToUpdate
    || (updateOwnContact && moduleKey === 'user_permissions')
    || (isAccountDisabled && moduleKey === 'accounts');

  const onChange = () => dispatch(triggerUserPermissionGroup({
    moduleKey,
    permissionGroupKey,
  }));

  useEffect(() => {
    const isSelectedContact = contactPermissions.some((contact) => contact.moduleKey === 'contacts');

    if (moduleKey === 'accounts') {
      setAccountDisabled(isSelectedContact);
    } else if (moduleKey === 'contacts') {
      const isSelectedAccount = contactPermissions.some((contact) => contact.moduleKey === 'accounts');

      if (isSelectedContact && !isSelectedAccount) {
        dispatch(triggerUserPermissionGroup({
          moduleKey: 'accounts',
          permissionGroupKey,
        }));
      }
    }
  }, [moduleKey, contactPermissions]);

  return (
    <Checkbox
      dataTest={ moduleKey }
      disabled={ isDisabled }
      checked={ isChecked }
      onChange={ onChange }
    />
  );
};

export default PermissionCheckBox;
