import React from 'react';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from './pagePreloader.scss';

const PagePreloader = () => (
  <div className={ styles.wrp }>
    <div className={ styles.aligner }>
      <Spinner />
    </div>
  </div>
);

export default PagePreloader;
