import { useEffect, useState } from 'react';
import cn from 'classnames';

import stripTags from '@/Framework/dom/stripTags';

import styles from './styles.scss';

interface IProps {
  description: string,
  dataTest: string,
  className?: string,
}
export const Description = ({ description, dataTest, className }: IProps) => {
  const [descriptionToRender, setDescriptionToRender] = useState(description);

  useEffect(() => {
    setDescriptionToRender(description ? stripTags(description) : null);
  }, [description]);

  return (descriptionToRender ? (
    <div
      className={ cn(styles.description, className) }
      data-test={ dataTest }
    >
      <div dangerouslySetInnerHTML={ { __html: description } } />
    </div>
  ) : null);
};
