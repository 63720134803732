import { Field } from 'redux-form';
import { components as ReactSelectComponents } from 'react-select';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import CompanySelectContainer from '@/condor/ui/components/Finsight/DealProfile/DealDetails/CompanySelectContainer';
import { UNDERWRITER } from '@/finsight/application/actions/pageFilters/constants';
import type { IFieldProps } from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/TrancheField';

const getMultiValueRemoveRenderer = (props) => (
  <ReactSelectComponents.MultiValueRemove { ...props }>
    <IconCancel />
  </ReactSelectComponents.MultiValueRemove>
);

const abbreviationValueRenderer = ({ data, ...otherProps }) => (
  <ReactSelectComponents.MultiValueLabel { ...data } { ...otherProps }>
    <Tooltip content={ data.name }>
      { data.abbreviation || data.label }
    </Tooltip>
  </ReactSelectComponents.MultiValueLabel>
);

export const CompanySelect = (props: IFieldProps) => (
  <Field
    { ...props }
    name={ props.input.name }
    component={ CompanySelectContainer }
    size="small"
    isMulti
    isSlim
    includedRole={ UNDERWRITER }
    components={ {
      MultiValueLabel: abbreviationValueRenderer,
      MultiValueRemove: getMultiValueRemoveRenderer,
    } }
  />
);
