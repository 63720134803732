import Modal from '@dealroadshow/uikit/core/components/Modal';
import CategoriesForm from './CategoriesForm';

import styles from './styles.scss';

const ManageStatsCategoriesModal = ({ closeModal }) => (
  <Modal
    isVisible
    dataTest="manageStatsCategoriesModal"
    title="Manage Categories"
    onCloseClicked={ closeModal }
    className={ styles.manageCategoriesModal }
  >
    <CategoriesForm closeModal={ closeModal } />
  </Modal>
);

export default ManageStatsCategoriesModal;
