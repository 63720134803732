import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';

import { Field, useForm, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import InputTags from '@/Framework/UI/Molecules/Form/InputTags';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import PreviewBlock from '../PreviewBlock';

import { useSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/AccountSSOForm';
import { validateDomain } from '@/Framework/UI/Organisms/FinalForm/validators/validateDomain';
import { useEnableSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/EnableSSO';
import { IEmailDomain } from '@/users/domain/vo/sso/SSOForm';
import { CONDOR_ACCOUNTS_LIST_BASE_URL } from '@/condor/ui/common/constants';

import styles from './styles.scss';

const validate = (value: string) => validateDomain({
  value,
  fieldName: 'tag',
});

const FormFields = () => {
  const [domainsToShow, setDomainsToShow] = useState([]);
  const [shouldRenderRequiredError, setShouldRenderRequiredError] = useState(false);

  const {
    selectedDomains,
    isSubmitting,
    isFetching,
    setSelectedDomains,
    initialValues,
    isChecking,
    checkedDomains,
    checkNewDomains,
    validateShortname,
  } = useSSOContext();
  const { push } = useHistory();

  const {
    isEnabled,
    setIsEnabled,
  } = useEnableSSOContext();

  const { change } = useForm();
  const {
    submitting,
    invalid,
    pristine,
    values: {
      emailDomains,
      enabled,
    },
  } = useFormState();
  useEffect(() => {
    setDomainsToShow(initialValues.emailDomains);
    setIsEnabled(initialValues.enabled);
  }, [initialValues]);

  useEffect(() => {
    change('enabled', isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    const handledDomains = selectedDomains.map((domain: IEmailDomain) => {
      if (checkedDomains.includes(domain.value)) {
        domain.isTaken = true;
        domain.isValid = false;
      }
      return domain;
    });
    setDomainsToShow(handledDomains);
  }, [checkedDomains, selectedDomains]);

  useEffect(() => {
    change('emailDomains', domainsToShow);
  }, [domainsToShow]);

  const onDomainsChange = (val: IEmailDomain[], newVal: IEmailDomain[]) => {
    change('emailDomains', val);
    setSelectedDomains(val);
    checkNewDomains(newVal);
  };

  const onCancelClick = () => push(CONDOR_ACCOUNTS_LIST_BASE_URL);

  const isDomainsInvalid = () => {
    if (!domainsToShow || domainsToShow.length === 0) return true;
    return domainsToShow.some((domain) => !domain.isValid);
  };

  return (
    <>
      { !isFetching && (
        <>
          <div className={ styles.formWrapper }>
            <div>
              <label className={ styles.label }>SSo subdomain</label>
              <Field
                name="subdomain"
                component={ Input }
                dataTest="ssoSubdomainInput"
                placeholder="SSO Subdomain"
              />
            </div>
            <div>
              <label className={ styles.label }>sso shortname</label>
              <Field
                name="shortname"
                component={ Input }
                dataTest="ssoShortName"
                placeholder="SSO Shortname"
                validate={ validateShortname }
              />
            </div>
            <div>
              <label className={ styles.label }>sso login URL</label>
              <Field
                name="loginUrl"
                component={ Input }
                dataTest="ssoLoginUrl"
                placeholder="SSO login URL"
              />
            </div>
            <div>
              <div className={ styles.inputTagsWrapper }>
                <label className={ styles.label }>email domains connected to SSO</label>
                <InputTags
                  tags={ domainsToShow }
                  dataTest="emailDomains"
                  validate={ validate }
                  onChange={ onDomainsChange }
                  placeholder="Enter Email Domains"
                  wrapperClassName={ styles.domainsField }
                  isFetching={ isFetching }
                  isDisabled={ isChecking }
                  autoFocus={ false }
                  withErrors={
                    (shouldRenderRequiredError && domainsToShow.length === 0)
                    || domainsToShow.some((domain) => domain.isTaken)
                  }
                  onBlurHandler={ () => {
                    setShouldRenderRequiredError(true);
                  } }
                />
                { domainsToShow.length === 0 && shouldRenderRequiredError && (
                  <p className={ styles.error }>Email Domains are required</p>
                ) }
                { domainsToShow.some(({
                  isValid,
                  isTaken,
                }) => !isValid && !isTaken) && (
                  <p className={ styles.error }>Some Domains are invalid</p>
                ) }
                { domainsToShow.some((domain) => domain.isTaken) && (
                  <p className={ styles.error }>Email domain is integrated with SSO</p>
                ) }
              </div>
            </div>
            <div>
              <label className={ styles.label }>USER EMAIL JSON PATH</label>
              <Field
                name="emailJsonPath"
                component={ Input }
                dataTest="ssoJSONPath"
                placeholder="User Email JSON Path"
              />
            </div>
            <div>
              <label className={ styles.label }>Additional settings</label>
              <div className={ styles.checkboxWrapper }>
                <Field
                  name="ignoreSignature"
                  component={ CheckboxInput }
                  dataTest="ignoreSignature"
                  type="checkbox"
                  label="Ignore Signature"
                />
              </div>
              <div className={ styles.checkboxWrapper }>
                <Field
                  name="allowUnencryptedAssertion"
                  component={ CheckboxInput }
                  type="checkbox"
                  dataTest="allowUnencryptedAssertion"
                  label="Allow Unencrypted Assertion"
                />
              </div>
            </div>
          </div>
          <PreviewBlock />
          <div className={ styles.formButtonsContainer }>
            <Button
              variant={ ButtonVariantType.action }
              className={ styles.successBtn }
              disabled={
                submitting ||
                (
                  pristine && (isEqual(emailDomains, initialValues.emailDomains) && enabled === initialValues.enabled)
                ) ||
                invalid ||
                isDomainsInvalid()
              }
              dataTest="submit"
              type="submit"
              title="Save"
            />
            <Button
              variant={ ButtonVariantType.text }
              dataTest="cancel"
              onClick={ onCancelClick }
              title="Cancel"
            />
          </div>
        </>
      ) }
      <Spinner isVisible={ isSubmitting || isFetching } isAbsolute overlay />
    </>
  );
};

export default FormFields;
