import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST } from '@/condor/ui/common/constants';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import {
  UnpublishModalContextProvider, useUnpublishModalContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/UnpublishModalContext';
import AreYouSureModal from '@/condor/ui/components/Finsight/CreditFlowResearch/common/AreYouSureModal';
import UnpublishActionModal from './UnpublishActionModal';
import PreviewModal from './PreviewModal';
import ProfileButtons from '@/condor/ui/components/Finsight/CreditFlowResearch/common/ProfileButtons';

const ArticlesProfileButtons = () => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { push } = useHistory();
  const { invalid, dirty, submitting, submitSucceeded } = useFormState();
  const { isEdit, isActive, isClone, isAttachmentsUploaded } = useArticleProfileContext();
  const { setIsOpenModal, isUnpublishing } = useUnpublishModalContext();
  const isDisabledCommon = invalid
    || submitting
    || isUnpublishing
    || isPreviewOpen
    || !isAttachmentsUploaded;
  const isDisabled = isDisabledCommon || (!dirty && !isClone);
  const isDisabledPublish = () => {
    if (isEdit) {
      return isActive ? (isDisabledCommon || !dirty) : isDisabledCommon;
    }
    return isDisabled;
  };

  const onPreviewAndPublishClick = useCallback(() => {
    setIsPreviewOpen(true);
  }, []);

  const onPublishClick = useCallback(() => {
    push(CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST);
  }, []);

  const onUnpublishClick = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  return (
    <>
      <ProfileButtons
        isEdit={ isEdit }
        isActive={ isActive }
        isDisabled={ isDisabled }
        isDisabledPublish={ isDisabledPublish() }
        isCancelDisabled={ submitting || isUnpublishing }
        isDisabledUnpublish={ submitting || isUnpublishing }
        onPreviewAndPublishClick={ onPreviewAndPublishClick }
        onCancelClick={ onPublishClick }
        onUnpublishClick={ onUnpublishClick }
      />
      <AreYouSureModal
        when={ dirty && !submitting && !submitSucceeded }
        disableNative={ false }
      />
      <UnpublishActionModal />
      { isPreviewOpen && (
        <PreviewModal setIsPreviewOpen={ setIsPreviewOpen } />
      ) }
    </>
  );
};

export default () => (
  <UnpublishModalContextProvider>
    <ArticlesProfileButtons />
  </UnpublishModalContextProvider>
);
