import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { components as ReactSelectComponents } from 'react-select';
import { useDIContext } from '@/Framework/DI/DIContext';
import MappingsRepository from '@/condor/infrastructure/repository/mappings/mappingsRepository';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from './styles.scss';

const LeisFieldMultiValue = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState<{
    totalCount: number,
    data: Array<{ id: string, name: string, isUploaded: boolean }>,
  }>(null);

  const accountId = useLocation().pathname.split('/').pop();
  const isCreatePage = Number.isNaN(Number(accountId));
  const mappingsRepository: MappingsRepository = useDIContext().container.get(MappingsRepository);

  const tryToRemove = async () => {
    if (
      isCreatePage
      /** Check if lei was created now */
      || !props.data.id
    ) {
      props.removeProps.onClick();
      return;
    }

    setIsLoading(true);

    try {
      const payload = await mappingsRepository.getAllocationsMappings({
        perPage: 10,
        page: 1,
        leiId: props.data.id,
        accountId,
      });

      if (payload.totalCount === 0) {
        props.removeProps.onClick();
      } else {
        setPayload(payload);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => setPayload(null);

  return (
    <>
      <ReactSelectComponents.MultiValue
        { ...props }
        removeProps={ {
          ...props.removeProps,
          onClick: tryToRemove,
        } }
        components={ {
          ...props.components,
          ...(isLoading && {
            Remove: () => null,
          }),
        } }
      >
        { props.children }
        { isLoading && <Spinner className={ styles.spinner } /> }
      </ReactSelectComponents.MultiValue>

      { payload && (
        <Modal
          title="Idetifier can't be unlinked"
          isVisible
          dataTest="leiRemovingModalWarning"
          onCloseClicked={ handleCloseModal }
          footer={ <Button variant={ ButtonVariantType.warning } onClick={ handleCloseModal } title="Cancel" dataTest="cancelButton" /> }
        >
          <div>
            There is an Allocation with the Account linked to the LEI. Please relink LEI before deleting linkage:
          </div>
          <ul>
            { payload.data.map((item) => (
              <li key={ item.id }>{ item.name }{ !item.isUploaded && ' (Incomplete upload)' }</li>
            )) }
            { (payload.totalCount > payload.data.length) && (
              <li>And { payload.totalCount - payload.data.length } others</li>
            ) }
          </ul>
        </Modal>
      ) }
    </>
  );
};

export default LeisFieldMultiValue;
