import React from 'react';
import styles from '@/condor/ui/components/Finsight/Filings/FilingsTables/tableStyles.scss';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';

export const getIssuerNameCellEe = ({ row: filing }) => (
  <Tooltip content="View SEC filing">
    <a
      href={ filing.url }
      target="_blank"
    >
      { filing.secIssuerName }
      <IconExternalLink className={ styles.link } />
    </a>
  </Tooltip>
);
