import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Tranche from './Tranche';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { TRANCHE_FIELDS } from '../../constants';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state, ownProps) => {
  return (
    {
      isExcluded: formSelector(state, `${ ownProps.field }.${ TRANCHE_FIELDS.IS_EXCLUDED.name }`),
    }
  );
};

export default connect(
  mapStateToProps,
)(Tranche);
