import {
  useMemo,
  useEffect,
  ReactNode,
  ElementType,
} from 'react';
import cn from 'classnames';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact, ReactEditor } from 'slate-react';

import { Toolbar, TextEditor } from './components';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';

import stripTags from '@/Framework/dom/stripTags';
import useHandlers from './helpers/useHandlers';
import { withLinks } from './helpers/links';
import { deserialize } from './helpers/serialize';

import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { TToolbarPosition } from '@/Framework/UI/Molecules/Form/RichTextInput/interfaces';

import styles from './richTextInput.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IProps {
  name: string,
  value: string,
  initialValue: string,
  onInputChange: (value: string) => void,
  isValidationFeedback: boolean,
  label: ReactNode,
  dataTest: string,
  formFieldCls: string,
  containerCls: string,
  editableWrpCls: string,
  className: string,
  editorStyles: React.CSSProperties,
  placeholder: string,
  isNarrow: boolean,
  isFocused: boolean,
  maxLength: number,
  isEnabledCountdownMessage: boolean,
  toolbarPosition: TToolbarPosition,
  /** Please, use toolbarComponent prop ONLY to modify, NOT HIDE the toolbar. If you need the toolbar hidden,
   *  use the Textarea component instead and apply 'white-space: pre-line' to the div
   *  where the submitted text is being inserted via dangerouslySetInnerHTML tag. */
  toolbarComponent: ElementType,
  /** @deprecated Please, do not use this prop. */
  hasLineBreaks: boolean,
}

const RichTextInput = (
  {
    name,
    value,
    initialValue,
    onInputChange,
    isValidationFeedback,
    label,
    dataTest,
    toolbarPosition = 'top',
    toolbarComponent: ToolbarComponent = Toolbar,
    className = '',
    formFieldCls = '',
    containerCls = '',
    editableWrpCls = '',
    isEnabledCountdownMessage = false,
    maxLength = 10000,
    hasLineBreaks = false,
    placeholder = '',
    editorStyles = {},
    isNarrow = false,
    isFocused = false,
  }: IProps,
) => {
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []) as ReactEditor;
  const slateInitialValue = useMemo(
    () => {
      const fromString = initialValue?.match(/(<([^>]+)>)/g)?.length ? initialValue : `<p>${ initialValue }</p>`;
      const document = new DOMParser().parseFromString(fromString, 'text/html');
      return deserialize(document.body);
    },
    [initialValue],
  );

  useEffect(() => {
    if (isFocused && !ReactEditor.isFocused(editor)) {
      setTimeout(() => ReactEditor.focus(editor), 0);
    }
  }, []);

  const symbolsLeft = value ? maxLength - (stripTags(value) as string).length : maxLength;

  const {
    onChange,
    onKeyDown,
    onPaste,
  } = useHandlers(onInputChange, editor, symbolsLeft, hasLineBreaks);

  const remainingCharactersCls = cn(styles.textareaCharacters, { [styles.warning]: symbolsLeft === 0 });

  return (
    <FormField
      isNarrow={ isNarrow }
      dataTest={ dataTest }
      className={ formFieldCls }
    >
      <div className={ cn(styles.wrap, containerCls) }>
        { label && (
          <label
            className={ formStyles.formLabel }
            htmlFor={ name }
          >
            { label }
          </label>
        ) }
        <Slate
          initialValue={ slateInitialValue as Descendant[] }
          editor={ editor }
          onChange={ onChange }
        >
          <TextEditor
            toolbarPosition={ toolbarPosition }
            ToolbarComponent={ ToolbarComponent }
            value={ value }
            placeholder={ placeholder }
            onKeyDown={ onKeyDown }
            onPaste={ onPaste }
            editorStyles={ editorStyles }
            isValidationFeedback={ isValidationFeedback }
            editableWrpCls={ editableWrpCls }
            className={ className }
          />
          { isEnabledCountdownMessage && (
            <div className={ remainingCharactersCls } data-test="charactersRemainingLabel">
              { `${ symbolsLeft } character${ symbolsLeft !== 1 ? 's' : '' } remaining` }
            </div>
          ) }
        </Slate>
      </div>
    </FormField>
  );
};

const RichTextInputWithFormIntegration = (
  { input, meta, ...otherProps }: IFinalFormFieldInputComponentProps,
) => (
  <RichTextInput
    name={ input.name }
    value={ input.value || '' }
    initialValue={ meta.initial || '' }
    onInputChange={ input.onChange }
    isValidationFeedback={ !!(meta && meta.touched && meta.error) }
    { ...otherProps as IProps }
  />
);

export default RichTextInputWithFormIntegration;
