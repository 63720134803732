import { useMemo, useState } from 'react';
import useAuditTrail from '@/evercall/application/condor/call/profile/auditTrail/useAuditTrail';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { DEFAULT_TIME_ZONE } from '@/Framework/TimeZone/constants';
import BaseAuditTrail from '@/Framework/UI/Organisms/AuditTrail';

import styles from './auditTrail.scss';

const AuditTrail = () => {
  const { activity } = useAuditTrail();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearch = (value) => {
    activity.debouncedSearch(value);
    setSearchValue(value);
  };

  const collection = useMemo(() => {
    return activity.collection.map((item) => {
      return {
        ...item,
        occurredAt: getDateFromUnixTime(item.occurredAt),
      };
    });
  }, [activity.collection]);

  return (
    <BaseAuditTrail
      className={ styles.cardContainer }
      tableWrpClassName={ styles.auditTrailTableWrapper }
      timeZone={ DEFAULT_TIME_ZONE }
      page={ activity.page }
      perPage={ activity.perPage }
      sortBy={ activity.sortBy }
      sortOrder={ activity.sortOrder }
      collection={ collection }
      totalCount={ activity.totalCount }
      isCollectionFetching={ activity.isFetching }
      onSortChange={ activity.sort }
      onPageChange={ ({ selected }) => activity.paginate(selected) }
      onItemsPerPageChange={ ({ value }) => activity.setItemsPerPage(value) }
      isAdmin={ false }
      onSearchChange={ (event) => handleSearch(event.target.value) }
      query={ searchValue }
    />
  );
};

export default AuditTrail;
