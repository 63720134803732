import { Link } from 'react-router-dom';
import cn from 'classnames';

import OpenMarketListContextProvider from './OpenMarketListContext';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import Button, { ButtonVariantType, buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import IconPlus from '@dealroadshow/uikit/core/components/Icon/IconPlus';
import OpenMarketHeader from './OpenMarketHeader';
import OpenMarketBody from './OpenMarketBody';

import * as constants from '@/condor/ui/common/constants';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './openMarket.scss';

const OpenMarketList = () => {
  return (
    <PageWrapper
      label="OpenMarket"
      contentCls={ cn(cardStyles.cardContainer, styles.cardContainer) }
      headerComponent={ (
        <div className={ pageStyles.pageHeaderActions }>
          <Link to={ constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE }>
            <Button
              dataTest="createEvent"
              variant={ ButtonVariantType.action }
              className={ cn(buttonStyles.btnIcon, buttonStyles.btnIconRight, buttonStyles.btnIconMobile) }
            >
              Create Event
              <IconPlus className={ styles.buttonIcon } />
            </Button>
          </Link>
          <Link to={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_CREATE }>
            <Button
              dataTest="createProfile"
              variant={ ButtonVariantType.action }
              className={ cn(
                spacesStyles.mrn,
                buttonStyles.btnIcon,
                buttonStyles.btnIconRight,
                buttonStyles.btnIconMobile,
              ) }
            >
              Create Profile
              <IconPlus className={ styles.buttonIcon } />
            </Button>
          </Link>
        </div>
      ) }
    >
      <OpenMarketHeader />
      <OpenMarketBody />
    </PageWrapper>
  );
};

export default () => (
  <OpenMarketListContextProvider>
    <OpenMarketList />
  </OpenMarketListContextProvider>
);
