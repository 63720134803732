import React, { useCallback } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Input from '@dealroadshow/uikit/core/components/Input';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import capitalize from '@/Framework/dataHelpers/string/capitalize';

import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import validate, { conditionalValidators } from './validate';
import { BANNER_FORM_FIELDS } from '../constants';
import PreviewBanner from '../PreviewBanner';
import ColorPicker from '@dealroadshow/uikit/core/components/Picker/ColorPicker';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Banner from '@/condor/domain/banner/Banner';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';

import styles from './bannerForm.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IProps {
  data: any,
  index: number,
  positions: any[],
  submit: (values: any) => void,
  deleteById: (id: string) => void,
}

const BannerForm = (
  {
    data,
    index,
    positions,
    submit,
    deleteById,
  }: IProps,
) => {
  const initialValues = {
    status: Banner.getNotLive(),
    positionIds: [],
    ...data,
  };
  const getForbiddenPositions = (positions) => filter(positions, { bannersCount: 1 });
  const getForbiddenKeys = (positions) => map(getForbiddenPositions(positions), 'positionId');
  const filteredForNewBanner = (data) => data.filter((position) => !forbiddenKeys.includes(position));
  const forbiddenKeys = getForbiddenKeys(positions);

  const isActiveDisableButton = Banner.isLive(data.status) && data.hash;
  const showDelete = !!data.hash;

  const getDataTestAttributes = (string: string): string => {
    return string
      .match(/\w+/g)
      ?.map((el, i) => (i === 0 ? el : capitalize(el)))
      .join('') ?? '';
  };

  const renderDetailsForm = useCallback(({
      values,
      invalid,
      pristine,
      form: { change },
    }, { Field }) => {
      const {
        status,
        positionIds,
      } = initialValues;
      const isDisabled = ({ positionId }) => {
        if (Banner.isNotLive(status)) {
          return forbiddenKeys.includes(positionId);
        }
        return !positionIds.includes(positionId) && forbiddenKeys.includes(positionId);
      };
      const isDraftValid = !isEmpty(conditionalValidators(values));
      const defaultColor = data.isNew ? '#ffe484' : data.color;

      // If the banner is new, we need to filter out the positions
      // to check if some positions are taken by updating another banner
      if (!initialValues.hash) {
        const filteredPositions = filteredForNewBanner(values.positionIds);
        if (!isEqual(filteredPositions, values.positionIds)) {
          change('positionIds', filteredPositions);
        }
      }
      return (
        <>
          <div>
            <div className={ styles.inputWrapper }>
              <div className={ styles.smallInput }>
                <Field
                  { ...BANNER_FORM_FIELDS.NAME }
                  component={ Input }
                />
              </div>
              <div className={ styles.smallInput }>
                <Field
                  { ...BANNER_FORM_FIELDS.COLOR }
                  containerClassName={ formStyles.formInput }
                  enablePresetColors
                  defaultColor={ defaultColor }
                  component={ ColorPicker }
                />
              </div>
            </div>
            <Field
              { ...BANNER_FORM_FIELDS.CONTENT }
              component={ RichTextInput }
              maxLength={ 500 }
              isFocused
              isEnabledCountdownMessage
              placeholder="Add banner text..."
              dataTest="condorAdminBannerFormEditor"
            />
            <PreviewBanner color={ values.color } content={ values.content } />
            <div>
              <div className={ styles.label }>{ BANNER_FORM_FIELDS.POSITIONS.label }</div>
              <div className={ styles.positionsBlock }>
                <Field
                  name="positionIds"
                  render={ ({ input }) => positions.map((item) => (
                    <Checkbox
                      key={ item.positionId }
                      className={ styles.checkbox }
                      label={ item.name }
                      disabled={ isDisabled(item) }
                      dataTest={ `${ getDataTestAttributes(item.name) }Checkbox` }
                      checked={ input.value.includes(item.positionId) }
                      onChange={ (e) => input.onChange(
                        e.target.checked
                          ? [...input.value, item.positionId]
                          : input.value.filter((id) => id !== item.positionId),
                      ) }
                    />
                  )) }
                />
              </div>
            </div>
          </div>
          <div className={ styles.buttonWrp }>
            { Banner.isNotLive(values.status) && (
              <Button
                { ...BANNER_FORM_FIELDS.SAVE_DRAFT }
                dataTest="saveBannerToDraftButton"
                variant={ ButtonVariantType.action }
                disabled={ pristine || isDraftValid }
                onClick={ () => submit({
                  ...values,
                  status: Banner.getNotLive(),
                }) }
              />
            ) }
            <Button
              { ...BANNER_FORM_FIELDS.SAVE_PUBLISH }
              dataTest="submitBannerToPublishButton"
              variant={ ButtonVariantType.success }
              disabled={ invalid }
              onClick={ () => submit({
                ...values,
                status: Banner.getLive(),
              }) }
            />
            <span className={ styles.saveInfo }>{ BANNER_FORM_FIELDS.PASSING.label }</span>
            <div className={ styles.buttonRight }>
              <Button
                { ...BANNER_FORM_FIELDS.DISABLE }
                className={ styles.buttonRight }
                dataTest="submitBannerToDisableButton"
                variant={ ButtonVariantType.text }
                disabled={ !isActiveDisableButton }
                onClick={ () => submit({
                  ...values,
                  status: Banner.getNotLive(),
                }) }
              />
              { showDelete && (
                <Button
                  { ...BANNER_FORM_FIELDS.DELETE }
                  className={ styles.deleteBtn }
                  dataTest="submitBannerToDeleteButton"
                  variant={ ButtonVariantType.text }
                  onClick={ () => deleteById(values.bannerId) }
                />
              ) }
            </div>
          </div>
        </>
      );
    },
    [initialValues]);

  return (
    <FinalForm
      name={ `bannerForm-${ data.bannerId }` }
      withFocusOnErrors
      keepDirtyOnReinitialize
      initialValues={ initialValues }
      validate={ validate }
      role={ `form-${ initialValues.bannerId }` }
      onSubmit={ submit }
      render={ renderDetailsForm }
      dataTest={ data.isNew ? `bannerForm-new-${ index }` : `bannerForm-${ index }` }
    />
  );
};

export default BannerForm;
