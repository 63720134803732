import cn from 'classnames';

import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import styles from './styles.scss';

const ProfileButtons = ({
  isEdit,
  isActive,
  isDisabled,
  isCancelDisabled,
  isDisabledPublish,
  isDisabledUnpublish,
  onPreviewAndPublishClick,
  onCancelClick,
  onUnpublishClick,
}) => (
  <div className={ styles.formButtonsContainer }>
    <div>
      <Button
        variant={ ButtonVariantType.action }
        dataTest="previewAndPublish"
        title={ isEdit ? 'Preview & Update' : 'Preview & Publish' }
        className={ cn({ [styles.previewAndPublishButton]: !isActive }) }
        disabled={ isDisabledPublish }
        onClick={ onPreviewAndPublishClick }
      />
      {
        !isActive && (
          <Button
            variant={ ButtonVariantType.action }
            dataTest="saveDraft"
            title="Save Draft"
            type="submit"
            disabled={ isDisabled }
          />
        )
      }
      <Button
        variant={ ButtonVariantType.text }
        dataTest="cancel"
        title="Cancel"
        disabled={ isCancelDisabled }
        onClick={ onCancelClick }
      />
    </div>
    {
      (isEdit && isActive) && (
        <Button
          variant={ ButtonVariantType.text }
          dataTest="unpublish"
          title="Unpublish"
          className={ styles.unpublishButton }
          onClick={ onUnpublishClick }
          disabled={ isDisabledUnpublish }
        />
      )
    }
  </div>
);

export default ProfileButtons;
