export const FIELD_TYPE = {
  isBoolean: 'boolean',
  isDate: 'date',
  isRadio: 'radio',
  isSelect: 'select',
  isTextarea: 'textarea',
  issuerSelect: 'issuerSelect',
  companySelect: 'companySelect',
  autoCalculated: 'autoCalculated',
};
