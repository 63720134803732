import { ReactNode } from 'react';
import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import { TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';
import getUTCDate from '@/Framework/DateTime/getUTCDate';

import styles from './styles.scss';

interface IProps {
  children?: ReactNode,
  author: string,
  date: string,
  authorDataTest: string,
  dateDataTest: string,
}

export const Metadata = ({ children, author, date, authorDataTest, dateDataTest }: IProps) => (
  <div className={ styles.metadata }>
    { children }
    <p className={ styles.author } data-test={ authorDataTest }>{ `by ${ author }` }</p>
    { date && (
      <p className={ styles.date } data-test={ dateDataTest }>{
        getFormatInTimeZoneDate(getUTCDate(new Date(date)), TIMEZONE_DEFAULT)
      } ET
      </p>
    ) }
  </div>
);
