import { Class, Transient, Value, Method } from 'constitute';
import { MultipartUploadStrategy, UploadDispatcher, MultipartUploadAdapter } from '@dealroadshow/file-uploader';
import ProcessingAdapter from './SocketProcessingAdapter';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';

const UploadAdapter = new Class(
  MultipartUploadAdapter,
  Transient.with([new Value({ url: evercallUrl.getBaseFilesUrl() })]),
);

const factory = (processingAdapter, uploadAdapter) => {
  return new UploadDispatcher(new MultipartUploadStrategy(uploadAdapter, processingAdapter));
};

export default new Method(factory, [ProcessingAdapter, UploadAdapter]);
