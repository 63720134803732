import React from 'react';

import styles from './common.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import IconNoBell from '@dealroadshow/uikit/core/components/Icon/IconNoBell';

const NoNotificationOverlay = ({ isNotificationEnabled }: { isNotificationEnabled: boolean }) => {
  if (isNotificationEnabled) {
    return null;
  }

  return (
    <div className={ styles.overlay }>
      <div className={ alignStyles.flexColumnCenter }>
        <IconNoBell />
        <p>User(s) will not receive any
          <br />
          notifications by email.
        </p>
      </div>
    </div>
  );
};

export default NoNotificationOverlay;
