import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import IssuerSelect from './IssuerSelect';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import {
  fetchDealCardFieldCompaniesOptions,
  clearDealCardCompanyFieldOptions,
} from '@/condor/application/actions/finsight/deal/actions';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state, { input }) => (
  {
    filteredOptions: selectors.getFilteredCompaniesOptionsByFields(state)[input.name],
    formValues: {
      additionalIssuers: formSelector(state, DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS.name),
    },
  }
);

const mapDispatchToProps = (dispatch, { input }) => (
  {
    fetchDealCardFieldCompaniesOptions: (payload, callback) => (
      dispatch(fetchDealCardFieldCompaniesOptions(payload, callback))),
    onBlur: () => dispatch(clearDealCardCompanyFieldOptions(input.name)),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IssuerSelect);
