import React from 'react';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';

interface IProps {
  toggleBulkDeleteAll: (items: IAccountProfileItem[]) => void,
  bulkDeleteItems: IAccountProfileItem[],
  accounts: IAccountProfileItem[],
}

const BulkDeleteHeadCell = ({
  bulkDeleteItems = [],
  toggleBulkDeleteAll,
  accounts,
}: IProps) => {
  const accountItemsLength = Object.keys(accounts).length;
  const isChecked = accounts.every(
    ({ id }) => bulkDeleteItems.find((item) => item.id === id),
  ) && (accountItemsLength > 0);

  const onChange = () => {
    toggleBulkDeleteAll(isChecked ? [] : accounts);
  };

  return (
    <Checkbox
      dataTest="bulkDeleteHeadCell"
      onChange={ onChange }
      checked={ isChecked }
    />
  );
};

export default BulkDeleteHeadCell;
