import { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';
import { IRegion } from '@/finsight/domain/vo/Region';

export const getRegionsByIds = (regions: IRegion[], includedRegionIds: string[]): ISelectOption[] => {
  return includedRegionIds.map((id) => {
    const region = regions.find((r) => r.id === id);
    return {
      value: region.id,
      label: region.abbreviation,
    };
  });
};

export const mapRegionsToOptions = (regions: IRegion[]): ISelectOption[] => {
  return regions.map((region) => ({
    value: region.id,
    label: region.abbreviation,
  }));
};
