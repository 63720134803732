import React, { createContext, PropsWithChildren, useContext } from 'react';
import useAccountTypeProfile from './useAccountTypeProfile';

export const AccountTypeProfileContext = createContext<ReturnType<typeof useAccountTypeProfile>>(null);

export const useAccountTypeProfileContext = () => {
  const context = useContext(AccountTypeProfileContext);
  if (!context) {
    throw new Error('useAccountTypeProfileContext must be used within the AccountTypeProfileContext');
  }
  return context;
};

const AccountTypeProfileContextProvider = ({ children }: PropsWithChildren) => (
  <AccountTypeProfileContext.Provider
    value={ useAccountTypeProfile() }
  >
    { children }
  </AccountTypeProfileContext.Provider>
);

export default AccountTypeProfileContextProvider;
