import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import { useLocation } from 'react-router';
import styles from './actionsCell.scss';

import {
  CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL,
  CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL,
} from '@/condor/ui/common/constants';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import IconAddCircle from '@dealroadshow/uikit/core/components/Icon/IconAddCircle';
import IconList from '@dealroadshow/uikit/core/components/Icon/IconList';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

interface IProps {
  row: any,
  isSelectedForEditing: boolean,
  isInlineEditing: boolean,
  isUpdating: boolean,
  inlineEditFormState: any,
  onSubmit: () => void,
  onCancel: () => void,
  onEditClick: () => void,
  onDeleteClick: (pathname: string) => void,
}

const ActionsCell = (props: IProps) => {
  const { pathname } = useLocation();

  const actions = props.isSelectedForEditing && props.isInlineEditing ?
    [
      {
        tooltipContent: 'Save',
        icon: IconCheck,
        onClick: props.onSubmit,
        disabled: props.inlineEditFormState.submitting ||
          props.inlineEditFormState.pristine ||
          props.inlineEditFormState.invalid,
        dataTest: 'saveChangesAction',
      },
      {
        tooltipContent: 'Cancel',
        icon: IconCancel,
        onClick: props.onCancel,
        dataTest: 'cancelChangesAction',
      },
    ] : [
      {
        tooltipContent: 'Add New Deal',
        icon: IconAddCircle,
        url: `${ CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL }?issuerId=${ props.row.id }`,
        className: spacesStyles.mrl,
        dataTest: 'addNewDealAction',
      },
      {
        tooltipContent: 'View deals',
        icon: IconList,
        url: `${ CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${ props.row.id }`,
        className: spacesStyles.mrl,
        dataTest: 'viewDealsAction',
      },
      {
        tooltipContent: 'Edit',
        icon: IconPencil,
        onClick: props.onEditClick,
        className: spacesStyles.mrl,
        dataTest: 'editAction',
      },
      {
        tooltipContent: 'Delete',
        icon: IconTrash,
        onClick: () => props.onDeleteClick(pathname),
        dataTest: 'deleteAction',
      },
    ];

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUpdating
          // @ts-ignore
          ? <TableRowActions actions={ actions } tableRowActionsClassName={ styles.actions } />
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    />
  );
};

export default ActionsCell;
