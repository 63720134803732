import { connect } from 'react-redux';
import {
  getManagerAnalyticsContactsByFilter,
  itemsPerPage,
  paginate,
} from '@/condor/application/actions/finsightManager/analytics/contactsCollection/actions';
import * as selectors from '@/condor/application/actions/finsightManager/analytics/contactsCollection/selectors';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';
import { BI_MODULE } from '@/condor/ui/components/FinsightManager/constants';

const mapStateToProps = (state) => (
  {
    totalCount: selectors.getTotalCount(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getManagerAnalyticsContactsByFilter(BI_MODULE));
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getManagerAnalyticsContactsByFilter(BI_MODULE));
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
