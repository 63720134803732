import React, { useState } from 'react';
import cn from 'classnames';

import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import Input from '@dealroadshow/uikit/core/components/Input';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes as ActionButtonVariantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './roadshows.scss';
import IconFileXls from '@dealroadshow/uikit/core/components/Icon/IconFileXls';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconFiltering from '@dealroadshow/uikit/core/components/Icon/IconFiltering';

const BULK_EDIT_RESTRICTION_COUNT = 200;

const RoadshowsHeader = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    setIsBulkModalVisible,
    editableRowId,
    checkedRoadshows,
    debouncedSearch,
  } = useEnhancedTaggingRoadshowsContext();

  const handleBulkEditingModal = () => {
    setIsBulkModalVisible(true);
  };

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  const isShownBulkEditButton = checkedRoadshows.length > 1 && !editableRowId;
  const isDisabledBulkEditButton = checkedRoadshows.length > BULK_EDIT_RESTRICTION_COUNT;

  return (
    <div className={ cn(cardStyles.cardHeader, styles.cardHeader) }>
      <div className={ cn(cardStyles.cardHeaderTitle, styles.cardHeaderTitle) }>
        <h3>Enhanced Tagging</h3>
        { isShownBulkEditButton && (
          <div className={ styles.headerTitleActions }>
            <ActionButton
              title={
                isDisabledBulkEditButton
                  ? `This feature does not allow bulk editing for more than ${ BULK_EDIT_RESTRICTION_COUNT } items (you have selected ${ checkedRoadshows.length } items)`
                  : 'Bulk Edit'
              }
              className={ styles.headerTitleActionsButton }
              icon={ IconPencil }
              variant={ ActionButtonVariantTypes.icon }
              dataTest="bulkEditModalCall"
              onClick={ handleBulkEditingModal }
              isDisabled={ isDisabledBulkEditButton }
            />
          </div>
        ) }
      </div>
      <div className={ cn(cardStyles.cardHeaderActions, styles.headerActions) }>
        <ActionButton
          title="Export to Excel"
          icon={ IconFileXls }
          variant={ ActionButtonVariantTypes.icon }
          dataTest="downloadXlsFile"
          isDisabled
        />
        { /* @ts-ignore */ }
        <Input
          name="search"
          formFieldClassName={ styles.searchInputWrapper }
          // @ts-ignore
          icon={ IconFiltering }
          placeholder="Filter by Company, Parent, Unique ID and Name"
          isNarrow
          dataTest="searchInput"
          value={ searchValue }
          onChange={ onSearchChange }
        />
      </div>
    </div>
  );
};

export default RoadshowsHeader;
