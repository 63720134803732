import noop from 'lodash/noop';
import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/createAction';
import { NotificationManager } from '@/Framework/Notification';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';
import { TUploadMethod } from '@/Framework/UI/Organisms/Upload/interfaces';
import { TOnUploadDone } from './interfaces';

interface IProps {
  uploadMethod: TUploadMethod,
  files: any,
  sort: any[],
  /* onUploadDone is needed if you want to insert url or other data into form field
   because when the new item is pushed to form inside UploadTable, the url is not yet formed :( */
  onUploadDone?: TOnUploadDone,
}

export default (
  {
    uploadMethod,
    onUploadDone = noop,
    files,
    sort,
  }: IProps,
  dispatch,
) => {
  const uploadStart = (payload) => dispatch(createAction(actionTypes.UPLOAD_ADD, payload));
  const uploadChunkSuccess = (payload) => dispatch(createAction(actionTypes.UPLOAD_CHUNK_SUCCESS, payload));
  const uploadError = (payload) => dispatch(createAction(actionTypes.UPLOAD_ERROR, payload));
  const uploadSuccess = (payload) => dispatch(createAction(actionTypes.UPLOAD_SUCCESS, payload));
  const uploadDone = (payload) => dispatch(createAction(actionTypes.UPLOAD_DONE, payload));
  const uploadProcessing = (payload) => dispatch(createAction(actionTypes.UPLOAD_PROCESSING, payload));
  const uploadCancelSuccess = (payload) => dispatch(createAction(actionTypes.UPLOAD_CANCEL_SUCCESS, payload));

  const onError = (payload, handleRemoveFile) => {
    const index = sort.findIndex((item) => (item.uuid === payload.uuid));
    if (index > -1 && payload.error.message && handleRemoveFile) {
      NotificationManager.error(payload.error.message);
      handleRemoveFile(payload.uuid, index);
    }
  };

  const cancelUpload = (uuid: string) => {
    if (files[uuid] && files[uuid].callback && typeof files[uuid].callback.cancel === 'function') {
      files[uuid].callback.cancel();
    } else if (files[uuid]) {
      uploadCancelSuccess({ uuid: files[uuid].process.uuid });
    }

    uploadCancelSuccess({ uuid });
  };

  const orderUploads = (oldIndex: number, newIndex: number) => dispatch(createAction(
    actionTypes.UPLOAD_ORDER,
    { oldIndex, newIndex },
  ));

  const uploadDocuments = async (files, handleRemoveFile) => Promise.all(
    files
      .sort((prev, next) => prev.size - next.size)
      .map(async (file) => {
        try {
          const upload: IUploadProcessing = await uploadMethod(file);

          uploadStart({
            uploadFile: file,
            callback: upload,
            uuid: upload.getUuid(),
          });

          upload
            .on(UploadEvent.uploadChunkDone, (data) => uploadChunkSuccess(data))
            .on(UploadEvent.error, (data) => {
              uploadError(data);
              onError(data, handleRemoveFile);
            })
            .on(UploadEvent.uploadDone, (data) => uploadSuccess(data))
            .on(ProcessingEvent.processing, (data) => uploadProcessing(data))
            .on(ProcessingEvent.processingDone, (data) => {
              uploadDone(data);
              onUploadDone(data);
            })
            .on(UploadEvent.cancel, (data) => uploadCancelSuccess(data));
        } catch (e) {
          NotificationManager.error(getMessageByErrorCode(e.code));
        }
      }),
  );

  const initUpload = (payload: any[]) => dispatch(createAction(actionTypes.UPLOAD_INIT, payload));

  return {
    initUpload,
    uploadDocuments,
    cancelUpload,
    orderUploads,
    // actions below can be used if you need custom actions
    uploadStart,
    uploadChunkSuccess,
    uploadError,
    uploadSuccess,
    uploadDone,
    uploadProcessing,
    uploadCancelSuccess,
  };
};
