import Logger from '@/Framework/browser/log/Logger';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import withTrancheCell from '@/condor/ui/components/Finsight/DealProfile/helpers/withTrancheCell';
import type { ITranche } from '@/condor/domain/vo/Finsight/Deal/Tranche';
import TRANCHE_FIELDS from '@/condor/ui/components/Finsight/DealProfile/constants/trancheFields';

import type { IFieldProps } from '@/condor/ui/components/Finsight/DealProfile/Tranches/Tranche/TrancheField';

interface ICalculation {
  name: string,
  calculate: (values: ITranche) => number,
}

const TextInput = withTrancheCell(Input);

const CALCULATIONS: Record<string, ICalculation> = {
  bookXCovered: {
    name: TRANCHE_FIELDS.BOOK_X_COVERED.name,
    calculate: ({ bookSize, size }: ITranche) => {
      return Number((parseFloat(bookSize) / parseFloat(size)).toFixed(2));
    },
  },
  bookAttrition: {
    name: TRANCHE_FIELDS.BOOK_ATTRITION.name,
    calculate: ({ bookSize, peakBook }: ITranche) => {
      return Number(((parseFloat(bookSize) - parseFloat(peakBook)) / parseFloat(peakBook)).toFixed(2));
    },
  },
};

export const AutoCalculatedField = ({ tranche, input, ...props }: IFieldProps) => {
  const calculateValue = () => {
    const calculation = Object.values(CALCULATIONS).find((calc) => input.name.includes(calc.name));

    if (!calculation) {
      Logger.warn(`No calculation found for field: ${ input.name }`);
      return '';
    }

    try {
      const result = calculation.calculate(tranche);
      return Number.isNaN(result) ? '' : result;
    } catch (error) {
      Logger.error(`Error calculating value for ${ input.name }:`, { error });
      return '';
    }
  };

  return (
    <TextInput
      { ...props }
      input={ {
        value: calculateValue(),
        disabled: true,
      } }
      size="small"
    />
  );
};
