import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import {
  DEAL_PROFILE_FIELDS_ARRAY,
  DEAL_PROFILE_FIELDS,
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
  REGULAR_EXPRESSIONS,
  TRANCHE_FIELDS,
} from '../constants';
import mapDateToValue from './mapDateToValue';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import { AMERICA_NEW_YORK_TIME_ZONE_ID } from '@/condor/ui/components/Finsight/constants';
import { IRatingAgency } from '@/finsight/domain/vo/RatingAgency/IRatingAgency';
import { DealProfileFormData } from '@/condor/domain/vo/Finsight/Deal/DealProfileFormData';
import { editorInitialValue } from '@/condor/application/Finsight/constants';

const getDealSubmitPayload = (formData: DealProfileFormData, ratingAgencies: IRatingAgency[]) => {
  const processSubmitValues = ({ values, fields, trancheIndex }) => Object.keys(values).reduce(
    (result: {
      sectorId?: string,
      subsectorId?: string,
    }, currentKey) => {
      const field = fields.find((field) => field.name === currentKey);

      if (!field) {
        result[currentKey] = values[currentKey];
        return result;
      }

      // Deals TIME ZONE ID is set manually.
      if (field.name === DEAL_PROFILE_FIELDS.TIME_ZONE_ID.name) {
        result[field.name] = AMERICA_NEW_YORK_TIME_ZONE_ID;
        return result;
      }

      // Deal's ABS SECTOR/SUBSECTOR field is used for sectorId and subsectorId properties.
      if (field.name === DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name) {
        if (values[field.name]) {
          result.sectorId = values[field.name].sectorId;
          result.subsectorId = mapOptionToValue(values[field.name]);
          return result;
        }
        result.sectorId = null;
        result.subsectorId = null;
        return result;
      }

      // Deal's RESET and REFINANCING properties' value's type on backend is TINYINT (not BOOLEAN).
      if ([DEAL_PROFILE_FIELDS.RESET.name, DEAL_PROFILE_FIELDS.REFINANCING.name].includes(field.name)) {
        result[field.name] = values[field.name] ? 1 : 0;
        return result;
      }

      // Deal's TRANCHES property is an array of objects.
      if (field.name === DEAL_PROFILE_FIELDS.TRANCHES.name) {
        result[field.name] = values[field.name]
          // Delete excluded tranches.
          .filter((tranche) => !tranche[TRANCHE_FIELDS.IS_EXCLUDED.name])
          // Recursion is used because both Tranche and Deal have some common value processing.
          .map((tranche, i) => processSubmitValues(
            {
              values: tranche,
              fields: DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
              trancheIndex: i,
            },
          ));
        return result;
      }

      // Tranche's ORDERING POSITION should be updated if Tranche was moved with drag and drop.
      if (field.name === TRANCHE_FIELDS.ORDERING_POSITION.name) {
        result[field.name] = !isEqual(values[field.name], trancheIndex)
          ? trancheIndex
          : values[field.name];
        return result;
      }

      // Tranche's IS EXCLUDED and PRIMARY REGION properties are created and used locally
      // and don't need to be sent in payload.
      if (
        [
          TRANCHE_FIELDS.IS_EXCLUDED.name,
          DEAL_PROFILE_FIELDS.PRIMARY_REGION.name,
        ].includes(field.name)
      ) {
        delete result[field.name];
        return result;
      }

      // Tranche's CUSIPS or ISINS codes are fetched and sent as arrays, but input as strings.
      if (
        [
          TRANCHE_FIELDS.CUSIPS.name,
          TRANCHE_FIELDS.CUSIPS_REGS.name,
          TRANCHE_FIELDS.ISINS.name,
        ].includes(field.name)
      ) {
        result[field.name] = !values[field.name]
          ? []
          : values[field.name]
            .replace(REGULAR_EXPRESSIONS.SYMBOL_SPACE, '')
            .split(REGULAR_EXPRESSIONS.SYMBOL_COMMA_OR_VERTICAL_BAR_OR_SEMICOLON);
        return result;
      }

      // Tranche's RATINGS property's value is an object
      // where the keys are agencies' ids and values are arrays with a single element - rating id.
      if (field.name === TRANCHE_FIELDS.RATINGS.name) {
        result[field.name] = Object.keys(values[field.name]).reduce(
          (ratings, currentAgencyAbbreviation) => {
            const ratingAgency = ratingAgencies.find((agency) => agency.abbreviation === currentAgencyAbbreviation);
            const selectedRating = values[field.name][currentAgencyAbbreviation];
            return (
              {
                ...ratings,
                ...(selectedRating && { [ratingAgency.id]: [mapOptionToValue(selectedRating)] }),
              }
            );
          },
          {},
        );
        return result;
      }

      if (field.name === DEAL_PROFILE_FIELDS.RELATIVE_VALUE.name) {
        result[field.name] = values[field.name] === editorInitialValue ? null : values[field.name];
        return result;
      }

      if (field.isSelect || field.issuerSelect) {
        result[field.name] = mapOptionToValue(values[field.name]);
        return result;
      }

      if (field.isMultiSelect || field.companySelect) {
        result[field.name] = values[field.name] ? values[field.name].map((val) => mapOptionToValue(val)) : [];
        return result;
      }

      if (field.isDate) {
        result[field.name] = mapDateToValue(values[field.name]);
        return result;
      }

      if (field.isNumber) {
        result[field.name] = values[field.name]
          ? Number(values[field.name])
          : null;
        return result;
      }

      if (field.isBoolean) {
        result[field.name] = values[field.name];
        return result;
      }

      if (field.isRadio) {
        result[field.name] = isBoolean(values[field.name]) ? values[field.name] : null;
        return result;
      }

      result[field.name] = values[field.name] || null;
      return result;
    },
    {},
  );

  return processSubmitValues({ values: formData, fields: DEAL_PROFILE_FIELDS_ARRAY, trancheIndex: null });
};

export default getDealSubmitPayload;
