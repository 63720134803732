import React, { useEffect } from 'react';
import cn from 'classnames';
import Head from 'next/head';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManagerAnalyticsContactsByFilter,
} from '@/condor/application/actions/finsightManager/analytics/contactsCollection/actions';
import * as selectors from '@/condor/application/actions/finsightManager/analytics/contactsCollection/selectors';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';

import ContactsTable from './ContactsTable';
import ContactsTablePaginator from './ContactsTable/ContactsTablePaginatorContainer';
import columns from './ContactsTable/columns';
import { BI_MODULE } from '@/condor/ui/components/FinsightManager/constants';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const BIContacts = () => {
  const dispatch = useDispatch();
  const contactTotalCount: number = useSelector(selectors.getTotalCount);

  useEffect(() => {
    dispatch(getManagerAnalyticsContactsByFilter(BI_MODULE));
  }, []);

  return (
    <>
      <Head>
        <title>B&I Manager | Deal Manager Portal</title>
      </Head>
      <div className={ cardStyles.cardContainer }>
        <div className={ cn(cardStyles.cardInner, spacesStyles.pbn) }>
          <div className={ cardStyles.cardHeader }>
            <div className={ cardStyles.cardHeaderTitle }>
              <h3>Contact Analytics</h3>
            </div>
            <div className={ cardStyles.cardHeaderActions }>
              Total Contacts: { contactTotalCount }
            </div>
          </div>
        </div>
        <ContactsTable
          className={ dataTableStyles.isHoverable }
          loadingComponent={ Spinner }
          columns={ columns }
        />
        <ContactsTablePaginator className={ cardStyles.cardInner } dataTest="analyticsBi" />
      </div>
    </>
  );
};

export default BIContacts;
