import React from 'react';
import cn from 'classnames';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import columns from './columns';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';

import styles from './expandableRowContent.scss';

interface IProps {
  rowData: { [key: string]: any },
}

const MapTradeExpandableRowContent = ({ rowData }: IProps) => {
  const { tranches } = rowData;

  return (
    <TableWrapper className={ styles.dataTableWrapper }>
      <DataTable
        className={ cn(dataTableStyles.dataTable, styles.dataTable) }
        dataTest="mapTradeExpandableDataTable"
        data={ tranches }
        columns={ columns }
      />
    </TableWrapper>
  );
};

export default MapTradeExpandableRowContent;
