import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import Link from '@/Framework/Router/ReactRouter/Link';
import SettingsFormFields from './SettingsFormFields';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';

import { CONDOR_EVERCALL_SETTINGS_FORM } from './constants';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';

import { ISettingsFormValues } from './interfaces';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';

import { useCallProfileContext } from '@/evercall/application/condor/call/profile/CallProfileContext';
import useCallSettings from '@/evercall/application/condor/call/profile/settings/useCallSettings';
import validate from './validate';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import { addHttpToUrl } from '@/Framework/url/helpers';

import styles from './settings.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import callProfileStyles from '../callProfile.scss';

const Settings = () => {
  const [isConferenceIdModalVisible, setIsConferenceIdModalVisible] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    state: {
      item,
      isFetching,
    },
    setItem,
  } = useCallProfileContext();

  const {
    state: {
      isUpdating,
      isUpdateSucceeded,
    },
    updateCallData,
    fetchGroupsSuggestions,
  } = useCallSettings({ setItem });

  const closeConferenceIdModal = () => setIsConferenceIdModalVisible(false);
  const getSubmitPayload = (values: ISettingsFormValues): IAdminCall => (
    {
      ...values,
      participantConferenceId: values.participantConferenceId || null,
      roadshowId: values.roadshowId || null,
      groupId: mapOptionToValue(values.groupId) as (string | null),
      roadshowEntryCode: values.roadshowEntryCode || null,
      replayUrl: values.replayUrl ? addHttpToUrl(values.replayUrl) : null,
    }
  );

  const submit = (values: ISettingsFormValues) => {
    if (!!item.participantConferenceId && !values.participantConferenceId) {
      setIsConferenceIdModalVisible(true);
      return null;
    }
    return updateCallData(getSubmitPayload(values));
  };

  const isFormVisible = !isFetching && !isFirstRender;

  useEffect(
    () => {
      setIsFirstRender(false);
    },
    [],
  );

  useEffect(
    () => {
      if (isUpdating && isConferenceIdModalVisible) {
        closeConferenceIdModal();
      }
    },
    [isUpdating],
  );

  return (
    <ProfileWrapper>
      <div className={ cn(headerStyles.isH3, styles.panelHeaderLabel) }>Call Settings</div>
      <Spinner
        isVisible={ !isFormVisible || isUpdating }
        overlay={ isUpdating }
        isFixed={ isUpdating }
      />
      { isFormVisible && (
        <FinalForm<ISettingsFormValues>
          name={ CONDOR_EVERCALL_SETTINGS_FORM }
          dataTest={ CONDOR_EVERCALL_SETTINGS_FORM }
          validate={ validate }
          className={ formStyles.formWrp }
          onSubmit={ submit }
          render={ (
            {
              errors,
              pristine,
              values,
            },
          ) => (
            <>
              <SettingsFormFields
                isUpdating={ isUpdating }
                isUpdateSucceeded={ isUpdateSucceeded }
                fetchGroupsSuggestions={ fetchGroupsSuggestions }
              />
              <div className={ callProfileStyles.formButtonsContainer }>
                <Button
                  type="submit"
                  variant={ ButtonVariantType.action }
                  title="Save"
                  dataTest="evercallProfileFormSaveButton"
                  disabled={ !isEmpty(errors) || pristine || isUpdating }
                />
                <Link to={ CONDOR_EVERCALL_LIST }>
                  <Button
                    variant={ ButtonVariantType.text }
                    title="Cancel"
                    dataTest="evercallProfileFormFormCancelButton"
                  />
                </Link>
                <AreYouSureModal
                  when={ !pristine && !isUpdating && !isUpdateSucceeded }
                  disableNative={ false }
                />
                <Modal
                  className={ styles.removedConferenceIdModal }
                  onCloseClicked={ closeConferenceIdModal }
                  isVisible={ isConferenceIdModalVisible }
                  title="Are you sure?"
                  dataTest="conferenceIdRemovedModal"
                  footer={ (
                    <>
                      <Button
                        onClick={ () => updateCallData(getSubmitPayload(values)) }
                        variant={ ButtonVariantType.warning }
                        title="Delete"
                        dataTest="deleteButton"
                      />
                      <Button
                        variant={ ButtonVariantType.text }
                        onClick={ closeConferenceIdModal }
                        title="Cancel"
                        dataTest="cancelButton"
                      />
                    </>
                  ) }
                >
                  <div className={ styles.removedConferenceIdWarningContainer }>
                    Removing the Participant Conference ID will erase the Pre-Registration list on the DM Portal.
                    If a new Participant ID is saved, the Pre-Registration list will re-sync with the new ID.
                  </div>
                </Modal>
              </div>
            </>
          ) }
        />
      ) }
    </ProfileWrapper>
  );
};

export default Settings;
