import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { components } from 'react-select';
import { Select } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import { SelectOptionWithCountryFlag } from '@dealroadshow/uikit/core/components/Select';
import CountryFlag from '@dealroadshow/uikit/core/components/CountryFlag';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import styles from '@/finsight/ui/components/SubscriptionForm/styles.scss';

const CountrySelect = ({ disabled }: { disabled?: boolean }) => {
  const { options, countriesOptions } = useCommentaryDealFlowContext();

  const { isFetchingCountries } = options;

  const getMultiValue = (props) => {
    const countryCode = props?.data?.countryCode?.toLowerCase()
    // @ts-ignore
    || countriesOptions.find((option) => option.value === props.data.value)?.countryCode?.toLowerCase();
    return (
      <components.MultiValue { ...props }>
        <CountryFlag countryCode={ countryCode } className={ styles.flagIcon } />
        { props?.data?.label }
      </components.MultiValue>
    );
  };

  return (
    <Field
      isLoading={ isFetchingCountries }
      name="articleParams.countryIds"
      component={ Select }
      label="Select Countries"
      placeholder="Select countries"
      dataTest="commentaryDealFlowSelectCountries"
      isMulti
      isDisabled={ disabled }
      options={ countriesOptions }
      components={ {
        Option: SelectOptionWithCountryFlag,
        MultiValue: getMultiValue,
      } }
    />
  );
};

export default CountrySelect;
