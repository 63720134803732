import { IPreviewAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';
import getFileExtension from '@/Framework/dataHelpers/string/getFileExtension';
import mapFileExtensionToIconType from '@/Framework/dataHelpers/mapFileExtensionToIconType';
import { mapExtensionToColor } from '@/Framework/dataHelpers/color';

import styles from './styles.scss';

const AttachmentItem = ({ attachment }: { attachment: IPreviewAttachment }) => {
  const extension = getFileExtension(attachment.displayName);

  const Icon = mapFileExtensionToIconType(extension);
  const color = mapExtensionToColor(extension);
  return (
    <p key={ attachment.uploadId } className={ styles.attachmentItem } data-test="previewFileItem">
      <Icon style={ { fill: color } } />
      <p className={ styles.attachmentItemName } data-test="previewFileName">{ attachment.displayName }</p>
    </p>
  );
};

export default AttachmentItem;
