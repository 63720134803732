import { connect } from 'react-redux';

import selectors from '@/condor/application/actions/accounts/mappings/allocations/selectors';
import {
  itemsPerPage,
  getMappingsAllocationsByFilter,
  paginate,
  sort,
  resetMappingsAllocationsCollection,
} from '@/condor/application/actions/accounts/mappings/allocations/actions';

import BaseMappingsPanel from '../BaseMappingsPanel';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import { platformCellCallback } from '../cellCallbacks/platformCellCallback';
import { uploadedCellCallback } from '../cellCallbacks/uploadedCellCallback';

const columns = [
  {
    name: 'name',
    title: 'Name',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 320,
  },
  {
    name: 'platform',
    title: 'Platform',
    cellCallback: platformCellCallback,
    className: alignStyles.alignLeft,
    width: 310,
  },
  {
    name: 'isUploaded',
    title: 'Uploaded state',
    cellCallback: uploadedCellCallback,
    className: alignStyles.alignLeft,
  },
];

const mapStateToProps = (state) => (
  {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    totalCount: selectors.getTotalCount(state),
    columns,
    dataTableDataTest: 'allocationsMappingsTable',
    paginatorDataTest: 'allocationsMappingsTablePaginator',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    getCollection: () => {
      dispatch(getMappingsAllocationsByFilter());
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getMappingsAllocationsByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getMappingsAllocationsByFilter());
    },
    reset: () => {
      dispatch(resetMappingsAllocationsCollection());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getMappingsAllocationsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseMappingsPanel);
