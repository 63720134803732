import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateCallName } from '@/evercall/ui/common/CallForm/helpers/validateCallDetailsFields';
import validateConferenceId from '@/evercall/ui/common/Oacc/validateConferenceId';
import { validateRegExp } from '@/Framework/UI/Organisms/FinalForm/validators/validateRegExp';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateAlphanumeric } from '@/Framework/UI/Organisms/FinalForm/validators/validateAlphanumeric';
import { ISettingsFormValues } from './interfaces';
import CallEvent from '@/evercall/domain/CallEvent';
import { validateLink } from '@/Framework/UI/Organisms/FinalForm/validators/validateLink';

const replayUrlField = {
  fieldName: 'replayUrl',
  fieldCaption: 'URL',
};

const validateReplayUrl = (value) => groupValidators(
  validateLink({
    ...replayUrlField,
    value,
  }),
  validateMaxLength({
    ...replayUrlField,
    length: 256,
    value,
  }),
);

export default (values: ISettingsFormValues) => groupValidators(
  validateCallName(values),
  validateConferenceId(
    {
      fieldName: 'participantConferenceId',
      value: values.participantConferenceId,
      fieldCaption: 'Conference ID',
    },
  ),
  values.roadshowId && validateRegExp(
    {
      fieldName: 'roadshowId',
      fieldCaption: 'Roadshow ID',
      value: values.roadshowId,
      expression: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    },
  ),
  validateMinLength({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
    length: 3,
  }),
  validateMaxLength({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
    length: 32,
  }),
  validateAlphanumeric({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
  }),
  CallEvent.isReplayViaUrl(values.replayMethod) && validateReplayUrl(values.replayUrl),
);
