import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';

import { Field, useForm, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import InputTags from '@/Framework/UI/Molecules/Form/InputTags';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import IconWarning from '@dealroadshow/uikit/core/components/Icon/IconWarning';

import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';

import FooterModal from './FooterModal';

import PlansPanel from '@/condor/ui/components/FinsightManager/ManagePlans/common/PlansPanel';
import TeamPanel from '@/condor/ui/components/FinsightManager/ManagePlans/common/TeamPanel';
import { PreviewText } from '@/condor/ui/components/FinsightManager/ManagePlans/common/PreviewText';
import { RequestSubmitType } from '@/condor/application/FinsightManager/ManagePlans/constants';
import {
  IEmailTag,
  useAddSubscribersFormContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm';
import {
  useCheckEmailsSubscriptionContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';

import { mapPlanToIcon } from './planIndicators';
import { mapPlanToPlanWithIndicator } from './countersWithIndicator';
import { UserPlanType } from '@/users/domain/UserPlan';

import styles from './styles.scss';

const validate = (value: string) => validateEmail({
  value,
  fieldName: 'tag',
});

const initialStatuses = {
  pro: 0,
  premium: 0,
  starter: 0,
};

const AddSubscribersForm = () => {
  const [emailsToShow, setEmailsToShow] = useState<IEmailTag[]>([]);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [emailsWithStatus, setEmailsWithStatus] = useState(initialStatuses);
  const addSubscribersForm = useForm('addSubscribersForm');
  const {
    values: {
      emailText,
      plan,
      isExportEnabled,
    },
  } = useFormState();
  const {
    onClose,
    selectedEmails,
    isAddSubscribersModalVisible,
    isCustomMessageBlockVisible,
    isSubmitting,
    setSelectedEmails,
  } = useAddSubscribersFormContext();

  const {
    checkedEmails,
    isChecking,
    checkNewEmails,
  } = useCheckEmailsSubscriptionContext();

  useEffect(() => {
    if (plan !== UserPlanType.PRO) {
      addSubscribersForm.change('isExportEnabled', false);
    }
  }, [plan]);

  const onIsExportEnabledChange = (e) => {
    addSubscribersForm.change('isExportEnabled', e.target.checked);
  };

  useEffect(() => {
    if (!isAddSubscribersModalVisible) {
      setEmailsToShow([]);
      setActiveSection(null);
      setEmailsWithStatus(initialStatuses);
      addSubscribersForm.reset();
    }
  }, [isAddSubscribersModalVisible]);

  useEffect(() => {
    const handledEmails = selectedEmails.map((email): IEmailTag => {
      if (!email.isValid) return email;
      const groups = Object.keys(checkedEmails);
      groups.forEach((group) => {
        checkedEmails[group].forEach((checkedEmail: string) => {
          if (checkedEmail.toLowerCase() === email.value.toLowerCase()) {
            email.userPlan = group.toLowerCase();
            email.customIcon = mapPlanToIcon[group.toLowerCase()];
          }
        });
      });
      return email;
    });
    setEmailsToShow(handledEmails);
  }, [checkedEmails, selectedEmails]);

  useEffect(() => {
    setEmailsWithStatus(() => {
      const newCounters = { ...initialStatuses };

      emailsToShow.forEach((email) => {
        if (email.userPlan) {
          newCounters[email.userPlan]++;
        }
      });

      return newCounters;
    });
  }, [emailsToShow]);

  const onEmailsChange = (val: IEmailTag[], newVal: IEmailTag[]) => {
    addSubscribersForm.change('emails', val);
    setSelectedEmails(val);
    checkNewEmails(newVal);
  };

  return (
    <Modal
      title="Add Subscribers"
      isVisible={ isAddSubscribersModalVisible }
      dataTest="addSubscribersModal"
      handleCloseClick={ onClose }
      className={ styles.modalContainer }
      footer={ (
        <FooterModal />
      ) }
    >
      <div className={ styles.modalBody }>
        { !isCustomMessageBlockVisible && (
          <div className={ styles.modalForm }>
            <div
              className={ cn(styles.section, {
                [styles.activeSection]: activeSection === 1,
              }) }
              onClick={ () => setActiveSection(1) }
            >
              <h3 className={ styles.title }>Step 1: Add Subscribers</h3>
              { Object.values(emailsWithStatus)
                .some((counter) => !!counter) && (
                <div className={ styles.warningBlock }>
                  <IconWarning className={ styles.warningIcon } />
                  <p>Existing Subscribers:</p>
                  { !!emailsWithStatus.pro && (
                    mapPlanToPlanWithIndicator[UserPlanType.PRO](emailsWithStatus.pro)
                  ) }
                  { !!emailsWithStatus.premium && (
                    mapPlanToPlanWithIndicator[UserPlanType.PREMIUM](emailsWithStatus.premium)
                  ) }
                  { !!emailsWithStatus.starter && (
                    mapPlanToPlanWithIndicator[UserPlanType.STARTER](emailsWithStatus.starter)
                  ) }
                </div>
              ) }
              <InputTags
                errorMessage="Some emails are invalid"
                tags={ emailsToShow }
                dataTest="addSubscribersEmailsInput"
                validate={ validate }
                isFetching={ isChecking }
                isDisabled={ isChecking }
                onChange={ onEmailsChange }
              />
              <p className={ styles.emailsHint }>You can enter several emails separating by comma, semicolon or
                space
              </p>
            </div>
            <div
              className={ cn(styles.section, {
                [styles.activeSection]: activeSection === 2,
              }) }
              onClick={ () => setActiveSection(2) }
            >
              <h3 className={ styles.title }>Step 2: Assign Plan</h3>
              <PlansPanel
                plan="All"
                name="plan"
                dataTest="addSubscribersFormUserPlan"
                withTag={ false }
                labelClassName={ styles.label }
              />
            </div>
            <div
              className={ cn(styles.section, {
                [styles.activeSection]: activeSection === 3,
              }) }
              onClick={ () => setActiveSection(3) }
            >
              <h3 className={ styles.title }>Step 3: Additional Features</h3>
              <Tooltip
                disabled={ plan === UserPlanType.PRO }
                content="Contacts must be subscribed to the Pro plan to activate Export to XLS."
                maxWidth={ 248 }
              >
                <Checkbox
                  disabled={ plan !== UserPlanType.PRO }
                  label="Export to XLS"
                  checked={ isExportEnabled }
                  onChange={ onIsExportEnabledChange }
                  dataTest="addSubscribersExportXLS"
                />
              </Tooltip>
            </div>
            <div
              className={ cn(styles.section, {
                [styles.activeSection]: activeSection === 4,
              }) }
              onClick={ () => setActiveSection(4) }
            >
              <h3 className={ styles.title }>Step 3: Assign Teams (Optional)</h3>
              <TeamPanel
                labelText="team"
                name="team"
                dataTest="addSubscribersTeamName"
              />
            </div>
          </div>
        ) }
        { isCustomMessageBlockVisible && (
          <>
            <div className={ styles.fieldWrapper }>
              <label className={ styles.fromFieldLabel }>From:</label>
              <div className={ styles.fromField }>
                Finsight Support<span className={ styles.fromFieldEmail }>{ ' <support@finsight.com>' }</span>
              </div>
            </div>
            <div className={ styles.fieldWrapper }>
              <label className={ styles.topFieldLabel }>BCC:</label>
              <InputTags
                wrapperClassName={ styles.topInput }
                tags={ selectedEmails.map((email) => ({
                  label: email.label,
                  value: email.value,
                  isValid: email.isValid,
                })) }
                isDisabled
                dataTest="addSubscribersFormEmailField"
              />
            </div>
            <div className={ styles.fieldWrapper }>
              <label className={ styles.topFieldLabel }>Subject:</label>
              <Field
                name="emailSubject"
                formFieldClassName={ styles.topInput }
                component={ Input }
                dataTest="addSubscribersFormSubjectField"
                initialValue="Welcome to Finsight.com! Your subscription plan has been updated."
              />
            </div>
            <Field
              isFocused
              name="emailText"
              component={ RichTextInput }
              containerCls={ styles.editor }
              /* Please do not do this. Instead, use Textarea and apply 'white-space: pre-line' to the div where
               * the submitted text is being inserted via dangerouslySetInnerHTML tag. */
              toolbarComponent={ () => null }
              placeholder="Type text here..."
              label="custom message text:"
              dataTest="addSubscribersFormMessageField"
            />
            <PreviewText
              type={ RequestSubmitType.UPGRADE }
              emailText={ emailText === '<p></p>' ? '' : emailText }
              planName={ plan }
            />
          </>
        ) }
      </div>
      { isSubmitting && (<Spinner overlay isAbsolute />) }
    </Modal>
  );
};

export default memo(AddSubscribersForm);
