import React from 'react';

import { Field, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import {
  useEnhancedTaggingCondorContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import { isSectorAvailableForTransactionType } from '@/dealroadshow/domain/TransactionTypes';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { TruncateTextWithTooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';

import spaces from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const SectorCallback = ({
  row: {
    subsectorId,
    id,
  },
  rowIndex,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();
  const { sectorOptions } = useEnhancedTaggingCondorContext();
  const { transactionTypeId } = useFormState().values.editableTableArray[rowIndex];

  if (!isSectorAvailableForTransactionType(transactionTypeId)) {
    return 'N/A';
  }

  if (editableRowId === id) {
    return (
      <Field
        name={ `${ fieldRowName }.subsectorId` }
        placeholder="ABS Sector & Subsector"
        formFieldClassName={ spaces.mbn }
        // @ts-ignore
        component={ FinalFormSelect }
        simpleValue
        asterisk
        options={ sectorOptions }
        dataTest={ `subsector[${ rowIndex }]` }
      />
    );
  }

  if (!subsectorId) {
    return '-';
  }

  const sectorName = sectorOptions.find((item) => item.value === subsectorId)?.label;

  return <TruncateTextWithTooltip value={ sectorName } />;
};

export default SectorCallback;
