import React, { useState, useMemo } from 'react';

import {
  useEnhancedTaggingRoadshowsContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import { useFormState, useForm } from '@/Framework/UI/Organisms/FinalForm';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import UnderwritersModal, { IFormData } from '@/condor/ui/common/EnhancedTagging/UnderwritersModal';

import styles from '../roadshows.scss';

const truncateName = (value: string, minLength = 3, maxLength = 6): string => {
  if (value.length <= maxLength) {
    return value;
  }

  if (value.length <= minLength) {
    return value;
  }

  let lastSpace = value.lastIndexOf(' ', maxLength);

  if (lastSpace < minLength) {
    lastSpace = maxLength;
  }

  return `${ value.substring(0, lastSpace) }...`;
};

const BULK_TABLE_NAME = 'bulkEditingForm';

const UnderwritersCallback = ({
  cellName,
  customCellProps,
  rowIndex,
  row,
}: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const {
    updateUnderwriters,
    editableRowId,
  } = useEnhancedTaggingRoadshowsContext();
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { values } = useFormState();
  const { change } = useForm();
  const underwriters = values.editableTableArray[rowIndex][cellName];
  const uwAbbrList = useMemo(() => {
    if (!underwriters.length) {
      return '-';
    }

    const [{
      abbreviation,
      name,
    }] = underwriters;

    const fullName = abbreviation || name;
    const truncatedName = truncateName(fullName);

    if (underwriters.length === 1) {
      return truncatedName;
    }

    if (fullName !== truncatedName) {
      return truncatedName;
    }

    return `${ truncatedName }, ...`;
  }, [underwriters]);

  const shouldBeSavedImmediately = editableRowId !== row.id && customCellProps.formProps.id !== BULK_TABLE_NAME;

  const uwFullNames = underwriters.map((item) => item.name)
    .join(', ');

  const onSubmitHandler = async (enhancedTaggingUnderwriters: IFormData[]) => {
    change(`${ fieldRowName }.${ cellName }`, enhancedTaggingUnderwriters);
    if (shouldBeSavedImmediately) {
      await updateUnderwriters(row.id, enhancedTaggingUnderwriters);
    }
  };

  return (
    <>
      <div>
        <Tooltip content={ uwFullNames } disabled={ !uwFullNames.length }>
          <Button
            variant={ ButtonVariantType.link }
            dataTest="underwritersModalCall"
            onClick={ () => setIsVisibleModal(true) }
          >
            <span className={ styles.underwriters }>{ uwAbbrList }</span>
          </Button>
        </Tooltip>
      </div>
      { isVisibleModal && (
        <UnderwritersModal
          isVisible={ isVisibleModal }
          setIsVisible={ setIsVisibleModal }
          collection={ underwriters }
          onSubmit={ onSubmitHandler }
        />
      ) }
    </>
  );
};

export default UnderwritersCallback;
