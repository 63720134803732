import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useFormState, useForm } from '@/Framework/UI/Organisms/FinalForm';
import { getStatDataForPreview } from './helpers';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useStatProfileContext } from '../StatProfileContext';
import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { IStatPreviewData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStatPayload';

const useStatPreview = (
  closePreviewModal: () => void,
  ) => {
  const [isFetching, setIsFetching] = useState(false);
  const [previewData, setPreviewData] = useState<IStatPreviewData>(null);
  const { currentUser } = useSessionContext();

  const { values } = useFormState<StatFormData>();
  const { change, submit } = useForm();
  const { initialStat, isEdit } = useStatProfileContext();

  const getPreviewData = () => {
      setPreviewData(getStatDataForPreview(values, isEdit, initialStat, currentUser));
  };

  const publishStat = async () => {
    setIsFetching(true);
    const hasSubmitError = await submit();
    if (hasSubmitError) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    change('isActive', true);
    getPreviewData();
    return () => {
      change('isActive', false);
    };
  }, []);

  return {
    isFetching,
    previewData,
    publishStat,
    closePreviewModal,
  };
};

type StatPreviewContextType = ReturnType<typeof useStatPreview>;

const StatPreviewContext = createContext<StatPreviewContextType>(null);

export const useStatPreviewContext = () => {
  const context = useContext(StatPreviewContext);

  if (!context) {
    throw new Error('useStatPreviewContext must be used within the StatPreviewContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  closePreviewModal: () => void,
}

const StatPreviewContextProvider = ({ children, closePreviewModal }: IProps) => (
  <StatPreviewContext.Provider value={ useStatPreview(closePreviewModal) }>
    { children }
  </StatPreviewContext.Provider>
);

export default StatPreviewContextProvider;
