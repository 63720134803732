import IconExpand from '@dealroadshow/uikit/core/components/Icon/IconExpand';
import IconCollapse from '@dealroadshow/uikit/core/components/Icon/IconCollapse';
import { TRANCHE_GROUPS_LABELS, TrancheGroup } from '@/condor/ui/components/Finsight/DealProfile/constants';
import Property from '@/condor/ui/components/Finsight/DealProfile/Tranches/Properties/Property';
import { useTranchesContext } from '@/condor/application/Finsight/DealProfile/TranchesContext';
import { IDealProfileFormField } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import RatingsMoreButton from '@/condor/ui/components/Finsight/DealProfile/RatingsMoreButton';

import styles from '@/condor/ui/components/Finsight/DealProfile/Tranches/Properties/properties.scss';

interface IProps {
  group: TrancheGroup,
  propertiesByGroup: IDealProfileFormField[],
}

const PropertyGroup = ({ group, propertiesByGroup }: IProps) => {
  const {
    toggleTrancheGroupIsCollapsed,
    collapsedGroup,
  } = useTranchesContext();
  const Icon = collapsedGroup.includes(group) ? IconExpand : IconCollapse;

  return (
    <div className={ styles.propertiesGroup }>
      <Icon
        isSmall
        className={ styles.expandIcon }
        onClick={ () => toggleTrancheGroupIsCollapsed(group) }
      />
      <div className={ styles.propertyGroupTitle }>
        { TRANCHE_GROUPS_LABELS[group] }
      </div>
      { propertiesByGroup.map(
        (item: IDealProfileFormField) => (
          <Property
            key={ item.name }
            { ...item }
          />
        )) }
      <RatingsMoreButton group={ group } />
      <div className={ styles.stripe } />
    </div>
  );
};

export default PropertyGroup;
