import React, { useEffect } from 'react';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import styles from './companyCreateModal.scss';
import { CompaniesProfileForm } from '@/condor/ui/components/Finsight/Companies/CompanyProfile/CompaniesProfileForm';

interface IProps {
  item: any,
  activeColumnName: string,
  additionalValidateFields?: any,
  industries: any[],
  onClose: () => void,
  onSelect: () => void,
  onSubmit: () => void,
  init: () => void,
  reset: () => void,
}

const CompanyCreateModal = (
  {
    item,
    activeColumnName,
    additionalValidateFields = {},
    industries,
    onClose,
    onSelect,
    onSubmit,
    init,
    reset,
  }: IProps,
) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <Modal
      title={ `Create [${ activeColumnName }] company` }
      isVisible
      onCloseClicked={ onClose }
      className={ styles.companyCreateModal }
      dataTest="createCompanyModal"
    >
      <div className={ styles.migrateContent }>
        <CompaniesProfileForm
          isCreateCompanyModal
          item={ item }
          industries={ industries }
          onSelect={ onSelect }
          onSubmit={ onSubmit }
          // @ts-ignore
          onCancelClick={ onClose }
          additionalValidateFields={ additionalValidateFields }
        />
      </div>
    </Modal>
  );
};

export default CompanyCreateModal;
