import React from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';
import Button from './Button';
import { TToolbarItemFormat } from '../interfaces';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  format: TToolbarItemFormat,
  icon: IconComponentType,
  className?: string,
  activeClassName?: string,
}

const MarkButton = ({
  format,
  icon: Icon,
  className,
  activeClassName,
}: IProps) => {
  const editor = useSlate();
  const isMarkActive = (): boolean => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };
  return (
    <Button
      activeClassName={ activeClassName }
      active={ isMarkActive() }
      format={ format }
      className={ className }
      onMouseDown={ (e) => {
        e.preventDefault();
        const isActive = isMarkActive();

        if (isActive) {
          Editor.removeMark(editor, format);
        } else {
          Editor.addMark(editor, format, true);
        }
      } }
    >
      <Icon />
    </Button>
  );
};

export default MarkButton;
