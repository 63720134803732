import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import AreYouSureModal from '@/condor/ui/components/Finsight/CreditFlowResearch/common/AreYouSureModal';
import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST } from '@/condor/ui/common/constants';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';
import UnpublishModal from './Modals/UnpublishModal';
import PreviewModal from './Modals/PreviewModal';
import ProfileButtons from '@/condor/ui/components/Finsight/CreditFlowResearch/common/ProfileButtons';

const StatProfileButtons = () => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isUnpublishOpen, setIsUnpublishOpen] = useState(false);
  const { push } = useHistory();
  const { invalid, dirty, submitting, submitSucceeded } = useFormState();
  const { isAttachmentUploaded, isPreviewUploaded, isEdit, isActive } = useStatProfileContext();

  const isDisabledCommon = invalid || submitting || !isAttachmentUploaded || !isPreviewUploaded;
  const isDisabled = isDisabledCommon || !dirty;
  const isDisabledPublish = () => {
    if (isEdit) {
      return isActive ? isDisabled : isDisabledCommon;
    }
    return isDisabled;
  };

  const openPreview = useCallback(() => {
    setIsPreviewOpen(true);
  }, []);
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
  }, []);

  const openUnpublish = useCallback(() => {
    setIsUnpublishOpen(true);
  }, []);

  const closeUnpublish = useCallback(() => {
    setIsUnpublishOpen(false);
  }, []);

  const onCancelClick = useCallback(() => {
    push(CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST);
  }, []);

  return (
    <>
      <ProfileButtons
        isEdit={ isEdit }
        isActive={ isActive }
        isDisabled={ isDisabled }
        isDisabledPublish={ isDisabledPublish() }
        isCancelDisabled={ submitting }
        isDisabledUnpublish={ submitting }
        onPreviewAndPublishClick={ openPreview }
        onCancelClick={ onCancelClick }
        onUnpublishClick={ openUnpublish }
      />
      <AreYouSureModal
        when={ dirty && !submitting && !submitSucceeded }
        disableNative={ false }
      />
      { isUnpublishOpen && (
        <UnpublishModal closeUnpublishModal={ closeUnpublish } />
      ) }
      { isPreviewOpen && (
        <PreviewModal closePreviewModal={ closePreview } />
      ) }
    </>
  );
};

export default StatProfileButtons;
