import groupBy from 'lodash/groupBy';
import {
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
  TRANCHE_FIELDS,
  TrancheGroup,
} from '../constants';

/**
 * @param {Array} ratingAgencies
 * @return {Array}
 */
const getDealTrancheProperties = (ratingAgencies) => {
  const ratingsPropertyIndex = DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.findIndex(
    (prop) => (prop.name === TRANCHE_FIELDS.RATINGS.name),
  );

  return groupBy([
    ...DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.slice(0, ratingsPropertyIndex),
    ...ratingAgencies.map((agency) => (
      {
        name: agency.abbreviation,
        label: agency.displayName,
        groupId: TrancheGroup.RATING_AGENCIES,
      }
    )),
    ...DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.slice(ratingsPropertyIndex + 1),
  ], (prop) => prop.groupId);
};

export default getDealTrancheProperties;
