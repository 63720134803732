import React from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useForm } from '@/Framework/UI/Organisms/FinalForm';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { DetailsSection } from '@/evercall/domain/vo/DetailsSection';

import SectionItem from './SectionItem';
import { DETAILS_SECTIONS } from './constants';

import styles from './detailsSections.scss';

const DetailsSections = ({
  fields: {
    name,
    value: detailsOrder = [],
    move: moveDetailsOrder,
  },
}: IFinalFormFieldArrayInputComponentProps<DetailsSection>) => {
  const form = useForm();
  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      const oldIndex = Number(active.id);
      const newIndex = Number(over.id);

      moveDetailsOrder(oldIndex, newIndex);
      form.blur(name);
    }
  };

  const sections = detailsOrder.map(
    (sectionName, index) => {
      const section = DETAILS_SECTIONS.find(({ id }) => isEqual(id, sectionName));
      return (
        <SectionItem
          key={ sectionName }
          rowCls={ cn({ [styles.lastSectionItemRow]: index === (detailsOrder.length - 1) }) }
          uniqueIdentifier={ index.toString() }
        >
          { section.renderSection() }
        </SectionItem>
      );
    },
  );

  return (
    <div className={ styles.detailsSectionsContainer }>
      <DndContext
        modifiers={ [restrictToParentElement] }
        onDragEnd={ onDragEnd }
        collisionDetection={ closestCenter }
        sensors={ sensors }
        measuring={ {
          droppable: {
            strategy: MeasuringStrategy.WhileDragging,
          },
        } }
      >
        <div>
          <SortableContext
            items={ detailsOrder.map((section, index) => index.toString()) }
            strategy={ verticalListSortingStrategy }
          >
            { sections }
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default DetailsSections;
