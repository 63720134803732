import React, { Component } from 'react';
import cn from 'classnames';
import { Field, FieldArray, reduxForm } from 'redux-form';
import isEqual from 'lodash/isEqual';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import Input from '@dealroadshow/uikit/core/components/Input';
import { Textarea } from '@dealroadshow/uikit/core/components/Textarea';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { PanelGroup, Panel, PanelArrow } from '@dealroadshow/uikit/core/components/PanelGroup';
import { FormField } from '@dealroadshow/uikit/core/components/Form/FormField';
import { InputLabel as Label } from '@dealroadshow/uikit/core/components/Input/Label';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';

import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import {
  ROADSHOW_FORM_DISCLAIMER,
} from '@/dealroadshow/ui/components/dmPortal/RoadshowForm/SecurityAndDisclaimerForm/constants';
import { ROADSHOW_FORM_DISCLAIMER_RETAIL_PUBLIC } from './constants';

import TransactionTypeContainer from '../components/TransactionType/TransactionTypeContainer';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import validate from './validators/dealSettingsGroupValidators';
import RetailTable from '../components/RetailTable';
import ActiveDates from '../components/ActiveDates/ActiveDates';
import ViewerSettings from '../components/ViewerSettings';
import RotatingEntryCodes from '../components/RotatingEntryCodes';
import ReviewEntryCode from '../components/ReviewEntryCode';
import RoadshowMappingForm from '../components/RoadshowMappingForm';
import { roadshowTypesOptions } from '@/condor/ui/components/DealRoadshows/Roadshow/roadshowTypesOptions';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import EntryCodeType from '@/dealroadshow/domain/EntryCodeType';
import RoadshowType, { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import * as uploadTypes from '@/Framework/UI/Organisms/Upload/uploadTypes';
import * as dealSettingsFormConstants from '../constants';
import Button, { ButtonVariantType, buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './dealSettingsForm.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const ENTRY_CODE_LOGIN_LIMIT_MAX_NUMBER = 100;
const LOGIN_LIMIT_DEFAULT_OPTION = {
  label: 'None',
  value: null,
};

const panelGroupArrow = (isOptional = true, isActive = false) => (
  <PanelArrow
    isActive={ isActive }
    isOptional={ isOptional }
  />
);

interface IProps {
  match: any,
  initialValues: any,
  isFetching: boolean,
  isPublic: boolean,
  viewerCustomizationEnabled: boolean,
  createTopButton: boolean,
  topButtonType: string,
  isViewerDisclaimerCustomizationEnabled: boolean,
  toggleIsLoginsMaxNumber: boolean,
  handleSubmit: () => void,
  getDealSettings: (roadshowId: string) => void,
  initUploadLogo: (logo: any) => void,
  initUploadDisclaimerLogo: (logo: any) => void,
  initUploadDisclaimerBackground: (logo: any) => void,
  resetDealSettings: () => void,
  industryList: any[],
  getIndustryList: () => void,
  absSectorList: any[],
  getAbsSectorList: () => void,
  getRoadshowMapping: (roadshowId: string) => void,
  getCdnList: () => void,
  changeEntryCodeLoginLimit: () => void,
  entryCodes: any[],
  uploadedLogo: any,
  currentLogoInForm: any,
  currentDisclaimerLogoInForm: any,
  currentDisclaimerBacgroundInForm: any,
  preSetColors: any,
  sortEntryCodes: () => void,
  type: RoadshowTypes,
  canBeChangedVideoType: boolean,
  hasEvercallError: boolean,
  setEvercallError: (error: boolean) => void,
  isRoadshowViewerCustomizeEnabled: boolean,
  viewerCustomSettings: any,
  viewerDisclaimerSettings: any,
  isCuePointsAdded: boolean,
  isTransactionTypeLocked: boolean,
  roadshowLoginLimit: any,
  change: (name: string, value: any) => void,
  uploadedDisclaimerLogo: any,
  uploadedDisclaimerBackgroundLogo: any,
  getAdminEvercallSetting: () => void,
  panelStartOpenState: any,
  cdnList: any[],
  pristine: boolean,
  reset: () => void,
}

class DealSettings extends Component<IProps, any> {
  constructor(props) {
    super(props);
    const getLoginLimitValues = () => {
      let loginLimitValues = [
        LOGIN_LIMIT_DEFAULT_OPTION,
      ];

      for (let loginLimitNumber = 1; loginLimitNumber <= ENTRY_CODE_LOGIN_LIMIT_MAX_NUMBER; loginLimitNumber++) {
        loginLimitValues.push({
          // @ts-ignore
          label: loginLimitNumber,
          value: loginLimitNumber,
        });
      }
      return loginLimitValues;
    };
    this.state = {
      loginLimitValues: getLoginLimitValues(),
      isEvercallTemplateEnabled: false,
      isEvercallTemplatePending: false,
    };
    this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleModeratedChange = this.handleModeratedChange.bind(this);
    this.calculateAndSetLoginLimitsAvailability = this.calculateAndSetLoginLimitsAvailability.bind(this);
    this.handleEvercallErrorChange = this.handleEvercallErrorChange.bind(this);
    this.handleGetEvercallData = this.handleGetEvercallData.bind(this);
    this.disabledEvercallTemplate = this.disabledEvercallTemplate.bind(this);
    this.handleActiveCustomiseViewer = this.handleActiveCustomiseViewer.bind(this);
    this.isModeratedDisabled = this.isModeratedDisabled.bind(this);
  }

  componentDidMount() {
    const { roadshowId } = this.props.match.params;
    this.props.getDealSettings(roadshowId);
    this.props.getRoadshowMapping(roadshowId);
    this.props.getCdnList();
    this.calculateAndSetLoginLimitsAvailability(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roadshowLoginLimit && this.props.roadshowLoginLimit === null) {
      this.props.change('loginsTimeLimit', null);
    }
    if (!isEqual(prevProps.initialValues, this.props.initialValues)
      && Object.keys(this.props.initialValues).length) {
      if (this.props.initialValues.retailTableLogo) {
        this.props.initUploadLogo(this.props.initialValues.retailTableLogo);
      }
      if (this.props.initialValues.disclaimerLogo) {
        this.props.initUploadDisclaimerLogo(this.props.initialValues.disclaimerLogo);
      }
      if (this.props.initialValues.disclaimerBackgroundImage) {
        this.props.initUploadDisclaimerBackground(this.props.initialValues.disclaimerBackgroundImage);
      }
      if (this.props.initialValues.loginsMaxNumber !== null) {
        this.props.change('isLoginsMaxNumber', true);
      }
    }
    if (!isEqual(prevProps, this.props)) {
      this.calculateAndSetLoginLimitsAvailability(this.props);
    }
    if (prevProps.isPublic && !this.props.isPublic) {
      let disclaimer = (
        ![
          ROADSHOW_FORM_DISCLAIMER,
          ROADSHOW_FORM_DISCLAIMER_RETAIL_PUBLIC,
        ].includes(this.props.initialValues.disclaimer)
      ) ?
        this.props.initialValues.disclaimer
        : ROADSHOW_FORM_DISCLAIMER;
      this.props.change('disclaimer', disclaimer);
    }

    if (
      EntryCodeType.isSingle(this.props.initialValues.appliedEntryCodeType) &&
      this.props.initialValues.singleEntryCodeCode !== null
    ) {
      this.props.change('singleEntryCodeCode', this.props.initialValues.singleEntryCodeCode);
    }
    if (prevProps.toggleIsLoginsMaxNumber !== this.props.toggleIsLoginsMaxNumber
      && this.props.toggleIsLoginsMaxNumber === false) {
      this.props.change('loginsMaxNumber', null);
    }
    if (!isEqual(prevProps.uploadedLogo, this.props.uploadedLogo)) {
      // we are checking only finished upload or delete events, not checking temporary conditions
      if ((this.props.uploadedLogo.process.status === uploadTypes.UPLOAD_DONE
          || this.props.uploadedLogo.process.status === '')
        // this is current value in redux form - dropzone is not observable by the form
        // so we need to track this event manually
        && typeof prevProps.currentLogoInForm !== 'undefined'
      ) {
        let updatedLogo = {
          ...prevProps.currentLogoInForm,
          id: this.props.uploadedLogo.process.uuid,
          url: this.props.uploadedLogo.url,
          name: this.props.uploadedLogo.name,
        };

        this.props.change('retailTableLogo', updatedLogo);
      }
    }
    if (!isEqual(prevProps.uploadedDisclaimerLogo, this.props.uploadedDisclaimerLogo)) {
      // we are checking only finished upload or delete events, not checking temporary conditions
      if ((this.props.uploadedDisclaimerLogo.process.status === uploadTypes.UPLOAD_DONE
          || this.props.uploadedDisclaimerLogo.process.status === '')
        // this is current value in redux form - dropzone is not observable by the form
        // so we need to track this event manually
        && typeof prevProps.currentDisclaimerLogoInForm !== 'undefined'
      ) {
        let updatedDisclaimerLogo = {
          ...prevProps.currentDisclaimerLogoInForm,
          id: this.props.uploadedDisclaimerLogo.process.uuid,
          url: this.props.uploadedDisclaimerLogo.url,
          name: this.props.uploadedDisclaimerLogo.name,
        };

        this.props.change('disclaimerLogo', updatedDisclaimerLogo);
      }
    }
    if (!isEqual(prevProps.uploadedDisclaimerBackgroundLogo, this.props.uploadedDisclaimerBackgroundLogo)) {
      // we are checking only finished upload or delete events, not checking temporary conditions
      if ((this.props.uploadedDisclaimerBackgroundLogo.process.status === uploadTypes.UPLOAD_DONE
          || this.props.uploadedDisclaimerBackgroundLogo.process.status === '')
        // this is current value in redux form - dropzone is not observable by the form
        // so we need to track this event manually
        && typeof prevProps.currentDisclaimerBacgroundInForm !== 'undefined'
      ) {
        let updatedDisclaimerBackgroundImage = {
          ...prevProps.currentDisclaimerBacgroundInForm,
          id: this.props.uploadedDisclaimerBackgroundLogo.process.uuid,
          url: this.props.uploadedDisclaimerBackgroundLogo.url,
          name: this.props.uploadedDisclaimerBackgroundLogo.name,
        };

        this.props.change('disclaimerBackgroundImage', updatedDisclaimerBackgroundImage);
      }
    }
    if (
      !isEqual(prevProps.viewerCustomizationEnabled, this.props.viewerCustomizationEnabled) ||
      !isEqual(prevProps.createTopButton, this.props.createTopButton) ||
      !isEqual(prevProps.isViewerDisclaimerCustomizationEnabled, this.props.isViewerDisclaimerCustomizationEnabled)
    ) {
      this.props.change('roadshowViewerCustomizeEnabled', true);
    }
  }

  componentWillUnmount() {
    this.props.resetDealSettings();
  }

  handleIsPublicChange(event) {
    if (event.target.checked) {
      this.props.change('disclaimer', ROADSHOW_FORM_DISCLAIMER_RETAIL_PUBLIC);
      this.props.change('displayParticipantsListEnabled', false);
    }
  }

  handleTypeChange(type) {
    this.props.change('type', type);
    if (!RoadshowType.hasSkippingAhead(type)) {
      this.props.change('mediaSkippingAheadDisabled', false);
    }
  }

  handleModeratedChange(event) {
    if (event.target.checked) {
      this.props.change('displayParticipantsListEnabled', true);
    }
  }

  calculateAndSetLoginLimitsAvailability(props) {
    let rotatingEntryCodes = props.entryCodes || props.initialValues.entryCodes || [];
    const roadshowLoginLimitSet = !!props.roadshowLoginLimit;

    let entryCodeLoginLimitSet = false;
    for (let i = 0; i < rotatingEntryCodes.length; i++) {
      if (
        rotatingEntryCodes[i].deletedAt === null
        && rotatingEntryCodes[i].loginsMaxNumber !== null
        && !isEqual(rotatingEntryCodes[i].loginsMaxNumber, LOGIN_LIMIT_DEFAULT_OPTION)
      ) {
        entryCodeLoginLimitSet = true;
        break;
      }
    }
    if (!roadshowLoginLimitSet && !entryCodeLoginLimitSet) {
      this.setState({
        entryCodeLoginLimitSettingAvailable: true,
        roadshowLoginLimitSettingAvailable: true,
      });
    } else if (roadshowLoginLimitSet && !entryCodeLoginLimitSet) {
      this.setState({
        entryCodeLoginLimitSettingAvailable: false,
        roadshowLoginLimitSettingAvailable: true,
      });
    } else {
      this.setState({
        entryCodeLoginLimitSettingAvailable: true,
        roadshowLoginLimitSettingAvailable: false,
      });
    }
  }

  disabledEvercallTemplate() {
    this.setState({ isEvercallTemplateEnabled: false });
  }

  handleEvercallErrorChange() {
    if (this.props.hasEvercallError) {
      this.props.setEvercallError(false);
    }
  }

  handleGetEvercallData() {
    this.props.change('viewerCustomizationEnabled', true);
    this.setState({ isEvercallTemplatePending: true });

    const updateData = async () => {
      await this.props.getAdminEvercallSetting();
    };
    updateData()
      .then(() => {
        this.props.change('roadshowViewerCustomizeEnabled', true);
        this.setState({ isEvercallTemplateEnabled: true });
        this.setState({ isEvercallTemplatePending: false });
      });
  }

  handleActiveCustomiseViewer(isRoadshowViewerCustomizeEnabled) {
    if (!this.props.isRoadshowViewerCustomizeEnabled && !isRoadshowViewerCustomizeEnabled) {
      this.props.change('viewerCustomizationEnabled', true);
    }
  }

  isModeratedDisabled() {
    if (
      RoadshowType.isSlidesAndAudio(this.props.type) ||
      RoadshowType.isVideoOnly(this.props.type) ||
      RoadshowType.isSlidesAndVideo(this.props.type)
    ) {
      return this.props.isCuePointsAdded;
    }

    return false;
  }

  render() {
    let allEntryCodes = this.props.entryCodes || this.props.initialValues.entryCodes || [];
    let rotatingEntryCodes = allEntryCodes.filter(({ type }) => !EntryCodeType.isReview(type));
    const reviewEntryCode = this.props.initialValues.entryCodes?.filter(({
      type,
      deletedAt,
    }) => (
      EntryCodeType.isReview(type) && !deletedAt
    ));
    const {
      loginLimitValues,
      entryCodeLoginLimitSettingAvailable,
      roadshowLoginLimitSettingAvailable,
    } = this.state;
    const hasSkippingAhead = RoadshowType.hasSkippingAhead(this.props.type);
    const isModeratedDisabled = this.isModeratedDisabled();

    return (
      <form
        className={ formStyles.formWrp }
        onSubmit={ this.props.handleSubmit }
        autoComplete="off"
        data-test="dealSettingsForm"
      >
        <PanelGroup
          defaultActiveKey={ this.props.panelStartOpenState || [dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, 'roadshowMappingForm'] }
          dataTest="dealSettingsPanelGroup"
        >
          <Spinner
            isVisible={ this.props.isFetching }
            overlay
            isFixed
          />
          <Panel
            id="roadshowMappingForm"
            key="roadshowMappingForm"
            header="Roadshow Mapping"
            destroyOnToggle={ false }
            arrow={ panelGroupArrow(false, false) }
            arrowActive={ panelGroupArrow(false, true) }
          >
            <div className={ cardStyles.cardInnerHorizontal }>
              <RoadshowMappingForm
                industryList={ this.props.industryList }
                getIndustryList={ this.props.getIndustryList }
                absSectorList={ this.props.absSectorList }
                getAbsSectorList={ this.props.getAbsSectorList }
              />
            </div>
          </Panel>
          <Panel
            id={ dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME }
            key={ dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME }
            header="Deal Settings"
            destroyOnToggle={ false }
            disabled
            showArrow={ false }
          >
            <div className={ cardStyles.cardInnerHorizontal }>
              <Field
                label="Internal Notes (Finsight Support Only)"
                name="internalNotes"
                component={ Textarea }
                textareaClassName={ styles.internalNotesContainer }
                format={ (value) => decodeSymbols(value) }
                maxLength={ 1000 }
                dataTest="internalNotesDealSettingTextArea"
              />
              <Field
                label="Roadshow ID"
                name="id"
                disabled
                component={ Input }
                dataTest="dealSettingsRoadshowIdInput"
              />
              <Field
                label="Deal Name"
                name="name"
                component={ Input }
                format={ (value) => decodeSymbols(value) }
                asterisk
                dataTest="dealSettingsDealNameInput"
              />
              <FieldArray
                name="reviewEntryCode"
                reviewEntryCode={ reviewEntryCode }
                tableName="REVIEW CODE"
                component={ ReviewEntryCode }
              />
              <FieldArray
                name="rotatingEntryCodes"
                rotatingEntryCodes={ rotatingEntryCodes }
                tableName="ENTRY CODES"
                component={ RotatingEntryCodes }
                loginLimitValues={ loginLimitValues }
                loginLimitsAvailable={ entryCodeLoginLimitSettingAvailable }
                changeEntryCodeLoginLimit={ this.props.changeEntryCodeLoginLimit }
                sortEntryCodes={ this.props.sortEntryCodes }
                timeZone={ this.props.initialValues.timeZone }
              />
              <div>
                <div className={ styles.loginLimitsContainer }>
                  <Field
                    name="loginsMaxNumber"
                    label="ROADSHOW LOGIN LIMIT"
                    component={ (fieldProps) => (
                      <Select
                        label={ fieldProps.label }
                        name={ fieldProps.input.name }
                        value={ fieldProps.input.value ? fieldProps.input.value : loginLimitValues[0].value }
                        onChange={ fieldProps.input.onChange }
                        onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                        options={ loginLimitValues }
                        placeholder="Select"
                        disabled={ !roadshowLoginLimitSettingAvailable }
                        simpleValue
                        // @ts-ignore
                        asterisk
                        dataTest="loginsMaxNumberSelect"
                      />
                    ) }
                    placeholder={ this.props.toggleIsLoginsMaxNumber ? 'Set login #' : 'None' }
                    asterisk
                  />
                </div>
                <div className={ styles.timeLimitsInput }>
                  <Field
                    label="Minutes Per Login"
                    name="loginsTimeLimit"
                    component={ Input }
                    disabled={ !this.props.roadshowLoginLimit }
                    dataTest="dealSettingsLoginsTimeLimitInput"
                  />
                </div>
              </div>

              <div className={ styles.typeAndModerated }>
                <Label>Roadshow Type</Label>
                <div className={ styles.typeAndModeratedLine }>
                  <Field
                    name="type"
                    label="Roadshow Type"
                    component={ (fieldProps) => (
                      <Select
                        selectClassName="form-select"
                        formFieldClassName={ styles.typeFormField }
                        name={ fieldProps.input.name }
                        value={ fieldProps.input.value }
                        onChange={ this.handleTypeChange }
                        simpleValue
                        onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                        options={ roadshowTypesOptions }
                        disabled={
                          RoadshowType.isVideoOnly(this.props.type) && !this.props.canBeChangedVideoType
                        }
                        placeholder="Select"
                        // @ts-ignore
                        asterisk
                        dataTest="condorRoadshowTypeSelect"
                      />
                    ) }
                  />
                  <Tooltip
                    disabled={ !isModeratedDisabled }
                    content="Moderation cannot be activated for this Roadshow as audio/video cues have been applied."
                  >
                    <Field
                      name="moderated"
                      dataTest="moderatedCheckbox"
                      label="Moderated"
                      component={ CheckboxInput }
                      type="checkbox"
                      onChange={ this.handleModeratedChange }
                      disabled={ isModeratedDisabled }
                    />
                  </Tooltip>
                </div>
              </div>

              <Field
                name="displayParticipantsListEnabled"
                dataTest="displayParticipantsListEnabledCheckbox"
                label={ (
                  <>
                    <span>Display Participant List on Viewer for all Deal Managers</span>
                    <span className={ styles.controlFormCheckboxTooltipIcon }>
                      <Tooltip content='This feature allows Deal Managers to "boot"
                        or kick-off active participants who are viewing the
                        roadshow. Note: Investors may re-enter the
                        roadshow if their entry code is still valid.'
                      >
                        <IconQuestion />
                      </Tooltip>
                    </span>
                  </>
                ) }
                component={ CheckboxInput }
                disabled={ this.props.isPublic }
                type="checkbox"
                className={ styles.checkboxGroupItem }
              />

              { hasSkippingAhead && (
                <Field
                  name="mediaSkippingAheadDisabled"
                  dataTest="mediaSkippingAheadDisabledCheckbox"
                  label={ (
                    <>
                      <span>Disable Navigation</span>
                      <span className={ styles.controlFormCheckboxTooltipIcon }>
                        <Tooltip
                          className={ styles.disableNavigationTooltip }
                          content={ (
                            <>
                              When navigation is disabled, users will not be able to:
                              <ul>
                                <li>Input a slide number to jump to a specific slide</li>
                                <li>Advance slides or scrub forward on an audio or video roadshow</li>
                                <li>Change audio or video playback speed</li>
                                <li>Skip forward or backward in an audio or video</li>
                                <li>Un-sync from a moderator</li>
                              </ul>
                            </>
                          ) }
                        >
                          <IconQuestion />
                        </Tooltip>
                      </span>
                    </>
                  ) }
                  component={ CheckboxInput }
                  type="checkbox"
                  className={ styles.checkboxGroupItem }
                />
              ) }

              <Field
                name="hideAudioDialIns"
                dataTest="hideAudioDialInsCheckbox"
                label={ (
                  <>
                    <span>Hide Audio Dial-Ins</span>
                    <span className={ styles.controlFormCheckboxTooltipIcon }>
                      <Tooltip
                        content="Hide audio dial-ins in the Roadshow to prevent investors from attempting to listen to the Roadshow media telephonically."
                      >
                        <IconQuestion />
                      </Tooltip>
                    </span>
                  </>
                ) }
                component={ CheckboxInput }
                type="checkbox"
                className={ styles.checkboxGroupItem }
              />

              <div className={ formStyles.formLabel }>
                Transaction Type
              </div>
              <Field
                name="transactionTypeId"
                component={ TransactionTypeContainer }
                placeholder="Select Transaction Type"
                disabled={ this.props.isTransactionTypeLocked }
                simpleValue
                asterisk
              />

              <FieldArray
                name="activeDates"
                component={ ActiveDates }
                timeZone={ this.props.initialValues.timeZone }
              />
              <br />
              <FormField>
                <Field
                  label="Disclaimer"
                  name="disclaimer"
                  component={ Textarea }
                  format={ (value) => decodeSymbols(value) }
                  dataTest="disclaimerDealSettingTextArea"
                />
              </FormField>
              <Field
                name="public"
                dataTest="publicCheckbox"
                label="Is Public:"
                component={ CheckboxInput }
                disabled={ EntryCodeType.isRotating(this.props.initialValues.appliedEntryCodeType) }
                type="checkbox"
                onChange={ this.handleIsPublicChange }
                className={ formStyles.formCheckbox }
              />
              { this.props.isPublic && EntryCodeType.isSingle(this.props.initialValues.appliedEntryCodeType) && (
                <FieldArray
                  name="retailTable"
                  component={ RetailTable }
                  timeZone={ this.props.initialValues.timeZone.timeZone }
                />
              ) }
              <Field
                name="cdnTuple"
                label={ (
                  <>
                    <span>CDN Order List</span>
                    <span className={ formStyles.formLabelIcon }>
                      <Tooltip
                        placement="top"
                        content="NOTE: This setting will change the asset load source order for this Roadshow. This change affects ALL investors."
                      >
                        <IconQuestion />
                      </Tooltip>
                    </span>
                  </>
                ) }
                component={ (fieldProps) => (
                  <Select
                    selectClassName="form-select"
                    label={ fieldProps.label }
                    name={ fieldProps.input.name }
                    value={ fieldProps.input.value }
                    onChange={ fieldProps.input.onChange }
                    onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                    options={ this.props.cdnList }
                    placeholder="Search"
                    simpleValue
                    dataTest="cdnOrderListSelect"
                  />
                ) }
              />
              <div className={ styles.customRoadshowContainer }>
                <div className={ styles.fieldsWrapper }>
                  <Field
                    name="roadshowViewerCustomizeEnabled"
                    dataTest="roadshowViewerCustomizeEnabled"
                    label="Customize Roadshow"
                    component={ CheckboxInput }
                    type="checkbox"
                    isNarrow
                    onClick={ () => this.handleActiveCustomiseViewer(this.props.isRoadshowViewerCustomizeEnabled) }
                  />
                  { this.props.isRoadshowViewerCustomizeEnabled && (
                    <ViewerSettings
                      topButtonType={ this.props.topButtonType }
                      createTopButton={ this.props.createTopButton }
                      isViewerDisclaimerCustomizationEnabled={ this.props.isViewerDisclaimerCustomizationEnabled }
                      preSetColors={ this.props.preSetColors }
                      viewerCustomizationEnabled={ this.props.viewerCustomizationEnabled }
                      isEvercallTemplateEnabled={ this.state.isEvercallTemplateEnabled }
                      viewerCustomSettings={ this.props.viewerCustomSettings }
                      viewerDisclaimerSettings={ this.props.viewerDisclaimerSettings }
                      change={ this.props.change }
                      disabledEvercallTemplate={ this.disabledEvercallTemplate }
                    />
                  ) }
                </div>
                { this.props.isRoadshowViewerCustomizeEnabled && (
                  <Button
                    variant={ ButtonVariantType.action }
                    className={ cn(
                      buttonStyles.btnIcon,
                      buttonStyles.btnIconRight,
                      buttonStyles.btnIconMobile,
                      spacesStyles.mrn,
                      styles.applyEvercall,
                    ) }
                    onClick={ this.handleGetEvercallData }
                    dataTest="applyEvercallBranding"
                  >
                    { this.state.isEvercallTemplatePending ?
                      <Spinner size="small" mode="light" /> :
                      'Apply Evercall Branding' }
                  </Button>
                ) }
              </div>
            </div>
          </Panel>
        </PanelGroup>
        <Button
          type="submit"
          variant={ ButtonVariantType.success }
          disabled={ this.props.isFetching || this.props.pristine }
          title="Save"
          dataTest="dealSettingFormSaveButton"
        />
        <Button
          variant={ ButtonVariantType.secondary }
          disabled={ this.props.isFetching }
          onClick={ this.props.reset }
          title="Cancel"
          dataTest="dealSettingFormCancelButton"
        />
      </form>
    );
  }
}

export default reduxForm({
  form: dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME,
  enableReinitialize: true,
  validate,
  onSubmitFail: (validateError) => {
    if (validateError) {
      let key = Object.keys(validateError)
        .reduce((k, l) => (
          (document.getElementsByName(k)[0]?.offsetTop < document.getElementsByName(l)[0]?.offsetTop)
            ? k
            : l
        ));
      document.getElementById(CONTENT_WRP_ID)
        .scrollTo(0, document.getElementsByName(key)[0]?.offsetTop - 100);
    }
  },
})(DealSettings);
