import identity from 'lodash/identity';
import Input from '@dealroadshow/uikit/core/components/Input';
import { addDelimiters } from '@/Framework/dataHelpers/convertTypes';

interface IProps {
  className?: string,
  name: string,
  dataTest: string,
  label?: string,
  placeholder?: string,
  fieldComponent: React.ComponentType<any>,
  isDisabled?: boolean,
  formFieldClassName?: string,
  isErrorMessage?: boolean,
}

export const normalizeDealSizeValue = (value) => {
  // TODO: use convertNumberWithCurrencyToNumber from ui/shared/helpers/convertTypes.ts
  if (value && /^([$£€¥₡₩₭₦₮₽฿₴]{0,1})[0-9,]{0,19}([$£€¥₡₩₭₦₮₽฿₴]{0,1})$/i.test(value)) {
    return addDelimiters(value);
  }
  return value;
};

const DealSize = ({
  className,
  name = 'dealSize',
  dataTest = 'dealSize',
  label = 'Deal Size (Actual)',
  placeholder = '10,000,000',
  fieldComponent: Field,
  isDisabled,
  formFieldClassName,
  isErrorMessage = true,
}: IProps) => {
  const handleOnBlur = (input, event) => {
    input.onBlur(event);
    const value = typeof event === 'string' ? event : event.target?.value;
    input.onChange(normalizeDealSizeValue(value || ''));
  };

  return (
    <div className={ className }>
      <Field
        name={ name }
        label={ label }
        placeholder={ placeholder }
        // See https://github.com/final-form/react-final-form/issues/130
        parse={ identity }
        dataTest={ dataTest }
        disabled={ isDisabled }
      >
        { ({ input, ...props }) => (
          // @ts-ignore
          <Input
            { ...props }
            formFieldClassName={ formFieldClassName }
            name={ input.name }
            isErrorMessage={ isErrorMessage }
            input={ {
              ...input,
              onBlur: handleOnBlur.bind(null, input),
              value: normalizeDealSizeValue(input.value),
            } }
          />
        ) }
      </Field>
    </div>
  );
};

export default DealSize;
