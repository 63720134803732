import React from 'react';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { getDealRoute } from '@/finsight/Router/RouteWithRedirect/getNewRoute';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

export const getBondNameCell = ({ row }) => {
  let { name } = row;
  let tooltip = '';

  if (row.deal && row.deal.viewId) {
    tooltip = `${ row.deal.primaryIssuer.name } ${ row.deal.seriesName }`;

    return (
      <Tooltip
        placement="bottom"
        content={ tooltip }
      >
        <a
          href={ `${ finsightWebUrl.getUrl() }${ getDealRoute(row.deal) }` }
          target="_blank"
        >
          { name }
        </a>
      </Tooltip>
    );
  }

  return name;
};

export const getDeleteCell = () => (<IconTrash />);
