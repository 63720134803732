import {
  ICategory,
  ISaveCategoryItem,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';

export const getSaveCategoriesPayload = (categoriesList: ICategory[] = []): ISaveCategoryItem[] => (
  categoriesList.map(({ isNew, id, ...category }) => ({
    ...category,
  ...(isNew ? {} : { id }),
  }),
));
