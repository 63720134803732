import * as constants from '@/Framework/UI/Organisms/Upload/constants';
import IconAudio from '@dealroadshow/uikit/core/components/Icon/IconAudio';
import IconVideo from '@dealroadshow/uikit/core/components/Icon/IconVideo';

const dropZoneTypeIcons = {
  [constants.UPLOAD_PRESENTATION_MEDIA_AUDIO_TEXT_NAME]: IconAudio,
  [constants.UPLOAD_PRESENTATION_MEDIA_VIDEO_TEXT_NAME]: IconVideo,
};

export const getDropZoneTypeIcon = (name: string) => dropZoneTypeIcons[name] || '';
