import React from 'react';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import styles from './bulkDeleteIcon.scss';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

interface IProps {
  bulkDelete: (ids: string[]) => void,
  bulkDeleteItems: any[],
}

const BulkDeleteIcon = ({
  bulkDeleteItems,
  bulkDelete,
}: IProps) => {
  if (!bulkDeleteItems.length) return null;

  const disableBulkDelete = bulkDeleteItems.some((item) => !item.canBeDeleted);

  const onClick = () => bulkDelete(bulkDeleteItems.map((item) => item.id));

  return (
    <Tooltip content={ disableBulkDelete ? 'One or more of the selected Accounts must be re-mapped before Deleting' : 'Delete' }>
      <IconTrash
        className={ styles.bulkDeleteIcon }
        isDisabled={ disableBulkDelete }
        onClick={ onClick }
      />
    </Tooltip>
  );
};

export default BulkDeleteIcon;
