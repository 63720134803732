import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSearchChange } from '@/condor/application/actions/finsight/import/bond/actions';
import SearchInput from '@/Framework/UI/Organisms/FinalForm/Fields/SearchInput';
import { SEARCH_PLACEHOLDER } from '@/finsight/ui/components/bonds/constants';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import IconSearch from '@dealroadshow/uikit/core/components/Icon/IconSearch';

const mapStateToProps = (state) => ({
  placeholder: SEARCH_PLACEHOLDER,
  icon: IconSearch,
  value: metadataSelectors.getSearch(state),
  name: 'bondSearch',
  isNarrow: true,
});

const mapDispatchToProps = {
  onChange: onSearchChange,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput));
