import { useEffect } from 'react';

import UploadInputsStatusContextProvider, {
  useUploadInputsStatusContext,
} from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';
import UploadAttachmentsComponent from '@/condor/ui/components/Finsight/CreditFlowResearch/common/UploadAttachments';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';

const UploadAttachments = (props: IFinalFormFieldArrayInputComponentProps<IAttachment>) => {
  const { setIsAttachmentsUploaded } = useArticleProfileContext();
  const { isAllUploaded } = useUploadInputsStatusContext();

  useEffect(() => {
    setIsAttachmentsUploaded(isAllUploaded);
  }, [isAllUploaded]);

  return (
    <UploadAttachmentsComponent
      title="Attachments"
      { ...props }
    />
  );
};

export default (props: IFinalFormFieldArrayInputComponentProps<IAttachment>) => (
  <UploadInputsStatusContextProvider>
    <UploadAttachments { ...props } />
  </UploadInputsStatusContextProvider>
);
