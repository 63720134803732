import React from 'react';
import ExpandCell from '@dealroadshow/uikit/core/components/Table/ExpandableTable/ExpandCell';
import { Label } from '@dealroadshow/uikit/core/components/Badge/Label';
import { CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import Link from '@/Framework/Router/ReactRouter/Link';

import styles from './styles.scss';

const columns = [
  {
    name: 'abbreviation',
    width: 57,
  },
  {
    name: 'sector',
    width: 58,
    cellCallback: ({
      row: {
        color,
        sector,
      },
    }) => sector && (
      <Label
        className={ styles.label }
        backgroundColor={ color }
      >
        { sector }
      </Label>
    ),
  },
  {
    name: 'issuer',
    cellCallback: (
      {
        row: {
          issuer,
          seriesName,
          dealId,
        },
      },
    ) => (
      <Link to={ `${ CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/${ dealId }` }>
        { `${ issuer.name }${ issuer.issuerTicker ? ` (${ issuer.issuerTicker }) ` : '' } ${ seriesName }` }
      </Link>
    ),
  },
  {
    name: 'expand',
    width: 38,
    cellCallback: (cellProps) => <ExpandCell isExpanded={ cellProps.isExpanded } />,
  },
];

export default columns;
