import React from 'react';
import LoginAt from '@/condor/ui/common/Date/LoginAt';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/CellProps';
import { commonCell } from '../../../helpers';
import { agingHeadCallback, nameCellCallback } from './callbacks';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const columns = [
  {
    name: 'contactFirstName',
    title: 'Name',
    cellCallback: nameCellCallback,
    sortable: true,
  },
  {
    name: 'contactTitleName',
    title: 'Title',
    cellCallback: commonCell,
    sortable: true,
  },
  {
    name: 'accountName',
    title: 'Account',
    cellCallback: commonCell,
    sortable: true,
  },
  {
    name: 'aging',
    width: 110,
    title: 'Aging',
    sortable: true,
    className: alignStyles.alignRight,
    cellCallback: commonCell,
    headCallback: agingHeadCallback,
  },
  {
    name: 'contactUniqueLoginCount',
    width: 110,
    title: 'Logins',
    className: alignStyles.alignRight,
    cellCallback: commonCell,
    sortable: true,
  },
  {
    name: 'lastLoginAt',
    title: 'Last Login (ET)',
    width: 150,
    minWidth: 150,
    className: alignStyles.alignRight,
    cellCallback: ({ row: { lastLoginAt } }: ICellProps) => <LoginAt date={ lastLoginAt } />,
    sortable: true,
  },
];

export default columns;
