import { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';
import { GroupBase } from 'react-select';
import GroupSelectWithToolbar, { IGroupSelectWithToolbarProps } from './GroupSelectWithToolbar';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';

type TGroupSelectWithFiltersFieldProps<
  SOption extends ISelectOption,
  Group extends GroupBase<SOption>,
> = IGroupSelectWithToolbarProps<SOption, Group> & IFinalFormFieldInputComponentProps<SOption>;

const GroupSelectWithToolbarField = <
  SOption extends ISelectOption,
  Group extends GroupBase<SOption>,
>({
  input,
  ...otherProps
}: TGroupSelectWithFiltersFieldProps<SOption, Group>) => (
  <GroupSelectWithToolbar<SOption, Group>
    { ...otherProps }
    name={ input.name }
    value={ input.value }
    onBlur={ input.onBlur }
    onChange={ input.onChange }
  />
);

export default GroupSelectWithToolbarField;
