import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';

import withTrancheCell from '@/condor/ui/components/Finsight/DealProfile/helpers/withTrancheCell';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';
import IssuerSelect from '@/condor/ui/components/Finsight/DealProfile/DealDetails/IssuerSelect/IssuerSelectContainer';

import { SelectField } from './SelectField';
import { RadioGroupField } from './RadioGroupField';
import { TextAreaField } from './TextAreaField';
import { TextInputField } from './TextInputField';
import { CompanySelect } from './CompanySelect';
import { AutoCalculatedField } from './AutoCalculatedField';

const Checkbox = withTrancheCell(CheckboxInput);
const DatePicker = withTrancheCell(CondorDatePicker);
const TrancheIssuerSelect = withTrancheCell(IssuerSelect);
const TrancheCompanySelect = withTrancheCell(CompanySelect);

const FIELD_COMPONENTS = {
  boolean: Checkbox,
  date: DatePicker,
  radio: RadioGroupField,
  select: SelectField,
  textarea: TextAreaField,
  text: TextInputField,
  issuerSelect: TrancheIssuerSelect,
  companySelect: TrancheCompanySelect,
  autoCalculated: AutoCalculatedField,
};

export default FIELD_COMPONENTS;
