import React from 'react';
import cn from 'classnames';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';

import styles from './status.scss';

interface IProps {
  status: {
    className: string,
    tooltipContent?: string,
  },
  className?: string,
  circleClassName?: string,
}

const Status = (props: IProps) => {
  const statusCircle = (
    <span className={ cn(styles.statusCircle, props.circleClassName, props.status.className) } />
  );

  return (
    <div className={ props.className }>
      { !props.status.tooltipContent
        ? statusCircle
        : (
          <Tooltip content={ props.status.tooltipContent }>{ statusCircle }</Tooltip>
        ) }
    </div>
  );
};

export default Status;
