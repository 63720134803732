import { useEffect } from 'react';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import SharedUploadImageInput, { TUploadPreviewFormValue } from '@/Framework/UI/Organisms/Upload/components/UploadImageInput';
import { useDIContext } from '@/Framework/DI/DIContext';
import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import { BYTES_IN_TWO_MEGABYTES, UPLOAD_LOGO_ALLOWED_FILES_TEXT } from '@/Framework/UI/Organisms/Upload/constants';
import UploadInputsStatusContextProvider, {
  useUploadInputsStatusContext,
} from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';

interface IUploadPreviewFieldProps extends IFinalFormFieldInputComponentProps<TUploadPreviewFormValue> {
  label: string,
  dataTest: string,
}

const UploadPreview = ({ dataTest, ...restProps }: IUploadPreviewFieldProps) => {
  const { container } = useDIContext();
  const importRepository = container.get<ImportRepository>(ImportRepository);
  const { setIsPreviewUploaded, onProcessingDone } = useStatProfileContext();
  const { isAllUploaded } = useUploadInputsStatusContext();

  useEffect(() => {
    setIsPreviewUploaded(isAllUploaded);
  }, [isAllUploaded]);

  return (
    <SharedUploadImageInput
      uploadMethod={ importRepository.attachmentUpload }
      maxSize={ BYTES_IN_TWO_MEGABYTES }
      allowedFileText={ UPLOAD_LOGO_ALLOWED_FILES_TEXT }
      maxSizeUnits="MB"
      showMaxSizeInfo
      maxSizeInfo="The max file size supported is 2 megabytes."
      shouldUrlStartWithSlash={ false }
      errorClassName="upload_error"
      dataTest={ dataTest }
      onProcessingDone={ onProcessingDone }
      urlHelper={ {
        getFileUrl: (url: string) => url,
      } }
      { ...restProps }
    />
  );
};

export default (props: IUploadPreviewFieldProps) => (
  <UploadInputsStatusContextProvider>
    <UploadPreview { ...props } />
  </UploadInputsStatusContextProvider>
);
