import cn from 'classnames';
import Input from '@dealroadshow/uikit/core/components/Input';
import { Field, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import TextEditorField from '@/Framework/UI/Organisms/FinalForm/Fields/TextEditorField';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';
import StatProfileButtons from './StatProfileButtons';
import CategoriesSelect from './CategoriesSelect';
import UploadAttachment from './UploadAttachment';
import UploadPreview from './UploadPreview';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './styles.scss';

const StatProfileFields = () => {
  const { setIsAttachmentUploaded, setIsPreviewUploaded } = useStatProfileContext();

  const onUploadAttachmentStart = () => setIsAttachmentUploaded(false);
  const onUploadPreviewStart = () => setIsPreviewUploaded(false);
  return (
    <>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbxl) }>Details</div>
      <Field
        component={ Input }
        name="name"
        label="Name"
        dataTest="name"
        placeholder="Name"
      />
      <Field
        name="categories"
        component={ CategoriesSelect }
      />
      <Field
        name="description"
        component={ TextEditorField }
        label="Description"
        dataTest="description"
        placeholder="Description"
        maxCharactersLimit={ 75000 }
        isCharLimit={ false }
      />
      <div data-test="attachments" className={ styles.attachmentsFieldContainer }>
        <FieldArray
          name="attachments"
          onUploadStart={ onUploadAttachmentStart }
          component={ UploadAttachment }
        />
      </div>
      <Field
        label="Preview"
        name="preview"
        uploadName="preview"
        onUploadStart={ onUploadPreviewStart }
        component={ UploadPreview }
        dataTest="preview"
      />
      <StatProfileButtons />
    </>
  );
};

export default StatProfileFields;
