import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import LastTableCell from '@/Framework/UI/Molecules/Tables/TableWrapper/LastTableCell';
import { IAdminCallListItem } from '@/evercall/domain/vo/call/admin/AdminCallListItem';
import { useCallsCollectionContext } from '@/evercall/application/condor/call/collection/CallsCollectionContext';
import IconUnlock from '@dealroadshow/uikit/core/components/Icon/IconUnlock';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconNotDisplay from '@dealroadshow/uikit/core/components/Icon/IconNotDisplay';
import IconDisplay from '@dealroadshow/uikit/core/components/Icon/IconDisplay';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';

interface IProps {
  row: IAdminCallListItem,
  cellData: string,
}

const CallDateCell = (
  {
    cellData,
    row,
  }: IProps,
) => {
  const {
    toggleCallIsDeleted,
    toggleCallIsHidden,
    toggleCallIsSupported,
  } = useCallsCollectionContext();
  return (
    <LastTableCell
      childrenToShowOnHover={
        (
          <TableRowActions
            actions={
              [
                {
                  tooltipContent: row.isSupported
                    ? 'Remove Support as Deal Manager'
                    : 'Add Support as Deal Manager',
                  onClick: () => toggleCallIsSupported({ id: row.id }),
                  icon: row.isSupported ? IconUnlock : IconLock,
                },
                {
                  tooltipContent: row.isHidden ? 'Move to Active Filter' : 'Move to Hidden Filter',
                  onClick: () => toggleCallIsHidden({ id: row.id }),
                  icon: row.isHidden ? IconNotDisplay : IconDisplay,
                },
                {
                  tooltipContent: row.isDeleted ? 'Restore Evercall' : 'Delete Evercall',
                  onClick: () => toggleCallIsDeleted({ id: row.id }),
                  icon: row.isDeleted ? IconRefresh : IconTrash,
                },
              ]
            }
          />
        )
      }
    >
      { cellData }
    </LastTableCell>
  );
};

export default CallDateCell;
