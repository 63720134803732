import UpdatedAtCell from './UpdatedAtCell';
import { Link } from '@/Framework/Router/Next/Link';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import finsightUrl from '@/finsight/Router/urlConstants';

import styles from './styles.scss';

const getStaUrl = (viewId: string) => (finsightWebUrl.getUrl(`${ finsightUrl.CREDIT_FLOW_STAT }-${ viewId }`));

export const nameCellCallback = ({ cellData, row }) => {
  if (!row.isActive) {
    return (
      <span className={ styles.name }>{ cellData || '-' }</span>
    );
  }
  return (
    <Link
      to={ getStaUrl(row.viewId) }
      className={ styles.name }
      target="_blank"
    >
      { cellData || '-' }
    </Link>
  );
};

export const updatedAtCellCallback = (props) => (
  <UpdatedAtCell { ...props } />
);
