import { connect } from 'react-redux';
import {
  getManagerAnalyticsContactsByFilter,
  sort,
} from '@/condor/application/actions/finsightManager/analytics/contactsCollection/actions';
import * as selectors from '@/condor/application/actions/finsightManager/analytics/contactsCollection/selectors';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import { BI_MODULE } from '@/condor/ui/components/FinsightManager/constants';

const mapStateToProps = (state) => (
  {
    isFetching: selectors.isFetching(state),
    data: selectors.getCollectionData(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    dataTest: 'analyticsBiTable',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({
        sortBy,
        sortOrder,
      }));
      dispatch(getManagerAnalyticsContactsByFilter(BI_MODULE));
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataTable);
