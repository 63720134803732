import { useParams } from 'react-router';
import StatProfile from '../StatProfile';

const EditStatProfile = () => {
  const statId = useParams().statId as string;

  return <StatProfile statId={ statId } />;
};

export default EditStatProfile;
