import { useHistory } from 'react-router';
import Tabs, { Tab, TabList } from '@dealroadshow/uikit/core/components/Tabs';
import { TABS_LIST } from './constants';
import { useOpenMarketListContext } from './OpenMarketListContext';

import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import styles from './openMarket.scss';

const OpenMarketTabsList = () => {
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { counters } = useOpenMarketListContext();

  const onClickTab = (path: string) => {
    if (!pathname.includes(path)) {
      push(path);
    }
  };

  const defaultTab = TABS_LIST.find(({ path }) => pathname.includes(path))?.key || TABS_LIST[0].key;

  return (
    <Tabs defaultTab={ defaultTab } dataTest="tabs" className={ styles.tabs }>
      <TabList dataTest="tabList">
        { TABS_LIST.map((tab) => (
          <Tab
            key={ tab.key }
            tabFor={ tab.key }
            className={ tabsStyles.tab }
            activeClassName={ tabsStyles.isTabActive }
            disabledClassName={ tabsStyles.isTabDisabled }
            disabled={ !counters[tab.key] && !pathname.includes(tab.path) }
            onClick={ () => onClickTab(tab.path) }
            name={ tab.title }
            counter={ counters[tab.key] }
          >
            <span className={ styles.tabTitle }>{ tab.title }</span>
          </Tab>
        )) }
      </TabList>
    </Tabs>
  );
};

export default OpenMarketTabsList;
