import React, { memo } from 'react';
import cn from 'classnames';
import Input from '@dealroadshow/uikit/core/components/Input';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';

import styles from './blacklist.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import { buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import IconPlusBold from '@dealroadshow/uikit/core/components/Icon/IconPlusBold';

const renderError = (e: string, i: number) => (
  <div key={ i }>{ e }</div>
);

interface IProps {
  onInputChange: () => void,
  onInputFocus: () => void,
  onInputBlur: () => void,
  onAddClick: () => void,
  inputValue: string,
  errors: any[],
  isAdding: boolean,
  isFocused: boolean,
}

const AddItemsInput = (props: IProps) => {
  const isButtonDisabled = !props.inputValue || props.isAdding;
  return (
    <div className={ styles.addItemInputWrapper }>
      <div className={ styles.addItemInputContainer }>
        { /* @ts-ignore */ }
        <Input
          disabled={ props.isAdding }
          inputClassName={ styles.addItemInput }
          isNarrow
          placeholder="Enter domain. Separate multiple by comma. (eg. abc.com, xyz.com)"
          value={ props.inputValue }
          onChange={ props.onInputChange }
          onFocus={ props.onInputFocus }
          onBlur={ props.onInputBlur }
          dataTest="blackListInput"
          meta={
            !props.errors.length
              ? {}
              : {
                touched: true,
                error: props.errors.length ? props.errors.map(renderError) : false,
              }
          }
          onKeyPress={ (e) => {
            // @ts-ignore
            if ((isEnterKey(e)) && props.isFocused && !isButtonDisabled) {
              // @ts-ignore
              e.target.blur();
              props.onAddClick();
            }
          } }
        />
      </div>
      <button
        type="button"
        disabled={ isButtonDisabled }
        className={ cn(
          buttonStyles.action,
          buttonStyles.btnIcon,
          spacesStyles.prm,
          spacesStyles.plm,
          spacesStyles.mrn,
          spacesStyles.mlm,
          styles.plusBtn,
        ) }
        onClick={ props.onAddClick }
        data-test="condorBlacklistPlusButton"
      >
        <IconPlusBold />
      </button>
    </div>
  );
};

export default memo(AddItemsInput);
