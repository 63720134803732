import { CallStatus } from '@/evercall/domain/vo/call/CallStatus';
import { CallEventType } from '@/evercall/domain/vo/call/CallEventType';
import { CallReplayMethod } from '@/evercall/domain/vo/call/CallReplayMethod';

class CallEvent {
  static isExpired = (callStatus: CallStatus) => (callStatus === CallStatus.Expired);

  static isPending = (callStatus: CallStatus) => (callStatus === CallStatus.Pending);

  static isLive = (callStatus: CallStatus) => (callStatus === CallStatus.Live);

  static isOacc = (callType: CallEventType) => (callType === CallEventType.Oacc);

  static isWebinar = (callType: CallEventType) => (callType === CallEventType.Webinar);

  static isMeeting = (callType: CallEventType) => (callType === CallEventType.Meeting);

  static isReplayViaUrl = (replayMethod: CallReplayMethod) => (replayMethod === CallReplayMethod.Url);
}

export default CallEvent;
