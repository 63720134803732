export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';
export const UPLOAD_INIT = 'UPLOAD_INIT';
export const UPLOAD_ADD = 'UPLOAD_ADD';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_CHUNK_SUCCESS = 'UPLOAD_CHUNK_SUCCESS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_PROCESSING = 'UPLOAD_PROCESSING';
export const UPLOAD_DONE = 'UPLOAD_DONE';
export const UPLOAD_CANCEL_SUCCESS = 'UPLOAD_CANCEL_SUCCESS';
export const UPLOAD_ORDER = 'UPLOAD_ORDER';
