import React, { createContext, useContext } from 'react';
import { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/SortOrder';
import CondorRepository from '@/dataroom/infrastructure/repository/CondorRepository';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { IDataroomListItem } from '@/dataroom/domain/vo/condor/IDataroomListItem';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';

const sortBy: string = 'createdAt';
const sortOrder: SortOrder = 'desc';

const useDataroomCondorListing = () => {
  const { container } = useDIContext();

  const condorRepository = container.get<CondorRepository>(CondorRepository);

  return useFetchCollection<IDataroomListItem>(
    condorRepository.getList,
    null,
    {
      sortBy,
      sortOrder,
    },
    (error) => container.get(DataroomErrorHandler)
      .handleError(error),
  );
};

type DataroomCondorListingContextType = ReturnType<typeof useDataroomCondorListing>;

export const DataroomCondorListingContext = createContext<DataroomCondorListingContextType>(null);

export const useDataroomCondorListingContext = () => {
  const context = useContext(DataroomCondorListingContext);
  if (!context) {
    throw new Error('useDataroomCondorListingContext must be used within a DataroomCondorListingContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const DataroomCondorListingContextProvider = ({ children }: IProps) => (
  <DataroomCondorListingContext.Provider value={ useDataroomCondorListing() }>
    { children }
  </DataroomCondorListingContext.Provider>
);

export default DataroomCondorListingContextProvider;
