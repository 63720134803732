import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { useEffect, useMemo } from 'react';
import { IGroupCategoryData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IGroupCategory';
import { getOptionsForGroupSelectWithFilters } from '@/condor/application/Finsight/CreditFlowResearch/Articles/helpers';
import { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import { IOptionCategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { useStatsCategories } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsCategories';

const useCategories = () => {
  const { regions, products } = useRegionsProductsContext();
  const { getCategories, categories } = useStatsCategories<IGroupCategoryData>();

  useEffect(() => {
    getCategories(true);
  }, []);

  const categoriesOptions = useMemo<IOption<IOptionCategory>[]>(
    () => getOptionsForGroupSelectWithFilters(
      products,
      regions,
      categories,
      'displayName',
      'categories',
    ),
    [products, regions, categories],
  );

  return { categoriesOptions };
};

export default useCategories;
