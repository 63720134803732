import React from 'react';

import { Field, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Input from '@dealroadshow/uikit/core/components/Input';
import CustomSectionSubfieldsTable from './CustomSectionSubfieldsTable';
import { DetailsSection } from '@/evercall/domain/vo/DetailsSection';

import styles from './detailsSections.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IDetailsSection {
  id: DetailsSection,
  renderSection: () => React.ReactNode,
}

export const DETAILS_SECTIONS: IDetailsSection[] = [
  {
    id: DetailsSection.Event,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableEventDetailsSection"
          dataTest="isEnableEventDetailsSection"
          label="Enable Event Details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.Roadshow,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableRoadshowDetailsSection"
          dataTest="isEnableRoadshowDetailsSection"
          label="Enable Presentation Details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.DialIn,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field
          isNarrow
          name="isEnableDialInDetailsSection"
          dataTest="isEnableDialInDetailsSection"
          label="Enable Dial-in details section"
          component={ CheckboxInput }
          type="checkbox"
        />
      </div>
    ),
  },
  {
    id: DetailsSection.Custom,
    renderSection: () => (
      <div className={ styles.sectionContainer }>
        <Field name="isEnableCustomDetailsSection" type="checkbox">
          { (props) => {
            const {
              input: {
                checked: isEnableCustomDetailsSection,
              },
            } = props;

            return (
              <>
                <CheckboxInput
                  { ...props }
                  isNarrow
                  dataTest="isEnableCustomDetailsSection"
                  label="Enable Custom section"
                />
                { isEnableCustomDetailsSection && (
                  <>
                    <div className={ styles.customSectionContainer }>
                      <Field
                        component={ Input }
                        containerClassName={ formStyles.formInput }
                        name="customDetailsSectionName"
                        label="Section Name"
                        dataTest="customDetailsSectionNameInput"
                      />
                      <div className={ styles.provideDetailsLabel }>Provide Custom Section details</div>
                    </div>
                    <FieldArray name="customDetailsSectionSubfields" component={ CustomSectionSubfieldsTable } />
                  </>
                ) }
              </>
            );
          } }
        </Field>
      </div>
    ),
  },
];
