import React from 'react';
import isEqual from 'lodash/isEqual';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import {
  useManageSubscriptionFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ManageSubscriptionFormContext';
import { useForm } from '@/Framework/UI/Organisms/FinalForm';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';

interface IProps {
  onClose: () => void,
}

const Footer = ({ onClose }: IProps) => {
  const {
    isEmailFormVisible,
    setIsEmailFormVisible,
    isNotificationEnabled,
    setIsNotificationEnabled,
    isSubmitting,
  } = useManageSubscriptionFormContext();

  const form = useForm();
  const onSubmitClick = () => {
    if (isEmailFormVisible) {
      form.submit();
    } else {
      setIsEmailFormVisible(true);
    }
  };

  const onChangeNotification = (event) => {
    setIsNotificationEnabled(event.target.checked);
  };

  return (
    <div className={ alignStyles.flexSpaceBetween }>
      <div>
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          title="Confirm"
          dataTest="manageSubscriptionConfirmButton"
          onClick={ onSubmitClick }
          disabled={ isEqual(form.getState().initialValues, form.getState().values) || isSubmitting }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ onClose }
          title="Cancel"
          dataTest="manageSubscriptionCancelButton"
          disabled={ isSubmitting }
        />
      </div>
      { isEmailFormVisible && (
        <Checkbox
          dataTest="manageUserPlanRequestNotificationCheckbox"
          label="Send email notification"
          checked={ isNotificationEnabled }
          onChange={ onChangeNotification }
          disabled={ isSubmitting }
        />
      ) }
    </div>
  );
};

export default Footer;
