import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import SidebarRepository from '@/condor/infrastructure/repository/SidebarRepository';
import * as actionTypes from '@/condor/application/actions/sidebar/actionTypes';
import { NAVIGATION_ITEMS } from './constants';
import { SessionService } from '@/users/application/Session/SessionService';

export const getCondorNavigationItems = () => (dispatch, getState) => {
  const { container } = getState();
  const sidebarRepository = container.get(SidebarRepository);
  const sessionService = container.get(SessionService);
  return dispatch(createAsyncRequest(
    {
      method: sidebarRepository.getCondorNavigationItems,
      types: [
        actionTypes.GET_CONDOR_NAVIGATION_ITEMS,
        actionTypes.GET_CONDOR_NAVIGATION_ITEMS_SUCCESS,
        actionTypes.GET_CONDOR_NAVIGATION_ITEMS_FAILURE,
      ],
      callbacks: {
        onSuccess: ({ response }) => {
          if (response.length === 0) {
            sessionService.logout();
          }
        },
        responseHandler: (response) => response.map((data) => {
          NAVIGATION_ITEMS.forEach((item) => {
            if (item.id === data.id) {
              data.href = item.href;
              data.icon = item.icon;
            }
          });
          return data;
        }),
      },
    },
  ));
};
