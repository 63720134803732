import { useState, useEffect, memo, useCallback } from 'react';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';

import TogglingFilters from '@/finsight/ui/common/components/TogglingFilters/TogglingFilters';
import styles from './checkboxWithFilters.scss';

interface IProps {
  label: string,
  name: string,
  isDisabled: boolean,
  input: any,
  meta: any,
  filterSet: {
    key: string,
    items: any[],
  },
}

const CheckboxWithFilters = ({
  label,
  name,
  input,
  filterSet,
  isDisabled,
  meta,
}: IProps) => {
  const [memoizedFilterSet, setMemoizedFilterSet] = useState({
    items: {
      key: filterSet.key,
      items: filterSet.items || [],
    },
  });

  const onFiltersChangeHandler = useCallback((filterName, selectedId) => {
    const selectedFiltersIds = input.value?.filters || [];
    const filters = selectedFiltersIds.includes(selectedId)
      ? selectedFiltersIds.filter((filter) => filter !== selectedId)
      : [...selectedFiltersIds, selectedId];

    const isChecked = filters.length > 0;

    input.onChange({
      checked: isChecked,
      filters,
    });
  }, [input]);

  const onCheckboxChangeHandler = (val) => {
    const isChecked = val.target.checked;
    let filters = [];

    if (isChecked) {
      if (filterSet.items.length === 1) {
        const item = filterSet.items[0];
        filters = [item.subscriptionId || item.id];
      } else {
        filters = memoizedFilterSet.items.items.map((item) => item.id);
      }
    }

    input.onChange({
      checked: isChecked,
      filters,
    });
  };

  useEffect(() => {
    const { filters = [] } = input.value;
    const updatedFilterSet = {
      items: {
        key: filterSet.key,
        items: filterSet.items.map((item) => ({
          ...item,
          id: item.subscriptionId || item.id,
          abbreviation: item.subscriptionName || item.name,
          isActive: filters.includes(item.subscriptionId) || filters.includes(item.id),
        })),
      },
    };
    setMemoizedFilterSet(updatedFilterSet);
  }, [input.value]);

  return (
    <div className={ styles.wrapper }>
      <CheckboxInput
        label={ label }
        className={ styles.checkboxItem }
        input={ {
          name,
          checked: !!input.value?.checked,
          onChange: onCheckboxChangeHandler,
        } }
        dataTest={ `subscription form ${ label } checkbox` }
        disabled={ isDisabled }
      />

      <div>
        { filterSet.items.length > 1 && (
          <TogglingFilters
            isDisabled={ isDisabled }
            // @ts-ignore
            filterSet={ memoizedFilterSet }
            applyFilter={ onFiltersChangeHandler }
            isTooltipEnabled={ false }
          />
        ) }

        { meta.error && (
          <div className={ styles.errorMessage }>
            { meta.error }
          </div>
        ) }
      </div>
    </div>
  );
};

export default memo(CheckboxWithFilters);
