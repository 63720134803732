import { ReactNode } from 'react';
import { components as SelectComponents, MenuListProps } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { ISelectOption } from '@dealroadshow/uikit/core/components/Select/interfaces';

interface IMenuListProps<
  SOption extends ISelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<SOption>,
> {
  toolbarComponent?: () => ReactNode,
  ownProps: MenuListProps<SOption, IsMulti, Group>,
}

const MenuList = <SOption extends ISelectOption, IsMulti extends boolean, Group extends GroupBase<SOption>>({
  toolbarComponent,
  ownProps: {
    children,
    ...otherProps
  },
}: IMenuListProps<SOption, IsMulti, Group>) => (
  <>
    { toolbarComponent ? toolbarComponent() : null }
    <SelectComponents.MenuList { ...otherProps }>
      { children }
    </SelectComponents.MenuList>
  </>
);

export default MenuList;
