import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import { DEFAULT_EMPTY_MESSAGE } from '@/Framework/UI/Molecules/Tables/constants';
import {
  BodyWrapper,
  HeaderRow,
  HeaderWrapper,
  dataTableStyles,
} from '@dealroadshow/uikit/core/components/Table/DataTable';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import FormWrapper from '../FormWrapper';
import EditableTableRow from './EditableTableRow';

interface IProps {
  className: string,
  columns: any[],
  collection: any[],
  isCollectionFetching: boolean,
  sortBy: string,
  sortOrder: string,
  onSortChange: (sortBy: string, sortOrder: string) => void,
  onSubmit: (formData: any) => void,
  selectedItemId: string | number,
  isUpdating: boolean,
  formProps: any,
  editableRowComponent?: React.ElementType,
  dataTest: string,
}

const EditableDataTable = (props: IProps) => {
  const { editableRowComponent: EditableRow = EditableTableRow } = props;

  const tableRef = useRef(null);

  /**
   * @param {Object} row
   * @param {Number} i
   */
  const renderRow = useCallback(
    (row, i) => (
      <EditableRow
        key={ row.id }
        index={ i }
        row={ row }
        tableRef={ tableRef }
        columns={ props.columns }
        isSelected={ props.selectedItemId === row.id }
        isUpdating={ props.isUpdating }
      />
    ),
    [props.columns, props.selectedItemId, props.isUpdating],
  );

  /**
   * @param {Object} column
   */
  const columnsIsVisible = useCallback(
    (column) => column.isVisible || column.isVisible === undefined,
    [],
  );

  return (
    <div
      className={ cn(dataTableStyles.dataTableContainer, props.className) }
      ref={ tableRef }
    >
      <Spinner
        overlay
        isSpinnerFixed={ props.formProps.isSpinnerFixed }
        isVisible={ props.isCollectionFetching }
      />
      <FormWrapper
        buttonsHidden
        enableReinitialize
        onSubmit={ props.onSubmit }
        withAreYouSureModal={ false }
        dataTest={ props.formProps.form }
        { ...props.formProps }
      >
        <table
          className={ cn(dataTableStyles.dataTable, dataTableStyles.isHoverable) }
          data-test={ `${ props.dataTest }${ props.isCollectionFetching ? 'Fetching' : '' }` }
        >
          <HeaderWrapper>
            <HeaderRow
              sortBy={ props.sortBy }
              sortOrder={ props.sortOrder }
              onSortChange={ props.onSortChange }
              columns={ props.columns }
            />
          </HeaderWrapper>
          <BodyWrapper>
            { props.collection.length > 0
              ? props.collection.map(renderRow)
              : (
                <tr>
                  <td
                    colSpan={ props.columns.filter(columnsIsVisible).length }
                    className={ dataTableStyles.emptyMessage }
                  >
                    <div className={ dataTableStyles.emptyMessage }>
                      { DEFAULT_EMPTY_MESSAGE }
                    </div>
                  </td>
                </tr>
              ) }
          </BodyWrapper>
        </table>
      </FormWrapper>
    </div>
  );
};

export default EditableDataTable;
