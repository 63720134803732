import { ReactNode } from 'react';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';
import { UploadTable, IDropzoneProps } from '@/Framework/UI/Organisms/Upload/components';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import UploadAttachmentsContextProvider, {
  useUploadAttachmentsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/UploadAttachmentsContext';
import {
  UPLOAD_ATTACHMENTS_ALLOWED_FILES,
  UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT,
  UPLOAD_TABLE_COLUMNS,
  UPLOAD_TABLE_WIDTHS,
} from '@/Framework/UI/Organisms/Upload/constants';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IUploadAttachmentsFields extends IFinalFormFieldArrayInputComponentProps<IAttachment> {
  title: string,
  columnsConfig?: string[],
  widthsConfig?: { [name: string]: string },
  UploadDropzoneArea?: (props: IDropzoneProps) => ReactNode,
}

const UploadAttachments = ({
  fields,
  title,
  columnsConfig = UPLOAD_TABLE_COLUMNS,
  widthsConfig = UPLOAD_TABLE_WIDTHS,
  UploadDropzoneArea,
}: IUploadAttachmentsFields) => {
  const {
    files,
    uploadDocuments,
    cancelDocumentUpload,
    changeDocumentsOrder,
  } = useUploadAttachmentsContext();

  return (
    <>
      <label className={ formStyles.formLabel }>{ title }</label>
      <UploadTable
        fieldComponent={ Field }
        uploadFiles={ files }
        uploadList={ fields.value || [] }
        formFieldName="attachments"
        fields={ fields }
        columns={ columnsConfig }
        widths={ widthsConfig }
        allowedFileTypes={ UPLOAD_ATTACHMENTS_ALLOWED_FILES }
        allowedFileText={ UPLOAD_ATTACHMENTS_ALLOWED_FILES_TEXT }
        limit={ 20 }
        uploadName=""
        onUpload={ uploadDocuments }
        onUploadCancel={ cancelDocumentUpload }
        onUploadOrder={ changeDocumentsOrder }
        UploadDropzoneArea={ UploadDropzoneArea }
      />
    </>
  );
};

export default ({ onUploadStart, ...props }: IUploadAttachmentsFields) => (
  <UploadAttachmentsContextProvider onUploadStart={ onUploadStart }>
    <UploadAttachments { ...props } />
  </UploadAttachmentsContextProvider>
);
